import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    xColumnArr: {},
    listOpt: {
      "s_start_date": "",
      "s_end_date": "",
      "s_stu_bookmark": "",
    },
    list: (inData: any) => { },
    stu_list: (inData: any) => { },
    goDelete: (inData: any) => { },
    unselect_stu: () => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.list({ [name]: value, "now_page": 1 });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ [key]: date_str, "now_page": 1 });
  };

  const go_prev_after = (sort: "prev" | "after",period:"day"|"month") => {
    let selected_date = listOpt.s_start_date;
    if (strFunc.is_empty(selected_date)) {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_str = "";
    let change_end_date_str = "";
    if(period=="day"){
      if (sort == "prev") {
        change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), 'day', -1), "Y-m-d");
      } else if (sort == "after") {
        change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), 'day', +1), "Y-m-d");
      }
      change_end_date_str=change_date_str;
    }else if(period=="month"){
      let tmp_change_date_str=DateFunc.get_date_format(new Date(selected_date),"Y-m-01");
      if (sort == "prev") {
        change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_change_date_str), 'month', -1), "Y-m-01");
        change_end_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_change_date_str), 'month', -1), "Y-m-t");
      } else if (sort == "after") {
        change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_change_date_str), 'month', +1), "Y-m-01");
        change_end_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_change_date_str), 'month', +1), "Y-m-t");
      }
    }

    myProps.list({
      "s_start_date": change_date_str,
      "s_end_date": change_end_date_str,
      "now_page": 1
    });
  };

  const go_today_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "now_page": 1
    });
  };
  const go_now_month_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
      "now_page": 1
    });
  };

  return (
    <div className="relative">
      <div style={{ lineHeight: "20px" }}>
        <div className="search_date_picker_wrap ml-1">
          <DatePicker
            wrapperClassName=""
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div className="search_date_picker_wrap">
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-dark ml-1" onClick={() => { go_prev_after("prev","month"); }}>◀</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_prev_after("prev","day"); }}>◁</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_prev_after("after","day"); }}>▷</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_prev_after("after","month"); }}>▶</button>
        <button className="btn btn-gray ml-1" onClick={go_today_search} >오늘</button>
        <button className="btn btn-gray ml-1" onClick={go_now_month_search} >이번달</button>

        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            myProps.stu_list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn btn-gray ml-1" onClick={() => { myProps.list({ "now_page": 1 }); }} >검색</button>
      </div>
      <div className="search-box relative py-0 ml-0 mt-1">
        {
          listOpt.s_user_seq.length > 0 &&
          <span className="text-blue-500">
            {listOpt.s_user_seq.length}명
            <button className="btn-s btn-gray ml-1 px-1" onClick={() => {
              myProps.unselect_stu();
            }}>X</button>
          </span>
        }
        {/* <button className="btn btn-red ml-1" onClick={myProps.goDelete}>삭제</button> */}
      </div>
    </div>
  );
};
export default SearchArea;