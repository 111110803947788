import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";
import strFunc from '@/lib/lyg/string';

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    xColumnArr: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = props.listOpt;
  const [schoolNameSelectArr, setSchoolNameSelectArr] = useState<any>([]);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  let is_under_width = MyScreenFunc.get_is_under();

  useEffect(() => {
    getClassFrontArr();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      myProps.setListOpt({ 
        ...listOpt,
        [name]: value
      });
      return false;
    }
    props.list({ [name]: value });
  };

  const goSearch = () => {
    props.list({ ...listOpt });
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr([
          {"text":"클래스 전체","value":""},
          ...response.data["data"]["class_arr"]
        ]);
      }else{
        
      }
    });
  };
  let right_box_class_name = "absolute right-0 top-2 btn-box-right";
  if (is_under_width) {
    right_box_class_name = "";
  }

  return (
    <div className="search-box relative">
      <select className="search-input_select" name="s_school_name" value={listOpt.s_school_name} onChange={handleInputChange}>
        <option value="">학교 전체</option>
        {
          schoolNameSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
        <option value="">학년 전체</option>
        {
          props.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
        <option value="">현황 전체</option>
        {
          props.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_is_par_join" value={listOpt.s_is_par_join} onChange={handleInputChange}>
        <option value="">학부모 가입</option>
        <option value="1">가입</option>
        <option value="empty">미가입</option>
      </select>

      <select className="search-input_select" name="s_class_seq" value={listOpt.s_class_seq} onChange={handleInputChange}>
        {
          classSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>

      <SearchLectureSelect
        name="s_stu_lecture_seq"
        value={listOpt.s_stu_lecture_seq}
        on_change_input={(inData: any) => {
          let name = inData.name;
          let value = inData.value;
          props.list({ now_page: 1, [name]: value });
        }}
      ></SearchLectureSelect>

      <div className="basic_search_item_book_mark"
        onClick={() => {
          let change_s_stu_bookmark = listOpt.s_stu_bookmark;
          if (change_s_stu_bookmark == "1") {
            change_s_stu_bookmark = "";
          } else {
            change_s_stu_bookmark = "1";
          }
          strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
          myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
        }}>
        {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
      </div>

      <div className={right_box_class_name}>
        <select className="search-input_select" name="s_search_text_type" value={listOpt.s_search_text_type} onChange={handleInputChange} >
          <option value="name">이름</option>
          <option value="phone">핸드폰</option>
        </select>
        <input type="text" className="search-input" name="s_search_text"
          style={{ width: 120 }} placeholder="검색어 입력" value={listOpt.s_search_text}
          onChange={handleInputChange} onKeyUp={(e: any) => { if (e.key === 'Enter') { goSearch(); } }} />
        <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      </div>
    </div>
  );
};

export default SearchArea;