import Style from "./css/style.module.css";
import RoomRowArea from "./room_row";

function CardRoomListArea(props: any) {
  let myProps = {
    room_info_arr:[],
    student_info_arr:[],
    now_ymdhis:"",
    pre_date_str:"",
    cardview_sort:"",
    open_view_room_popup:(inData:any)=>{},
    show_detail_view:(inData:any)=>{},
    open_view_desk_popup:(inData:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    openTodoWriteOnePopup:(inData:any)=>{},
    go_change_todo_state_by_ajax:(inData:any)=>{},
    ...props
  };
  let room_info_arr=myProps.room_info_arr;
  let student_info_arr=myProps.student_info_arr;
  let now_ymdhis=myProps.now_ymdhis;
  let pre_date_str=myProps.pre_date_str;

  const get_room_row_tags=()=>{
    let room_row_tags=room_info_arr.map((room_info:any,idx:number)=>{
      return (
        <RoomRowArea
          key={idx}
          room_info={room_info}
          Style={Style}
          student_info_arr={student_info_arr}
          now_ymdhis={now_ymdhis}
          pre_date_str={pre_date_str}
          cardview_sort={myProps.cardview_sort}
          open_view_room_popup={myProps.open_view_room_popup}
          show_detail_view={myProps.show_detail_view}
          open_view_desk_popup={myProps.open_view_desk_popup}
          openTodoDaySort={myProps.openTodoDaySort}
          openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
          go_change_todo_state_by_ajax={myProps.go_change_todo_state_by_ajax}
        ></RoomRowArea>
      );
    });

    return room_row_tags;
  };

  return (
    <div>
      {get_room_row_tags()}
    </div>
  );
};
export default CardRoomListArea;