//import LoginOtherArea from "@/pcomponents/common/content/basic/login/login_other";
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';
import { useNavigate } from 'react-router-dom';

function LoginOtherArea(props: any) {
  let myProps = {
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrap_ref = useRef<any>(null);
  const [group_manager_arr, set_group_manager_arr] = useState<any>([]);
  const [user_comp_link, set_user_comp_link] = useState<any>([]);
  const [groupArr, setGroupArr] = useState<any>([]);
  const [group_seq, set_group_seq] = useState("");
  const [groupLinkArr, setGroupLinkArr] = useState<any>([]);
  const [mcomp_seq, set_mcomp_seq] = useState("");

  useEffect(() => {
    get_group_manager_by_ajax();
    set_mcomp_seq(user.comp_seq);
  }, [user]);

  const show_wrap = () => {
    if (wrap_ref.current) {
      wrap_ref.current.style.display = "inline-block";
    }
  };
  const unshow_wrap = () => {
    if (wrap_ref.current) {
      wrap_ref.current.style.display = "none";
    }
  };

  const get_group_manager_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      unshow_wrap();
      return false;
    }
    get_user_comp_link_by_ajax();
    let form_json_data = {
      "s_user_seq": user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_group_manager_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            unshow_wrap();
          } else {
            show_wrap();
            let tmp_group_seq_arr = [];
            for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
              let group_info = response.data["data"]["info_arr"][i];
              tmp_group_seq_arr.push(group_info["a_group_seq"]);
            }
            set_group_seq(tmp_group_seq_arr[0]);
            list_group_link(tmp_group_seq_arr[0]);
            list_group({
              group_seq_arr: tmp_group_seq_arr,
            });
          }
        } else {

        }
      });
  };

  const get_user_comp_link_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_main_seq": user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user_comp_link/link/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_user_comp_link(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_group = (inData: any) => {
    let opt_obj = {
      "group_seq_arr": [],
      ...inData
    };
    if (opt_obj["group_seq_arr"].length == 0) {
      return false;
    }
    let group_list_form_data = {
      "s_pri_arr": opt_obj["group_seq_arr"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group/list', group_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setGroupArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_group_link = (in_group_seq: any) => {
    if (in_group_seq == "") {
      setGroupLinkArr([]);
      return false;
    }
    let group_list_form_data = {
      "s_group_seq": in_group_seq,
      "s_addon_mcomp": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_link/list', group_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setGroupLinkArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const login_other_comp = (inData: any) => {
    let opt_obj = {
      select_mcomp_seq: mcomp_seq,
      ...inData
    };
    let select_mcomp_seq = opt_obj.select_mcomp_seq;
    if (strFunc.is_empty(select_mcomp_seq)) {
      alert("학원 선택 없음.");
      return false;
    }

    let tmp_mcomp_info = null;
    for (let i = 0; i < groupLinkArr.length; i++) {
      let group_link = groupLinkArr[i];
      if (group_link["a_mcomp_seq"] == select_mcomp_seq) {
        if (group_link["mcomp_info"]) {
          tmp_mcomp_info = group_link["mcomp_info"];
        }
      }
    }
    if (tmp_mcomp_info == null) {
      alert("학원 정보 없음.");
      return false;
    }
    let login_user_seq = "super_user";

    // if(!confirm("다른 학원으로 로그인 하시겠습니까?")){
    //   return false;
    // }
    open_data_page_popup({
      comp_id: tmp_mcomp_info["a_id"],
      user_id: login_user_seq,
    });
  };

  const open_data_page_popup = (inData: any) => {
    let opt_obj = {
      comp_id: "",
      user_id: "",
      ...inData
    };
    let url_str = process.env.REACT_APP_MANAGE_URL + "/comp/login_other";
    let url_data = "?comp_id=" + opt_obj.comp_id + "&user_id=" + opt_obj.user_id;
    window.open(url_str + url_data, "_blank");
  };

  return (
    <div ref={wrap_ref} className="login_other_box" style={{ display: "none"}}>
      <span>
        그룹
        <select className="search-input_select" value={group_seq}
          onChange={(e: any) => {
            set_group_seq(e.target.value);
            list_group_link(e.target.value);
          }} style={{ width: 80 }}>
          <option value="" >선택없음</option>
          {
            groupArr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_seq}>{item.a_name}</option>
              );
            })
          }
        </select>
      </span>
      <span className="ml-2">
        학원
        <select className="search-input_select" name="s_mcomp_seq" value={mcomp_seq}
          onChange={(e: any) => {
            set_mcomp_seq(e.target.value);
            login_other_comp({ select_mcomp_seq: e.target.value });
          }} style={{ width: 80 }} >
          <option value="" >선택없음</option>
          {
            groupLinkArr.map((item: any, idx: number) => {

              return (
                <option key={idx} value={item.a_mcomp_seq}>{item.a_mcomp_name}</option>
              );
            })
          }
        </select>
      </span>
      {/* <button className="btn-s btn-dark" onClick={()=>{login_other_comp({});}}>타학원 로그인</button> */}
    </div>
  );
};
export default LoginOtherArea;