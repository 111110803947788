import { useState, forwardRef, useImperativeHandle } from "react";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import CalendarTable from "./calendar_table";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import LygCalendarFunc from '@/lib/lyg/calendar';

const AttendCalendarArea = forwardRef((props: any, ref) => {
  let myProps = {
    select_date: "",
    ...props
  };
  let select_date = myProps.select_date;
  if (strFunc.is_empty(select_date)) {
    select_date = DateFunc.get_date_format(new Date(), "Y-m-d");
  }
  const [attend_info_arr, set_attend_info_arr] = useState([]);
  const [select_stu_seq_arr, set_select_stu_seq_arr] = useState([]);
  const [is_show_out, set_is_show_out] = useState(false);
  const [is_all_show, set_is_all_show] = useState(false);
  const [is_show_memo, set_is_show_memo] = useState(false);

  const setInitData = (inData: any) => {
    let opt_obj = {
      select_date: select_date,
      stu_seq_arr: select_stu_seq_arr,
      is_show_out: is_show_out,
      is_all_show: is_all_show,
      ...inData
    };
    if (strFunc.is_empty(opt_obj.select_date)) {
      opt_obj.select_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    list_attend_by_ajax({
      select_date: opt_obj.select_date,
      stu_seq_arr: opt_obj["stu_seq_arr"],
      is_show_out: opt_obj.is_show_out,
      is_all_show: opt_obj.is_all_show
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const list_attend_by_ajax = (inData: any) => {
    let opt_obj = {
      select_date: select_date,
      stu_seq_arr: select_stu_seq_arr,
      is_show_out: is_show_out,
      is_all_show: is_all_show,
      ...inData
    };
    if (strFunc.is_empty(opt_obj.select_date)) {
      opt_obj.select_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    set_select_stu_seq_arr(opt_obj.stu_seq_arr);
    set_is_show_out(opt_obj.is_show_out);
    set_is_all_show(opt_obj.is_all_show);
    if (strFunc.is_empty(opt_obj["stu_seq_arr"])) {
      if (opt_obj.is_all_show == false) {
        set_attend_info_arr([]);
        return false;
      }
    }
    //let cal_data = LygCalendarFunc.get_cal_data_by_select_date(select_date);
    let s_start_date=DateFunc.get_date_format(new Date(opt_obj.select_date),"Y-m-01");
    let s_end_date=DateFunc.get_date_format(new Date(opt_obj.select_date),"Y-m-t");
    let from_json_data: any = {
      "s_date_type": "a_ymd",
      "s_start_date": s_start_date,
      "s_end_date": s_end_date,
      "s_user_seq": opt_obj.stu_seq_arr,
      s_sort: opt_obj.is_show_out?"":"in",
      "order_id":"a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/list', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_attend_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <div>
        <div style={{ display: "inline-block", height: 28, width: 1 }}></div>
        <span>
          {select_date.substring(0,4)}년 {select_date.substring(5,7)}월
        </span>
        <label>
          <input type="checkbox" className="ml-1" checked={is_all_show} onChange={() => { 
            list_attend_by_ajax({
              is_all_show: !is_all_show
            });
          }}></input>
          <span className="ml-1">전체보기</span>
        </label>
        <label>
          <input type="checkbox" className="ml-1" checked={is_show_out} onChange={() => { 
            list_attend_by_ajax({
              is_show_out: !is_show_out
            });
          }}></input>
          <span className="ml-1">하원보기</span>
        </label>
        <label>
          <input type="checkbox" className="ml-1" checked={is_show_memo} onChange={() => { 
            set_is_show_memo(!is_show_memo);
          }}></input>
          <span className="ml-1">메모보기</span>
        </label>
        <span className="ml-2">
          {select_stu_seq_arr.length}명
        </span>
        <span className="ml-2">
          {attend_info_arr.length}건
        </span>
      </div>
      <CalendarTable
        select_date={select_date}
        attend_info_arr={attend_info_arr}
        is_show_memo={is_show_memo}
      ></CalendarTable>
    </div>
  );
});
export default AttendCalendarArea;