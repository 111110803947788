function TempleteListArea(props: any) {
  let myProps = {
    katalk_templete_arr: [],
    ...props
  };
  let katalk_templete_arr = myProps.katalk_templete_arr;

  const get_tempete_tags = () => {
    let tempete_tags = katalk_templete_arr.map((item: any, idx: number) => {
      let sort_text="";
      if(item["a_sort"]=="attend_in"){
        sort_text="등원";
      }else if(item["a_sort"]=="attend_out"){
        sort_text="하원";
      }else if(item["a_sort"]=="home_work_msg"){
        sort_text="숙제";
      }
      return (
        <div key={idx} className="mt-1">
          <div>{sort_text}</div>
          <div className="p-1 mt-1 border">{item["a_templete_content"]}</div>
        </div>
      );
    });
    return tempete_tags;
  };

  return (
    <div>
      {get_tempete_tags()}
    </div>
  );
};
export default TempleteListArea;