import * as XLSX from "xlsx-js-style";
import style_data from "./style/index";
import strFunc from "@/lib/lyg/string";
import GetDailyReportData from "./get_data";

const go_down_excel_daily_list = (inData: any) => {
  let opt_obj = {
    list_tab:"",
    tab_id:"",
    info_arr:[],
    group_data_arr_ref:null,
    select_date:"",
    is_print_mode:true,
    is_show_etc_group:true,
    show_group_time:{},
    able_time_arr_ref:[],
    xColumnArr:{},
    group_data_arr:[],
    ...inData,
  };
  const wb = XLSX.utils.book_new();
  let daily_report_data=GetDailyReportData.get_data(opt_obj);

  //넓이
  var wscols = daily_report_data.wscols;
  //높이
  var wsrows = daily_report_data.wsrows;

  //헤더
  let header_row_data: any = daily_report_data.header_row_data;
  let merge_cell_arr: any = daily_report_data.merge_cell_arr;
  let data: any = daily_report_data.data;

  let abc_arr = strFunc.get_abc_arr(header_row_data.length);

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(data);//[row,row2]
  XLSX.utils.book_append_sheet(wb, ws, "daily_report");

  //넓이
  ws['!cols'] = wscols;
  //높이
  ws['!rows'] = wsrows;

  //합치기
  let merge_cell_data_arr = [];
  for (let i = 0; i < merge_cell_arr.length; i++) {
    let merge_cell = merge_cell_arr[i];
    if (merge_cell["row_span"] >= 2||merge_cell["col_span"] >= 2) {
      let plus_row_num = merge_cell.row_span - 1;
      let plus_x_num = merge_cell.col_span - 1;
      merge_cell_data_arr.push({
        s: { r: merge_cell.y, c: merge_cell.x },
        e: { r: merge_cell.y + plus_row_num, c: merge_cell.x+plus_x_num },
      });
    }
  }
  if (merge_cell_data_arr.length > 0) {
    ws["!merges"] = merge_cell_data_arr;
  }

  //스타일
  let all_row_i = data.length;
  for (let row_i = 0; row_i < all_row_i; row_i++) {
    for (let i = 0; i < abc_arr.length; i++) {
      let cell_style: any = {
        border: style_data.border_1,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true
        },
      };
      if (row_i == 0 || i == 0) {
        cell_style["font"] = {
          "bold": true
        };
      } else {
        cell_style["fill"] = { fgColor: { rgb: "FFFFFF" } };
      }
      if (ws[abc_arr[i] + (row_i + 1)]) {
        ws[abc_arr[i] + (row_i + 1)].s = cell_style;
      }
    }
  }

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, "daily_report.xlsx");
};

export default go_down_excel_daily_list;