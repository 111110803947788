import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hmenu, smenu, hmenu_master_arr, get_hmenu_arr } from "@/pcomponents/comp/layout/menu/data/menu_arr";

const GNBArea = (props: any) => {
  let myProps = {
    site_map_btn: null,
    ...props
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state: any) => state.user);

  const movePage = (url: string) => {
    navigate(url);
  };

  let now_h_key = "";
  for (let i = 0; i < smenu.length; i++) {
    if (smenu[i].url == window.location.pathname) {
      now_h_key = smenu[i].hmenu;
    }
  }
  let comp_sort_detail = "plani";
  if (location.hostname == "dalirant.planiedu.com") {
    comp_sort_detail = "dalirant";
  }
  let menuArr = get_hmenu_arr({
    "user_grade": user.user_grade,
    "comp_sort_detail": comp_sort_detail,
  });

  const menuArrTags = menuArr.map((item, idx) => {
    let gnb_class_name = "gnb_item";
    if (item.isShow == false) {
      return "";
    }
    if (item.key == now_h_key) {
      gnb_class_name += " active";
    }

    return (
      <div key={idx} className={gnb_class_name} onClick={() => { movePage(item.url); }} >
        {(item.ico && item.ico_active) &&
          <img src={(item.key == now_h_key) ? item.ico_active : item.ico} className="gnb_item_ico" />
        }
        {item.name}
      </div>
    );
  });

  return (
    <div className="gnb_area">
      {myProps.site_map_btn && (
        <div className="gnb_item" >
          {myProps.site_map_btn}
        </div>
      )}
      {menuArrTags}
    </div>
  );
};

export default GNBArea;