import { useState, useRef, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../main/xcolumn/list";
import strFunc from '@/lib/lyg/string';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function AddAttendPopup(props: any) {
  let myProps = {
    attend_sort: "",
    attend_sort_detail: "",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    callback: (inData: any) => { },
    closePopup: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const [pageData, setPageData] = useState({
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  });
  const [row_data, set_row_data] = useState({
    "a_ymd": myProps.attend_date,
    "a_user_seq": "",
    "a_sort": myProps.attend_sort,
    "a_sort_detail": myProps.attend_sort_detail,
    "a_time": DateFunc.get_date_format(new Date(), "h:i:s"),
    "a_user_name": "",
    "a_memo": "",
    "a_writer": user.user_name,
    "a_writer_seq": user.user_seq,
  });
  const [userInfoArr, setUserInfoArr] = useState(myProps.userInfoArr);
  const [pre_attend_arr, set_pre_attend_arr] = useState([]);
  const [is_send_msg, set_is_send_msg] = useState(true);
  const attenTimeHRef = useRef<HTMLSelectElement>(null);
  const attenTimeMRef = useRef<HTMLSelectElement>(null);
  const attenTimeSRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    let attend_state_arr = xColumnArr.select_arr.a_sort_detail;
    let sort_text = "등록";
    let attend_state_len = attend_state_arr.length;
    for (let i = 0; i < attend_state_len; i++) {
      let item = attend_state_arr[i];
      if (row_data["a_sort_detail"] == item.value) {
        sort_text = item.text;
      }
    }
    setPageData({
      ...pageData,
      ...{
        "write_btn_text": sort_text,
        "confirm_msg": sort_text + " 처리 하시겠습니까?"
      }
    });
    get_pre_attend_info();
  }, []);

  const get_pre_attend_info = () => {
    if (userInfoArr.length == 0) {
      return false;
    }
    if (userInfoArr.length != 1) {
      return false;
    }
    if (strFunc.is_empty(myProps.attend_date)) {
      return false;
    }


    let formJsonData = {
      "s_ymd": myProps.attend_date,
      "s_user_seq": userInfoArr[0]["a_seq"],
      "s_sort": myProps.attend_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_pre_attend_arr(response.data["data"]["info_arr"]);
            let pre_attend_info = response.data["data"]["info_arr"][0];
            set_row_data({
              ...row_data,
              a_memo: pre_attend_info["a_memo"],
            });
          }
        } else {

        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    if (target.type === 'checkbox') {
      value = target.checked ? "1" : "";
    }
    const name = target.name;
    if (name == "a_sort_detail") {
      let attend_state_arr = xColumnArr.select_arr.a_sort_detail;
      let sort_text = "등록";
      let attend_state_len = attend_state_arr.length;
      for (let i = 0; i < attend_state_len; i++) {
        let item = attend_state_arr[i];
        if (value == item.value) {
          sort_text = item.text;
        }
      }
      setPageData({
        ...pageData,
        ...{
          "write_btn_text": sort_text,
          "confirm_msg": sort_text + " 처리 하시겠습니까?"
        }
      });
    }

    set_row_data({
      ...row_data,
      ...{ [name]: value }
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    set_row_data({
      ...row_data,
      ...{ [key]: date_str }
    });
  };

  const onChangeAttendTime = () => {
    let hms_arr = [
      attenTimeHRef.current ? attenTimeHRef.current.value : "00",
      attenTimeMRef.current ? attenTimeMRef.current.value : "00",
      attenTimeSRef.current ? attenTimeSRef.current.value : "00",
    ];
    set_row_data({
      ...row_data,
      ...{ "a_time": hms_arr.join(":") }
    });
  };

  const goWrite = (e: any) => {
    if (userInfoArr.length == 0) {
      alert("원생 선택이 없습니다.");
      return false;
    }

    if(strFunc.is_empty(row_data["a_sort_detail"])){
      alert("구분을 선택해주세요.");
      return false;
    }

    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let attend_data_arr: any = getAttendArrByUser();
    if (attend_data_arr.length == 0) {
      alert("출석데이터가 없습니다.");
      return false;
    }

    let formJsonData = {
      "data_arr": attend_data_arr,
      "is_send_msg": is_send_msg,
      "is_default_val": "1",
      "is_update": "",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/add_attend', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback(response.data["data"]);
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const getAttendArrByUser = () => {
    let attend_data_arr: any = [];

    let user_len = userInfoArr.length;
    for (let i = 0; i < user_len; i++) {
      let user_info = userInfoArr[i];

      let attend_info = {
        ...row_data,
        "a_user_seq": user_info["a_seq"],
        "a_user_name": user_info["a_user_name"],
      };
      attend_data_arr.push(attend_info);
    }
    return attend_data_arr;
  };

  let h_select_arr = [];
  for (let i = 0; i < 24; i++) {
    let i_str = strFunc.str_pad({ "str": i, "pad_length": 2 });
    h_select_arr.push(i_str);
  }
  let ms_select_arr = [];
  for (let i = 0; i < 60; i++) {
    let i_str = strFunc.str_pad({ "str": i, "pad_length": 2 });
    ms_select_arr.push(i_str);
  }
  let attend_time_arr = row_data.a_time.split(":");
  let attend_time_h = attend_time_arr[0] ? attend_time_arr[0] : "00";
  let attend_time_m = attend_time_arr[1] ? attend_time_arr[1] : "00";
  let attend_time_s = attend_time_arr[2] ? attend_time_arr[2] : "00";

  let select_attend_sort_arr = [];
  for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
    let row_sort_detaill = xColumnArr.select_arr.a_sort_detail[i];
    if (row_data.a_sort == "in") {
      if (row_sort_detaill.value != "out") {
        select_attend_sort_arr.push(row_sort_detaill);
      }
    } else {
      if (row_sort_detaill.value == "out") {
        select_attend_sort_arr.push(row_sort_detaill);
      }
    }
  }

  return (
    <div className="p-2">
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>날짜</th>
              <td>
                <DatePicker
                  selected={row_data.a_ymd != "" ? new Date(row_data.a_ymd) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_ymd", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>
                시간
              </th>
              <td>
                <select className="row-input" name="attend_time_h" value={attend_time_h}
                  ref={attenTimeHRef} onChange={onChangeAttendTime}
                  style={{ width: 60 }}>
                  {h_select_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_m" value={attend_time_m}
                  ref={attenTimeMRef} onChange={onChangeAttendTime}
                  style={{ width: 60 }}>
                  {ms_select_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_s" value={attend_time_s}
                  ref={attenTimeSRef} onChange={onChangeAttendTime}
                  style={{ width: 60 }}>
                  {ms_select_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>
                <span className="ml-1" style={{ fontSize: "13px" }}>
                  시:분:초
                </span>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td>
                <SelectBoxBar
                  valueTextArr={select_attend_sort_arr}
                  value={row_data.a_sort_detail}
                  name={"a_sort_detail"}
                  on_change={(inData: any) => {
                    handleInputChange({
                      target: {
                        name: "a_sort_detail",
                        value: inData["value"]
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <input type="text" className="row-input" name="a_memo" value={row_data.a_memo} onChange={handleInputChange} placeholder="메모" />
              </td>
            </tr>
            <tr>
              <th>알림전송</th>
              <td>
                <SelectBoxBar
                  valueTextArr={[
                    { "value": "1", "text": "알림전송" },
                    { "value": "", "text": "미전송" },
                  ]}
                  value={is_send_msg? "1" : ""}
                  name={"a_sort_detail"}
                  on_change={(inData: any) => {
                    if(inData["value"]=="1"){
                      set_is_send_msg(true);
                    }else{
                      set_is_send_msg(false);
                    }
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-brown" onClick={goWrite}>{pageData["write_btn_text"]} 처리</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <div className="mt-2">
        <h5>
          선택 원생
          <span className="ml-1" style={{ fontWeight: "normal" }}>
            ({userInfoArr.length} 명)
          </span>
        </h5>
        <div className="list-table-div">
          <table>
            <colgroup>

            </colgroup>
            <thead>
              <tr>
                <th>이름</th>
                <th>학교</th>
                <th>학년</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {
                userInfoArr.map((item: any, idx: number) => {
                  let grade_text = item["a_stu_grade"];
                  if (xColumnArr.select_arr.a_stu_grade) {
                    for (let i = 0; i < xColumnArr.select_arr.a_stu_grade.length; i++) {
                      let sort_item = xColumnArr.select_arr.a_stu_grade[i];
                      if (sort_item["value"] == item["a_stu_grade"]) {
                        grade_text = sort_item["text"];
                      }
                    }
                  }
                  let row_attend_info_arr = [];
                  let is_exist_attend = false;
                  for (let i = 0; i < pre_attend_arr.length; i++) {
                    if (pre_attend_arr[i]["a_user_seq"] == item["a_seq"]) {
                      row_attend_info_arr.push(pre_attend_arr[i]);
                      is_exist_attend = true;
                    }
                  }

                  return (
                    <tr key={idx}>
                      <td className="text-center">
                        {item["a_user_name"]}
                      </td>
                      <td className="text-center">
                        {item["a_school_name"]}
                      </td>
                      <td className="text-center">
                        {grade_text}
                      </td>
                      <td className="text-center">
                        {row_attend_info_arr.length}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AddAttendPopup;
