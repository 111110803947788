//import LoadingComponent from "@/pcomponents/common/content/loading";
/*
const [loading_data, set_loading_data] = useState({
  is_loading: false,
  text: "로딩중..",
});
*/
function LoadingComponent(props:any){
  let myProps={
    is_display:true,
    text:"로딩중..",
    ...props
  };

  let loading_style:any={
    position:"fixed",
    left:0,
    top:"40%",
    width:"100%",
    textAlign:"center",
    display:"none",
    zIndex:99,
    cursor:"pointer"
  };
  if(myProps.is_display){
    loading_style["display"]="block";
  }
  let text_box_style:any={
    display:"inline-block",
    background:"#fff",
    padding:15,
    border:"1px solid #ddd",
    textAlign:"center",
    borderRadius:"5px",
  };
  let text_span_style:any={
    color:"#379d89",
    background:"#fff",
    lineHeight:"30px",
    padding:"0px 15px",
    fontWeight:"bold",
    fontSize:15
  };
  return (
    <div style={loading_style} onClick={(e:any)=>{e.target.style.display="none";}}>
      <div style={text_box_style}>
        <span style={text_span_style}>
          {myProps.text}
        </span>
      </div>
    </div>
  );
}
export default LoadingComponent;