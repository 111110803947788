import CompLayout from "@/pcomponents/comp/layout/layout";
import DiscoverMapStuWriteArea from "@/pages/comp/edu/discover_map/common/stu_write";

function DiscoverMapListPage() {
  
  return (
    <CompLayout isConTitle={false}>
      <DiscoverMapStuWriteArea></DiscoverMapStuWriteArea>
    </CompLayout>
  );
};
export default DiscoverMapListPage;