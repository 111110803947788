import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';
import AttendStateFunc from "@/pcomponents/common/content/edu/attend/func/state_func";

function FindStudentPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    listOpt: {},
    "desk_info": null,
    "now_ymdhis": "",
    "now_lecture_data": null,
    "add_time_sec": 60 * 60,
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        "desk_info": null,
        "now_ymdhis": "",
        select_time:{
          "start_h": "00",
          "start_m": "00",
          "end_date": "0000-00-00",
          "end_h": "00",
          "end_m": "00",
        },
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  const xColumnArr = getXcolumnJson();
  let user = useSelector((state: any) => state.user);
  const listAggridRef = useRef<any>();
  let now_ymd=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    order_id:strFunc.get_storage("s_find_stu_order_id", "a_stu_grade, a_user_name"),
    s_grade: ["student"],
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    // s_addon_class_user: "1",
    // s_addon_link_user: "1",
    s_except_user_seq: "",
    s_addon_attend: "1",
    s_addon_attend_start_date: now_ymd,
    s_addon_attend_end_date: now_ymd,
    s_addon_desk_use:"1",
    s_addon_desk_use_start_date:now_ymd,
    s_addon_desk_use_end_date:now_ymd,
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd,
    s_attend_end_date:now_ymd,
    ...myProps.listOpt
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec = strFunc.timeToSeconds(now_date_json.h, now_date_json.i, now_date_json.s);
  let now_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(myProps.now_ymdhis),'sec',myProps.add_time_sec));
  let default_select_time = {
    "start_h": now_date_json.h,
    "start_m": now_date_json.i,
    "end_date": now_next_date_json.Y+"-"+now_next_date_json.m+"-"+now_next_date_json.d,
    "end_h": now_next_date_json.h,
    "end_m": now_next_date_json.i,
  };
  const [select_time, set_select_time] = useState(default_select_time);
  let select_time_term_minute =
    strFunc.timeToSeconds(select_time.end_h, select_time.end_m, 0)
    - strFunc.timeToSeconds(select_time.start_h, select_time.start_m, 0);
  if (select_time_term_minute != 0) {
    if(select_time_term_minute<0){
      select_time_term_minute=86400+select_time_term_minute;
    }
    select_time_term_minute = select_time_term_minute / 60;
  }

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt = { ...listOpt, ...inOptObj };
    tmp_list_opt["sc"] = listAggridRef.current.getListSc();
    setListOpt(tmp_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onChangeTimeRowInput = (key: string, val: string) => {
    let time_col_arr = [
      "start_h", "start_m",
      "end_h", "end_m"
    ];
    let change_select_time:any={...select_time};
    if (strFunc.str_in_array(key, time_col_arr) != -1) {
      val = strFunc.comma(val);
      val = val.substring(0, 2);
      if (key == "start_h" || key == "end_h") {
        if (parseInt(val) >= 24) {
          val = "23";
        }
      } else {
        if (parseInt(val) >= 60) {
          val = "59";
        }
      }
      if (parseInt(val) < 0) {
        val = "0";
      }
    }
    change_select_time[key] = val;
    set_select_time(change_select_time);
  };

  const go_change_time_input_by_plus_minus = (inData: any) => {
    let opt_obj = {
      "sort": "static",//static,add
      "plus_min": 0,
      "static_min": 0,
      ...inData
    };
    //now_sec
    //next_sec
    let static_min = parseInt(strFunc.uncomma(opt_obj["static_min"]));
    let plus_min = parseInt(strFunc.uncomma(opt_obj["plus_min"]));
    let change_next_sec = 0;
    let change_next_date_json:any=null;
    if (opt_obj["sort"] == "static") {
      change_next_sec = (static_min * 60);
      change_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(myProps.now_ymdhis),'sec',change_next_sec));
    } else if (opt_obj["sort"] == "add") {
      change_next_sec += (plus_min * 60);
      let tmp_end_ymdhis=select_time["end_date"]+" "+
        strFunc.str_pad({"str":select_time.end_h,"pad_length":2})+
        ":"+strFunc.str_pad({"str":select_time.end_m,"pad_length":2})+
        ":"+strFunc.str_pad({"str":now_date_json.s,"pad_length":2});
      change_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(tmp_end_ymdhis),'sec',change_next_sec));
    }
    //하루이상 안되게
    let start_end_diff=change_next_date_json.date_obj.getTime()-now_date_json.date_obj.getTime();
    let start_end_diff_sec=start_end_diff/1000;
    
    if(start_end_diff_sec>=86400){
      return false;
    }
    
    //지금보다 이전이면 안되게
    if(now_date_json.date_obj.getTime()>=change_next_date_json.date_obj.getTime()){
      return false
    }

    let change_select_time:any={...select_time};
    change_select_time["end_h"]=change_next_date_json.h;
    change_select_time["end_m"]=change_next_date_json.i;
    change_select_time["end_date"]=change_next_date_json.Y+"-"+change_next_date_json.m+"-"+change_next_date_json.d;
    set_select_time(change_select_time);
  };

  const goCallBackData = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    } else if (selected_row.length != 1) {
      alert("한명만 선택해주세요.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      "desk_info": myProps.desk_info,
      "now_ymdhis": myProps.now_ymdhis,
      "select_time": select_time,
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_par_phone") {
      let a_user_phone = strFunc.autoHypenPhone(params.data.a_user_phone);
      let par_phone_num = "학부모 미등록";
      if (params.data.link_user_arr && params.data.link_user_arr[0] && params.data.link_user_arr[0]["par_info"]) {
        par_phone_num = params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str = (
        <div>
          <div className="leading-5">{par_phone_num}</div>
          <div className="leading-5">{a_user_phone}</div>
        </div>);
    }else if (key == "row_view_attend") {
      let attend_info:any = null;
      let attend_text="";
      let attend_color="";
      if (params.data.attend_in_arr && params.data.attend_in_arr.length > 0) {
        attend_info = params.data.attend_in_arr[0];
        let attend_state_data=AttendStateFunc.get_color_by_attend_info({ "attend_info": attend_info });
        attend_text=attend_state_data["text"];
        attend_color=attend_state_data["background"];
      }
      render_str =
        <div>
          {attend_info&&
            <span style={{background:attend_color,padding:"2px",borderRadius:"3px"}}>{attend_text}</span>
          }
        </div>;
    }else if(key=="row_view_desk"){
      let desku_info=null;
      let desk_text="";
      let desk_color="";
      if(params.data.desk_use_arr&&params.data.desk_use_arr.length>0){
        desku_info=params.data.desk_use_arr[0];
        let start_sec=strFunc.timeToSeconds(desku_info["a_start_h"],desku_info["a_start_m"],0);
        let end_sec=strFunc.timeToSeconds(desku_info["a_end_h"],desku_info["a_end_m"],0);
        let start_hi=strFunc.HourMinuteToTime(desku_info["a_start_h"],desku_info["a_start_m"]);
        let end_hi=strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"]);
        let room_name="";
        if(desku_info["room_name"]){
          room_name=desku_info["room_name"];
        }
        desk_text=room_name+" "+start_hi+"~"+end_hi;
        if(start_sec<=now_sec&&now_sec<end_sec){
          desk_color="green";
        }
      }
      render_str=
        <div className="text-center" style={{color:desk_color}}>
          {desku_info&&
            <span>{desk_text}</span>
          }
        </div>;
    }
    return render_str;
  };
  const getRowHeight = useCallback((params: any) => {
    return 45;
  }, []);

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        xColumnArr={{ ...xColumnArr }}
        list={list}
      ></SearchArea>
      <div className="btn-box-left relative h-8">
        <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
        <span className="px-2">|</span>
        <SearchOrderRadio
          valueTextArr={[
            { "text": "등록순", "value": "a_create_date DESC" },
            { "text": "이름순", "value": "a_user_name" },
            { "text": "학년이름", "value": "a_stu_grade, a_user_name" },
          ]}
          value={listOpt.order_id}
          handleInputChange={(e: any) => {
            strFunc.set_storage("s_find_stu_order_id", e.target.value);
            list({
              "now_page": 1,
              "order_id": e.target.value
            });
          }}
        ></SearchOrderRadio>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <div className="text-center mt-1">
        할당시간
        <span className="mx-2">
          <input type="text" className="text-center" name="start_h" value={strFunc.str_pad({ "str": select_time.start_h, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />:
          <input type="text" className="text-center" name="start_m" value={strFunc.str_pad({ "str": select_time.start_m, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />
        </span>
        ~
        <span className="mx-2">
          <input type="text" className="text-center" name="end_h" value={strFunc.str_pad({ "str": select_time.end_h, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />:
          <input type="text" className="text-center" name="end_m" value={strFunc.str_pad({ "str": select_time.end_m, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />
        </span>
        <span style={{ fontWeight: "bold", color: "blue" }}>
          ({select_time_term_minute}분)
        </span>
      </div>
      <div className="text-center mt-1">
        <button className="btn btn-dark"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 60 })
          }}>1시간</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 90 })
          }}>1시간반</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 120 })
          }}>2시간</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": 1 })
          }}>+1분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": 10 })
          }}>+10분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": -1 })
          }}>-1분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": -10 })
          }}>-10분</button>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: false,
          floatingFilter: false,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          getRowHeight: getRowHeight
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
}

export default FindStudentPopup;