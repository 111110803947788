import CompLayout from "@/pcomponents/comp/layout/layout";
import DiscorverMapHistoryListArea from "@/pages/comp/edu/discover_map/common/history_list";

function DiscoverMapHistoryPage() {

  return (
    <CompLayout isConTitle={false}>
      <DiscorverMapHistoryListArea></DiscorverMapHistoryListArea>
    </CompLayout>
  );
};
export default DiscoverMapHistoryPage;