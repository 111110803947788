
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function InitDataManageArea(props: any) {
  let myProps = {
    select_year: DateFunc.get_date_format(new Date(), "Y"),
    list: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let now_year = parseInt(DateFunc.get_date_format(new Date(), "Y"));
  let pre_year = now_year - 1;
  const [migrate_data, set_migrate_data] = useState({
    "from_year": pre_year,
    "to_year": now_year,
  });

  const point_init_tot = () => {
    if (!confirm(myProps.select_year+"년도 합계 데이터를 초기화 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "s_year": myProps.select_year
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/trade/simple_init/write_data_tot', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const migrate_init_from_pre_year_by_ajax = () => {
    if (!confirm("전년도 초기데이터를 가져오시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      'from_trade_year': migrate_data.from_year,
      'to_trade_year': migrate_data.to_year,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/trade/trade_simple/migrate', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let select_year_arr = [];
  for (let i = now_year - 50; i <= now_year; i++) {
    select_year_arr.push(i);
  };

  return (
    <div>
      {user.user_seq == "9999" &&
        <div className="text-right">
          {myProps.select_year}
          <button className="btn btn-dark ml-1" onClick={() => { point_init_tot(); }} >초기화</button>
          <span className="ml-2">
            From
            <select className="border" value={migrate_data.from_year}
              onChange={(e: any) => {
                set_migrate_data({
                  ...migrate_data,
                  "from_year": e.target.value
                });
              }}>
              {select_year_arr.map((data: any) => {
                return <option key={data} value={data}>{data}</option>;
              })}
            </select>
            To
            <select className="border" value={migrate_data.to_year}
              onChange={(e: any) => {
                set_migrate_data({
                  ...migrate_data,
                  "to_year": e.target.value
                });
              }}>
              {select_year_arr.map((data: any) => {
                return <option key={data} value={data}>{data}</option>;
              })}
            </select>
          </span>
          <button className="btn btn-dark ml-1" onClick={() => { migrate_init_from_pre_year_by_ajax(); }}>전년도 데이터 가져오기</button>
        </div>
      }
    </div>
  );
};
export default InitDataManageArea;