import strFunc from "@/lib/lyg/string";
function StuNameTd(props: any) {
  let myProps = {
    info: {},
    select_stu_grade_arr: [],
    open_stu_layer_popup:(inData:any)=>{},
    ...props
  };
  let info = myProps.info;
  let stu_grade_str = "";
  for (let i = 0; i < myProps.select_stu_grade_arr.length; i++) {
    if (info["a_stu_grade"] == myProps.select_stu_grade_arr[i]["value"]) {
      stu_grade_str = myProps.select_stu_grade_arr[i]["text"];
    }
  }

  return (
    <td title={info.a_user_name + " " + stu_grade_str + " " + info["a_school_name"]}
      onClick={()=>{myProps.open_stu_layer_popup({
        stu_info:info,
        lecture_info:myProps.lecture_info,
      });}}
      style={{cursor:"pointer"}}>
      <input type="text" value={strFunc.cut_str(info.a_user_name, 3, "")+" " + stu_grade_str}  
        readOnly
        style={{width:"99%",border:0,textAlign:"center",cursor:"pointer"}}/>
    </td>
  );
};
export default StuNameTd;