import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeUser, setUser } from '@/store/user/user';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import person_img from "@/img/ico/person_ico.png";
import DateFunc from '@/lib/lyg/date_func';
import popupFunc from "@/lib/lyg/popup";
import strFunc from '@/lib/lyg/string';
import DarkMode from "@/pcomponents/common/dark";
import AttendReceiveArea from "./area/attend_receive";
import PaytCheckArea from "./area/payt_check";

function CorpMain() {
  let user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);

  const [rowData, setRowData] = useState<any>({
    "a_seq": "1",
    "a_company_sort": "",//school,preschool
    "a_company_name": "",
    "a_represent_name": "",
    "a_company_tel": "",
    // "a_company_fax":"",
    "a_company_email": "",
    "a_company_busin_num": "",
    "a_post_address": "",
    "a_post_address_detail": "",
    "a_post_address_post": "",
    "a_stu_num_ciphers": "4",

    "logo_img_url": person_img,
    "logo_img_thum_url": "",
    "logo_arr": []
  });
  const [mcomp_point, set_mcomp_point] = useState<any>(0);

  useEffect(() => {
    
    getListOne();
    getPointNumByAjax();
  }, [user]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value // ? target.checked : target.value;
    if (target.type === 'checkbox') {
      if (target.checked) {
        value = target.value;
      } else {
        value = "";
      }
    }
    const name = target.name;

    if (name == "a_company_tel") {
      value = strFunc.autoHypenPhone(value);
    } else if (name == "a_stu_num_ciphers") {
      value = strFunc.comma(value);
    } else if (name == "a_company_busin_num") {
      value = strFunc.autoHypenbusin_num(value);
    }

    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };

  const getListOne = useCallback(() => {
    let formJsonData = {
      "s_pri_arr": [user.corp_seq],
      "s_addon_logo_img": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let corp_info = response.data["data"]["info_arr"][0];
            let initCorpInfo: any = {};
            for (let key in rowData) {
              initCorpInfo[key] = corp_info[key];
            }
            if (corp_info["logo_arr"] && corp_info["logo_arr"].length > 0) {
              initCorpInfo["logo_img_url"] = corp_info["logo_arr"][0]["img_url"];
              initCorpInfo["logo_img_thum_url"] = corp_info["logo_arr"][0]["thum_img_url"];
            }

            setRowData({
              ...rowData,
              ...initCorpInfo
            });
          }
        }
      });
  }, [rowData, user]);

  const getPointNumByAjax = () => {
    if (user.comp_seq) {

    } else {
      return false;
    }
    let point_form_json = {
      "s_pri_arr": [user.comp_seq],
      "is_addon_point_tot": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/mcomp/list', point_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let corp_info = response.data["data"]["info_arr"][0];
            if (corp_info.point_tot_info) {
              set_mcomp_point(corp_info.point_tot_info.tot);
            }
          }
        } else {

        }
      });
  };

  const goWrite = (e: any) => {
    if (rowData.a_company_name == "") {
      alert("학원명 입력이 필요합니다.");
      return false;
    }
    if (!confirm("수정 하시겠습니까?")) {
      return false;
    }

    let formJsonData = {
      "data_arr": [rowData],
      "is_update": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (fileInput.current && (fileInput.current as HTMLInputElement).value != "") {
            goWriteLogoImg(response.data["data"][0]);
          } else {
            refreshToken();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteLogoImg = (corp_info: any) => {
    if (writeFileForm.current == null) {
      refreshToken();
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data = new FormData(form);
    let file_data_row = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "corp",
      "a_par_seq": corp_info.a_seq,
      "a_sort1": "logo",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    form_json_data.append("data_arr", JSON.stringify([file_data_row]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          refreshToken();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refreshToken = () => {
    let formJsonData = {
      "token_id": localStorage.intoyou_token
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/refresh_token', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.intoyou_token = response.data["data"]["token_id"];
          var client_info = response.data.data["client_info"];
          dispatch(setUser(client_info));
          alert("저장됨.");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDeleteLogo = (fileInfo: any) => {
    if (!confirm("로고를 삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "data_arr": [fileInfo],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          refreshToken();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let corp_sort_arr = [
    { "value": "school", "text": "학원" },
    { "value": "preschool", "text": "유치원" }
  ];
  let corp_sort_radios = corp_sort_arr.map((item, idx) => {
    let bChecked = false;
    if (item.value == rowData.a_company_sort) {
      bChecked = true;
    }
    return (
      <label key={idx} className="mx-2">
        <input type="checkbox" name="a_company_sort" value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="write-table-div mt-1">
          <table>
            <colgroup>
              <col width="110px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>로고</th>
                <td>
                  <div className="text-center">
                    <a onClick={() => {
                      popupFunc.openPopup({ 'url': rowData.logo_img_url, 'width': 700, 'height': 420, 'pop_name': 'name', 'auto_size': false });
                    }}>
                      <img src={rowData.logo_img_thum_url ? rowData.logo_img_thum_url : person_img} alt="사람" style={{ width: 100 }} />
                    </a>
                    {
                      rowData.logo_arr.length > 0 &&
                      <button className="btn-s btn-gray" onClick={() => { goDeleteLogo(rowData.logo_arr[0]); }}>X</button>
                    }
                    <form method="post" ref={writeFileForm} >
                      <input type="file" className="row-input mt-1" name="input_file[]" ref={fileInput} style={{ width: 200 }} />
                    </form>
                  </div>
                </td>
              </tr>
              <tr>
                <th>구분</th>
                <td>
                  <div>
                    {corp_sort_radios}
                  </div>
                </td>
              </tr>
              <tr>
                <th>학원명</th>
                <td>
                  <input type="text" className="row-input" name="a_company_name" value={rowData.a_company_name} onChange={handleInputChange} placeholder="상호" />
                </td>
              </tr>
              <tr>
                <th>대표자</th>
                <td>
                  <input type="text" className="row-input" name="a_represent_name" value={rowData.a_represent_name} onChange={handleInputChange} placeholder="대표자" />
                </td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>
                  <input type="text" className="row-input" name="a_company_tel" value={rowData.a_company_tel} onChange={handleInputChange} placeholder="전화번호" />
                </td>
              </tr>
              <tr>
                <th>사업자번호</th>
                <td>
                  <input type="text" className="row-input" name="a_company_busin_num" value={rowData.a_company_busin_num} onChange={handleInputChange} placeholder="사업자번호" />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>
                  <input type="text" className="row-input" name="a_company_email" value={rowData.a_company_email} onChange={handleInputChange} placeholder="이메일" />
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  <input type="text" className="row-input" name="a_post_address" value={rowData.a_post_address} onChange={handleInputChange} placeholder="주소" />
                </td>
              </tr>
              <tr>
                <th>상세주소</th>
                <td>
                  <input type="text" className="row-input" name="a_post_address_detail" value={rowData.a_post_address_detail} onChange={handleInputChange} placeholder="상세주소" />
                </td>
              </tr>
              {false &&
                <tr>
                  <th>출결번호자릿수</th>
                  <td>
                    <input type="text" className="row-input" name="a_stu_num_ciphers" value={rowData.a_stu_num_ciphers} onChange={handleInputChange} placeholder="자릿수" />
                  </td>
                </tr>
              }
              <tr>
                <th>포인트</th>
                <td style={{ color: "red" }}>
                  {strFunc.comma(mcomp_point)}
                </td>
              </tr>
              <tr>
                <th>다크모드</th>
                <td>
                  <button className="btn btn-dark"><DarkMode>다크모드</DarkMode></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-box-center mt-2">
          <button className="btn btn-line-gray" onClick={goWrite}>저장</button>
        </div>
        {user.user_grade == "master" &&
          <div>
            <AttendReceiveArea></AttendReceiveArea>
          </div>
        }
        <div className="mt-3">
          {/* <PaytCheckArea></PaytCheckArea> */}
        </div>
      </div>
    </CompLayout>
  );
}

export default CorpMain;