import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function DailyReportListArea(props: any) {
  let myProps = {
    daily_report_arr: [],
    set_daily_report_arr: (inData: any) => { },
    ...props
  };
  let daily_report_arr = myProps.daily_report_arr;
  let select_stu_grade2 = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });

  const remove_daily_report_by_idx = (idx: number) => {
    let new_daily_report_arr = daily_report_arr.filter((item: any, index: number) => { return index !== idx; });
    myProps.set_daily_report_arr(new_daily_report_arr);
  };

  const on_change_row_input = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      idx: 0,
      ...inData,
    };
    
    let name = opt_obj.key;
    let value = opt_obj.value;
    let idx = opt_obj.idx;
    let change_daily_report_arr = [...myProps.daily_report_arr];
    if(change_daily_report_arr[idx]){
      change_daily_report_arr[idx][name] = value;
    }
    myProps.set_daily_report_arr(change_daily_report_arr);
  };

  const get_daily_report_list_tags = () => {
    let report_list_tags = daily_report_arr.map((item: any, idx: number) => {
      let idx_num = idx + 1;

      let stu_school = "";
      let stu_grade = "";
      if (item.stu_info) {
        stu_school = item.stu_info.a_school_name;
        stu_grade = "";
        for (let i = 0; i < select_stu_grade2.length; i++) {
          if (select_stu_grade2[i].value == item.stu_info.a_stu_grade) {
            stu_grade = select_stu_grade2[i].text;
          }
        }
      }
      return (
        <tr key={idx} style={{ fontSize: "15px" }}>
          <td className="text-center">{idx_num}</td>
          <td className="text-center">
            {item["a_stu_name"]}
          </td>
          <td className="text-center" title={stu_school + " " + stu_grade}>
            {strFunc.cut_str(stu_school, 2, "") + " " + stu_grade}
          </td>
          <td className="text-center">
            {item["a_lecture_name"]}
          </td>
          <td className="text-center">
            <input type="text" className="row-input" value={item.hello_msg?item.hello_msg:""} name="hello_msg"
              onChange={(e: any) => {
                on_change_row_input({
                  key: "hello_msg",
                  value: e.target.value,
                  idx: idx
                });
              }} autoComplete="off" />
          </td>
          <td className="text-center">
            <button className="btn-s btn-red ml-1" onClick={() => { remove_daily_report_by_idx(idx); }}>X</button>
          </td>
        </tr>
      );
    });
    return report_list_tags;
  };

  return (
    <div>
      <div>
        선택된 일일보고서 : {daily_report_arr.length}건
      </div>
      <div className="mt-1 basic_list_div">
        <table>
          <colgroup>
            <col width={"50"}></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>학생명</th>
              <th>학년</th>
              <th>수업</th>
              <th>인사말</th>
              <th>제외</th>
            </tr>
          </thead>
          <tbody>
            {get_daily_report_list_tags()}
          </tbody>
        </table>

      </div>
    </div>
  );
};
export default DailyReportListArea;