import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import InfoArrFunc from "@/pages/comp/plan/todo/stu_card_list/area/card_list/func/info_arr_func";
import SearchArea from "../search";
import SearchRoomArea from "../search_room";
import RoomListArea from "../room_list";
import CardRoomListArea from "../card_room_list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudentPopup from "../../popup/find_stu_popup";
import ViewDeskPopup from "../../popup/view_desk_popup";
import SelectStuAllowDeskPopup from "../../popup/select_stu_alloc_popup";
import ViewRoomPopup from "@/pages/comp/edu/desk/main/popup/view_room_popup";
import ViewStuLayerPopup from "@/pages/comp/basic/student/popup/view_layer";
import ViewTodoDaySortPopup from "@/pages/comp/plan/calendar/area/popup/view_todo_day_sort";
import WriteTodoPopup from "@/pages/comp/plan/stu_todo_history/popup/write";
import RoomMsgFunc from "./func/msg_func";

function RoomListWrap(props: any) {
  let myProps = {
    s_room_seq: "",
    is_show_search_room: true,
    is_show_paging: true,
    is_detal_popup: true,
    is_able_card_view: false,
    on_refresh_parent: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    now_page: 1,
    num_per_page: 20,
    order_id: 'a_order_num, a_name',
    order_type: '',
    s_room_seq: myProps.s_room_seq,
    s_is_use: "1",
    s_addon_desk: "1",
    s_addon_use: "1",
    s_addon_lecture: "1",
    s_addon_now_time: "1",
    s_select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
  });
  const listOptRef = useRef<any>(listOpt);
  const room_list_wrap_ref = useRef<any>();
  const RoomListAreaRef = useRef();
  const [search_room_list, set_search_room_list] = useState([]);
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [student_info_arr, set_student_info_arr] = useState([]);
  const pre_room_info_arr = useRef([]);
  const [now_ymdhis, set_now_ymdhis] = useState(DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"));
  const [is_now_time, set_is_now_time] = useState(true);
  const now_ymdhis_ref = useRef(now_ymdhis);
  const [is_show_only_now_lecture, set_is_show_only_now_lecture] = useState(true);
  const [is_show_msg, set_is_show_msg] = useState(strFunc.get_storage("desk_main_is_show_msg", "1") == "1" ? true : false);
  const [room_msg_arr, set_room_msg_arr] = useState<any>([]);
  const [is_show_stu_select_list, set_is_show_stu_select_list] =
    useState(strFunc.get_storage("desk_main_is_show_stu_select_list", "") == "1" ? true : false);
  const [is_stu_filter_lecture, set_is_stu_filter_lecture] =
    useState(strFunc.get_storage("desk_main_is_stu_filter_lecture", "") == "1" ? true : false);
  const [is_stu_filter_use_desk, set_is_stu_filter_use_desk] =
    useState(strFunc.get_storage("desk_main_is_stu_filter_use_desk", "") == "1" ? true : false);
  const [select_stu_filter, set_select_stu_filter] = useState({
    s_stu_grade: "",
    s_user_name: "",
    s_attend_sort_detail: "no_absent",//attend,late,absent,empty,no_absent
  });
  const [select_stu_data, set_select_stu_data] = useState({
    is_select: false,
    stu_info: null,
  });
  const [is_add_attend_by_desk, set_is_add_attend_by_desk] =
    useState(strFunc.get_storage("desk_main_is_add_attend_by_desk", "1") == "1" ? true : false);
  const [is_able_card_view, set_is_able_card_view] = useState(myProps.is_able_card_view);
  const [cardview_sort, set_cardview_sort] = useState(strFunc.get_storage("desk_main_cardview_sort", "todo"));//todo,attend

  //하루전꺼부터 조회
  let tmp_start_date = listOpt.s_select_date;
  let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
  let pre_date_change_num = -1;
  if (tmp_start_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");

  const [popupData, setPopupData] = useState<any>({
    stu_info: null,
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    room_seq: "",
    select_stu_name: "",
    select_stu_seq: "",
    select_date: "",
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
    },

    isOpen: false,
    sort: "find_stu",//find_stu,view_desk,view_room,stu_view
    title: "팝업",
    width: "800px",
    height: "100%",
    has_header: true,
  });

  useEffect(() => {
    search_room_list_by_ajax();
    get_student_arr_by_ajax();
  }, []);

  useEffect(() => {
    const count_loop = setInterval(tick_now_ymdhis, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, [is_now_time]);

  const tick_now_ymdhis = () => {
    if (is_now_time == false) { return false; }
    let tick_date_obj = DateFunc.get_change_date(new Date(now_ymdhis_ref.current), 'sec', +1);
    now_ymdhis_ref.current = DateFunc.get_date_format(tick_date_obj, "Y-m-d h:i:s");
    set_now_ymdhis(now_ymdhis_ref.current);
    if (parseInt(DateFunc.get_date_format(tick_date_obj, "s")) == 0) {
      list({});
      get_student_arr_by_ajax();
    }
  };
  
  const search_room_list_by_ajax = () => {
    let search_room_form_json = {
      order_id: 'a_order_num, a_name',
      order_type: '',
      s_is_use: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', search_room_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          set_search_room_list(response.data["data"]["info_arr"]);
          let tmp_s_room_seq = listOpt.s_room_seq;
          if (listOpt.s_room_seq == "") {
            if (response.data["data"]["info_arr"].length > 0) {
              //tmp_s_room_seq = response.data["data"]["info_arr"][0]["a_seq"];
            }
          }
          list({ s_room_seq: tmp_s_room_seq });
        }
      });
  };

  const list = (inOptObj: any) => {
    let tmp_listOpt: any = {
      ...listOptRef.current,
      is_now_time: is_now_time,
      ...inOptObj
    };
    listOptRef.current = tmp_listOpt;
    setListOpt(listOptRef.current);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', tmp_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (tmp_listOpt.is_now_time) {
            set_now_ymdhis(response.data["data"]["now_ymdhis"]);
            now_ymdhis_ref.current = response.data["data"]["now_ymdhis"];
            let change_room_msg_arr = RoomMsgFunc.get_msg_arr_by_room_list({
              room_info_arr: response.data["data"]["info_arr"],
              pre_room_info_arr: pre_room_info_arr.current,
              room_msg_arr: room_msg_arr,
              now_ymdhis: response.data["data"]["now_ymdhis"],
            });
            set_room_msg_arr(change_room_msg_arr);
            pre_room_info_arr.current = response.data["data"]["info_arr"];
            myProps.on_refresh_parent();
          }
        } else {

        }
      });
  };

  const get_student_arr_by_ajax = () => {
    let tmp_s_select_date = listOpt.s_select_date
    let form_json_data: any = {
      order_id: "a_stu_grade, a_user_name",
      s_stu_state: "now",
      s_grade: ["student"],
      s_addon_attend: "1",
      s_addon_attend_start_date: tmp_s_select_date,
      s_addon_attend_end_date: tmp_s_select_date,
      s_addon_lecture_time: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (is_able_card_view) {
      form_json_data = {
        ...form_json_data,
        s_addon_todo: "1",
        s_addon_todo_start_date: tmp_s_select_date,
        s_addon_todo_end_date: tmp_s_select_date,
        s_addon_todo_s_todo_sort: "",
        s_addon_todo_s_state: "",
        s_addon_todo_addon_par: "",
        s_addon_home_work_file: "1",
        s_addon_home_work_file_start_date: tmp_s_select_date,
        s_addon_home_work_file_end_date: tmp_s_select_date,
        s_addon_plan_study_file: "1",
        s_addon_plan_study_file_start_date: tmp_s_select_date,
        s_addon_plan_study_file_end_date: tmp_s_select_date,
      };
      form_json_data["s_addon_todo_start_date"] = pre_date_str;
      form_json_data["s_addon_home_work_file_start_date"] = pre_date_str;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let tmp_stu_info_arr = response.data["data"]["info_arr"];
          if (is_able_card_view) {
            tmp_stu_info_arr = InfoArrFunc.get_info_arr_by_filter({
              info_arr: response.data["data"]["info_arr"],
              list_opt: form_json_data,
              base_date_str: form_json_data["s_addon_todo_end_date"],
              pre_date_str: pre_date_str
            });
          }
          set_student_info_arr(tmp_stu_info_arr);
        } else {

        }
      });
  };

  const go_change_todo_state_by_ajax=(inData: any)=>{
    let opt_obj: any = {
      "todo_info": null,
      "is_success": "",
      ...inData,
    };
    if (opt_obj.todo_info == null||strFunc.is_empty(opt_obj.todo_info["a_seq"])) {
      alert("내용 없음.");
      return false;
    }

    let confirm_msg=opt_obj.todo_info["a_title"]+"을 완료처리 하시겠습니까?";
    if(opt_obj.is_success!="1"){
      confirm_msg=opt_obj.todo_info["a_title"]+"을 완료취소 하시겠습니까?";
    }
    if(!confirm(confirm_msg)){
      return false;
    }

    let change_todo_state_row:any={
      a_ymd: opt_obj.todo_info["a_ymd"],
      a_seq: opt_obj.todo_info["a_seq"],
    };

    let a_is_success = opt_obj.is_success;
    if(a_is_success=="1"){
      change_todo_state_row["a_is_success"]=a_is_success;
      change_todo_state_row["a_state"]="complete";
      change_todo_state_row["a_end_date"]=DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    }else{
      change_todo_state_row["a_is_success"]="";
      change_todo_state_row["a_state"]="ready";
      change_todo_state_row["a_amount_num"]="0";
    }
    let form_json_data={
      data_arr:[change_todo_state_row],
      is_update:"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          get_student_arr_by_ajax();
        } else {

        }
      });
  };

  const open_find_stu_popup = (inData: any) => {
    let opt_obj: any = {
      "desk_info": null,
      "now_ymdhis": "",
      "now_lecture_data": null,
      ...inData,
    };

    setPopupData({
      ...popupData,
      "desk_info": opt_obj["desk_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "학생찾기",
      "sort": "find_stu",
      width: "800px",
    });
  };

  const open_view_desk_popup = (inData: any) => {
    let opt_obj = {
      room_info: null,
      desk_info: null,
      desku_info: null,
      now_ymdhis: "",
      now_lecture_data: null,
      ...inData
    };
    setPopupData({
      ...popupData,
      "room_info": opt_obj["room_info"],
      "desk_info": opt_obj["desk_info"],
      "desku_info": opt_obj["desku_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "책상보기",
      "sort": "view_desk",
      width: "800px",
      has_header: true,
    });
  };

  const open_view_room_popup = (inData: any) => {
    let opt_obj: any = {
      room_seq: "",
      ...inData,
    };

    setPopupData({
      ...popupData,
      room_seq: opt_obj["room_seq"],
      "isOpen": true,
      "title": "좌석설정",
      "sort": "view_room",
      width: "100%",
      has_header: true,
    });
  };

  const open_select_stu_alloc_desk_popup = (inData: any) => {
    let opt_obj: any = {
      "desk_info": null,
      "now_ymdhis": "",
      "now_lecture_data": null,
      ...inData,
    };

    if (select_stu_data.is_select == false || select_stu_data.stu_info == null) {
      alert("학생선택이 필요합니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      stu_info: select_stu_data.stu_info,
      "desk_info": opt_obj["desk_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "학생찾기",
      "sort": "select_stu_alloc",
      width: "800px",
      has_header: true,
    });
  };

  const callBackDataFindStu = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      "desk_info": null,
      "now_ymdhis": "",
      "select_time": {
        "start_h": 0,
        "start_m": 0,
        "end_date": "0000-00-00",
        "end_h": 0,
        "end_m": 0,
      },
      ...inData,
    };

    if (opt_obj["info_arr"].length == 0) {
      alert("학생 선택이 필요합니다.");
      return false;
    }
    if (opt_obj["desk_info"] == null) {
      alert("책상정보가 없습니다.");
      return false;
    }
    if (!confirm("등록 하시겠습니까?")) {
      return false;
    }
    let u_info = opt_obj["info_arr"][0];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(opt_obj["now_ymdhis"]));
    let tmp_end_hi = strFunc.HourMinuteToTime(opt_obj["select_time"]["end_h"], opt_obj["select_time"]["end_m"]);
    let tmp_end_date_json = DateFunc.get_date_json(new Date(opt_obj["select_time"]["end_date"] + " " + tmp_end_hi + ":" + tmp_start_date_json.s));
    let target_time_sec = tmp_end_date_json.date_obj.getTime() - tmp_start_date_json.date_obj.getTime();
    target_time_sec = target_time_sec / 1000;

    let desk_u_row = {
      "a_ymd": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Ymd"),
      "a_seq": "",
      "a_room_seq": opt_obj["desk_info"]["a_room_seq"],
      "a_x": opt_obj["desk_info"]["a_x"],
      "a_y": opt_obj["desk_info"]["a_y"],
      "a_name": opt_obj["desk_info"]["a_name"],
      "a_date": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Y-m-d"),
      "a_end_date": opt_obj["select_time"]["end_date"],
      "a_user_seq": u_info["a_seq"],
      "a_user_name": u_info["a_user_name"],
      "a_user_grade": u_info["a_user_grade"],
      "a_start_h": opt_obj["select_time"]["start_h"],
      "a_start_m": opt_obj["select_time"]["start_m"],
      "a_end_h": opt_obj["select_time"]["end_h"],
      "a_end_m": opt_obj["select_time"]["end_m"],
      "a_target_time_sec": target_time_sec,
      "a_writer_seq": user.user_seq,
      "a_writer": user.user_name,
    };

    let is_after_write_attend = "";
    if (is_add_attend_by_desk) {
      //is_after_write_attend="1";
    }
    let desku_w_form_data = {
      "data_arr": [desk_u_row],
      "is_default_val": "1",
      "is_after_write_attend": is_after_write_attend,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/add_arr', desku_w_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          get_student_arr_by_ajax();
          if (select_stu_data.is_select) {
            set_select_stu_data({
              is_select: false,
              stu_info: null,
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const show_detail_view = (inData: any) => {
    let opt_obj = {
      stu_info: null,
      ...inData,
    };

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: 0,
        info_arr: [opt_obj.stu_info],
        default_write_info: {},
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });
  };

  const openTodoDaySort = (inData: any) => {
    let opt_obj = {
      select_stu_name: "",
      select_stu_seq: "",
      select_date: listOpt.s_select_date,
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "todo_day_sort",
      title: "",
      width: "98%",
      height: "99%",
      ...opt_obj
    });
  };

  const openTodoWriteOnePopup = (inData: any) => {
    let opt_obj = {
      select_date: "",
      select_stu_name: "",
      select_stu_seq: "",
      todo_info: {},
      todo_sort: "study",
      isUpdate: true,
      ...inData
    };
    if (opt_obj["isUpdate"]) {
      if (strFunc.is_empty(opt_obj["todo_info"]["a_seq"])) {
        alert("내용 없음.");
        return false;
      }
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "todo_write_one",
      select_stu_name: opt_obj["select_stu_name"],
      select_stu_seq: opt_obj["select_stu_seq"],
      select_date: opt_obj["select_date"],
      todo_sort: opt_obj["todo_sort"],
      todo_info: opt_obj["todo_info"],
      isUpdate: opt_obj["isUpdate"],
      title: "맞춤플랜",
      ...opt_obj
    });
  };

  return (
    <div ref={room_list_wrap_ref}>
      <div>
        <SearchArea
          list={list}
          listOpt={listOpt}
          now_ymdhis={now_ymdhis}
          set_now_ymdhis={set_now_ymdhis}
          is_now_time={is_now_time}
          set_is_now_time={set_is_now_time}
          is_show_only_now_lecture={is_show_only_now_lecture}
          set_is_show_only_now_lecture={set_is_show_only_now_lecture}
          is_show_msg={is_show_msg}
          set_is_show_msg={set_is_show_msg}
          set_room_msg_arr={set_room_msg_arr}
          is_show_stu_select_list={is_show_stu_select_list}
          set_is_show_stu_select_list={set_is_show_stu_select_list}
          is_stu_filter_lecture={is_stu_filter_lecture}
          set_is_stu_filter_lecture={set_is_stu_filter_lecture}
          select_stu_filter={select_stu_filter}
          set_select_stu_filter={set_select_stu_filter}
          is_stu_filter_use_desk={is_stu_filter_use_desk}
          set_is_stu_filter_use_desk={set_is_stu_filter_use_desk}
          select_stu_data={select_stu_data}
          set_select_stu_data={set_select_stu_data}
          is_add_attend_by_desk={is_add_attend_by_desk}
          set_is_add_attend_by_desk={set_is_add_attend_by_desk}
          is_able_card_view={is_able_card_view}
          cardview_sort={cardview_sort}
          set_cardview_sort={set_cardview_sort}
        ></SearchArea>
        {myProps.is_show_search_room &&
          <SearchRoomArea list={list} s_room_seq={listOpt.s_room_seq} search_room_list={search_room_list}></SearchRoomArea>
        }
        {is_able_card_view == false &&
          <RoomListArea ref={RoomListAreaRef}
            room_info_arr={infoArr}
            now_ymdhis={now_ymdhis}
            pre_date_str={pre_date_str}
            open_find_stu_popup={open_find_stu_popup}
            open_view_desk_popup={open_view_desk_popup}
            open_select_stu_alloc_desk_popup={open_select_stu_alloc_desk_popup}
            is_detal_popup={myProps.is_detal_popup}
            is_show_only_now_lecture={is_show_only_now_lecture}
            is_show_msg={is_show_msg}
            is_show_stu_select_list={is_show_stu_select_list}
            is_stu_filter_lecture={is_stu_filter_lecture}
            is_stu_filter_use_desk={is_stu_filter_use_desk}
            select_stu_filter={select_stu_filter}
            set_select_stu_filter={set_select_stu_filter}
            room_msg_arr={room_msg_arr}
            student_info_arr={student_info_arr}
            select_stu_data={select_stu_data}
            set_select_stu_data={set_select_stu_data}
          ></RoomListArea>
        }
        {is_able_card_view &&
          <CardRoomListArea
            room_info_arr={infoArr}
            student_info_arr={student_info_arr}
            now_ymdhis={now_ymdhis}
            pre_date_str={pre_date_str}
            cardview_sort={cardview_sort}
            open_view_room_popup={open_view_room_popup}
            show_detail_view={show_detail_view}
            open_view_desk_popup={open_view_desk_popup}
            openTodoDaySort={openTodoDaySort}
            openTodoWriteOnePopup={openTodoWriteOnePopup}
            go_change_todo_state_by_ajax={go_change_todo_state_by_ajax}
          ></CardRoomListArea>
        }
        {myProps.is_show_paging &&
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header}>
          {popupData.sort === "find_stu" &&
            <FindStudentPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={callBackDataFindStu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindStudentPopup>
          }
          {popupData.sort === "select_stu_alloc" &&
            <SelectStuAllowDeskPopup
              stu_info={popupData.stu_info}
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={callBackDataFindStu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></SelectStuAllowDeskPopup>
          }
          {popupData.sort == "view_desk" &&
            <ViewDeskPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              desku_info={popupData.desku_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={() => { list({ "now_page": "1" }) }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewDeskPopup>
          }
          {popupData.sort == "view_room" &&
            <ViewRoomPopup
              room_seq={popupData.room_seq}
              is_window_popup={false}
              callBackRfresh={() => { list({ "now_page": "1" }) }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewRoomPopup>
          }
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
          {popupData.sort == "todo_day_sort" &&
            <ViewTodoDaySortPopup
              list_opt={{
                "s_stu_user_name": popupData.select_stu_name,
                "s_stu_user_seq": popupData.select_stu_seq,
                "s_start_date": popupData.select_date,
                "s_end_date": popupData.select_date,
              }}
              list={()=>{
                list({});
                get_student_arr_by_ajax();
              }}
              openTodoWriteOnePopup={openTodoWriteOnePopup}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewTodoDaySortPopup>
          }
          {popupData.sort == "todo_write_one" &&
            <WriteTodoPopup
              stu_seq={popupData.select_stu_seq}
              stu_name={popupData.select_stu_name}
              select_date={popupData.select_date}
              rowData={popupData.todo_info}
              todo_sort={popupData.todo_sort}
              isUpdate={popupData.isUpdate}
              callback={(inData: any) => {
                list({});
                get_student_arr_by_ajax();
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteTodoPopup>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default RoomListWrap;