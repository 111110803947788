import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"todo",
    list_id:"stu_todo_history",
    x_column_list_arr:[
      {"key":"row_view_reserve_date","name":"예정일","width":"80","is_show":"1"},
      {"key":"a_stu_name","name":"학생","width":"100","is_show":"1"},
      {"key":"row_view_par_title","name":"교과","width":"150","is_show":"1"},
      {"key":"row_view_par_section","name":"대메뉴","width":"150","is_show":"1"},
      {"key":"a_title","name":"제목","width":"350","is_show":"1"},
      {"key":"row_view_state","name":"상태","width":"70","is_show":"1"},
      {"key":"row_view_success_amount","name":"목표","width":"80","is_show":"1"},
      {"key":"row_view_per","name":"진행","width":"70","is_show":"1"},
      {"key":"row_view_time_str","name":"시간","width":"90","is_show":"1"},
      {"key":"a_writer_name","name":"작성자","width":"90","is_show":"1"},
      {"key":"row_view_file_cnt","name":"첨부","width":"130","is_show":"1"},
      {"key":"a_sort","name":"구분","width":"80","is_show":"1"},
      {"key":"a_subject","name":"과목","width":"120","is_show":"1"},
      {"key":"a_start_date","name":"시작일","width":"100","is_show":""},
      {"key":"a_reserve_end_date","name":"마감일","width":"100","is_show":""},
      {"key":"a_end_date","name":"종료일","width":"100","is_show":""},
      {"key":"a_create_date","name":"작성일","width":"120","is_show":""},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_reserve_start_date DESC',
      'order_type':'',
      's_date_type':'a_reserve_start_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;