import GNBArea from "@/pcomponents/comp/layout/menu/gnb";
import prj_logo_img from "@/img/logo/sntb_logo.png";
import dalirant250_logo from "@/img/logo/dalirant_w_h_500.png";
import option_ico_right_ico from "@/img/ico/head/option_ico_right.png";
import { useNavigate } from 'react-router-dom';
import CheckLoginComponent from '@/pcomponents/comp/check/login_check';
import checkVersion from '@/pcomponents/comp/check/version/check_version';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnFunc from '@/pcomponents/common/xcolumn/get_data/get_xcolumn';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import popupFunc from "@/lib/lyg/popup";
import { setUnreadTot } from '@/store/chat/chat';
import { setUser, removeUser } from '@/store/user/user';
import person_img from "@/img/ico/person_ico.png";
import SiteMapBtn from "./site_map_btn";
import TopSiteMap from "./site_map";
import SNBArea from "../menu/snb";
import LoginOtherArea from "@/pcomponents/common/content/basic/login/login_other";

const Header = (props: any) => {
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const chat = useSelector((state: any) => state.chat);
  const [is_view_site_map, set_is_view_site_map] = useState(false);
  const SiteMapBtnRef = useRef<any>(null);

  useEffect(() => {
    checkVersion();
    getXcolumnFunc({ "user_seq": user.user_seq });
  }, []);
  useEffect(() => {
    const timerId = setInterval(getUnreadCount, 10000);
    return () => clearInterval(timerId);
  }, [user]);

  const getUnreadCount = () => {
    if (user.user_seq == "") {
      return false;
    }
    let unread_list_opt = {
      "s_user_key": user.comp_seq + "," + user.user_seq,
      "is_need_count": "1",
      "is_need_info_arr": "",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/chat/unread/list', unread_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          dispatch(setUnreadTot({
            "tot": response.data["data"]["tot"]
          }));
        }
      });
  };

  const goLogOut = () => {
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/logout', {
      token_id: localStorage.intoyou_token,
    }, get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("intoyou_token");
      navigate("/login");
    });
  };

  let person_img_url = person_img;
  let person_img_url_pop = person_img;
  if (user.profile_thum_url) {
    person_img_url = user.profile_thum_url;
    person_img_url_pop = user.profile_url;
  }

  let comp_sort_detail = "plani";
  if (location.hostname == "dalirant.planiedu.com") {
    comp_sort_detail = "dalirant";
  }

  let logo_url_str = prj_logo_img;
  if (comp_sort_detail == "dalirant") {
    logo_url_str = dalirant250_logo;
  }
  if (user.logo_url) {
    logo_url_str = user.logo_url;
  }

  return (
    <>
      <div id="header">
        <CheckLoginComponent></CheckLoginComponent>
        <div className="header_con">
          <div className="header_top_wrap">
            <div className="header_top_link_wrap">
              {/* <a className="header_top_link_btn" href="https://www.pageshop.co.kr/PreviewBook/Classification" target="_blank" >교재샵</a>
            <span className="header_top_split" >|</span> */}

              <a className="header_top_link_btn"
                onClick={() => { navigate("/comp/basic/corp/main"); }} >
                <img src={option_ico_right_ico} style={{ height: 18 }} />
              </a>
              <span className="header_top_split" >|</span>
              <a className="header_top_link_btn"
                onClick={() => {
                  let win_h = (window.innerHeight || document.documentElement.clientHeight);
                  popupFunc.openPopup({ 'url': "/comp/chat/chat_popup", 'width': 1200, 'height': win_h, 'pop_name': 'chat', 'auto_size': false });
                }}>
                플랜톡
                {chat.unreadToT != "0" &&
                  <span className="text-red-300 text-xs" >({chat.unreadToT})</span>
                }
              </a>
              <span className="header_top_split" >|</span>
              <a className="header_top_link_btn"
                onClick={() => {
                  navigate("/comp/board/data_main");
                }}>
                자료실
              </a>
              <span className="header_top_split" >|</span>
              <a className="header_top_link_btn" onClick={() => { navigate("/comp/mypage") }} >{user.user_name} 님</a>
              <button className="btn-s2 btn-line-gray ml-4" onClick={goLogOut} >로그아웃</button>
            </div>
          </div>
          <div className="gnb_wrap">
            <div className="logo_area">
              <a onClick={() => { navigate("/comp"); }} className="logo_area_img_a">
                <img src={logo_url_str} alt="로고" />
              </a>
            </div>
            <GNBArea
              site_map_btn={
                <SiteMapBtn ref={SiteMapBtnRef} onIsActive={(inData: boolean) => { set_is_view_site_map(inData); }}></SiteMapBtn>
              }
            ></GNBArea>
          </div>
        </div>
        {is_view_site_map &&
          <TopSiteMap ></TopSiteMap>
        }
      </div>
      <div className="snb_area_wrap">
        <SNBArea></SNBArea>
        <LoginOtherArea></LoginOtherArea>
      </div>
    </>
  );
};

export default Header;