import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

export const default_daily_report_last_info={
  a_stu_seq:"",
  a_writer_seq:"",
  a_lecture_seq:"",
  a_date:"",
  a_lecture_name:"",
  a_lecture_time:"",
  a_stu_name:"",
  a_writer:"",
  a_subject:"",
  a_grade_score:"",
  a_attend_sort_detail:"",
  a_attend_time:"",
  a_homework_per_num:"",
  a_homework_file_cnt:"",
  a_textbook:"",
  a_textbook_seq:"",
  a_subtextbook:"",
  a_subtextbook_seq:"",
  a_homework:"",
  a_textbook_study:"",
  a_subtextbook_study:"",
  a_textbook_study_det:"",
  a_subtextbook_study_det:"",
  a_textbook_study_num:"",
  a_textbook_study_det_num:"",
  a_todo_time_sec:"",
  a_todo_success_amount:"",
  a_todo_amount:"",
  a_todo_sub_success_amount:"",
  a_todo_sub_amount:"",
  a_memo:"",
};
export const get_last_daily_report_by_month_prescribe_info=(inData:any)=>{
  let opt_obj = {
    row_data: {},
    end_date:"",
    is_able_last_info:false,
    ...inData
  };
  let row_data = opt_obj["row_data"];
  if(strFunc.is_empty(opt_obj["end_date"])){
    opt_obj["end_date"]=DateFunc.get_date_format(new Date(),"Y-m-d");
  }
  let last_daily_report={...default_daily_report_last_info};
 
  if (strFunc.is_empty(row_data["a_stu_seq"])
    || strFunc.is_empty(row_data["a_subject_name"])) {
      return last_daily_report;
  }
  if(row_data.daily_info_arr&&row_data.daily_info_arr.length>0){
    for(let i=0;i<row_data.daily_info_arr.length;i++){
      let tmp_daily_info=row_data.daily_info_arr[i];
      if(tmp_daily_info["a_subject"]==row_data["a_subject_name"]){
        if(opt_obj["end_date"]>=tmp_daily_info["a_date"]){
          last_daily_report={...default_daily_report_last_info,...tmp_daily_info};
          return last_daily_report;
        }
      }
    }
  }
  if(opt_obj.is_able_last_info){
    if(row_data.daily_last_info_arr&&row_data.daily_last_info_arr.length>0){
      if(opt_obj["end_date"]>=row_data.daily_last_info_arr[0]["a_date"]){
        last_daily_report={...default_daily_report_last_info,...row_data.daily_last_info_arr[0]}; 
      }
      return last_daily_report;
    }
  }
  
  return last_daily_report;
};