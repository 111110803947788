//import SearchSubjectArea from "@/pages/comp/plan/study/study/list/area/search_area/search_subject";
import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from '@/lib/lyg/string';

function SearchSubjectArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    is_show_key_json:{},
    ...props
  };
  let is_show_key_json={
    s_subject_sort: true,
    s_subject_main_seq: true,
    s_subject_seq: true,
	  s_title_like:true,
    ...myProps.is_show_key_json
  };

  let listOpt = myProps.listOpt;
  const [subjectArr, setsubjectArr] = useState<any>([]);
  const [subject_main_arr, set_subject_main_arr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  useEffect(() => {
    list_subject_by_ajax({});
    get_subject_main_by_ajax({});
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_listOpt: any = {
      ...listOpt,
      [name]: value
    };
    if (name == "s_subject_sort") {
      get_subject_main_by_ajax({ s_sort: value });
      list_subject_by_ajax({ s_sort: value });
      change_listOpt["s_subject_main_seq"] = "";
      change_listOpt["s_subject_seq"] = "";
    } else if (name == "s_subject_main_seq") {
      list_subject_by_ajax({ s_main_seq: value });
      change_listOpt["s_subject_seq"] = "";
    }

    myProps.setListOpt(change_listOpt);
    if (name == "s_search_text") {
      return false;
    }
    myProps.list(change_listOpt);
  };

  const get_subject_main_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      ...inData
    };
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_subject_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      s_main_seq: listOpt.s_subject_main_seq,
      ...inData
    };
    if (strFunc.is_empty(opt_obj.s_main_seq)) {
      setsubjectArr([]);
      return false;
    }
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "s_main_seq": opt_obj.s_main_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <span>
      <select className="search-input_select" name="s_subject_sort" value={listOpt.s_subject_sort}
        onChange={handleInputChange}
        style={{ width: 70 }} >
        <option value="">과목</option>
        {
          subject_option_arr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select ml-1" name="s_subject_main_seq" value={listOpt.s_subject_main_seq}
        onChange={handleInputChange}
        style={{ width: 70 }}>
        <option value="">대분류</option>
        {
          subject_main_arr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <select className="search-input_select ml-1" name="s_subject_seq" value={listOpt.s_subject_seq}
        onChange={handleInputChange}
        style={{ width: 70 }}>
        <option value="">분류</option>
        {
          subjectArr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      {is_show_key_json.s_title_like&&
      <input type="text" className="search-input ml-1" name="s_title_like" value={listOpt.s_title_like}
        onChange={handleInputChange} style={{ width: 70 }}
        placeholder="제목" />
      }
    </span>
  );
};
export default SearchSubjectArea;