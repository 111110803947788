import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import Style from "./css/style.module.css";
import StuSearchList from "./area/stu_list";
import ClassSearchList from "./area/class_list";
import PlanCalendarArea from "./area/calendar";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewTodoDaySortPopup from "./area/popup/view_todo_day_sort";
import WriteTodoArrPopup from "@/pages/comp/plan/calendar/area/popup/write_todo_arr";
import WriteTodoPopup from "@/pages/comp/plan/stu_todo_history/popup/write";
import TopSearchTabArea from "./area/left_tab";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useLocation } from "react-router";

function PlanCalendarPage() {
  const { state } = useLocation();
  let page_state:any={
    stu_seq_arr:[],
    stu_info_arr:[],
    ...state
  };
  const [select_tab, set_select_tab] = useState("stu");//stu,class
  const StuSearchListRef = useRef<any>(null);
  const ClassSearchListRef = useRef<any>(null);
  const PlanCalendarAreaRef = useRef<any>(null);
  const left_box_ref = useRef<any>(null);
  const search_plan_todo = (inData: any) => {
    PlanCalendarAreaRef.current.list(inData);
  };

  useEffect(() => {
    if(!strFunc.is_empty(page_state.stu_seq_arr)){
      if(PlanCalendarAreaRef.current){
        search_plan_todo({
          "s_stu_user_seq":page_state.stu_seq_arr,
          "s_stu_info_arr":page_state.stu_info_arr,
        });
      }
    }

    const height_count_loop = setInterval(() => {
      if (left_box_ref.current) {
        if (StuSearchListRef.current) {
          let tmp_height = left_box_ref.current.offsetHeight - 160;
          StuSearchListRef.current.set_height(tmp_height + "px");
        }
      }
    }, 1000);
    return () => {
      clearInterval(height_count_loop);
    };
  }, []);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "todo_day_sort",//view
    rowData: {},
    isUpdate: false,
    select_stu_name: "",
    select_stu_seq: "",
    select_date: "",
    todo_info: {},
    title: "맞춤플랜 보기",
    width: "900px",
    height: "100%",
  });

  const openTodoDaySort = (inData: any) => {
    let opt_obj = {
      select_stu_name: "",
      select_stu_seq: "",
      select_date: "",
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "todo_day_sort",
      title: "",
      ...opt_obj
    });
  };
  const openTodoWritePopup = (inData: any) => {
    let opt_obj = {
      select_stu_name: "",
      select_stu_seq: [],
      select_date: "",
      ...inData
    };
    if (StuSearchListRef.current) {
      let select_stu_seq_arr = [];
      let select_stu_arr = StuSearchListRef.current.get_select_info_arr();
      for (let i = 0; i < select_stu_arr.length; i++) {
        select_stu_seq_arr.push(select_stu_arr[i]["a_seq"]);
      }
      opt_obj.select_stu_seq = select_stu_seq_arr;
    } else {
      opt_obj.select_stu_seq = [];
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "todo_write",
      title: "맞춤플랜 작성",
      ...opt_obj
    });
  };
  const openTodoWriteOnePopup = (inData: any) => {
    let opt_obj = {
      select_date: "",
      todo_info: {},
      ...inData
    };
    if (strFunc.is_empty(opt_obj["todo_info"]["a_seq"])) {
      alert("내용 없음.");
      return false;
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "todo_write_one",
      todo_info: opt_obj["todo_info"],
      isUpdate: true,
      title: "맞춤플랜",
      ...opt_obj
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <div>
          {/* <SearchTopArea
            ref={SearchTopAreaRef}
          ></SearchTopArea> */}
        </div>
        <div className={Style.wrap}>
          <div className={Style.left_box} ref={left_box_ref}>
            <TopSearchTabArea
              select_tab={select_tab}
              set_select_tab={set_select_tab}
            ></TopSearchTabArea>
            {select_tab == "stu" &&
              <StuSearchList
                ref={StuSearchListRef}
                list={(inData: any) => { search_plan_todo(inData); }}
              ></StuSearchList>
            }
            {select_tab == "class" &&
              <ClassSearchList
                ref={ClassSearchListRef}
                list={(inData: any) => { search_plan_todo(inData); }}
              ></ClassSearchList>
            }
          </div>
          <div className={Style.cal_box}>
            <PlanCalendarArea
              ref={PlanCalendarAreaRef}
              openTodoDaySort={openTodoDaySort}
              openTodoWritePopup={openTodoWritePopup}
              removeSelectStu={(stu_seq: any) => {
                if (select_tab == "stu") {
                  StuSearchListRef.current.removeSelectStu(stu_seq);
                } else if (select_tab == "class") {
                  ClassSearchListRef.current.removeSelectStu(stu_seq);
                }
              }}
              onSearchDateChange={(inData: any) => {
                if (StuSearchListRef.current) {
                  StuSearchListRef.current.list({
                    s_addon_todo_start_date: DateFunc.get_date_format(new Date(inData), "Y-m-01"),
                    s_addon_todo_end_date: DateFunc.get_date_format(new Date(inData), "Y-m-t"),
                  });
                }
              }}
            ></PlanCalendarArea>
          </div>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px", paddingBottom: 85 }} y={"0"}>
          {popupData.sort == "todo_day_sort" &&
            <ViewTodoDaySortPopup
              list_opt={{
                "s_stu_user_name": popupData.select_stu_name,
                "s_stu_user_seq": popupData.select_stu_seq,
                "s_start_date": popupData.select_date,
                "s_end_date": popupData.select_date,
              }}
              list={(inData: any) => {
                search_plan_todo(inData);
                if (StuSearchListRef.current) {
                  StuSearchListRef.current.list({});
                }
              }}
              openTodoWriteOnePopup={openTodoWriteOnePopup}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewTodoDaySortPopup>
          }
          {popupData.sort == "todo_write" &&
            <WriteTodoArrPopup
              select_date={popupData.select_date}
              stu_seq_arr={strFunc.is_empty(popupData.select_stu_seq) ? [] : popupData.select_stu_seq}
              calback={(inData: any) => {
                search_plan_todo({});
                if (StuSearchListRef.current) {
                  StuSearchListRef.current.list({});
                }
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteTodoArrPopup>
          }
          {popupData.sort == "todo_write_one" &&
            <WriteTodoPopup
              rowData={popupData.todo_info}
              isUpdate={popupData.isUpdate}
              callback={(inData: any) => {
                search_plan_todo({});
                if (StuSearchListRef.current) {
                  StuSearchListRef.current.list({});
                }
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteTodoPopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default PlanCalendarPage;