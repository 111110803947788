import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";
import { useState } from 'react';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    xColumnArr: {},
    listOpt: {
      "s_start_date": "",
      "s_end_date": "",
      "s_search_text_type": "",
      "s_search_text": "",
      "s_stu_bookmark": "",
      "s_subject": "",
    },
    list: (inData: any) => { },
    stu_list: (inData: any) => { },
    openOnePopup: (inData: any) => { },
    openMoveDatePopup: (inData: any) => { },
    openListOptPopup: (inData: any) => { },
    goDelete: (inData: any) => { },
    unselect_stu: () => { },
    go_change_select_key_val: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let state_sort_opt_arr = [];
  if (myProps.xColumnArr.select_arr && myProps.xColumnArr.select_arr.a_state) {
    state_sort_opt_arr = myProps.xColumnArr.select_arr && myProps.xColumnArr.select_arr.a_state;
  }
  const [is_more_search, set_is_more_search] = useState(false);
  const [change_data_json, set_change_data_json] = useState({
    "a_sort": "study",
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value, "now_page": 1 });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ [key]: date_str, "now_page": 1 });
  };

  const go_prev_after = (sort: "prev" | "after",change_sort:"day"|"month") => {
    let selected_date = listOpt.s_start_date;
    if (strFunc.is_empty(selected_date)) {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    if(change_sort=="month"){
      selected_date = DateFunc.get_date_format(new Date(selected_date), "Y-m-01");
    }
    let change_date_str = "";
    let change_end_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), change_sort, -1), "Y-m-d");
    } else if (sort == "after") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(selected_date), change_sort, +1), "Y-m-d");
    }
    change_end_date_str=change_date_str;
    if(change_sort=="month"){
      change_end_date_str = DateFunc.get_date_format(new Date(change_date_str), "Y-m-t");
    }
    myProps.list({
      "s_start_date": change_date_str,
      "s_end_date": change_end_date_str,
      "now_page": 1
    });
  };

  const go_today_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "now_page": 1
    });
  };
  const go_now_month_search = () => {
    myProps.list({
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
      "now_page": 1
    });
  };

  const on_change_change_data_json = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    let tmp_change_data_json: any = { ...change_data_json };
    tmp_change_data_json[name] = value;

    set_change_data_json(tmp_change_data_json);
  };

  return (
    <div className="relative">
      <div className="search-box relative py-0" style={{ lineHeight: "20px" }}>
        <select className="search-input_select" name="s_date_type" value={listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_create_date">작성일</option>
          <option value="a_reserve_start_date">예정일</option>
        </select>
        <div className="search_date_picker_wrap">
          <DatePicker
            wrapperClassName=""
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div className="search_date_picker_wrap">
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-dark" onClick={() => { go_prev_after("prev","month"); }}>◀</button>
        <button className="btn btn-dark" onClick={() => { go_prev_after("prev","day"); }}>◁</button>
        <button className="btn btn-dark" onClick={() => { go_prev_after("after","day"); }}>▷</button>
        <button className="btn btn-dark" onClick={() => { go_prev_after("after","month"); }}>▶</button>
        <button className="btn btn-gray" onClick={go_today_search} >오늘</button>
        <button className="btn btn-gray" onClick={go_now_month_search} >이번달</button>
        <select className="search-input_select" value={listOpt.s_is_success} name="s_is_success"
          onChange={handleInputChange} >
          <option value="">완료여부</option>
          <option value="1">예</option>
          <option value="empty">아니오</option>
        </select>
        <select className="search-input_select" value={listOpt.s_state} name="s_state"
          onChange={handleInputChange} >
          <option value="">상태</option>
          {state_sort_opt_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item.value} >{item.text}</option>
            );
          })}
        </select>
        <select className="search-input_select" value={listOpt.s_todo_sort} name="s_todo_sort"
          onChange={handleInputChange}>
          <option value="">구분</option>
          <option value="homework">숙제</option>
          <option value="study">학습</option>
          <option value="video">강의</option>
        </select>
        <select className="search-input_select" name="s_subject" value={listOpt.s_subject}
          onChange={handleInputChange}
          style={{ width: 70 }} >
          <option value="">과목</option>
          {
            subject_option_arr.map((item: any, idx: number) => {
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            myProps.stu_list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn btn-gray" onClick={() => { myProps.list({ "now_page": 1 }); }} >검색</button>
      </div>
      <div className="search-box relative py-0 ml-0 mt-1">
        {
          listOpt.s_stu_user_seq.length > 0 &&
          <span className="text-blue-500">
            {listOpt.s_stu_user_seq.length}명
            <button className="btn-s btn-gray ml-1 px-1" onClick={() => {
              myProps.unselect_stu();
            }}>X</button>
          </span>
        }
        <button className="btn btn-dark" onClick={() => { myProps.openOnePopup("write", true); }}>수정</button>
        <button className="btn btn-dark" onClick={() => { myProps.openMoveDatePopup(); }}>일정미루기</button>
        <button className="btn btn-dark" onClick={() => { myProps.openListOptPopup(); }}>리스트설정</button>
        <button className="btn btn-red" onClick={myProps.goDelete}>삭제</button>
        <button className="btn btn-gray" onClick={() => { set_is_more_search(!is_more_search); }} >더보기</button>
      </div>
      {is_more_search &&
        <div className="search-box relative py-0 ml-0 mt-1">
          <span>구분변경:</span>
          <select className="search-input_select" onChange={on_change_change_data_json}
            value={change_data_json.a_sort} name="a_sort">
            <option value="study" >학습</option>
            <option value="homework" >숙제</option>
            <option value="video" >강의</option>
          </select>
          <button className="btn btn-sky" onClick={() => {
            myProps.go_change_select_key_val({
              key: "a_sort",
              value: change_data_json.a_sort
            });
          }}>변경</button>
        </div>
      }
    </div>
  );
};
export default SearchArea;