//import AttendStateFunc from "@/pcomponents/common/content/edu/attend/func/state_func";
class AttendStateFunc
{
  static select_arr={
    a_sort_detail:[
      {"value":"attend","text":"등원"},
      {"value":"late","text":"등원(지각)"},
      {"value":"absent","text":"결석"},
      {"value":"out","text":"하원"},
    ],
  };
  static get_color_by_attend_info(inData:any){
    let opt_obj={
      attend_info:null,
      ...inData
    };
    let attend_info=opt_obj.attend_info;
    let state_data={"value":"","text":"미등원","background":"#707070"};
    let attend_in_state_arr=[
      {"value":"attend","text":"등원","background":"#eab358"},
      {"value":"late","text":"지각","background":"#ea880b"},
      {"value":"absent","text":"결석","background":"#f26b6f"},
    ];
    if(attend_info["a_sort"]=="in"){
      for(let i=0;i<attend_in_state_arr.length;i++){
        let item=attend_in_state_arr[i];
        if(item["value"]==attend_info["a_sort_detail"]){
          state_data={...item};
        }
      }
    }else{
      state_data={"value":"","text":"하원","background":"#53a500"};
    }
    return state_data;
  };
  static get_attend_data_by_user_info(inData:any){
    let opt_obj={
      user_info:null,
      select_date:"",
      ...inData
    };
    let user_info=opt_obj.user_info;
    let select_date=opt_obj.select_date;
    let attend_data:any={
      in:null,
      out:null,
    };
    let attend_in_info:any=null;
    if(user_info.attend_in_arr&&user_info.attend_in_arr.length>0){
      for(let i=0;i<user_info.attend_in_arr.length;i++){
        let row_attend_info=user_info.attend_in_arr[i];
        if(attend_in_info==null){
          if(select_date!=""){
            if(select_date==row_attend_info["a_ymd"]){
              attend_in_info=row_attend_info;
            }
          }else{
            attend_in_info=row_attend_info;
          }
        }
      }
    }
    let attend_out_info:any=null;
    if(user_info.attend_out_arr&&user_info.attend_out_arr.length>0){
      for(let i=0;i<user_info.attend_out_arr.length;i++){
        let row_attend_info=user_info.attend_out_arr[i];
        if(attend_out_info==null){
          if(select_date!=""){
            if(select_date==row_attend_info["a_ymd"]){
              attend_out_info=row_attend_info;
            }
          }else{
            attend_out_info=row_attend_info;
          }
        }
      }
      attend_out_info=user_info.attend_out_arr[0];
    }
    attend_data.in=attend_in_info;
    if(attend_in_info!=null){
      attend_data.out=attend_out_info;
    }
    return attend_data;
  };
}
export default AttendStateFunc;