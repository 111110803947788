import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function ListTabArea(props: any) {
  let myProps = {
    list_tab: "day",//week,day
    set_list_tab: (inData: any) => { },
    open_daily_report_text_popup: (inData: any) => { },
    ...props
  };
  let list_tab_arr = [
    { value: "week", text: "주별" },
    { value: "day", text: "일별" },
  ];

  return (
    <div style={{position:"relative"}}>
      <button className="btn-m btn-dark" style={{background:"#ea5a2f"}}
            onClick={() => { myProps.open_daily_report_text_popup(); }}>데일리진도상담</button>
      <div style={{display:"flex",gap:"2px",justifyContent:"center"}}>
        <SelectBoxBar
          valueTextArr={list_tab_arr}
          value={myProps.list_tab}
          on_change={(inData: any) => {
            myProps.set_list_tab(inData.value);
          }}
          box_style={{minWidth:"50px"}}
        ></SelectBoxBar>
      </div>
    </div>
  );
};
export default ListTabArea;