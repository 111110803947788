import Style from "./css/style.module.css";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import DailyLastArea from "./daily_report";
import strFunc from "@/lib/lyg/string";
import sntb_logo_img from "@/img/logo/sntb_logo.png";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';

function WriteArea(props: any) {
  let myProps = {
    info: {},
    set_info: (inData: any) => { },
    stu_info: {},
    is_view_mode: false,
    ...props
  };
  let info = myProps.info;
  let stu_info = myProps.stu_info;
  let is_view_mode = myProps.is_view_mode;

  let last_daily_report_en = info["last_daily_report_en"];
  let last_daily_report_math = info["last_daily_report_math"];

  if (strFunc.is_empty(last_daily_report_en)) {
    if (!strFunc.is_empty(info["a_en_daily_report_json"])) {
      last_daily_report_en = JSON.parse(info["a_en_daily_report_json"]);
    }
  }
  if (strFunc.is_empty(last_daily_report_math)) {
    if (!strFunc.is_empty(info["a_math_daily_report_json"])) {
      last_daily_report_math = JSON.parse(info["a_math_daily_report_json"]);
    }
  }

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData,
    };
    let name = opt_obj.name;
    let value = opt_obj.value;

    let change_info = { ...info };
    change_info[name] = value;
    myProps.set_info(change_info);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.set_info({
      ...info,
      ...{ [key]: date_str }
    });
  };

  return (
    <div>
      <div className="text-center" style={{ position: "relative", fontSize: "24px", fontWeight: "bold", lineHeight: "36px" }}>
        <span style={{ position: "absolute", left: "0", top: "7px", fontSize: "14px" }}>
          {is_view_mode ?
            info["a_date"]
            :
            <div style={{ display: "inline-block", width: 100 }}>
              <DatePicker
                selected={info.a_date != "" ? new Date(info.a_date) : null}
                onChange={(date: Date) => {
                  onChangeDatePicker("a_date", date);
                }}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputWrite />}
                renderCustomHeader={customHeaderFunc}
              />
            </div>
          }
        </span>
        개별 디스커버리 맵
        <span style={{ position: "absolute", right: "0", top: "0" }}>
          <img src={sntb_logo_img} alt="logo" style={{ height: "38px" }} />
        </span>
      </div>
      <div className={Style.table_base} style={{ marginTop: "5px" }}>
        <table>
          <colgroup>
            <col width={"14%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"8%"}></col>
            <col width={"8%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <th>학년</th>
              <th>과목</th>
              <th colSpan={7} ></th>
            </tr>
            <tr>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_stu_name"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_stu_name"
                      value={info.a_stu_name}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }}
                    />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_stu_grade"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_stu_grade"
                      value={info.a_stu_grade}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <th rowSpan={3}>
                영어
              </th>
              <td colSpan={7} rowSpan={3}>
                <DailyLastArea
                  last_daily_report={last_daily_report_en ? last_daily_report_en : {}}
                ></DailyLastArea>
              </td>
            </tr>
            <tr>
              <th>T분류</th>
              <th>RUTA</th>
            </tr>
            <tr>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_stu_total_code"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_stu_total_code"
                      value={info.a_stu_total_code}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_stu_think_type"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_stu_think_type"
                      value={info.a_stu_think_type}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>입학일</th>
              <th></th>
              <th rowSpan={3}>수학</th>
              <td colSpan={7} rowSpan={3}>
                <DailyLastArea
                  last_daily_report={last_daily_report_math ? last_daily_report_math : {}}
                ></DailyLastArea>
              </td>
            </tr>
            <tr>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_stu_join_date"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_stu_join_date"
                      value={info.a_stu_join_date}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <div className={Style.table_box}></div>
              </td>
              <td>
                <div className={Style.table_box}></div>
              </td>
            </tr>
            <tr>
              <th rowSpan={2}>직전시험성적</th>
              <th>수학</th>
              <th>영어</th>
              <th>국어</th>
              <th>사회</th>
              <th>과학</th>
              <th>평균</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_math_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_math_score"
                      value={info.a_exam_math_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_en_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_en_score"
                      value={info.a_exam_en_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_language_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_language_score"
                      value={info.a_exam_language_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_society_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_society_score"
                      value={info.a_exam_society_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_science_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_science_score"
                      value={info.a_exam_science_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
              <td>
                <div className={Style.table_box}>
                  {is_view_mode ?
                    info["a_exam_average_score"]
                    :
                    <input type="text" className={Style.box_input}
                      name="a_exam_average_score"
                      value={info.a_exam_average_score}
                      onChange={(e: any) => {
                        handleInputChange({ name: e.target.name, value: e.target.value });
                      }} />
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>난제상황</th>
              <td colSpan={9}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_stu_problem_condition}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_stu_problem_condition"
                      value={info.a_stu_problem_condition}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_stu_problem_condition", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <th colSpan={5}>수업반응</th>
              <th colSpan={4}>성향</th>
            </tr>
            <tr>
              <th>영어</th>
              <td colSpan={5}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_lesson_reaction_en}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_lesson_reaction_en"
                      value={info.a_lesson_reaction_en}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_lesson_reaction_en", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
              <td colSpan={4} rowSpan={2}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_stu_tendency}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_stu_tendency"
                      value={info.a_stu_tendency}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_stu_tendency", value: e.target.value });
                      }}
                      style={{ minHeight: "90px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>수학</th>
              <td colSpan={5}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_lesson_reaction_math}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_lesson_reaction_math"
                      value={info.a_lesson_reaction_math}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_lesson_reaction_math", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <th colSpan={5}>학습태도</th>
              <th colSpan={4}>학습정서</th>
            </tr>
            <tr>
              <th>영어</th>
              <td colSpan={5}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_attitude_en}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_attitude_en"
                      value={info.a_attitude_en}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_attitude_en", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
              <td colSpan={4}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_emotion_en}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_emotion_en"
                      value={info.a_emotion_en}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_emotion_en", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>수학</th>
              <td colSpan={5}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_attitude_math}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_attitude_math"
                      value={info.a_attitude_math}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_attitude_math", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
              <td colSpan={4}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_emotion_math}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_emotion_math"
                      value={info.a_emotion_math}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_emotion_math", value: e.target.value });
                      }}
                      style={{ minHeight: "40px" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th >
                원장총평
              </th>
              <td colSpan={9}>
                <div className={Style.table_box2}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_director_review}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_director_review"
                      value={info.a_director_review}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_director_review", value: e.target.value });
                      }}
                      style={{ minHeight: "40px", color: "blue" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th className={Style.prescribe_th}>
                학습처방<br />
                영어
              </th>
              <td colSpan={9}>
                <div className={Style.table_box2 + " " + Style.prescribe_td}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_prescribe_en}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_prescribe_en"
                      value={info.a_prescribe_en}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_prescribe_en", value: e.target.value });
                      }}
                      style={{ minHeight: "40px", color: "blue" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th className={Style.prescribe_th}>
                학습처방<br />
                수학
              </th>
              <td colSpan={9}>
                <div className={Style.table_box2 + " " + Style.prescribe_td}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_prescribe_math}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_prescribe_math"
                      value={info.a_prescribe_math}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_prescribe_math", value: e.target.value });
                      }}
                      style={{ minHeight: "40px", color: "blue" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th className={Style.prescribe_th}>
                T.P
              </th>
              <td colSpan={9}>
                <div className={Style.table_box2 + " " + Style.prescribe_td}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_teaching_point}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_teaching_point"
                      value={info.a_teaching_point}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_teaching_point", value: e.target.value });
                      }}
                      style={{ minHeight: "40px", color: "blue" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th className={Style.prescribe_th}>
                최종상담
              </th>
              <td colSpan={9}>
                <div className={Style.table_box2 + " " + Style.prescribe_td}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_last_counsel}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      class_name={Style.box_textarea}
                      name="a_last_counsel"
                      value={info.a_last_counsel}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_last_counsel", value: e.target.value });
                      }}
                      style={{ minHeight: "40px", color: "blue" }}
                    ></TextAreaComponent>
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default WriteArea;