import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import LoadingComponent from "@/pcomponents/common/content/loading";
import ViewDailyReportContentArea from "./area/view_content";
import WriteArea from "./area/write";

function WriteTdDailyMemoPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    list: (inData: any) => { },
    daily_report: {},
    td_sort: "a_memo",//textbook,subtextbook,amount, sub_amount
    callback_data: (inData: any) => { },
    ...props
  };

  const [daily_report, set_daily_report] = useState({
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_memo: "",
    ...myProps.daily_report
  });
  
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장중..",
  });

  const write_daily_report_by_ajax = () => {
    let form_json_data: any = {
      "data_arr": [daily_report],
    };
    if (strFunc.is_empty(daily_report["a_seq"])) {
      form_json_data["is_default_val"] = "1";
    }
    set_loading_data({
      is_display: true,
      text: "저장중..",
    });
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display: false,
              text: "저장중..",
            });
          }, 1000);
          myProps.list({});
          let last_daily_info=response.data.data[0];
          let change_daily_report = {
            ...daily_report,
            "a_ymd":last_daily_info["a_ymd"],
            "a_seq":last_daily_info["a_seq"],
          };

          myProps.callback_data({
            change_daily_report: change_daily_report,
          });
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div style={{ position: "relative", height: "100%",padding:"10px" }} >
      <h4 style={{ position: "relative" }}>
        <span style={{color:"orange"}}>{daily_report.a_subject}</span>
        <span className="ml-2">{daily_report.a_lecture_name} {daily_report.a_lecture_time}</span>
        <span className="ml-2" style={{color:"blue"}}>{daily_report.a_stu_name}</span>
      </h4>
      <div className="mt-1">
        <ViewDailyReportContentArea
          daily_report={daily_report}
        ></ViewDailyReportContentArea>
      </div>
      <div className="mt-1">
        <WriteArea
          prop_daily_report={myProps.daily_report}
          daily_report={daily_report}
          set_daily_report={set_daily_report}
          td_sort={myProps.td_sort}
        ></WriteArea>
      </div>
      <div className="text-center" >
        <button className="btn-l btn-gray" onClick={write_daily_report_by_ajax} >저장</button>
        <button className="btn-l btn-line-gray ml-1" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      {loading_data.is_display &&
        <LoadingComponent text={loading_data.text}></LoadingComponent>
      }
    </div>
  );
};
export default WriteTdDailyMemoPopup;