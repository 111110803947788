import { useState, useRef, useEffect } from 'react';
import DisCoverMapWriteArea from "@/pages/comp/edu/discover_map/common/write";
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import getXcolumnJson from "./xcolumn/list";
import StuSearchArea from "./area/stu_search";

function DiscoverMapStuWriteArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_user_name:"",
    s_user_name_like: "",
    s_grade: ["student"],
    s_addon_daily_report_last: "1",
    s_addon_discover_map: "1",
    s_addon_discover_map_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    s_addon_discover_map_end_date: DateFunc.get_date_format(new Date(), "Y-m-t"),

    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [select_stu_info,set_select_stu_info]=useState<any>(null);
  const [select_discover_seq,set_select_discover_seq]=useState<any>({});
  const [is_search_stu_ing, set_is_search_stu_ing] = useState(false);
  const [discover_map_arr, set_discover_map_arr] = useState<any>([]);
  const DisCoverMapWriteAreaRef = useRef<any>(null);

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let tmp_listOpt: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    let tmp_list_form_json = { ...tmp_listOpt };
    list_discover_map_by_ajax(tmp_listOpt);

    let least_need_search_key_arr = ["s_user_name_like", "s_school_name",
      "s_stu_grade", "s_stu_bookmark", "s_stu_lecture_seq","s_user_name"];
    let tmp_exist_least_search = false;
    for (let i = 0; i < least_need_search_key_arr.length; i++) {
      let tmp_key = least_need_search_key_arr[i];
      if (!strFunc.is_empty(tmp_list_form_json[tmp_key])) {
        tmp_exist_least_search = true;
      }
    }
    if (tmp_exist_least_search == false) {
      setInfoArr([]);
      return false;
    }
    set_is_search_stu_ing(true);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_discover_map_by_ajax=(inOptObj: any)=>{
    let tmp_listOpt: any = {
      ...listOpt,
      ...inOptObj
    };
    let form_json_data:any={
      "s_date_type":"a_date",
      "s_start_date":tmp_listOpt["s_addon_discover_map_start_date"],
      "s_end_date":tmp_listOpt["s_addon_discover_map_end_date"],
      "order_id":"a_date DESC, a_create_date DESC",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      "max_limit_num":"100",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_discover_map_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_write_page = (inData: any) => {
    let opt_obj = {
      stu_seq: "",
      info: {},
      info_arr: [],
      is_update: false,
      is_view_mode: true,
      ...inData
    };
    if (DisCoverMapWriteAreaRef.current) {
      DisCoverMapWriteAreaRef.current.initData({
        ...opt_obj
      });
    }
  };

  return (
    <div>
      <div>
        <StuSearchArea
          list_opt={listOpt}
          set_list_opt={setListOpt}
          list={list}
          infoArr={infoArr}
          select_stu_info={select_stu_info}
          set_select_stu_info={(inData:any)=>{
            set_select_stu_info(inData);
            let dis_info={};
            let dis_info_arr:any=[];
            let tmp_is_updaet=false;
            if(inData["discover_map_arr"]&&inData["discover_map_arr"].length>0){
              dis_info_arr=inData["discover_map_arr"];
              dis_info=dis_info_arr[0];
              tmp_is_updaet=true;
            }
            go_write_page({
              stu_seq: inData.a_seq,
              info: dis_info,
              info_arr:dis_info_arr,
              is_update: tmp_is_updaet,
              is_view_mode: false
            });
            setListOpt({
              ...listOpt,
              s_user_name_like:"",
            });
            set_is_search_stu_ing(false);
            set_select_discover_seq("");
          }}
          is_search_stu_ing={is_search_stu_ing}
          discover_map_arr={discover_map_arr}
          go_write_page={go_write_page}
          select_discover_seq={select_discover_seq}
          set_select_discover_seq={(inData:any)=>{
            set_select_discover_seq(inData);
            for(let i=0;i<discover_map_arr.length;i++){
              let tmp_item=discover_map_arr[i];
              let tmp_row_pri_val=tmp_item["a_ymd"]+","+tmp_item["a_seq"];
              if(tmp_row_pri_val==inData){
                set_select_stu_info({
                  a_seq:tmp_item["a_stu_seq"],
                  a_user_name:tmp_item["a_stu_name"],
                  a_stu_grade:tmp_item["a_stu_grade"],
                  a_stu_total_code:tmp_item["a_stu_total_code"],
                  a_stu_think_type:tmp_item["a_stu_think_type"],
                  a_stu_join_date:tmp_item["a_stu_join_date"],
                });
              }
            }
            
          }}
        ></StuSearchArea>
      </div>
      <div className="mt-1">
        <DisCoverMapWriteArea
          ref={DisCoverMapWriteAreaRef}
          list={(inData: any) => {
            list({});
          }}
        ></DisCoverMapWriteArea>
      </div>
    </div>
  );
};
export default DiscoverMapStuWriteArea;