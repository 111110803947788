import CoolenMoveBtn from "@/pages/comp/plan/coolen/common/move_btn";
import Style from "./css/style.module.css";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ClassUserManagePopup from "@/pages/comp/plan/coolen/popup/class_user_manage";

function MoveButtonArea(props: any) {
  let myProps = {
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "en_class_manage",
    title: "영어 클래스 관리",
    width: "90%",
    height: "90%",
    has_header: true,
    coolen_sort: "",
  });

  const open_coolen_class_manage_popoup = (inData: any) => {
    let opt_obj = {
      coolen_sort: "",
      ...inData,
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "en_class_manage",
      title: "영어 클래스 관리",
      coolen_sort: opt_obj.coolen_sort,
    });
  };

  let allow_manage_btn_grade_arr = ["master", "teacher", "staff"];

  let cool_manage_btn:any = "";
  if (strFunc.str_in_array(user.user_grade, allow_manage_btn_grade_arr) != -1) {
    cool_manage_btn = (
      <button className="btn btn-dark"
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={() => { open_coolen_class_manage_popoup({}); }} >관리</button>
    );
  }

  let btn_group_list_arr: any = [
    [
      {
        is_coolen: true,
        coolen_sort: "inside",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/coolen_brige.png"} title="브릿지영어 열기" />,
        "on_click": () => { },
        "addon_text": cool_manage_btn
      },
      {
        is_coolen: true,
        coolen_sort: "bank",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/coolen_grammer.png"} title="문제은행(그래머스퀘어) 열기" />,
        "on_click": () => { },
        "addon_text": cool_manage_btn
      }
    ],
  ];
 
  if (strFunc.str_in_array(user.user_grade, allow_manage_btn_grade_arr) != -1) {
    btn_group_list_arr.push([
      {
        is_coolen: false,
        coolen_sort: "",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/nelt.png"} title="브릿지영어 테스트" />,
        "on_click": () => { window.open("https://www.netutor.co.kr/"); },
      },
      {
        is_coolen: false,
        coolen_sort: "",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/class_card.png"} title="클래스 카드" />,
        "on_click": () => { window.open("https://www.classcard.net/"); },
      }
    ]);
    btn_group_list_arr.push([
      {
        is_coolen: false,
        coolen_sort: "",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/answerme.png"} title="앤써미 영어" />,
        "on_click": () => { window.open("https://answermeenglish.com/"); },
      },
      {
        is_coolen: false,
        coolen_sort: "",
        "btn_class_name": Style.move_btn,
        "text": <img src={process.env.REACT_APP_API_URL + "/img/comp/english/textbook_mall.png"} title="교재몰" />,
        "on_click": () => { },
      }
    ]);
  }

  const get_btn_tags = (btn_data_arr: any) => {
    let gtn_tags = btn_data_arr.map((item: any, idx: number) => {
      let addon_text = "";
      if (item.addon_text) {
        addon_text = item.addon_text
      }
      return (
        <div key={idx} className={Style.move_btn_box}>
          <CoolenMoveBtn
            btn_class_name={item.btn_class_name}
            btn_title={item.text}
            is_coolen={item.is_coolen}
            coolen_sort={item.coolen_sort}
            on_click={item.on_click}
            addon_text={addon_text} ></CoolenMoveBtn>
        </div>
      );
    });
    return gtn_tags;
  };
  let comp_sort_detail="plani";
  if (location.hostname == "dalirant.planiedu.com") {
    comp_sort_detail="dalirant";
  }
  const get_btn_group_tags = () => {
    let btn_group_tags = btn_group_list_arr.map((item: any, idx: number) => {
      let row_btn_tags = get_btn_tags(item);
      if(comp_sort_detail=="dalirant"){
        if(idx!=0){
          return "";
        }
      }
      return (
        <div key={idx} className={Style.move_btn_wrap}>
          {row_btn_tags}
        </div>
      );
    });

    return btn_group_tags;
  };

  return (
    <div>
      {get_btn_group_tags()}
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
           has_header={popupData.has_header} >
          {popupData.sort == "en_class_manage" &&
            <ClassUserManagePopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ClassUserManagePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default MoveButtonArea;