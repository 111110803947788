import strFunc from "@/lib/lyg/string";
import StuCardBox from "./card_box";
import CardBoxArea from "@/pages/comp/edu/attend/list_card/area/card_list/area/card_box";
import DateFunc from '@/lib/lyg/date_func';

function StudentCardListArea(props: any) {
  let myProps = {
    room_info: null,
    student_info_arr: [],
    now_desku_arr: [],
    now_ymdhis: "",
    pre_date_str: "",
    cardview_sort: "",
    Style: {},
    show_detail_view: (inData: any) => { },
    open_view_desk_popup: (inData: any) => { },
    openTodoDaySort: (inData: any) => { },
    openTodoWriteOnePopup: (inData: any) => { },
    go_change_todo_state_by_ajax: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let room_info = myProps.room_info;
  let student_info_arr = myProps.student_info_arr;
  let now_desku_arr = myProps.now_desku_arr;
  let now_ymdhis = myProps.now_ymdhis;
  let pre_date_str = myProps.pre_date_str;

  if (myProps.now_ymdhis == "") {
    myProps.now_ymdhis = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
  }
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec = now_date_json.date_obj.getTime() / 1000;

  const get_now_desk_u_tags = () => {
    //정렬
    now_desku_arr = strFunc.get_sorted_arr_of_key_val_obj({
      arr: now_desku_arr,
      sort: "asc",//asc,desc
      key: "a_name",
      is_number: true,
    });
    let desk_u_tags = now_desku_arr.map((desku_info: any, idx: number) => {
      let stu_info: any = null;
      let desk_info: any = null;

      for (let i = 0; i < student_info_arr.length; i++) {
        if (student_info_arr[i]["a_seq"] == desku_info["a_user_seq"]) {
          stu_info = student_info_arr[i];
          break;
        }
      }
      if (stu_info == null) {
        return "";
      }
      if (room_info.desk_arr) {
        for (let i = 0; i < room_info.desk_arr.length; i++) {
          if (room_info.desk_arr[i]["a_x"] == desku_info["a_x"] && room_info.desk_arr[i]["a_y"] == desku_info["a_y"]) {
            desk_info = room_info.desk_arr[i];
            break;
          }
        }
      }

      let desk_num_str = "";
      let time_period = "";//21:00 ~ 22:30
      let remain_minute_num = 0;
      let per_target_num: any = "";
      let per_num = 0;//35
      if (desku_info != null) {
        let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
        let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
        let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        if (!strFunc.is_empty(desku_info["a_end_date"])) {
          end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        }
        let end_date_json = DateFunc.get_date_json(new Date(end_date_str));
        let start_sec = start_date_json.date_obj.getTime() / 1000;
        let end_sec = end_date_json.date_obj.getTime() / 1000;

        let target_time_sec = end_sec - start_sec;

        if (target_time_sec != 0) {
          per_target_num = target_time_sec;
        }
        if (now_sec >= start_sec && now_sec < end_sec) {
          per_num = now_sec - start_sec;
          remain_minute_num = Math.round(per_target_num - per_num);
          if (remain_minute_num != 0) {
            remain_minute_num = Math.ceil(remain_minute_num / 60);
          }
        } else if (now_sec >= end_sec) {
          per_num = per_target_num;
          remain_minute_num = 0;
        }

        desk_num_str = desku_info["a_name"];
        time_period = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
        time_period += " ~ " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]);
      }

      let top_sub_text = (
        <span title={time_period}>
          <span>좌석 {desk_num_str}.</span>
          <span className="ml-2">
            <span style={{ color: "#2eb62c" }}>{remain_minute_num}</span>
            <span className="ml-1">분</span>
          </span>
        </span>
      );

      return (
        <div key={idx}>
          {myProps.cardview_sort == "todo" ?
            (<StuCardBox
              key={idx}
              stu_info={stu_info}
              desku_info={desku_info}
              desk_info={desk_info}
              room_info={room_info}
              Style={Style}
              now_ymdhis={now_ymdhis}
              pre_date_str={pre_date_str}
              show_detail_user_by_seq={(inData: any) => {
                let tmp_select_stu_info = null;
                for (let i = 0; i < myProps.student_info_arr.length; i++) {
                  if (myProps.student_info_arr[i]["a_seq"] == inData) {
                    tmp_select_stu_info = myProps.student_info_arr[i];
                    break;
                  }
                }
                myProps.show_detail_view({ "stu_info": tmp_select_stu_info });
              }}
              open_view_desk_popup={myProps.open_view_desk_popup}
              openTodoDaySort={myProps.openTodoDaySort}
              openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
            ></StuCardBox>) :
            <CardBoxArea
              key={idx}
              user_info={stu_info}
              base_date_str={now_ymdhis.substring(0, 10)}
              pre_date_str={pre_date_str}
              is_view_attend={false}
              is_view_todo_study={true}
              sub_title_text={top_sub_text}
              open_show_detail_view_stu={(inData: any) => {
                myProps.show_detail_view({ "stu_info": inData.user_info });
              }}
              openViewTodoDayPopup={(inData: any) => {
                if (inData.stu_info) {
                  let tmp_todo_open_opt:any={
                    select_stu_name: inData.stu_info.a_user_name,
                    select_stu_seq: inData.stu_info.a_seq,
                  };
                  if(inData.select_date){
                    tmp_todo_open_opt["select_date"]=inData.select_date;
                  }
                  myProps.openTodoDaySort(tmp_todo_open_opt);
                }
              }}
              on_click_sub_title={() => {
                myProps.open_view_desk_popup({
                  room_info: room_info,
                  desk_info: desk_info,
                  desku_info: desku_info,
                  now_ymdhis: myProps.now_ymdhis,
                });
              }}
              openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
              go_change_todo_state_by_ajax={myProps.go_change_todo_state_by_ajax}
            ></CardBoxArea>}
        </div>
      );
    });

    if (strFunc.is_empty(desk_u_tags)) {
      desk_u_tags = (
        <div className="text-center" style={{ color: "gray", lineHeight: "40px", width: "100%" }}>
          내용이 없습니다.
        </div>
      );
    }

    return desk_u_tags;
  };

  return (
    <div className={Style.card_list_wrap} style={{ marginTop: "10px" }}>
      {get_now_desk_u_tags()}
    </div>
  );
};
export default StudentCardListArea;