import Style from "./css/style.module.css";
import CardBoxArea from "./card_box";

function CardListArea(props: any) {
  let myProps = {
    stu_info_arr: [],
    set_stu_info_arr: (inData: any) => { },
    check_seq_arr: [],
    set_check_seq_arr: (inData: any) => { },
    copy_clipboard_report: (inData: any) => { },
    counsel_todo_arr: [],
    wrtie_counsel_todo_by_ajax: (inData: any) => { },
    open_view_counsel_todo: (inData: any) => { },
    ...props
  };
  let stu_info_arr = myProps.stu_info_arr;

  const get_card_box_tags=()=>{
    let card_box_tags=stu_info_arr.map((stu_info:any, idx:number)=>{
      return (
        <CardBoxArea key={idx}
          stu_info={stu_info} 
          stu_info_arr={stu_info_arr}
          set_stu_info_arr={myProps.set_stu_info_arr}
          Style={Style} 
          check_seq_arr={myProps.check_seq_arr} 
          set_check_seq_arr={myProps.set_check_seq_arr}
          copy_clipboard_report={myProps.copy_clipboard_report}
          counsel_todo_arr={myProps.counsel_todo_arr}
          wrtie_counsel_todo_by_ajax={myProps.wrtie_counsel_todo_by_ajax}
          open_view_counsel_todo={myProps.open_view_counsel_todo}></CardBoxArea>
      );
    });

    return card_box_tags;
  };

  return (
    <div className={Style.card_wrap}>
      {get_card_box_tags()}
    </div>
  );
};
export default CardListArea;