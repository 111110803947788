import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import getXcolumnJson from "../../list/xcolumn/list";
import WriteArea from "./area/write";
import ParViewArea from "./area/par_view";

function WritePopupContent(props: any) {
  let myProps = {
    stu_seq: "",
    stu_name: "",
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    todo_sort: "study",
    rowData: {},
    isUpdate: false,
    callback: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (myProps.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const fileWriteOneRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any>({
    "a_ymd": strFunc.str_replace("-", "", myProps.select_date),
    "a_seq": "",
    "a_is_mcomp_public": "1",
    "a_par_mcomp_seq": user.comp_seq,
    "a_sort": myProps.todo_sort,//할일구분(homework:숙제,study:학습,video:강의)
    "a_par_id": "",
    "a_par_seq": "",

    "a_writer_mcomp_seq": user.comp_seq,
    "a_writer_mcomp_user_seq": user.user_seq,
    "a_writer_seq": user.main_user_seq,
    "a_writer_name": user.user_name,
    "a_stu_mcomp_seq": user.comp_seq,
    "a_stu_mcomp_user_seq": myProps.stu_seq,
    "a_stu_seq": "",
    "a_stu_name": myProps.stu_name,

    "a_start_date": "",
    "a_start_date_last": "",
    "a_end_date": "",
    "a_is_reserve": "1",
    "a_reserve_start_date": myProps.select_date,
    "a_reserve_end_date": myProps.select_date,

    "a_subject": "",
    "a_title": "할일",
    "a_content": "",

    "a_success_sort": "amount",//목표구분(time:시간,amount:분량,공백:없음)
    "a_amount_sort": "개",//단위(개,페이지..)
    "a_amount_num": "",//달성량
    "a_success_amount": "10",//성공기준
    "a_success_time_sec": "",//목표시간
    "a_time_sec": "",//경과시간
    "a_is_success": "",
    "a_state": "ready",//상태(ready:준비,ing:하는중,stop:중지,complete:성공)

    "a_result_memo": "",
    "a_is_need_photo": "",
  });

  useEffect(() => {
    if (myProps.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (myProps.rowData[key] != undefined) {
          changeRowData[key] = myProps.rowData[key];
        }
      }
      get_todo_info_by_ajax({
        todo_pri_val:changeRowData["a_ymd"]+","+changeRowData["a_seq"]
      });
    } else {
      get_stu_info_by_ajax(myProps.stu_seq);
    }
  }, []);

  const get_todo_info_by_ajax = (inData:any) => {
    let opt_obj={
      "todo_pri_val":"",
      ...inData
    };
    if (strFunc.is_empty(opt_obj.todo_pri_val)) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr": [opt_obj.todo_pri_val],
      "s_addon_par_content": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            setRowData(response.data["data"]["info_arr"][0]);
          }
        }
      });
  };

  const get_stu_info_by_ajax = (stu_seq: any) => {
    if (strFunc.is_empty(stu_seq)) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr": [stu_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "s_addon_comp_link": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_user_info = response.data["data"]["info_arr"][0];
            let a_stu_seq = "";
            if (tmp_user_info.comp_link_info && tmp_user_info.comp_link_info["a_main_user_seq"]) {
              a_stu_seq = tmp_user_info.comp_link_info["a_main_user_seq"];
            }
            setRowData({
              ...rowData,
              a_stu_mcomp_user_seq: tmp_user_info["a_seq"],
              a_stu_seq: a_stu_seq,
              a_stu_name: tmp_user_info["a_user_name"],
            });
          }
        }
      });
  };

  const goWrite = useCallback((e: any) => {
    if (rowData.a_title == "") {
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if (myProps.isUpdate) {
      if (strFunc.is_empty(rowData["a_seq"])) {
        alert("키값이 없습니다.");
        return false;
      }
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };
    if (myProps.isUpdate == false) {
      wRowData["a_ymd"] = strFunc.str_replace("-", "", rowData["a_reserve_start_date"]);
      wRowData["a_seq"] = "";
    }

    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (myProps.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let childFileInput = null;
          let file_input_value = "";
          if (fileWriteOneRef.current) {
            childFileInput = fileWriteOneRef.current.getFileInput();
            file_input_value = childFileInput.current.value;
          }
          if (file_input_value == "") {
            alert(response.data["msg"]);
            myProps.callback(response.data["data"]);
            myProps.closePopup();
          } else {
            let priVal = xColumnArr.func.getPriValByDataRow({
              xColumnArr: xColumnArr,
              rowData: response.data["data"][0]
            });
            fileWriteOneRef.current.goWrite({
              "a_par_seq": priVal
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  return (
    <div>
      <WriteArea
        rowData={rowData}
        setRowData={setRowData}
        xColumnArr={xColumnArr}
      ></WriteArea>
      <ParViewArea
        rowData={rowData}
      ></ParViewArea>
      {
        rowData.a_seq == "" &&
        <WriteInputFrame
          ref={fileWriteOneRef}
          init_data={{
            a_par_id: xColumnArr.table,
            a_par_seq: myProps.rowData.a_ymd + "," + myProps.rowData.a_seq,
          }}
          callback={() => { alert("저장되었습니다."); myProps.callback(); myProps.closePopup(); }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      {
        rowData.a_seq != "" &&
        <FileListWriteFrame
          listOpt={
            {
              s_par_id: xColumnArr.table,
              s_par_seq: xColumnArr.func.getPriValByDataRow({
                xColumnArr: xColumnArr,
                rowData: myProps.rowData
              }),
              s_start_date: strFunc.get_ymd_to_y_m_d(rowData.a_ymd)
            }
          }
        ></FileListWriteFrame>
      }
    </div>
  );
}

export default WritePopupContent;
