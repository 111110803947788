import { useSelector, useDispatch } from 'react-redux';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import FindPrescribePopup from "@/pages/comp/edu/prescribe/prescribe_check/popup/find";
import FindMultyStaffPopup from "@/pages/comp/basic/user_staff/popup/find_multy";
import WriteCounselPopup from "@/pages/comp/basic/counsel/popup/write";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";
import { useNavigate } from "react-router";
import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";
import ImgFileWriteList from "@/pages/comp/basic/file/frame/img/write_list";
import { month_prescribe_select_arr } from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import DailyLastArea from "./area/daily_last";

function MonthPrescribeWriteArea(props: any) {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    rowData: {},
    setRowData: (inData: any) => { },
    isUpdate: false,
    list: (inData: any) => { },
    is_view_mode: false,
    is_view_stu_info: false,
    comment_list: [],
    select_stu_info_arr: [],
    set_select_stu_info_arr: (inData: any) => { },
    refresh_comment: (inData: any) => { },
    last_daily_report: {},
    set_last_daily_report: (inData: any) => { },
    set_refresh: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  let select_mcomp_data=myProps.select_mcomp_data;
  let rowData = myProps.rowData;
  let isUpdate = myProps.isUpdate;
  let is_view_mode = myProps.is_view_mode;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let row_pri_val = rowData["a_ymd"] + "," + rowData["a_seq"];
  let comment_init_data = {
    writer_seq: rowData.a_writer_seq,
    writer_name: rowData.a_writer_name,
    par_id: "month_prescribe",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(rowData["a_reserve_date"]), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };
  if (isUpdate) {
    comment_init_data["s_start_date"] = rowData["a_reserve_date"];
  }
  let img_file_init_list_opt = {
    "s_start_date": DateFunc.get_date_format(new Date(rowData["a_reserve_date"]), "Y-01-01"),
    "s_par_id": "month_prescribe",
    "s_par_seq": row_pri_val,
    "s_sort1": "a_lack",
    "s_sort2": "",
    "s_sort3": "",
  };

  const ImgFileWriteListRef = useRef<any>(null);
  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    isUpdate: false,
    rowData: {},
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (ImgFileWriteListRef.current) {
      ImgFileWriteListRef.current.set_init_data({
        listOpt: img_file_init_list_opt,
      });
    }
  }, [row_pri_val]);

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_row_data: any = { ...rowData };
    if (name == "a_content" && !strFunc.is_empty(value) && change_row_data["a_is_success"] != "1") {
      change_row_data["a_is_success"] = "1";
      change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      change_row_data["a_success_writer_seq"] = user.user_seq;
      change_row_data["a_success_writer_name"] = user.user_name;
    }
    if (name == "a_success_check_msg" && !strFunc.is_empty(value) && change_row_data["a_is_success_check"] != "1") {
      change_row_data["a_is_success_check"] = "1";
      change_row_data["a_success_check_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    }
    if (name == "a_is_success") {
      if (e.target.checked) {
        value = "1";
      } else {
        value = "";
      }
      if (value == "1") {
        if (change_row_data[name] != "1") {
          change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
          change_row_data["a_success_writer_seq"] = user.user_seq;
          change_row_data["a_success_writer_name"] = user.user_name;
        }
      }
    } else if (name == "a_is_success_check") {
      if (e.target.checked) {
        value = "1";
      } else {
        value = "";
      }
      if (value == "1") {
        if (change_row_data[name] != "1") {
          change_row_data["a_success_check_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        }
      }
    } else if (name == "a_is_request_counsel") {
      if (e.target.checked) {
        value = "1";
      } else {
        value = "";
      }
      if (strFunc.is_empty(change_row_data["a_request_counselor_seq"])) {
        value = "";
      }
      if (value == "1") {
        if (change_row_data[name] != "1") {
          change_row_data["a_request_counsel_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        }
      }
    } else if (name == "a_subject_name") {
      update_subject_name_of_writer_by_ajax({
        subject_name: value
      });
    }
    change_row_data[name] = value;
    myProps.setRowData(change_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const open_find_stu_popup = () => {
    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find",
      width: "800px",
      height: "80%",
    });
  };
  const callBackDataStuPopup = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData,
    };
    if (optObj["info_arr"].length > 0) {
      myProps.set_select_stu_info_arr([
        ...myProps.select_stu_info_arr,
        ...optObj["info_arr"]
      ]);
      let select_stu_info = optObj["info_arr"][0];
      let change_row_data: any = {
        ...rowData,
        a_stu_seq: select_stu_info["a_seq"],
        a_stu_name: select_stu_info["a_user_name"],
      };
      if (select_stu_info.last_mprescribe_arr && select_stu_info.last_mprescribe_arr.length > 0) {
        for (let i = 0; i < select_stu_info.last_mprescribe_arr.length; i++) {
          let last_mprescribe_info = select_stu_info.last_mprescribe_arr[i];
          if (last_mprescribe_info["a_subject_name"] == change_row_data["a_subject_name"]) {
            change_row_data["a_progress_reason"] = last_mprescribe_info["a_progress_reason"];
            change_row_data["a_progress"] = last_mprescribe_info["a_progress"];
            change_row_data["a_grow"] = last_mprescribe_info["a_grow"];
            change_row_data["a_homework_degree"] = last_mprescribe_info["a_homework_degree"];
            change_row_data["a_understand_degree"] = last_mprescribe_info["a_understand_degree"];
            change_row_data["a_study_amount"] = last_mprescribe_info["a_study_amount"];
            change_row_data["a_lesson_attitude"] = last_mprescribe_info["a_lesson_attitude"];
            change_row_data["a_study_emotion"] = last_mprescribe_info["a_study_emotion"];
          }
        }
      }
      let row_report_info:any={};
      if (select_stu_info.daily_report_arr && select_stu_info.daily_report_arr.length > 0) {
        for (let i = 0; i < select_stu_info.daily_report_arr.length; i++) {
          let tmp_report_info = select_stu_info.daily_report_arr[i];
          if (tmp_report_info["a_subject"] == change_row_data["a_subject_name"]) {
            if(strFunc.is_empty(change_row_data["a_seq"])&&strFunc.is_empty(row_report_info["a_seq"])){
              row_report_info=tmp_report_info;
            }
          }
        }
      }
      myProps.set_last_daily_report(row_report_info);
      myProps.setRowData(change_row_data);
    }
  };
  const open_find_counselor_popup = () => {
    let pop_title = "상담자찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find_counselor",
      width: "800px",
      height: "80%",
    });
  };
  const callBackDataCounselorPopup = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData,
    };
    if (opt_obj["info_arr"].length > 0) {
      let select_info = opt_obj["info_arr"][0];
      let change_row_data = {
        ...rowData,
        a_is_request_counsel: "1",
        a_request_counsel_date: DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"),
        a_request_counselor_seq: select_info["a_seq"],
        a_request_counselor: select_info["a_user_name"],
        a_request_counsel_writer_seq: user.user_seq,
        a_request_counsel_writer: user.user_name,
      };
      myProps.setRowData(change_row_data);
    }
  }

  const openFindPrescribePopup = (inData: any) => {
    let opt_obj = {
      sort: "find_pres",
      row_num: 0,
      ...inData,
    };
    let pop_title = "찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      width: "800px",
      height: "80%",
    });
  };

  const openWriteCounselPopup = (inData: any) => {
    let opt_obj = {
      sort: "write_counsel",
      ...inData,
    };

    if (strFunc.is_empty(rowData.a_seq)) {
      return false;
    }
    if (strFunc.is_empty(rowData.a_stu_seq)) {
      alert("학생을 선택해주세요.");
      return false;
    }
    let row_is_update = false;
    let tmp_row_data: any = {
      a_par_id: "month_prescribe",
      a_par_seq: row_pri_val,
      a_content: MonthPrescribeViewFunc.get_view_text_str({ info: rowData })
    };
    let pop_title = "상담등록";
    if (!strFunc.is_empty(rowData.a_request_counsel_seq)) {
      let counsel_pri_val_arr = rowData.a_request_counsel_seq.split(",");
      if (counsel_pri_val_arr.length == 2) {
        tmp_row_data = {
          a_ymd: counsel_pri_val_arr[0],
          a_seq: counsel_pri_val_arr[1],
        };
        row_is_update = true;
        pop_title = "상담수정";
      }
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      isUpdate: row_is_update,
      rowData: tmp_row_data,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataFindPrescribe = (inData: any) => {
    let opt_obj = {
      cate_info: null,
      info_arr: [],
      select_check_idx: 0,
      select_cate_idx: 0,
      row_num: 0,
      key_str: "",
      pri_val: "",
      ...inData,
    };
    if (opt_obj["cate_info"] == null) {
      return false;
    }
    let week_info: any = rowData;
    if (week_info == null) {
      return false;
    }
    let check_info_arr = opt_obj["info_arr"];
    if (check_info_arr.length == 0) {
      return false;
    }
    let check_info = check_info_arr[0];
    let value_str = (opt_obj["select_cate_idx"] + 1) + ") " + opt_obj["cate_info"]["a_title"];
    value_str += "-" + check_info["a_title"];//(opt_obj["select_check_idx"]+1)
    let change_row_data = { ...rowData };
    change_row_data["a_grow"] = value_str;
    myProps.setRowData(change_row_data);
  };

  const update_subject_name_of_writer_by_ajax = (inData: any) => {
    let opt_obj = {
      subject_name: "",
      ...inData
    };
    if (strFunc.is_empty(opt_obj["subject_name"])) {
      return false;
    }
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let up_row_data = {
      a_seq: user.user_seq,
      a_main_subject: opt_obj["subject_name"],
    };
    let formJsonData:any = {
      "data_arr": [up_row_data],
      "is_update": "1"
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        formJsonData["api_key"]=select_mcomp_data.api_key;
        formJsonData["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {

        }
      });
  };

  let stu_grade_str = "";
  let stu_total_code="";
  if (rowData.stu_info) {
    stu_total_code = rowData.stu_info.a_stu_total_code;
    stu_grade_str = rowData.stu_info.a_stu_grade;
    for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
      if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
        stu_grade_str = select_stu_grade_option_arr[i]["text"];
      }
    }
  }

  let th_text_style: any = {
    textAlign: "left", paddingLeft: 5
  };

  return (
    <div className="write_table_small write_table_round">
      <table>
        <colgroup>
          <col width={"20%"}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={2}>
              <div style={{ position: "relative",padding:5 }}>
                학생:
                <span style={{ color: "blue" }}>
                  {rowData.a_stu_name}
                  <span className="ml-1">{stu_total_code}</span>
                  {(myProps.is_view_stu_info && rowData.stu_info) &&
                    <span className="ml-1" style={{ color: "#737373" }}>
                      ({strFunc.cut_str(rowData.stu_info["a_school_name"], 2, "")} {stu_grade_str})
                    </span>
                  }
                </span>
                <button className="btn-s btn-sky ml-1" onClick={open_find_stu_popup}>찾기</button>
                <span className="ml-1">
                  과목:
                  <select className="row-input" name="a_subject_name"
                    value={rowData.a_subject_name}
                    onChange={on_change_input}
                    style={{ width: 60 }} >
                    <option value="">과목</option>
                    {select_subject_option_arr.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item["value"]}>{item["text"]}</option>
                      );
                    })}
                  </select>
                </span>
                <span className="ml-1">
                  날짜:
                  <div style={{ display: "inline-block", width: 100 }}>
                    <DatePicker
                      selected={rowData.a_reserve_date != "" ? new Date(rowData.a_reserve_date) : null}
                      onChange={(date: Date) => {
                        onChangeDatePicker("a_reserve_date", date);
                      }}
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomInputWrite />}
                      renderCustomHeader={customHeaderFunc}
                    />
                  </div>
                </span>
                <span className="ml-1">
                  작성자:
                  {rowData.a_writer_name}
                </span>

                <span style={{ position: "absolute", right: 0, top: 3 }}>
                  {(!strFunc.is_empty(rowData.a_seq) && rowData.a_is_request_counsel == "1") &&
                    <button className="btn btn-dark"
                      onClick={() => {
                        if (!strFunc.is_empty(rowData.a_seq) && rowData.a_is_request_counsel == "1") {
                          let a_counsel_content = MonthPrescribeViewFunc.get_view_text_str({ info: rowData });
                          let counsel_write_row_data: any = {
                            a_par_id: "month_prescribe",
                            a_par_seq: row_pri_val,
                            a_stu_seq: rowData.a_stu_seq,
                            a_stu_name: rowData.a_stu_name,
                            a_content: a_counsel_content,
                          };
                          if (!strFunc.is_empty(rowData.a_request_counselor_seq)) {
                            // counsel_write_row_data.a_counseler_seq = rowData.a_request_counselor_seq;
                            // counsel_write_row_data.a_counseler = rowData.a_request_counselor;
                          }
                          let counsel_is_update = false;
                          if (rowData.counsel_arr && rowData.counsel_arr.length > 0) {
                            counsel_is_update = true;
                            counsel_write_row_data = rowData.counsel_arr[0];
                          }
                          let send_data = {
                            counsel_write_row_data: counsel_write_row_data,
                            counsel_is_update: counsel_is_update,
                          };
                          navigate("/comp/basic/counsel/list", { state: send_data });
                        }
                      }}>
                      상담{(rowData.counsel_arr && rowData.counsel_arr.length > 0) ? "수정" : "등록"}
                    </button>
                  }
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <th style={th_text_style}>
              1. 진도전략
            </th>
            <td>
              <div className="text-left mt-1" style={{ minHeight: 20 }}>
                {is_view_mode ?
                  <MultyLineTextView text={rowData.a_progress_reason}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_progress_reason"}
                    value={rowData.a_progress_reason}
                    class_name={"row-input"}
                    height={32}
                    style={{ "width": "100%" }}
                    onChange={on_change_input}
                    placeholder={"내용입력"}
                  ></TextAreaComponent>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th style={th_text_style}>
              2. 현재 진도
            </th>
            <td>
              <div>
                <DailyLastArea
                  last_daily_report={myProps.last_daily_report}></DailyLastArea>
                {/* {is_view_mode ?
                  <MultyLineTextView text={rowData.a_progress}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_progress"}
                    value={rowData.a_progress}
                    class_name={"row-input"}
                    height={28}
                    style={{ "width": "100%" }}
                    onChange={on_change_input}
                    placeholder={"내용입력"}
                  ></TextAreaComponent>
                } */}
              </div>
            </td>
          </tr>
          <tr>
            <th style={th_text_style}>
              3. 성장한 부분
              {is_view_mode == false &&
                <button className="btn-s btn-dark ml-1" onClick={() => {
                  openFindPrescribePopup({});
                }}>찾기</button>
              }
            </th>
            <td>
              <div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView text={rowData.a_grow}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_grow"}
                      value={rowData.a_grow}
                      class_name={"row-input"}
                      height={32}
                      style={{ "width": "100%" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={th_text_style}>
              <span style={{ fontWeight: "bold", background: "#fff59c", paddingRight: 5 }}>4. 발견</span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div>
                <div className="write_table_small mt-1">
                  <table>
                    <colgroup>
                      <col width={"20%"}></col>
                      <col width={"80%"}></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>숙제 수행도</th>
                        <td>
                          <SelectBoxBar
                            valueTextArr={month_prescribe_select_arr["a_homework_degree"]}
                            value={rowData.a_homework_degree}
                            name={"a_homework_degree"}
                            on_change={(inData: any) => {
                              if (is_view_mode == false) {
                                on_change_input({
                                  target: {
                                    name: inData.name,
                                    value: inData.value,
                                  }
                                });
                              }
                            }}
                            style_type={"wide"}
                          ></SelectBoxBar>
                        </td>
                      </tr>
                      <tr>
                        <th>이해점수</th>
                        <td>
                          <SelectBoxBar
                            valueTextArr={month_prescribe_select_arr["a_understand_degree"]}
                            value={rowData.a_understand_degree}
                            name={"a_understand_degree"}
                            on_change={(inData: any) => {
                              if (is_view_mode == false) {
                                on_change_input({
                                  target: {
                                    name: inData.name,
                                    value: inData.value,
                                  }
                                });
                              }
                            }}
                            style_type={"wide"}
                          ></SelectBoxBar>
                        </td>
                      </tr>
                      <tr>
                        <th>일일메모</th>
                        <td>
                          <MultyLineTextView text={myProps.last_daily_report?myProps.last_daily_report["a_memo"]:""}></MultyLineTextView>
                        </td>
                      </tr>
                      <tr>
                        <th>수업태도</th>
                        <td>
                          <SelectBoxBar
                            valueTextArr={month_prescribe_select_arr["a_lesson_attitude"]}
                            value={rowData.a_lesson_attitude}
                            name={"a_lesson_attitude"}
                            on_change={(inData: any) => {
                              if (is_view_mode == false) {
                                on_change_input({
                                  target: {
                                    name: inData.name,
                                    value: inData.value,
                                  }
                                });
                              }
                            }}
                            style_type={"wide"}
                          ></SelectBoxBar>
                        </td>
                      </tr>
                      <tr>
                        <th>학습정서(감정)</th>
                        <td>
                          {is_view_mode ?
                            <MultyLineTextView text={rowData.a_study_emotion}></MultyLineTextView>
                            :
                            <TextAreaComponent
                              name={"a_study_emotion"}
                              value={rowData.a_study_emotion}
                              class_name={"row-input"}
                              height={24}
                              style={{ "width": "100%" }}
                              onChange={on_change_input}
                              placeholder={"학습정서"}
                            ></TextAreaComponent>
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>새로운 발견</div>
                          <div>(상황중심기술)</div>
                        </th>
                        <td>
                          <div className="text-left mt-1" style={{ minHeight: 20 }}>
                            {is_view_mode ?
                              <MultyLineTextView text={rowData.a_lack}></MultyLineTextView>
                              :
                              <TextAreaComponent
                                name={"a_lack"}
                                value={rowData.a_lack}
                                class_name={"row-input"}
                                height={32}
                                style={{ "width": "100%" }}
                                onChange={on_change_input}
                                placeholder={"내용입력"}
                              ></TextAreaComponent>
                            }
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {isUpdate &&
                <div>
                  <ImgFileWriteList
                    ref={ImgFileWriteListRef}
                    select_mcomp_data={{...select_mcomp_data}}
                    is_view={false}
                    listOpt={img_file_init_list_opt}
                    is_save_btn={true}
                    is_select_save={true}
                  ></ImgFileWriteList>
                </div>
              }
              {isUpdate &&
                <CommentPartArea
                  select_mcomp_data={{...select_mcomp_data}}
                  info_arr={myProps.comment_list}
                  list={(inData: any) => {
                    myProps.refresh_comment();
                    myProps.list({ select_seq: row_pri_val });
                  }}
                  is_show_toggle={false}
                  is_show_detail={true}
                  is_send_write_push={true}
                  title_str={"질문"}
                  wrap_style={{ width: "100%" }}
                  init_data={{
                    ...comment_init_data,
                    par_locate: "a_lack",
                    par_locate_name: "질문",
                  }}
                ></CommentPartArea>
              }
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
            <div className="text-left pl-2" style={{ position: "relative",lineHeight:"23px",fontSize:"14px",color:"#fff",background:"#515151" }}>
                5. 학습 처방
                <span style={{ position: "absolute", right: 5 }} title={rowData.a_is_success == "1" ? rowData.a_success_date : ""} >
                  <label>
                    <input type="checkbox" name="a_is_success" value="1"
                      className="ml-2 mr-1"
                      checked={rowData.a_is_success == "1"}
                      onChange={on_change_input}
                    />
                    완료
                  </label>
                  <label>
                    <input type="checkbox" name="a_is_request_counsel" value="1"
                      className="ml-2 mr-1"
                      checked={rowData.a_is_request_counsel == "1"}
                      onChange={on_change_input}
                    />
                    상담요청
                  </label>
                  <span className="ml-1">
                    {rowData.a_request_counselor &&
                      <span>
                        ({rowData.a_request_counselor})
                      </span>
                    }
                    {rowData.a_request_counselor &&
                      <button className="btn-s btn-red ml-1" onClick={() => {
                        let change_row_data = {
                          ...rowData,
                          a_is_request_counsel: "",
                          a_request_counselor_seq: "",
                          a_request_counselor: "",
                        };
                        myProps.setRowData(change_row_data);
                      }}>x</button>
                    }
                    <button className="btn-s btn-gray ml-1" onClick={open_find_counselor_popup}>찾기</button>
                  </span>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView
                      text={rowData.a_content}
                      style={{ color: "blue" }}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_content"}
                      value={rowData.a_content}
                      class_name={"row-input"}
                      height={32}
                      style={{ color: "blue" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
              <div>
                <div className="text-left" style={{ fontWeight: "bold", position: "relative" }}>
                  <label>
                    <input type="checkbox" name="a_is_success_check" value="1"
                      className="ml-1 mr-1"
                      checked={rowData.a_is_success_check == "1"}
                      onChange={on_change_input}
                    />
                    완료확인
                  </label>
                  {rowData.a_is_request_counsel == "1" &&
                    <span style={{ position: "absolute", right: 0 }}>
                      <button className="btn-s btn-sky"
                        onClick={() => {
                          openWriteCounselPopup({});
                        }}
                        style={{ background: strFunc.is_empty(rowData.a_request_counsel_seq) ? "gray" : "" }}>
                        상담{strFunc.is_empty(rowData.a_request_counsel_seq) ? "등록" : "수정"}
                      </button>
                    </span>
                  }
                </div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView text={rowData.a_success_check_msg}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_success_check_msg"}
                      value={rowData.a_success_check_msg}
                      class_name={"row-input"}
                      height={32}
                      style={{ "width": "100%" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
              {isUpdate &&
                <CommentPartArea
                  select_mcomp_data={{...select_mcomp_data}}
                  info_arr={myProps.comment_list}
                  list={(inData: any) => {
                    myProps.refresh_comment();
                    myProps.list({ select_seq: row_pri_val });
                  }}
                  is_show_toggle={false}
                  is_show_detail={true}
                  is_send_write_push={true}
                  title_str={"질문"}
                  wrap_style={{ width: "100%" }}
                  init_data={{
                    ...comment_init_data,
                    par_locate: "a_content",
                    par_locate_name: "질문",
                  }}
                ></CommentPartArea>
              }
            </td>
          </tr>
        </tbody>
      </table>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "find" &&
            <FindMultyStudentPopup
              select_mcomp_data={{...select_mcomp_data}}
              callBackData={callBackDataStuPopup}
              listOpt={{
                s_addon_daily_report: "1",
                s_addon_daily_report_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'day',-5), "Y-m-d"),
                s_addon_daily_report_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
                s_select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
                s_addon_month_prescribe: "1",
                s_addon_month_prescribe_start_date: "",
                s_addon_month_prescribe_end_date: "",
                s_addon_month_prescribe_writer_seq: user.user_seq,
              }}
              is_show_month_prescribe={true}
              is_multi_select_by_click={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
          {popupData.sort === "find_counselor" &&
            <FindMultyStaffPopup
              select_mcomp_data={{...select_mcomp_data}}
              callBackData={callBackDataCounselorPopup}
              listOpt={{

              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStaffPopup>
          }
          {popupData.sort == "find_pres" &&
            <FindPrescribePopup
              select_mcomp_data={{...select_mcomp_data}}
              callBackData={callBackDataFindPrescribe}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindPrescribePopup>
          }
          {popupData.sort == "write_counsel" &&
            <WriteCounselPopup
              select_mcomp_data={{...select_mcomp_data}}
              rowData={popupData.rowData}
              isUpdate={popupData.isUpdate}
              stu_seq={rowData.a_stu_seq}
              stu_name={rowData.a_stu_name}
              list={(inData: any) => {
                myProps.list({ select_seq: row_pri_val });
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteCounselPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default MonthPrescribeWriteArea;