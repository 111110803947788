import { useRef } from 'react';

function AttendTd(props: any) {
  let myProps = {
    info: {},
    openAttendPopup:(inData: any) => { },
    deleteAttend:(inData: any) => { },
    ...props
  };
  let info=myProps.info;
  const attend_btn_box_ref=useRef<any>(null);

  let attend_in_info:any=info["attend_in_info"];
  let attend_out_info:any=info["attend_out_info"];
  let attend_color:any=info["attend_color"];
  let attend_text_color:any=info["attend_text_color"];
  let attend_state_str:any=info["attend_state_str"];
  let attend_memo:any=info["attend_memo"];
  let attend_time:any=info["attend_time"];
  let attend_out_time:any=info["attend_out_time"];

  const on_attend_click=()=>{
    if(attend_in_info!=null){
      if(attend_btn_box_ref.current){
        attend_btn_box_ref.current.style.display="inline-block";
      }
    }else{
      myProps.openAttendPopup([info],"in","attend");
    }
  };

  const go_attend_click=()=>{
    if(attend_in_info!=null){
      myProps.openAttendPopup([info],"out","out");
    }else{
      myProps.openAttendPopup([info],"in","attend");
    }
  };

  const del_attend_click=()=>{
    if(attend_in_info!=null){
      if(attend_out_info!=null){
        myProps.deleteAttend([attend_out_info],"하원 취소 하시겠습니까?");
      }else{
        myProps.deleteAttend([attend_in_info],"등원 취소 하시겠습니까?");
      }
    }
  };

  let attend_btn_box_style:any={
    position:"absolute",
    zIndex:1,
    display:"none",
    minWidth:"150px",
    lineHeight:"50px",
    padding:"0px 2px",
    background:"#000",
    borderRadius:"5px",
    textAlign:"center"
  };

  let attend_btn_text="등원";
  if(attend_in_info!=null){
    attend_btn_text="하원";
  }

  return (
    <td
      style={{background:attend_color,color:attend_text_color}}
      title={"등원: "+attend_time+"\n하원: "+attend_out_time+"\n"+attend_memo}
    >
      <div style={{position:"relative"}}>
        <input type="text" value={attend_state_str}  
          readOnly
          onClick={on_attend_click}
          onBlur={()=>{
            setTimeout(()=>{
              if(attend_btn_box_ref.current){
                attend_btn_box_ref.current.style.display="none";
              }
            },200);
          }}
          style={{width:"99%",border:0,textAlign:"center",cursor:"pointer",background:attend_color,color:attend_text_color}}/>
        <span ref={attend_btn_box_ref} 
          onMouseLeave={()=>{
            if(attend_btn_box_ref.current){
              attend_btn_box_ref.current.style.display="none";
            }
          }} style={attend_btn_box_style}>
          {attend_out_info==null&&
            <button className="btn btn-sky" onClick={()=>{go_attend_click();}}>{attend_btn_text}</button>
          }
          {attend_in_info!=null&&
            <button className="btn btn-red ml-2" onClick={()=>{del_attend_click();}}>취소</button>
          }
        </span>
      </div>
    </td>
  );
};
export default AttendTd;