import strFunc from '@/lib/lyg/string';
import DailyReportDataFunc from "@/pcomponents/common/content/edu/daily_report/func/data_func";
import DateFunc from '@/lib/lyg/date_func';

class DailyReportTestListFunc
{
  static get_stu_info_arr_by_props(inData:any){
    let opt_obj={
      stu_info_arr:[],
      select_date:"",
      user:{},
      ...inData
    };
    let stu_info_arr=opt_obj.stu_info_arr;
    let select_date=opt_obj.select_date;
    let user=opt_obj.user;
    let this_obj=this;

    let new_stu_info_arr:any=[];
    for(let i=0;i<stu_info_arr.length;i++){
      let stu_info=stu_info_arr[i];
      let is_exist_daily_report=false;
      let select_daily_report=null;
      let select_daily_report_arr=[];
      let math_report="";
      let en_report="";
      //strFunc.get_storage("daily_report_comment","");
      let comment="";

      if(stu_info["daily_report_arr"]&&stu_info["daily_report_arr"].length>0){
        for(let j=0;j<stu_info["daily_report_arr"].length;j++){
          let daily_report=stu_info["daily_report_arr"][j];
          if(daily_report["a_date"]!=select_date){
            continue;
          }
          if(strFunc.is_empty(daily_report["a_seq"])){
            continue;
          }
          is_exist_daily_report=true;
          if(select_daily_report==null){
            select_daily_report=daily_report;
          }
          //시간표에있는지확인
          let time_arr:any=[];
          if(stu_info.lecture_arr&&daily_report["a_lecture_seq"]){
            time_arr=this_obj.get_time_arr_by_lecture_arr({
              lecture_seq:daily_report["a_lecture_seq"],
              lecture_arr:stu_info.lecture_arr,
            });
            let is_exist_time=this_obj.is_exist_time_of_time_table({
              time_arr:time_arr,
              lecture_time:daily_report["a_lecture_time"],
              select_date:select_date,
            });
            if(is_exist_time==false){
              continue;
            }
          }else{
            continue;
          }

          select_daily_report_arr.push(daily_report);
          let show_plain_text=DailyReportDataFunc.get_show_plain_text_by_info({
            info:daily_report,
            is_stu_name:false,
            is_time:false,
            is_amount_memo:false,
            is_memo:false,
          });
          if(daily_report["a_subject"]=="수학"&&math_report==""){
            math_report=show_plain_text;
          }
          if(daily_report["a_subject"]=="영어"&&en_report==""){
            en_report=show_plain_text;
          }
        }
      }
      if(is_exist_daily_report==false){
        continue;
      }
      let line_break="\n";
      let report_str="<"+user.corp_name+">";
      report_str+=line_break;
      report_str+=stu_info["a_user_name"]+" 학생 1:1퍼스널 학습";
      report_str+=line_break;
      if(math_report){
        report_str+=math_report;
        report_str+=line_break;
        report_str+=line_break;
      }
      if(en_report){
        report_str+=en_report;
        report_str+=line_break;
      }

      stu_info_arr[i]["report"]=report_str;
      stu_info_arr[i]["comment"]=comment;
      stu_info_arr[i]["daily_report"]=select_daily_report;
      stu_info_arr[i]["daily_report_arr"]=select_daily_report_arr;

      new_stu_info_arr.push(stu_info_arr[i]);
    }
    return new_stu_info_arr;
  }
  static get_time_arr_by_lecture_arr(inData:any){
    let opt_obj={
      lecture_seq:"",
      lecture_arr:[],
      ...inData
    };
    let time_arr:any=[];
    let lecture_seq=opt_obj.lecture_seq;
    let lecture_arr=opt_obj.lecture_arr;
    for(let i=0;i<lecture_arr.length;i++){
      let lecture=lecture_arr[i];
      if(lecture["a_seq"]==lecture_seq){
        time_arr=lecture["stu_union_time_arr"];
        break;
      }
    }
    return time_arr;
  };
  static is_exist_time_of_time_table(inData:any){
    let opt_obj={
      time_arr:[],
      lecture_time:"",
      select_date:"",
      ...inData
    };
    let time_arr=opt_obj.time_arr;
    let lecture_time=opt_obj.lecture_time;
    let select_date=opt_obj.select_date;
    let day_num="";
    if(select_date){
      let select_date_json=DateFunc.get_date_json(new Date(select_date));
      day_num=select_date_json.day;
    }
    
    for(let i=0;i<time_arr.length;i++){
      let time=time_arr[i];
      let start_str=strFunc.HourMinuteToTime(time.a_start_h, time.a_start_m);
      let end_str=strFunc.HourMinuteToTime(time.a_end_h, time.a_end_m);
      let st_end_str=start_str+"~"+end_str;
      if(time.a_day_num==day_num){
        if(st_end_str==lecture_time){
          return true;
        }
      }
    }
    return false;
  };
  static get_stu_info_arr_by_counsel_added(inData:any){
    let opt_obj={
      stu_info_arr:[],
      counsel_todo_arr:[],
      select_date:"",
      ...inData
    };
    let stu_info_arr=opt_obj.stu_info_arr;
    let counsel_todo_arr=opt_obj.counsel_todo_arr;
    let select_date=opt_obj.select_date;

    for(let i=0;i<stu_info_arr.length;i++){
      let stu_info=stu_info_arr[i];
      let counsel_todo=null;
      let row_counsel_todo_arr=[];
      for(let j=0;j<counsel_todo_arr.length;j++){
        let todo=counsel_todo_arr[j];
        if(stu_info["a_seq"]==todo["a_stu_seq"]
          &&select_date==todo["a_reserve_date"]
        ){
          if(counsel_todo==null){
            counsel_todo=todo;
          }
        }else if(stu_info["a_seq"]==todo["a_stu_seq"]){
          row_counsel_todo_arr.push(todo);
        }
      }
      stu_info_arr[i]["counsel_todo"]=counsel_todo;
      stu_info_arr[i]["counsel_todo_arr"]=row_counsel_todo_arr;
      if(row_counsel_todo_arr.length>0){
        //stu_info_arr[i]["comment"]=row_counsel_todo_arr[0]["a_reference"];
      }
    }

    return stu_info_arr;
  }
  static get_stu_info_arr_by_temp_memo_added(inData:any){
    let opt_obj={
      stu_info_arr:[],
      temp_memo_arr:[],
      select_date:"",
      ...inData
    };
    let stu_info_arr=opt_obj.stu_info_arr;
    let temp_memo_arr=opt_obj.temp_memo_arr;
    let select_date=opt_obj.select_date;
    
    for(let i=0;i<stu_info_arr.length;i++){
      let stu_info=stu_info_arr[i];
      let row_temp_memo=null;
      let row_temp_memo_arr=[];
      
      for(let j=0;j<temp_memo_arr.length;j++){
        let temp_memo=temp_memo_arr[j];
        if(stu_info["a_seq"]==temp_memo["a_par_seq"]&&temp_memo["a_date"]<=select_date){
          if(row_temp_memo==null){
            row_temp_memo=temp_memo;
          }
          row_temp_memo_arr.push(temp_memo);
        }
      }
      stu_info_arr[i]["temp_memo"]=row_temp_memo;
      stu_info_arr[i]["temp_memo_arr"]=row_temp_memo_arr;
      if(row_temp_memo_arr.length>0){
        //stu_info_arr[i]["comment"]=row_temp_memo_arr[0]["a_content"];
      }
    }

    return stu_info_arr;
  }
}
export default DailyReportTestListFunc;