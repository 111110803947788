import person_ico2 from "@/img/ico/person_ico2.png";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { add_memo_select_arr } from "@/pages/comp/basic/student/common/write/data/default_data";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function TopInfoArea(props: any) {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    info: {},
    set_info: (inData: any) => { },
    add_memo_info: {},
    set_add_memo_info: (inData: any) => { },
    refresh_data: () => { },
    openModifyClassPopup: () => { },
    is_update: false,
    Style: {},
    is_view_mode: false,
    ...props
  };
  let select_mcomp_data=myProps.select_mcomp_data;
  let user = useSelector((state: any) => state.user);
  let info = myProps.info;
  let add_memo_info = myProps.add_memo_info;
  let Style = myProps.Style;
  let is_view_mode = myProps.is_view_mode;
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);
  let default_file_input_data = {
    "url": person_ico2,
    "name": "",
  };

  useEffect(() => {
    
  }, []);

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.set_info({
      ...info,
      ...{ [key]: date_str }
    });
  };
  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };
  const handleInputChange2 = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_add_memo_info({
      ...add_memo_info,
      ...{ [name]: value }
    });
  };

  const goProfileWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    if(strFunc.is_empty(user.comp_seq)){
      alert("학원키 없음.");
      return false;
    }
    
    let default_file_row_data:any = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": info.a_seq,
      "a_sort1": "profile",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    if(!strFunc.is_empty(select_mcomp_data.mcomp_seq)){
      default_file_row_data["a_mcomp_seq"]=select_mcomp_data.mcomp_seq;
      default_file_row_data["a_create_seq"]=select_mcomp_data.api_user_seq;
    }
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    if (!confirm("프로필 사진을 저장 하시겠습니까?")) {
      return false;
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify([file_row_data]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goProfileDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("프로필 사진을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        //파일안보이게
        myProps.refresh_data();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  let profile_img_src = person_ico2;
  let profile_orin_img_src = "";
  let profile_file_info: any = null;
  if (info.profile_arr && info.profile_arr.length > 0) {
    if (info.profile_arr[0]["is_image"] == "1") {
      profile_img_src = info.profile_arr[0]["thum_img_url"];
      profile_orin_img_src = info.profile_arr[0]["img_url"];
      profile_file_info = info.profile_arr[0];
    }
  }

  let class_name_str = "";
  if (info.class_arr) {
    let class_name_arr = [];
    for (let i = 0; i < info.class_arr.length; i++) {
      class_name_arr.push(info.class_arr[i]["class_name"]);
    }
    class_name_str = class_name_arr.join(" / ");;
  }

  let stu_grade_str = info["a_stu_grade"];
  for (let i = 0; i < select_stu_grade_arr.length; i++) {
    if (select_stu_grade_arr[i]["value"] == info["a_stu_grade"]) {
      stu_grade_str = select_stu_grade_arr[i]["text"];
    }
  }

  return (
    <div>
      <div className="write_table_small mt-2">
        <table>
          <colgroup>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"16%"}></col>
            <col width={"10%"}></col>
            <col width={"16%"}></col>
            <col width={"10%"}></col>
            <col width={"16%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <td rowSpan={4}>
                <div className={Style.profile_img_wrap}>
                  <a href={profile_orin_img_src} target="_blank">
                    <img src={profile_img_src} />
                  </a>
                  {(myProps.is_update && is_view_mode == false) &&
                    <>
                      <button className={Style.del_btn} onClick={() => { goProfileDelete(profile_file_info); }}>X</button>
                      <label className={Style.change_btn} htmlFor="files" >
                        사진선택
                      </label>
                      <form id="write_form" method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
                        <input type="file" id="files" className="input_file" name="input_file[]"
                          ref={fileInput} hidden accept="image/*" onChange={(e: any) => {
                            if (e.target.files.length > 0) {
                              let file = e.target.files[0];
                              goProfileWrite({});
                            }
                          }} />
                      </form>
                    </>
                  }
                </div>
              </td>
              <th>
                이름
                <span className="emphasis_star">*</span>
              </th>
              <td>
                {is_view_mode ?
                  info.a_user_name
                  :
                  <input type="text" className="row-input" value={info.a_user_name}
                    onChange={(e: any) => { handleInputChange({ name: "a_user_name", value: e.target.value }); }}
                    placeholder="이름" autoComplete="off" style={{ width: 90 }} />
                }
                <span className="ml-1">
                  {(is_view_mode&&!strFunc.is_empty(info.a_stu_total_code)) ?
                    <span style={{color:"#00968b",fontSize:"12px",fontWeight:"bold"}}>
                      {info.a_stu_total_code}
                    </span>
                    :
                    <select className="row-input" value={info.a_stu_total_code}
                      onChange={(e:any)=>{handleInputChange({name:"a_stu_total_code",value:e.target.value});}}
                      style={{width:80}} placeholder="총점">
                      <option value="" >T분류</option>
                      {
                        add_memo_select_arr.a_stu_total_code.map((item:any,idx:number)=>{
                          return (
                            <option key={idx} value={item["value"]} >{item["text"]}</option>
                          );
                        })
                      }
                    </select>
                  }
                </span>
              </td>
              <td colSpan={2} className="text-center">
                <span>
                  상담일:
                  {is_view_mode ?
                    " " + info.a_join_counsel_date
                    :
                    <div style={{ display: "inline-block", width: 95, marginLeft: 5 }}>
                      <DatePicker
                        selected={info.a_join_counsel_date != "" ? new Date(info.a_join_counsel_date) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_join_counsel_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  }
                </span>
                <span className="ml-2">
                  입학일:
                  {is_view_mode ?
                    " " + info.a_join_date
                    :
                    <div style={{ display: "inline-block", width: 95, marginLeft: 5 }}>
                      <DatePicker
                        selected={info.a_join_date != "" ? new Date(info.a_join_date) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_join_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  }
                </span>
              </td>
              <th style={{fontSize:"12px"}}>
                학생 사고유형
              </th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_stu_think_type}
                  value={info.a_stu_think_type}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange({ name: "a_stu_think_type", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>
                학교
                <span className="emphasis_star">*</span>
              </th>
              <td>
                {is_view_mode ?
                  info.a_school_name
                  :
                  <input type="text" className="row-input" value={info.a_school_name}
                    onChange={(e: any) => { handleInputChange({ name: "a_school_name", value: e.target.value }); }}
                    placeholder="학교" />
                }
              </td>
              <th>
                클래스
              </th>
              <td>
                {class_name_str}
                {(is_view_mode == false) &&
                  <button
                    className="btn btn-gray ml-1"
                    onClick={() => { myProps.openModifyClassPopup(); }}>관리</button>
                }
              </td>
              <th style={{fontSize:"12px"}}>
                학부모 교육유형
              </th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_parent_edu_type}
                  value={add_memo_info.a_parent_edu_type}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_parent_edu_type", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>
                학년
                <span className="emphasis_star">*</span>
              </th>
              <td>
                {is_view_mode ?
                  stu_grade_str
                  :
                  <select className="row-input" value={info.a_stu_grade}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_grade", value: e.target.value }); }} >
                    {select_stu_grade_arr.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.value}>{item.text}</option>
                      );
                    })}
                  </select>
                }
              </td>
              <th>
                메모
              </th>
              <td colSpan={6} style={{ paddingBottom: 1 }}>
                {is_view_mode ?
                  <MultyLineTextView text={info.a_memo}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    class_name="row-input"
                    name="a_memo"
                    value={info.a_memo}
                    onChange={(inData: any) => {
                      handleInputChange({ name: "a_memo", value: inData.target.value });
                    }}
                    placeholder="메모"
                  ></TextAreaComponent>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TopInfoArea;