import strFunc from '@/lib/lyg/string';

function ViewPopupContent(props:any) {
  let myProps = {
    rowData:{},
    is_able_write:true,
    ...props
  };
  let rowData=myProps.rowData;

  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={4} style={{color:props.rowData.a_color}}>
                [{props.rowData.a_sort}] {props.rowData.a_title}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {
                  props.rowData.a_board_content && props.rowData.a_board_content.split('\n').map( (line:string,idx:number) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{props.rowData.a_writer}</td>
              <th>조회수</th>
              <td>{props.rowData.a_read_cnt}</td>
            </tr>
            <tr>
              <th>작성</th>
              <td colSpan={3} style={{color:"#666"}}>
                {rowData.a_create_date}
                {!strFunc.is_empty(rowData.a_update_date)&&
                  <span className='ml-2'>수정: {rowData.a_update_date}</span>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {myProps.is_able_write&&
          <button className="btn btn-line-gray" onClick={()=>{props.closePopup();props.openOnePopup("write",true);}}>수정</button>
        }
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
