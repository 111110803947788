//import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";

const FindStudentList = forwardRef((props: any, ref) => {
  const myProps = {
    listOpt: {},
    show_key_arr: [],
    grid_opt: {},
    list: (inOptObj: any) => { },
    discover_select: (inOptObj: any) => { },//stu_info,discover_map,discover_map_arr
    discover_delete: (inOptObj: any) => { },//discover_map
    is_show_search_area: true,
    ...props
  };
  let default_grid_opt = {
    is_idx_num: false,
    is_add_checkbox: true,
    floatingFilter: false,
    rowMultiSelectWithClick: true,
    rowSelection: "multiple",
  };
  myProps.grid_opt = {
    ...default_grid_opt,
    ...myProps.grid_opt
  };
  const xColumnArr = getXcolumnJson();
  for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
    let x_column = xColumnArr.x_column_list_arr[i];
    if (myProps.show_key_arr.length > 0) {
      if (strFunc.str_in_array(x_column["key"], myProps.show_key_arr) != -1) {
        xColumnArr.x_column_list_arr[i].is_show = "1";
      }
    }
  }

  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    s_addon_class_user: "1",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_discover_map: "",
    s_addon_discover_map_start_date: "",
    s_addon_discover_map_end_date: "",
    ...myProps.listOpt
  });

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll
  }));

  const list = (inOptObj: any) => {
    let tmpPageData = {
      ...listOpt,
      ...inOptObj
    };
    tmpPageData["sc"] = listAggridRef.current.getListSc();
    setListOpt(tmpPageData);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmpPageData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const setSelectFalseAll = () => {
    listAggridRef.current.setSelectFalseAll();
    myProps.list({
      "s_stu_user_seq": "",
    });
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key != "idx_num" && key != "chk_box") {
      let tmp_all_nodes = listAggridRef.current.getNodeRows();
      for (let i = 0; i < tmp_all_nodes.length; i++) {
        if (tmp_all_nodes[i].data["a_seq"] == params.data["a_seq"]) {
          tmp_all_nodes[i].setSelected(true);
        } else {
          tmp_all_nodes[i].setSelected(false);
        }
      }
    }

  }

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "a_user_name") {
      render_str = render_str + " " + params.data["a_stu_total_code"];
    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_stu_grade") {
      let school_name = strFunc.cut_str(params.data["a_school_name"], 2, " ");
      let stu_grade_str = params.data["a_stu_grade"];
      if (xColumnArr.select_arr["a_stu_grade"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
          if (xColumnArr.select_arr["a_stu_grade"][i]["value"] == params.data["a_stu_grade"]) {
            stu_grade_str = xColumnArr.select_arr["a_stu_grade"][i]["text"];
          }
        }
      }
      //xColumnArr
      render_str = school_name + stu_grade_str;
    } else if (key == "row_view_discover_map") {
      if (params.data["discover_map_arr"] && params.data["discover_map_arr"].length > 0) {
        let last_discover_map: any = params.data["discover_map_arr"][0];
        render_str = (
          <div>
            {last_discover_map["a_date"].substring(0, 10)}
            <button className="btn btn-dark ml-2"
              onClick={() => {
                myProps.discover_select({
                  stu_info: params.data,
                  discover_map_arr: params.data["discover_map_arr"],
                  discover_map: last_discover_map
                });
              }}>수정</button>
            ({params.data["discover_map_arr"].length})
            <button className="btn btn-red ml-1"
              onClick={() => {
                myProps.discover_delete({
                  discover_map: last_discover_map
                });
              }}>삭제</button>
          </div>
        );
      }
    }else if(key=="row_view_discover_map_view"){
      if (params.data["discover_map_arr"] && params.data["discover_map_arr"].length > 0) {
        let last_discover_map: any = params.data["discover_map_arr"][0];
        let tmp_success_cnt=0;
        for(let i=0;i<params.data["discover_map_arr"].length;i++){
          let tmp_item=params.data["discover_map_arr"][i];
          if(tmp_item["a_is_success"]=="1"){
            tmp_success_cnt++;
          }
        }
        render_str = (
          <div>
            <span>{tmp_success_cnt}/{params.data["discover_map_arr"].length}</span>
            <span className="ml-1">{last_discover_map["a_date"].substring(0, 10)}</span>
          </div>
        );
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    myProps.list({
      now_page: 1,
      "s_stu_user_seq": select_seq_arr,
      select_stu_info_arr: selected_row
    });
  };

  return (
    <div>
      {myProps.is_show_search_area &&
        <SearchArea
          xColumnArr={xColumnArr}
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
        ></SearchArea>
      }
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: myProps.grid_opt.is_idx_num,
          is_add_checkbox: myProps.grid_opt.is_add_checkbox,
          floatingFilter: myProps.grid_opt.floatingFilter,
          rowMultiSelectWithClick: myProps.grid_opt.rowMultiSelectWithClick,
          rowSelection: myProps.grid_opt.rowSelection,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
    </div>
  );
});

export default FindStudentList;