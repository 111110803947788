import CompLayout from "@/pcomponents/comp/layout/layout";
import RoomListWrap from "@/pages/comp/edu/desk/main/area/room_list_wrap";

function DeskPlanPage() {

  return (
    <CompLayout isConTitle={false}>
      <RoomListWrap
        is_able_card_view={true}
      ></RoomListWrap>
    </CompLayout>
  );
};
export default DeskPlanPage;