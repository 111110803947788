import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '@/pages/main/board/notice/popup/write';
import ViewPopupContent from '@/pages/main/board/notice/popup/view';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";

function SystemNoticeListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_is_add_top_notice": "1",
    "s_id": "system_notice",
    s_title_like: "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [group_manager_arr, set_group_manager_arr] = useState<any>([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    s_id: listOpt.s_id,
    isUpdate: false,
    rowData: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    get_group_manager_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    form_json_data["sc"] = listAggridRef.current.getListSc();
    setListOpt(form_json_data);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/board/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["top_info_arr"]) {
            response.data["data"]["info_arr"] = [
              ...response.data["data"]["top_info_arr"],
              ...response.data["data"]["info_arr"],
            ];
            response.data["data"]["count_info"]["tot"] =
              parseInt(response.data["data"]["count_info"]["tot"]) + response.data["data"]["top_info_arr"].length;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_group_manager_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_group_manager_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    } else if (selected_row.length >= 2) {
      alert("한개만 선택해주세요.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/board/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const openOnePopup = (sort: string, isUpdate: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_title" || key == "a_public_sort") {
      openOnePopup("view", true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_manage") {
      render_str = (
        <div>
          <button className="btn btn-dark" onClick={() => { setTimeout(() => { openOnePopup("write", true); }, 100); }}>수정</button>
        </div>
      );
    } else if (key == "a_title") {
      let title_sort = "";
      if (params.data.a_sort != "") {
        title_sort = "[" + params.data.a_sort + "] ";
      }
      render_str = (
        <div style={{ color: params.data.a_color }}>
          {title_sort}{render_str}
        </div>
      );
    } else if (key == "idx_num") {
      if (params.data.a_is_top == "1") {
        render_str = (
          <span style={{ color: "blue", fontSize: 12 }}>공지</span>
        );
      }
    } else if (key == "row_vew_is_popup") {
      if (params.data.a_is_popup == "1") {
        let period_str = params.data.a_start_date + " ~ " + params.data.a_end_date;
        render_str = (
          <span style={{ color: "blue" }} title={period_str}>예</span>
        );
      }
    }
    return render_str;
  }, []);


  return (
    <CompLayout>
      <div className="con_wrap">
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
        ></SearchArea>
        <div className="btn-box-left relative h-8">
          {group_manager_arr.length > 0 &&
            <div style={{ textAlign: "right", width: "100%" }}>
              <button className="btn btn-dark" onClick={() => { openOnePopup("write", false); }}>등록</button>
              <button className="btn btn-dark ml-1" onClick={() => { openOnePopup("write", true); }}>수정</button>
              <button className="btn btn-red ml-1" onClick={goDelete}>삭제</button>
            </div>
          }
        </div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        <div className="text-right mt-1">
          <button className="btn btn-dark" onClick={()=>{
             strFunc.set_storage("except_system_notice_pri_val_arr",JSON.stringify([]));
          }}>팝업표시 초기화</button>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              s_id={popupData.s_id}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent
              rowData={popupData.rowData} callback={() => { list({}); }}
              is_able_write={group_manager_arr.length > 0}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              openOnePopup={(sort: string, isUpdate: boolean) => { openOnePopup(sort, isUpdate); }} ></ViewPopupContent>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default SystemNoticeListPage;