import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LygCalendarFunc from '@/lib/lyg/calendar';
import TopYearMonthArea from "./area/top_year_month";
import Style from "./css/style.module.css";
import TdDataFunc from "./func/td_data_func";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import SearchMiddleArea from "./area/search_middle";
import ColorHelpArea from "@/pages/comp/edu/month_prescribe/common/help/color_help";

const CalendarTable = forwardRef((props: any, ref) => {
  const myProps = {
    "listOpt": {},
    "select_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    teacher_info_arr: [],
    lecture_user_time_arr: [],
    "onSearchDateChange": (inData: any) => { },
    go_search_user_list: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    open_reserve_prescribe_popup: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };

  let init_now_date_json = DateFunc.get_date_json(new Date());
  let init_s_select_date = DateFunc.get_date_format(new Date(), "Y-m-d");
  if (!strFunc.is_empty(myProps.select_date)) {
    init_s_select_date = myProps.select_date;
  }

  if ((init_now_date_json.d + 1) < init_now_date_json.day) {
    let init_minus_select_d = init_now_date_json.day - (init_now_date_json.d + 1);
    init_s_select_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'day', -init_minus_select_d), "Y-m-d");
  }

  const [select_date, set_select_date] = useState(init_s_select_date);
  const [stu_info_arr, set_stu_info_arr] = useState<any>([]);
  const [mprescribe_arr, set_mprescribe_arr] = useState([]);
  const [is_show_counseler, set_is_show_counseler] = useState(true);
  const [select_week_num, set_select_week_num] = useState(-1);

  const now_date_json = DateFunc.get_date_json(new Date());
  let day_num_list = [0, 1, 2, 3, 4, 5, 6];
  let day_name_list = ["일", "월", "화", "수", "목", "금", "토"];
  let cal_data = LygCalendarFunc.get_cal_data_by_select_date(select_date);
  let td_data_arr = LygCalendarFunc.get_td_data_arr({ cal_data: cal_data });
  let td_week_data_arr = LygCalendarFunc.get_td_week_data_arr({ cal_data: cal_data, td_data_arr: td_data_arr, standard_day_of_week: 1 });
  let pre_month_date = cal_data["pre_month_date"];
  let after_month_date = cal_data["after_month_date"];

  const setInitData = (inOptObj: any) => {
    let optObj = {
      select_date: myProps.select_date,
      stu_info_arr: [],
      ...inOptObj
    };
    set_select_date(optObj.select_date);
    set_stu_info_arr(optObj.stu_info_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, set_stu_info_arr, set_mprescribe_arr,
  }));

  const handleDateChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    let change_searchDate = select_date;
    let tmp_year = select_date.substring(0, 4);
    let tmp_month = select_date.substring(5, 7);
    let tmp_day = select_date.substring(8, 10);
    let tmp_date_json: any = DateFunc.get_date_json(new Date(select_date));
    if (name == "s_month") {
      tmp_month = value;
    } else if (name == "s_year") {
      tmp_year = value;
    }
    if (parseInt(tmp_day) > tmp_date_json.last_day) {
      tmp_day = strFunc.str_pad({ "str": tmp_date_json.last_day, "pad_length": 2 });
    }
    change_searchDate = tmp_year + "-" + tmp_month + "-" + tmp_day;

    set_select_date(change_searchDate);
    if (myProps.onSearchDateChange) {
      myProps.onSearchDateChange(change_searchDate);
    }
  };

  const get_col_tags = () => {
    let col_tags = day_num_list.map((item: any, idx: number) => {
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };

  const get_th_tags = () => {
    let th_tags = day_num_list.map((item: number, idx: number) => {
      let th_title = day_name_list[item];
      let th_text_class_name = "th_text";
      if (idx == 0) {
        th_text_class_name += " th_text_sun";
      } else if (idx == 6) {
        th_text_class_name += " th_text_sat";
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  const get_td_tags = (inData: any) => {
    let opt_obj = {
      "td_week_data_arr": [],
      ...inData
    };

    let td_tags = opt_obj["td_week_data_arr"].map((item: any, idx: number) => {
      let td_tag_class_name = "";
      let td_class_name = "td_title";
      if (item.is_this_month) {
        td_class_name += " td_title_select_month";
      }
      if (item.day_of_week == 0) {
        td_class_name += " td_title_sun";
      } else if (item.day_of_week == 6) {
        td_class_name += " td_title_sat";
      }
      if (item.is_this_month
        && cal_data.select_month_json.m == now_date_json.m
        && cal_data.select_month_json.Y == now_date_json.Y
        && item.day == parseInt(now_date_json.d)) {

        td_tag_class_name = "td_title_now_date";
      }

      let select_date_str = item["year"] + "-" + DateFunc.get_digit_str(item["month"]) + "-" + DateFunc.get_digit_str(item["day"]);

      return (
        <td key={idx} className={td_tag_class_name}>
          <div className={td_class_name} >
            {item.day}
          </div>
          <div className="td_content_wrap">
            {item.content}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags = () => {
    td_data_arr = TdDataFunc.setting_data_at_td_data_arr({
      td_data_arr: td_data_arr,
      mprescribe_arr: mprescribe_arr,
      lecture_user_time_arr: myProps.lecture_user_time_arr,
      Style: Style,
      is_show_counseler: is_show_counseler,
      open_stu_layer_popup: myProps.open_stu_layer_popup,
      open_reserve_prescribe_popup: myProps.open_reserve_prescribe_popup,
    });
    let td_week_arr: any = LygCalendarFunc.get_td_week_arr_by_td_data_arr({
      cal_data: cal_data,
      td_data_arr: td_data_arr,
    });
    if (select_week_num != -1) {
      let new_td_week_arr = [];
      for (let i = 0; i < td_week_arr.length; i++) {
        if (td_week_arr[i][0]["date_str"] == myProps.listOpt.s_week_start_date) {
          new_td_week_arr.push(td_week_arr[i]);
        }
      }
      td_week_arr = new_td_week_arr;
    }

    let tr_tags = td_week_arr.map((item: any, idx: number) => {
      let td_tags = get_td_tags({
        "td_week_data_arr": item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <TopYearMonthArea
        select_date={select_date}
        set_select_date={set_select_date}
        onSearchDateChange={(inData: any) => {
          myProps.onSearchDateChange(inData);
          set_select_week_num(-1);
        }}
        handleDateChange={handleDateChange}
        pre_month_date={pre_month_date}
        after_month_date={after_month_date}
      ></TopYearMonthArea>
      <SearchMiddleArea
        select_date={select_date}
        listOpt={myProps.listOpt}
        list={myProps.list}
        go_search_user_list={myProps.go_search_user_list}
        is_show_counseler={is_show_counseler}
        set_is_show_counseler={set_is_show_counseler}
        teacher_info_arr={myProps.teacher_info_arr}
        td_week_data_arr={td_week_data_arr}
        select_week_num={select_week_num}
        set_select_week_num={set_select_week_num}
      ></SearchMiddleArea>
      <div className="basic_cal_wrap">
        <table>
          <colgroup>
            {get_col_tags()}
          </colgroup>
          <thead>
            <tr>
              {get_th_tags()}
            </tr>
          </thead>
          <tbody>
            {get_tr_tags()}
          </tbody>
        </table>
      </div>
      <ColorHelpArea
        wrap_div_style={{textAlign:"right"}}
      ></ColorHelpArea>
    </div>
  );
});

export default CalendarTable;
