import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";
import strFunc from '@/lib/lyg/string';

function SearchArea(props: any) {
  let myProps = {
    xColumnArr: {},
    listOpt: {},
    setListOpt: (inOptObj: any) => { },
    list: (inOptObj: any) => { },
    ...props
  };
  let xColumnArr=myProps.xColumnArr;
  let listOpt=myProps.listOpt;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let tmp_listOpt = { ...listOpt };
    tmp_listOpt[name] = value;
    myProps.setListOpt({
      ...tmp_listOpt
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value });
  };

  return (
    <div className="relative" style={{ minHeight: 52 }}>
      <div className="search-box relative py-0">
        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={listOpt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 90, display: "inline-block" }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <select className="search-input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
          <option value="">학년</option>
          <option value="elementary">초</option>
          <option value="middle">중</option>
          <option value="high">고</option>
          {
            xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
      </div>
      <div className="search-box relative py-0 ml-0 mt-1">
        <input type="text" className="search-input" name="s_search_text"
          style={{ width: 80 }} placeholder="이름" value={listOpt.s_search_text}
          onChange={handleInputChange} onKeyUp={(e: any) => { if (e.key === 'Enter') { myProps.list({ "now_page": 1 }); } }} />
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn btn-gray" onClick={() => { myProps.list({ "now_page": 1 }); }} >검색</button>
      </div>
    </div>
  );
};
export default SearchArea;