import Style from "../../../../css/style.module.css";
import CircleArea from "./circle";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const CardBoxTopTitleArea = (props:any) => {
  let myProps:any={
    user_info:{},
    sub_title_text:"",
    todo_stat_data:{},
    todo_color:"green",
    openViewTodoDayPopup:(inData:any)=>{},
    open_show_detail_view_stu:(inData:any)=>{},
    on_click_sub_title:()=>{},
    ...props
  };
  let user_info=myProps.user_info;

  let stu_total_code=user_info.a_stu_total_code;

  let a_stu_grade_str = user_info.a_stu_grade;
  let select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  for (let i = 0; i < select_option_arr.length; i++) {
    if (select_option_arr[i]["value"] == a_stu_grade_str) {
      a_stu_grade_str = select_option_arr[i]["text"];
    }
  }

  return (
    <div className={Style.card_box_top}>
      <div className={Style.card_box_todo_per} onClick={()=>{
        myProps.openViewTodoDayPopup({
          stu_info: user_info
        });
      }}>
        <CircleArea 
          per_num={myProps.todo_stat_data.total.percent}
          color={myProps.todo_color}
          back_color={"#ddd"}
        ></CircleArea>
        <div className={Style.card_box_todo_per_text}>
          <p style={{fontSize:10}}>학습</p>
          <p>{myProps.todo_stat_data.total.percent}%</p>
        </div>
      </div>
      <div className={Style.card_box_title_wrap}>
        <p className={Style.card_box_title} 
          onClick={()=>{
            myProps.open_show_detail_view_stu({
              user_info: user_info
            });
          }}>
          {myProps.user_info.a_user_name} 
          {stu_total_code&&
            <span className="ml-1" style={{fontSize:"12px",color:"#3FAC96"}}>{stu_total_code}</span>
          }
          <span className="ml-1">{a_stu_grade_str}</span>
        </p>
        <p className={Style.card_box_sub_title}
          onClick={()=>{
            myProps.on_click_sub_title();
          }}>{myProps.sub_title_text}</p>
      </div>
    </div>
  );
};

export default CardBoxTopTitleArea;