import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function CounselBox(props: any) {
  let myProps = {
    stu_info: {},
    Style: {},
    ...props
  };
  let stu_info = myProps.stu_info;
  let Style = myProps.Style;
  let counsel_todo_arr = [];
  if (stu_info["counsel_todo_arr"]) {
    counsel_todo_arr = stu_info["counsel_todo_arr"];
  }
  let last_counsel_info = null;
  if (counsel_todo_arr && counsel_todo_arr.length > 0) {
    last_counsel_info = counsel_todo_arr[0];
  }
  if(stu_info["counsel_todo"]){
    last_counsel_info=stu_info["counsel_todo"];
  }

  return (
    <div className={Style.card_box_con_box}>
      {last_counsel_info != null &&
        <>
          <div>
            {last_counsel_info["a_reserve_date"]} {last_counsel_info["a_kind"]}
            <span className="ml-1" style={{color:"#666"}}>작성: {last_counsel_info["a_writer"]}</span>
          </div>
          <div className="mt-1" style={{ color: "gray" }}>
            {last_counsel_info["a_is_success"]=="1"?
              <span style={{color:"blue"}}>완료</span>
              :
              <span style={{color:"gray"}}>준비</span>
            }
            <span className="ml-1">
              {last_counsel_info["a_sort1"]}
            </span>
          </div>
          <div className="mt-1">
            <MultyLineTextView text={last_counsel_info["a_content"]}></MultyLineTextView>
          </div>
          <div className="mt-1">
            <MultyLineTextView text={last_counsel_info["a_feedback"]}></MultyLineTextView>
          </div>
          {/* <div className="mt-1">
            <MultyLineTextView text={last_counsel_info["a_reference"]}></MultyLineTextView>
          </div> */}
        </>
      }
    </div>
  );
};
export default CounselBox;