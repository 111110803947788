import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import WeekSearch from "./area/week_search/week_search";
import SearchArea from "./area/search_area";
import { useNavigate } from 'react-router-dom';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import AddAttendPopup from "../popup/add_attend";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import AttendCalendarArea from "./area/calendar";

function AttendMain(){
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const AttendCalendarAreaRef= useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade:["student"],
    s_search_text_type:"a_user_name",
    s_search_text:"",
    s_addon_class_user:"1",
    s_addon_link_user:"1",
    //s_addon_profile:"1",
    s_addon_attend:"1",
    s_addon_attend_start_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    s_addon_attend_end_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
  });

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"add_attend",
    attend_sort:"in",//in,out
    attend_sort_detail:"",
    attend_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    userInfoArr:[],
    title:"팝업",
    width:"450px",
    height:"60%",
  });
  let is_under_width=MyScreenFunc.get_is_under();

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    let change_list_opt={
      ...listOpt,
      ...inOptObj,
    };
    change_list_opt["sc"]=listAggridRef.current.getListSc();
    setListOpt(change_list_opt);

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',change_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const deleteAttend=(dataArr:any,confirm_msg:string)=>{
    if(!confirm(confirm_msg)){
      return false;
    }
    let del_form_data={
      "data_arr":dataArr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/attend/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
       list({});
      }else{
        
      }
    });
  };

  const sendKatalkAttendMsg=(templete_sort:"attend_in"|"attend_out",select_user:any)=>{
    if(!confirm("알림톡을 보내시겠습니까?")){
      return false;
    }
    let selected_row=listAggridRef.current.getSelectedRows();
    if(!strFunc.is_empty(select_user)){
      selected_row=[select_user];
    }
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    let receive_arr:any=[];
    for(let i=0;i<selected_row.length;i++){
      let user_info=selected_row[i];
      let late_str="아니오";
      let leave_str="";
      let a_time="";
      let attend_info=null;
      if(templete_sort=="attend_in"){
        if(user_info.attend_in_arr&&user_info.attend_in_arr.length>0){
          attend_info=user_info.attend_in_arr[0];
        }
      }else if(templete_sort=="attend_out"){
        if(user_info.attend_out_arr&&user_info.attend_out_arr.length>0){
          attend_info=user_info.attend_out_arr[0];
        }
      }
      if(attend_info!=null){
        a_time=attend_info["a_time"];
        if(attend_info["a_sort_detail"]=="late"){
          late_str="예";
        }else if(attend_info["a_sort_detail"]=="absent"){
          late_str="결석";
        }
      }

      let par_info=null;
      if(user_info.link_user_arr&&user_info.link_user_arr[0]&&user_info.link_user_arr[0]["par_info"]){
        par_info=user_info.link_user_arr[0]["par_info"];
      }

      if(par_info==null){
        alert("부모님 정보가 없습니다.");
        return false;
      }
      if(strFunc.is_empty(par_info.a_user_phone)){
        alert("부모님 핸드폰정보가 없습니다.");
        return false;
      }
      
      receive_arr.push({
        "user_seq":par_info.a_seq,
        "user_name":par_info.a_user_name,
        "phone_num":par_info.a_user_phone,//user_info.a_user_phone
        "late_str":late_str,
        "leave_str":leave_str,
        "attend_time":a_time,
        "stu_name":user_info.a_user_name,
      });
    }
    let a_title="등원알림";
    if(templete_sort=="attend_out"){
      a_title="하원알림";
    }

    let send_form_data:any={
      "msg_row":{
        "a_owner_mcomp_seq":user.comp_seq,
        "a_sender_mcomp_seq":user.comp_seq,
        "a_sender_user_seq":user.user_seq,
        "a_sender":user.user_name,
        "a_title":a_title,
        "a_content":"",
      },
      "templete_sort":templete_sort,
      "receive_arr":receive_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/katalk/send',send_form_data,get_axios_data())
    .then((response) => {
      alert(response.data["msg"]);
      if(response.data["result"]==="true"){
        list({});
      }else{
        
      }
    });
  };

  const openAttendPopup=(attend_sort:string,attend_sort_detail:string,select_user:any)=>{
    let pop_title="등원";
    if(attend_sort=="out"){
      pop_title="하원";
    }
    if(xColumnArr.select_arr.a_sort_detail){
      for(let i=0;i<xColumnArr.select_arr.a_sort_detail.length;i++){
        let item=xColumnArr.select_arr.a_sort_detail[i];
        if(item.value==attend_sort_detail){
          pop_title=item.text;
        }
      }
    }
    let selected_row=listAggridRef.current.getSelectedRows();
    if(!strFunc.is_empty(select_user)){
      selected_row=[select_user];
    }
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date=listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      attend_sort:attend_sort,
      attend_sort_detail:attend_sort_detail,
      attend_date:attend_date,
      userInfoArr:selected_row,
    });
  };

  const cellClickedListener = (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      
    }
    if (key!= "idx_num"&&key!="chk_box"&&key!="a_user_name") {
      let tmp_all_nodes=listAggridRef.current.getNodeRows();
      for(let i=0;i<tmp_all_nodes.length;i++){
        if(tmp_all_nodes[i].data["a_seq"]==params.data["a_seq"]){
          tmp_all_nodes[i].setSelected(true);
        }else{
          tmp_all_nodes[i].setSelected(false);
        }
      }
    }
  };

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    let tmp_stu_seq_arr=[];
    for(let i=0;i<selected_row.length;i++){
      tmp_stu_seq_arr.push(selected_row[i]["a_seq"]);
    }
    AttendCalendarAreaRef.current.setInitData({
      "stu_seq_arr":tmp_stu_seq_arr,
    });
  };

  const cellRenderer= (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_attend_in"){
      let attend_in_btns=getAttendInCellRenderBtns(params);
      render_str=
      <div>
        {attend_in_btns}
      </div>;
    }else if(key=="row_view_attend_out"){
      let btn_class="btn-s btn-gray";
      let is_checked_btn=false;
      let attend_info:any=null;
      if(params.data.attend_out_arr&&params.data.attend_out_arr.length>0){
        attend_info=params.data.attend_out_arr[0];
        if(attend_info["a_sort"]=="out"){
          btn_class="btn-s btn-brown";
          is_checked_btn=true;
        }
      }
      render_str=
        <div>
          <button className={btn_class+" py-1"} onClick={()=>{
            if(is_checked_btn){
              if(attend_info!=null){
                deleteAttend([attend_info],"하원 취소 하시겠습니까?");
              }
            }else{
              openAttendPopup("out","out",params.data);
            }
            }} >하원</button>
        </div>;
    }else if(key=="row_view_attend_in_time"){
      if(params.data.attend_in_arr&&params.data.attend_in_arr.length>0){
        let attend_info=params.data.attend_in_arr[0];
        render_str=attend_info["a_time"];
      }
    }else if(key=="row_view_attend_out_time"){
      if(params.data.attend_out_arr&&params.data.attend_out_arr.length>0){
        let attend_info=params.data.attend_out_arr[0];
        render_str=attend_info["a_time"];
      }
    }else if(key=="row_view_attend_memo"){
      if(params.data.attend_in_arr&&params.data.attend_in_arr.length>0){
        let attend_info=params.data.attend_in_arr[0];
        render_str=attend_info["a_memo"];
      }
    }else if(key=="row_view_send_msg"){
      let in_btn:any="";
      let out_btn:any="";
      if(params.data.attend_in_arr&&params.data.attend_in_arr.length>0){
        //등원알림
        in_btn=(
          <button className="btn-s btn-dark" onClick={()=>{
            setTimeout(()=>{sendKatalkAttendMsg("attend_in",params.data);},100);
          }}>등원</button>
        );
      }
      if(params.data.attend_out_arr&&params.data.attend_out_arr.length>0){
        //하원알림
        out_btn=(
          <button className="btn-s btn-dark ml-1" onClick={()=>{
            setTimeout(()=>{sendKatalkAttendMsg("attend_out",params.data);},100);
          }}>하원</button>
        );
      }
      render_str=(
        <span>
          {in_btn}
          {out_btn}
        </span>
      );
    }else if(key=="row_view_par_phone"){
      let par_phone_num="학부모 미등록";
      if(params.data.link_user_arr&&params.data.link_user_arr[0]&&params.data.link_user_arr[0]["par_info"]){
        par_phone_num=params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str=par_phone_num;
    }
    return render_str;
  };

  const getAttendInCellRenderBtns=(params:any)=>{
    let attend_in_state_arr=[
      {"value":"attend","text":"등원"},
      {"value":"late","text":"등원(지각)"},
      {"value":"absent","text":"결석"},
    ];
    let attend_in_btns=attend_in_state_arr.map((item,idx)=>{
      let btn_class="btn-s btn-gray";
      let is_checked_btn=false;
      let attend_info:any=null;
      if(params.data.attend_in_arr&&params.data.attend_in_arr.length>0){
        attend_info=params.data.attend_in_arr[0];
        if(attend_info["a_sort_detail"]==item["value"]){
          btn_class="btn-s btn-brown";
          is_checked_btn=true;
        }
      }
      return (
        <button key={idx}
         className={btn_class+" py-1 ml-1"}
         onClick={()=>{
          if(is_checked_btn){
            if(attend_info!=null){
              deleteAttend([attend_info],"등원 취소 하시겠습니까?");
            }
          }else{
            openAttendPopup("in",item.value,params.data);
          }
        }}
         >{item.text}</button>
      );
    });
    return attend_in_btns;
  };

  return (
    <CompLayout isLeftArea={true} isConTitle={false}>
      <WeekSearch
        search_date={listOpt.s_addon_attend_start_date}
        onSearchDateChange={(change_date:string)=>{
          list({
            "s_addon_attend_start_date":change_date,
            "s_addon_attend_end_date":change_date,
          });
          
          AttendCalendarAreaRef.current.setInitData({
            select_date:change_date,
          });
        }}
      ></WeekSearch>
      <div className="con_wrap">
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          xColumnArr={xColumnArr}
          list={list}
        ></SearchArea>
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"50%"}/>
            <col width={"50%"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:"900px"}}>
                  <div className="btn-box-left relative h-8">
                    <SearchOrderRadio
                      valueTextArr={[
                        {"text":"등록순","value":"a_create_date DESC"},
                        {"text":"이름순","value":"a_user_name"},
                      ]}
                      value={listOpt.order_id}
                      handleInputChange={(e:any)=>{
                        list({
                          "now_page":1,
                          "order_id":e.target.value
                        });
                      }}
                    ></SearchOrderRadio>
                    <div className="absolute right-0 top-1 btn-box-right">
                      <button className="btn btn-dark" onClick={()=>{openAttendPopup("in","attend",null);}}>선택등원</button>
                      <button className="btn btn-dark" onClick={()=>{openAttendPopup("out","out",null);}}>선택하원</button>
                      <button className="btn btn-dark" onClick={()=>{
                        navigate("/comp/edu/attend/stat_month");
                      }}>출결통계</button>
                    </div>
                  </div>
                  <ListAggrid
                    ref={listAggridRef}
                    infoArr={infoArr}
                    xColumnArr={{...xColumnArr}}
                    list={list}
                    gridOpt={{
                      fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                      is_idx_num:true,
                      is_add_checkbox:true,
                      floatingFilter:false,
                      rowMultiSelectWithClick: true,
                      domLayout:"autoHeight",
                      onGridReady:()=>{
                        list({});
                      },
                      onCellClicked:cellClickedListener,
                      onSelectionChanged:onSelectionChanged,
                      cellRenderer:cellRenderer
                    }}
                    ></ListAggrid>
                    <Paging now_page={listOpt.now_page}
                      num_per_page={listOpt.num_per_page}
                      total_rec={countInfo.tot} 
                      onChangePage={(now_page:number)=>{list({now_page:now_page});}}
                      is_view_num_per_page={true}
                      onChangeNumPerPage={(num_per_page:number)=>{list({num_per_page:num_per_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:"730px",paddingLeft:"10px"}}>
                  <AttendCalendarArea
                    ref={AttendCalendarAreaRef}
                    select_date={listOpt.s_addon_attend_start_date}
                  ></AttendCalendarArea>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="add_attend"&&
            <AddAttendPopup attend_sort={popupData.attend_sort} attend_sort_detail={popupData.attend_sort_detail} 
              attend_date={popupData.attend_date}
              userInfoArr={popupData.userInfoArr} 
              callback={()=>{list({});}} 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></AddAttendPopup>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default AttendMain;