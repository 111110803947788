import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from '@/lib/lyg/string';
import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import DateFunc from '@/lib/lyg/date_func';
import { useNavigate } from 'react-router-dom';

function SearchMiddleArea(props: any) {
  let myProps = {
    select_date: "",
    listOpt: {},
    list: (inData: any) => { },
    go_search_user_list: (inData: any) => { },
    is_show_counseler: true,
    set_is_show_counseler: (inData: any) => { },
    teacher_info_arr: [],
    td_week_data_arr: [],
    select_week_num: -1,
    set_select_week_num: (inData: any) => { },
    ...props
  };
  const navigate = useNavigate();
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let week_select_arr: any = [];
  week_select_arr.push({ text: "전체", value: "-1" });
  for (let i = 0; i < myProps.td_week_data_arr.length; i++) {
    week_select_arr.push({ text: (i + 1) + "주", value: i });
  }

  const search_by_week_num = (week_num_str: string) => {
    let week_num = parseInt(week_num_str);
    if (week_num != -1 && myProps.td_week_data_arr.length > week_num) {
      let week_data = myProps.td_week_data_arr[week_num];
      let week_start_date = week_data.row_td_data_arr[0]["date_str"];
      myProps.list({
        s_week_start_date: week_start_date,
        s_week_end_date: week_data.end_date
      });
      myProps.go_search_user_list({
        now_page: "1",
        s_addon_month_prescribe_start_date: week_start_date,
        s_addon_month_prescribe_end_date: week_data.end_date
      });
    } else {
      myProps.list({
        s_week_start_date: "",
        s_week_end_date: ""
      });
      myProps.go_search_user_list({
        now_page: "1",
        s_addon_month_prescribe_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps.select_date), 'month', -1), "Y-m-01"),
        s_addon_month_prescribe_end_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps.select_date), 'month', +1), "Y-m-t")
      });
    }
  };

  return (
    <div className="basic_cal_middle_wrap">
      <div className="basic_cal_middle_tab_wrap">
        <select className="search-input ml-2" value={myProps.listOpt.s_subject}
          style={{ width: 80 }}
          onChange={(e: any) => {
            myProps.list({ s_subject: e.target.value });
          }}>
          <option value="">과목</option>
          {select_subject_option_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item.value} >{item.text}</option>
            );
          })}
        </select>
        <select className="search-input ml-2" value={myProps.listOpt.s_writer_seq}
          style={{ width: 80 }}
          onChange={(e: any) => {
            myProps.list({ s_writer_seq: e.target.value });
          }}>
          <option value="">작성자</option>
          {myProps.teacher_info_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item.a_seq} >{item.a_user_name}</option>
            );
          })}
        </select>

        <span className="ml-2">
          <input type="text" className="search-input" value={myProps.listOpt.s_stu_name_like} onChange={(e: any) => {
            myProps.list({ s_stu_name_like: e.target.value });
          }} style={{ width: 70 }} placeholder="학생" />
        </span>
        <span className="ml-2">
          <select className="search-input" value={myProps.listOpt.s_is_success} onChange={(e: any) => {
            myProps.list({ s_is_success: e.target.value });
          }} style={{ width: 58 }} >
            <option value="" >상태</option>
            <option value="1" >완료</option>
            <option value="empty" >미완료</option>
          </select>
        </span>
        <span className="ml-2">
          <select className="search-input" name="s_is_request_counsel" value={myProps.listOpt.s_is_request_counsel}
            onChange={(e: any) => {
              myProps.list({ s_is_request_counsel: e.target.value });
            }}
            style={{ width: "80px" }}>
            <option value="">상담요청</option>
            <option value="1">예</option>
            <option value="empty">아니오</option>
          </select>
        </span>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = myProps.listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            myProps.go_search_user_list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {myProps.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <div className="ml-2" style={{ display: "inline-block" }}>
          <SelectTab
            select_arr={week_select_arr}
            value={myProps.select_week_num}
            on_select={(value: string) => {
              myProps.set_select_week_num(value);
              search_by_week_num(value);
            }}
          ></SelectTab>
        </div>
      </div>
      <div className="basic_cal_middle_right">
        <span>
          <label>
            <input type="checkbox" checked={myProps.is_show_counseler} onChange={(e: any) => {
              myProps.set_is_show_counseler(e.target.checked);
            }} /> 작성자표시
          </label>
        </span>
        <button className="btn btn-dark ml-1" 
          onClick={()=>{
            navigate("/comp/edu/month_prescribe/list");
          }}>리스트</button>
      </div>
    </div>
  );
};
export default SearchMiddleArea;