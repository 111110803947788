//import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';

class MyScreenFunc
{
  static get_is_under(){
    var win_w=window.screen.width;
    if(win_w<800){
      return true;
    }else{
      return false;
    }
  }
}
export default MyScreenFunc;