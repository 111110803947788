import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import DailyReportListArea from "./area/daily_report_list";
import KatalkMsgData from "@/pcomponents/common/content/basic/katalk_msg/data";

function SendHomeWorkMsgPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    list: (inData: any) => { },
    daily_report_arr: [],
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [daily_report_arr, set_daily_report_arr] = useState<any>(myProps.daily_report_arr);
  const [corp_opt, set_corp_opt] = useState(KatalkMsgData.default_corp_opt);

  useEffect(() => {
    get_corp_opt_by_ajax();
    if (myProps.daily_report_arr.length == 0) {
      alert("선택이 없습니다.");
      myProps.closePopup();
    }
  }, []);

  const get_corp_opt_by_ajax = () => {
    if (strFunc.is_empty(user.corp_seq)) {
      return false;
    }
    let form_json_data = {
      "s_pri_arr": [user.corp_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp_opt/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let row_corp_opt = response.data["data"]["info_arr"][0];
            set_corp_opt(row_corp_opt);
            for(let i=0;i<daily_report_arr.length;i++){
              daily_report_arr[i]["hello_msg"] = row_corp_opt["a_homework_msg"];
            }
          }
        } else {

        }
      });
  };

  const go_send_home_work_msg_of_daily_report_by_ajax = (inData: any) => {
    let opt_obj = {
      report_data_arr: daily_report_arr,
      hello_msg: corp_opt.a_homework_msg,
      ...inData
    };
    //보고서 모으기
    let report_data_arr: any = opt_obj.report_data_arr;
    if (report_data_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    if (!confirm("학부모에게 숙제메세지를 보내시겠습니까?")) {
      return false;
    }

    let daily_pri_val_arr: any = [];
    for (let i = 0; i < opt_obj.report_data_arr.length; i++) {
      let tmp_row = opt_obj.report_data_arr[i];
      daily_pri_val_arr.push(tmp_row["a_ymd"] + "," + tmp_row["a_seq"]);
    }

    let w_report_form_json = {
      //"daily_pri_val_arr": daily_pri_val_arr,
      daily_report_arr: opt_obj.report_data_arr,
      "hello_msg": opt_obj["hello_msg"],
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/send_home_work_msg', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("숙제메세지를 보냈습니다.");
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div style={{width:"95%",margin:"auto"}}>
      <h1 className="text-center">숙제메세지 보내기</h1>
      <div className="text-center mt-1">
        <button className="btn btn-dark" onClick={() => {
          go_send_home_work_msg_of_daily_report_by_ajax({});
        }}>메세지 보내기</button>
        <button className="btn btn-dark ml-2" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <DailyReportListArea
        daily_report_arr={daily_report_arr}
        set_daily_report_arr={set_daily_report_arr}
      ></DailyReportListArea>
    </div>
  );
};
export default SendHomeWorkMsgPopup;