//import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import strFunc from '@/lib/lyg/string';
import { useRef, useEffect } from 'react';
function TextAreaComponent(props:any){
   let myProps={
    name:"",
    value:"",
    onChange:(e:any)=>{},
    class_name:"row-input",
    placeholder:"",
    style:{},
    height:26,
    scroll_add_height:2,
    autoComplete:"off",
    is_auto_height:true,
    is_read_only:false,
    ...props
  };
  if(myProps.value==undefined||myProps.value==null){
    myProps.value="";
  }
  let text_area_style:any={
    "width":"100%",
    "resize":"none",
    "lineHeight":"20px",
    padding:"1px",
    ...myProps.style,
    height:myProps.height,
  };

  const textarea_ref=useRef<any>(null);

  useEffect(()=>{
    set_auto_height_textarea();
  },[myProps.value.split("\n").length]);

  const set_auto_height_textarea=()=>{
    if(myProps.is_auto_height!=true){
      return false;
    }
    if(textarea_ref.current){
      let tmp_area_h=myProps.height;
      let line_cnt=myProps.value.split("\n").length
      let basic_height=parseInt(strFunc.getNumber2(text_area_style.lineHeight))*(line_cnt+1);
      textarea_ref.current.style.height=basic_height+"px";
      if(textarea_ref.current.scrollHeight>myProps.height){
        tmp_area_h = (textarea_ref.current.scrollHeight+myProps.scroll_add_height);
      }
      textarea_ref.current.style.height=tmp_area_h+"px";
    }
  };

  return (
  <textarea
    className={myProps.class_name}
    name={myProps.name}
    value={myProps.value}
    placeholder={myProps.placeholder}
    style={text_area_style}
    autoComplete={myProps.autoComplete}
    readOnly={myProps.is_read_only}
    ref={textarea_ref}
    onChange={(e: any) => {
      myProps.onChange(e);
    }}
  ></textarea>
  );
};
export default TextAreaComponent;