import CompLayout from "@/pcomponents/comp/layout/layout";
import Style from "./css/style.module.css";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FolderManagePopup from "@/pages/comp/edu/lecture/backup_folder/popup/manage_list";
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search";
import WriteLectureComponent from "./area/write";

function LectureMain() {
  const xColumnArr = getXcolumnJson();
  const writeLectureComponentRef = useRef<any>(null);
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_name_like": "",
    "s_teacher_like": "",
    "s_subject":"",
    "select_seq": "",
    'order_id': 'a_order_num',
    "s_state": "ing",
    "s_addon_time": "1",
    "s_addon_user": "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [isShowRight, setIsShowRight] = useState(true);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "backup_folder",//view
    has_header: true,
    title: "시간표 백업관리",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let change_list_opt={
      ...listOpt,
      ...inOptObj
    };
    change_list_opt["sc"]=listAggridRef.current.getListSc();
    setListOpt(change_list_opt);
    let form_json_data = change_list_opt;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          if (!strFunc.is_empty(form_json_data.select_seq)) {
            setTimeout(() => {
              let list_row_nodes = listAggridRef.current.getNodeRows();
              for (let i = 0; i < list_row_nodes.length; i++) {
                let tmp_row_node = list_row_nodes[i];
                if (tmp_row_node["data"]["a_seq"] == form_json_data.select_seq) {
                  tmp_row_node.setSelected(true);
                }
              }
            }, 100);
          }
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const moveOrderNum = (row_data: any, up_down: string) => {
    let tmp_section_arr: any = [...listAggridRef.current.getRows()];
    let row_num = 0;
    for (let i = 0; i < tmp_section_arr.length; i++) {
      if (tmp_section_arr[i]["a_seq"] == row_data["a_seq"]) {
        row_num = i;
      }
    }
    let next_row_num = row_num;
    if (up_down == "up") {
      next_row_num = row_num - 1;
    } else if (up_down == "down") {
      next_row_num = row_num + 1;
    }

    if (next_row_num < 0) {
      return false;
    }
    if (next_row_num >= tmp_section_arr.length) {
      return false;
    }

    let now_row_data = tmp_section_arr[row_num];
    let change_row_data = tmp_section_arr[next_row_num];

    tmp_section_arr[row_num] = change_row_data;
    tmp_section_arr[next_row_num] = now_row_data;
    save_list_order_num_by_arr(tmp_section_arr);
    setTimeout(() => {
      setInfoArr(tmp_section_arr);
      listAggridRef.current.setInfoArr(tmp_section_arr);
    }, 100);
  };

  const save_list_order_num_by_arr = (tmp_section_arr: []) => {
    let w_order_info_arr = [];
    for (let i = 0; i < tmp_section_arr.length; i++) {
      w_order_info_arr.push({
        "a_seq": tmp_section_arr[i]["a_seq"],
        "a_order_num": i,
      });
    }
    let write_form_data = {
      "is_update": "1",
      "data_arr": w_order_info_arr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/write', write_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_delete_waste_time_table_by_ajax = () => {
    if (!confirm("시간표 파일을 정리하시겠습니까?")) {
      return false;
    }
    let write_form_data = {

    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/delete_waste', write_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_backup_manage_popup = () => {
    let pop_title = "시간표 백업관리";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "backup_folder",
      width: "800px",
      height: "80%",
    });
  };

  const goWriteFrame = (isUpdate: boolean) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let row_data = {
      "a_order_num": infoArr.length
    };
    if (isUpdate) {
      if (selected_row.length > 0) {
        row_data = selected_row[0];
        isUpdate = true;
      }
    }
    if (writeLectureComponentRef.current == null) {
      return false;
    }
    writeLectureComponentRef.current.setRowDataChild(row_data, isUpdate);
  };

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let row_data = {
      "a_order_num": infoArr.length
    };
    let isUpdate = false;
    if (selected_row.length > 0) {
      row_data = selected_row[0];
      isUpdate = true;
    }
    if (writeLectureComponentRef.current == null) {
      return false;
    }
    writeLectureComponentRef.current.setRowDataChild(row_data, isUpdate);
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_stu_cnt") {
      if (params.data["student_arr"]) {
        render_str = (
          <div className="text-center">
            {params.data["student_arr"].length}명
          </div>
        );
      }
    } else if (key == "row_view_order") {
      render_str = (
        <div className="text-center">
          <button className="btn-s btn-dark" onClick={() => { moveOrderNum(params.data, "up"); }} >▲</button>
          <button className="btn-s btn-dark ml-1" onClick={() => { moveOrderNum(params.data, "down"); }} >▼</button>
        </div>
      );
    }

    return render_str;
  }, []);
  return (
    <CompLayout>
      <div className={"con_wrap " + Style.room_wrap}>
        <div className={Style.room_left_wrap} style={{ position: "relative" }}>
          <SearchArea
            listOpt={listOpt}
            list={list}
            xColumnArr={xColumnArr}
            goWriteFrame={goWriteFrame}
            goDelete={goDelete}
            isShowRight={isShowRight}
            setIsShowRight={setIsShowRight}
          ></SearchArea>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{ ...xColumnArr }}
            list={list}
            gridOpt={{
              fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num: false,
              is_add_checkbox: false,
              floatingFilter: false,
              onGridReady: () => {
                list({});
              },
              onCellClicked: cellClickedListener,
              cellRenderer: cellRenderer,
              onSelectionChanged: onSelectionChanged
            }}
          ></ListAggrid>
          <div className="mt-1">
            <button className="btn btn-dark" onClick={go_delete_waste_time_table_by_ajax} >정리</button>
            <button className="btn btn-dark ml-1" onClick={open_backup_manage_popup} >백업관리</button>
          </div>
        </div>
        {isShowRight &&
          <div className={Style.room_center_wrap}>
            <WriteLectureComponent
              ref={writeLectureComponentRef}
              list={list}
            ></WriteLectureComponent>
          </div>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "backup_folder" &&
            <FolderManagePopup
              list={list}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FolderManagePopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default LectureMain;