import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react"
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

const DeskComponent = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => {

    },
    ...props
  };
  const [deskXY, setDeskXY] = useState({
    x: 5,
    y: 5
  });
  const [deskArr, setDeskArr] = useState<any[]>([]);

  useEffect(() => {

  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setDeskXY, setDeskArr, getDeskArr
  }));

  const getDeskArr = () => {
    return deskArr;
  };

  let col_per_num = 100 / deskXY.x;
  let x_arr = [];
  for (let i = 0; i < deskXY.x; i++) {
    x_arr.push(i);
  }

  const goAddDesk = (x_num: number, y_num: number) => {
    if (checkIsExistDeskXY(x_num, y_num)) {
      return false;
    }
    let add_desk_row = {
      a_x: x_num,
      a_y: y_num,
      a_name: (deskArr.length + 1) + "",
      a_is_able_use: "1",
      a_able_memo: "",
    };
    setDeskArr([
      ...deskArr,
      ...[add_desk_row]
    ]);
  };
  const goRemoveDesk = (x_num: number, y_num: number) => {
    let desk_len = deskArr.length;
    let tmpDeskArr = [];
    for (let i = 0; i < desk_len; i++) {
      let desk_info = deskArr[i];
      if (desk_info.a_x == x_num && desk_info.a_y == y_num) {

      } else {
        tmpDeskArr.push(desk_info);
      }
    }
    setDeskArr(tmpDeskArr);
  };

  const checkIsExistDeskXY = (x_num: number, y_num: number) => {
    let desk_len = deskArr.length;
    let is_exist = false;
    for (let i = 0; i < desk_len; i++) {
      let desk_info = deskArr[i];
      if (desk_info.a_x == x_num && desk_info.a_y == y_num) {
        is_exist = true;
      }
    }
    return is_exist;
  };

  const getDeskInfoByXY = (x_num: number, y_num: number) => {
    let desk_len = deskArr.length;
    let is_exist = false;
    for (let i = 0; i < desk_len; i++) {
      let desk_info = deskArr[i];
      if (desk_info.a_x == x_num && desk_info.a_y == y_num) {
        return desk_info;
      }
    }
    return is_exist;
  };

  const onChangeDeskName = (x: number, y: number, value: string) => {
    let desk_len = deskArr.length;
    let tmpDeskArr = [...deskArr];
    for (let i = 0; i < desk_len; i++) {
      if (tmpDeskArr[i].a_x == x && tmpDeskArr[i].a_y == y) {
        tmpDeskArr[i].a_name = value;
      }
    }
    setDeskArr(tmpDeskArr);
  };

  const onChangeDeskInput = (inData: any) => {
    let opt_obj = {
      x: 0,
      y: 0,
      name: "",
      value: "",
      ...inData
    };
    let x = opt_obj.x;
    let y = opt_obj.y;
    let value = opt_obj.value;
    let name = opt_obj.name;
    let desk_len = deskArr.length;
    let tmpDeskArr = [...deskArr];
    for (let i = 0; i < desk_len; i++) {
      if (tmpDeskArr[i].a_x == x && tmpDeskArr[i].a_y == y) {
        tmpDeskArr[i][name] = value;
      }
    }
    setDeskArr(tmpDeskArr);
  };

  const goToggleDeskAble = (x: number, y: number) => {
    let desk_len = deskArr.length;
    let tmpDeskArr = [...deskArr];
    for (let i = 0; i < desk_len; i++) {
      if (tmpDeskArr[i].a_x == x && tmpDeskArr[i].a_y == y) {
        if (tmpDeskArr[i].a_is_able_use == "1") {
          tmpDeskArr[i].a_is_able_use = "";
        } else {
          tmpDeskArr[i].a_is_able_use = "1";
        }
      }
    }
    setDeskArr(tmpDeskArr);
  };

  const goAutoDeskNum = () => {
    let tmpDeskArr: any = [];
    let desk_num = 1;
    for (let y = 0; y < deskXY.y; y++) {
      for (let x = 0; x < deskXY.x; x++) {
        let desk_info = getDeskInfoByXY(x, y);
        if (desk_info) {
          desk_info.a_name = desk_num;
          tmpDeskArr.push(desk_info);
          desk_num++;
        }
      }
    }
    setDeskArr(tmpDeskArr);
  };

  const getTrArr = () => {
    let x_arr: any[] = [];
    let y_arr = [];
    for (let i = 0; i < deskXY.x; i++) {
      x_arr.push(i);
    }
    for (let i = 0; i < deskXY.y; i++) {
      y_arr.push(i);
    }

    let trArrTag = y_arr.map((tr_num: number, tr_idx: number) => {
      let tdArrTag = x_arr.map((x_num: number, x_idx: number) => {

        let desk_plus_div: any = "";
        let desk_info_div: any = "";
        let desk_info = getDeskInfoByXY(x_num, tr_num);
        if (!desk_info) {
          desk_plus_div = (
            <div className={Style.desk_plus_div} onClick={() => { goAddDesk(x_num, tr_num); }}>+</div>
          );
        } else {
          let able_state_text = "불가";
          let able_btn_class_name = Style.desk_info_able_btn;
          if (desk_info.a_is_able_use == "1") {
            able_btn_class_name += " " + Style.active;
            able_state_text = "사용";
          }
          desk_info_div = (
            <div className={Style.desk_info_div}>
              <input type="text" className={Style.desk_name_input} value={desk_info.a_name}
                placeholder="번호"
                onChange={(e: any) => {
                  onChangeDeskName(x_num, tr_num, e.target.value);
                }} />
              <div className={Style.desk_info_btn_area}>
                {desk_info.a_is_able_use != "1" &&
                  <input type="text" className={Style.desk_able_input} value={desk_info.a_able_memo}
                    placeholder="메모"
                    title={desk_info.a_able_memo}
                    onChange={(e: any) => {
                      onChangeDeskInput({
                        x: x_num,
                        y: tr_num,
                        name: "a_able_memo",
                        value: e.target.value
                      });
                    }} />
                }
                <div className={able_btn_class_name} onClick={() => { goToggleDeskAble(x_num, tr_num); }}>
                  {able_state_text}
                </div>
                <div className={Style.desk_info_x_btn} onClick={() => { goRemoveDesk(x_num, tr_num); }}>X</div>
              </div>
            </div>
          );
        }

        return (
          <td key={x_idx}>
            <div className={Style.desk_td_div}>
              {desk_plus_div}
              {desk_info_div}
            </div>
          </td>
        );
      });

      return (
        <tr key={tr_idx}>
          {tdArrTag}
        </tr>
      );
    });

    return trArrTag;
  };

  return (
    <div className={Style.desk_table_wrap}>
      <h5>
        책상설정

        <button className="btn btn-dark ml-2" onClick={() => { setDeskArr([]); }}>초기화</button>
        <button className="btn btn-dark ml-2" onClick={goAutoDeskNum}>자동번호설정</button>

      </h5>
      <table className="mt-1">
        <colgroup>
          {
            x_arr.map((item: number, idx: number) => {
              return (
                <col width={col_per_num + "%"} key={idx} ></col>
              );
            })
          }
        </colgroup>
        <tbody>
          {getTrArr()}
        </tbody>
      </table>
    </div>
  );
});

export default DeskComponent;