import { forwardRef, useImperativeHandle } from "react";
import go_down_excel_daily_list from "./daily_list";
import getXcolumnJson2 from "@/pages/comp/edu/daily_report/card_list/area/daily_list/xcolumn/list";
import UserGroupDataFunc from "@/pcomponents/common/content/list/user/search/tab_area/func/group_data";

const ExcelDownDailyReportArea = forwardRef((props, ref) => {
  let myProps = {
    ...props
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    go_excel_down
  }));

  const go_excel_down=(inData:any)=>{
    let opt_obj={
      list_tab:"",
      tab_id:"",
      info_arr:[],
      group_data_arr_ref:null,
      select_date:"",
      is_print_mode:true,
      is_show_etc_group:true,
      show_group_time:{},
      able_time_arr_ref:[],
      xColumnArr:{},
      ...inData,
    };

    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: opt_obj.select_date,
      info_arr: opt_obj.info_arr,
      tab_id: opt_obj.tab_id,
    });
    opt_obj.group_data_arr=group_data_arr;

    if(opt_obj.list_tab=="day"){
      opt_obj.xColumnArr = getXcolumnJson2();
      go_down_excel_daily_list(opt_obj);
    }else{
      alert("지원하지 않는 탭입니다.");
    }
  };

  return (
    <></>
  );
});
export default ExcelDownDailyReportArea;