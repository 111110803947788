import ExcelDownComponent from "@/pcomponents/common/excel/down";
import { useRef,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import getXcolumnJson from "./xcolumn/list";

const StuListExcelDown = forwardRef((props:any, ref) => {
   let myProps={
    infoArr:[],
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const ExcelDownComponentRef = useRef<any>();
  let infoArr = myProps.infoArr;

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    goExcelDown
  }))

  const goExcelDown = () => {
    if (ExcelDownComponentRef.current) {
      let tmp_info_arr: any = [...infoArr];
      for (let i = 0; i < tmp_info_arr.length; i++) {
        let info = tmp_info_arr[i];
        let par_phone_num = "학부모 미등록";
        if (info.link_user_arr && info.link_user_arr[0] && info.link_user_arr[0]["par_info"]) {
          par_phone_num = info.link_user_arr[0]["par_info"]["a_user_phone"];
        }
        tmp_info_arr[i]["row_view_par_phone"] = par_phone_num;

        //클래스
        if (info.class_arr) {
          let class_name_arr = [];
          for (let i = 0; i < info.class_arr.length; i++) {
            class_name_arr.push(info.class_arr[i]["class_name"]);
          }
          tmp_info_arr[i]["row_view_class_name"] = class_name_arr.join(" / ");;
        }
        //row_view_is_app
        if (info.comp_link_info) {
          tmp_info_arr[i]["row_view_is_app"] = "가입";
        }
        tmp_info_arr[i]["a_stu_num"] = strFunc.getNumber2(tmp_info_arr[i]["a_stu_num"])

        //학년
        for (let j = 0; j < xColumnArr.select_arr["a_stu_grade"].length; j++) {
          let tmp_row_opt = xColumnArr.select_arr["a_stu_grade"][j];
          if (tmp_row_opt["value"] == tmp_info_arr[i]["a_stu_grade"]) {
            tmp_info_arr[i]["a_stu_grade"] = tmp_row_opt["text"];
          }
        }

        tmp_info_arr[i]["row_view_school_grade"] = tmp_info_arr[i]["a_school_name"].substring(0,2) + " " + tmp_info_arr[i]["a_stu_grade"];

        tmp_info_arr[i]["row_view_char_opt"] = 0;
        if (tmp_info_arr[i].char_opt) {
          tmp_info_arr[i]["row_view_char_opt"] = tmp_info_arr[i].char_opt["a_gold"];
        }
      }
      ExcelDownComponentRef.current.setInitData({
        infoArr: tmp_info_arr
      });
      setTimeout(() => {
        ExcelDownComponentRef.current.goDownExcel();
      }, 100);
    }
  };
  
  return (
  <>
    <ExcelDownComponent
        ref={ExcelDownComponentRef}
        xColumnArr={xColumnArr}
        is_add_example={false}
      ></ExcelDownComponent>
  </>
  );
});
export default StuListExcelDown;