import SearchSubjectArea from "@/pages/comp/plan/study/study/list/area/search_area/search_subject";

function SelectAddStudyTitleArea(props: any) {
  let myProps = {
    on_select_click: () => { },
    default_study_title: {},
    set_default_study_title: (inData: any) => { },
    ...props
  };

  return (
    <div className="text-center" style={{ padding: "10px" ,background:"#f5f5f5"}}>
      <SearchSubjectArea
        listOpt={myProps.default_study_title}
        setListOpt={myProps.set_default_study_title}
        is_show_key_json={{
          s_title_like:false,
        }}
      ></SearchSubjectArea>
      <button className="btn btn-darkgreen ml-2" onClick={myProps.on_select_click}>교재관리로 가져오기</button>
      <button className="btn btn-dark ml-1" onClick={() => { myProps.closePopup(); }}>닫기</button>
      <div style={{fontSize:"15px",color:"#666",marginTop:"10px"}}>
        ※교재로 가져올때 선택된 분류로 지정이 됩니다.
      </div>
    </div>
  );
};

export default SelectAddStudyTitleArea;