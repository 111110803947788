import Style from "../../css/style.module.css";
import CardBoxArea from "../card_box";

const CardGroupArea = (props:any) => {
  let myProps={
    group_arr:[],
    base_date_str:"",
    pre_date_str:"",
    xColumnArr:{},
    deleteAttend:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    openViewTodoDayPopup:(stu_info:any)=>{},
    go_change_todo_state_by_ajax:(inData:any)=>{},
    ...props
  };

  const get_card_tag_box_arr=(group_info:any)=>{
    let card_tags=group_info.info_arr.map((user_info:any,idx:number)=>{
      return (
        <CardBoxArea
          key={idx}
          user_info={user_info}
          base_date_str={myProps.base_date_str}
          pre_date_str={myProps.pre_date_str}
          xColumnArr={myProps.xColumnArr}
          deleteAttend={myProps.deleteAttend}
          openAttendPopup={myProps.openAttendPopup}
          openViewTodoDayPopup={myProps.openViewTodoDayPopup}
          go_change_todo_state_by_ajax={myProps.go_change_todo_state_by_ajax}
        ></CardBoxArea>
      );
    });
    return card_tags;
  };

  return (
    <div>
      {myProps.group_arr.map((item:any,idx:number)=>{
        return (
          <div className={Style.card_group} key={idx}>
            <div className={Style.card_group_title} >
              <div className={Style.card_group_title_pre_bar}></div>
              {item.title}
            </div>
            <div className={Style.card_group_con} >
              {get_card_tag_box_arr(item)}
            </div>
          </div>
        );
      })}
   </div>
  );
};

export default CardGroupArea;