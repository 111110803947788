
function StuListSelectArea(props: any) {
  let myProps = {
    infoArr: [],
    select_stu_info: null,
    set_select_stu_info: (inData: any) => { },
    ...props
  };
  let infoArr = myProps.infoArr;
  let select_stu_info = myProps.select_stu_info ? myProps.select_stu_info : {};
  let is_search_stu_ing = myProps.is_search_stu_ing;

  const get_stu_tags = () => {
    let stu_tags = infoArr.map((info: any) => {
      let stu_item_style: any = {
        cursor: "pointer",
        padding: "5px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
        fontSize: "14px",
        color: "#666",
      };

      let is_select_stu = false;
      if (info["a_seq"] == select_stu_info["a_seq"]) {
        is_select_stu = true;
        stu_item_style.backgroundColor = "#ffcc00";
      }

      return (
        <div key={info.a_seq} style={stu_item_style}
          onClick={() => {
            myProps.set_select_stu_info(info);
          }}>
          {info.a_user_name}
        </div>
      );
    });
    return stu_tags;
  };

  let stu_list_wrap_style = {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  };

  return (
    <div style={stu_list_wrap_style}>
      {get_stu_tags()}
    </div>
  );
};
export default StuListSelectArea;