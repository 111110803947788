import StudyDetailListArea from "./study_list";

function SectionStudyArea(props: any) {
  let myProps = {
    Style: {},
    study_map_info: {},
    callback_refresh_data: (inData: any) => { },
    open_map_detail_write_popup: (inData: any) => { },
    ...props
  };
  let Style=myProps.Style;
  let section_arr = myProps.study_map_info.section_arr;
  let study_detail_arr = myProps.study_map_info.study_detail_arr;
  let map_detail_arr = myProps.study_map_info.detail_arr;

  const get_section_tags=()=>{
    let section_tags=section_arr.map((item:any,idx:number)=>{
      let row_map_detail:any=null;
      for(let i=0;i<map_detail_arr.length;i++){
        let tmp_map_detail=map_detail_arr[i];
        if(tmp_map_detail["a_section_name"]==item["a_title"]
          &&tmp_map_detail["a_unit_name"]==""){
            row_map_detail=tmp_map_detail;
        }
      }
      let row_map_detail_text="없음";
      let row_map_detail_style:any={
        color:"gray",
      };
      if(row_map_detail!=null){
        row_map_detail_text="미완료";
        row_map_detail_style["color"]="orange";
        if(row_map_detail["a_is_success"]=="1"){
          row_map_detail_text="완료";
          row_map_detail_style["color"]="blue";
        }
      }
      return (
        <div key={idx} className={Style.section_row}>
          <div className={Style.section_title_wrap}
            onClick={()=>{
              myProps.open_map_detail_write_popup({
                map_detail_info:row_map_detail,
                section_study_name:{
                  section_name:item["a_title"],
                  unit_name:"",
                },
              });
            }}
          >
            {idx+1}. {item.a_title}
            <span className={Style.study_detail_right}>
              <span style={row_map_detail_style}>{row_map_detail_text}</span>
            </span>
          </div>
          <StudyDetailListArea
            Style={Style}
            section_info={item}
            study_detail_arr={study_detail_arr}
            map_detail_arr={map_detail_arr}
            open_map_detail_write_popup={myProps.open_map_detail_write_popup}
          ></StudyDetailListArea>
        </div>
      );
    });

    return section_tags;
  };

  return (
    <div>
      {get_section_tags()}
    </div>
  );
};
export default SectionStudyArea;