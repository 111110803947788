import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import TradeListArea from "./area/trade_list";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    s_stu_grade: "",
    s_addon_class_user: "1",
    s_addon_trade_tot: "1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [select_seq_arr, set_select_seq_arr] = useState([]);
  const [classSelectArr, setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr, setSchoolNameSelectArr] = useState<any>([]);
  const TradeListAreaRef = useRef<any>(null);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    stu_seq: "",
    stu_name: "",
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    getClassFrontArr();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_list_opt: any = {};
    change_list_opt[name] = value;

    if (name == "s_search_text") {
      setListOpt({ ...listOpt, ...change_list_opt });
      return false;
    }
    list({ ...change_list_opt, "now_page": 1 });
  };

  const getClassFrontArr = () => {
    let class_list_form = {
      "s_add_school_arr": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/front_class_arr', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setClassSelectArr(response.data["data"]["class_arr"]);
          setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
        } else {

        }
      });
  };

  const list = (inOptObj: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inOptObj
    };
    change_list_opt["sc"] = listAggridRef.current.getListSc();
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_trade_tot") {
      if (params.data.trade_tot_info) {
        render_str = strFunc.comma(params.data.trade_tot_info.remain_money);
      }
    } else if (key == "a_user_name") {
      let stu_grade_str = "";
      if (xColumnArr.select_arr.a_stu_grade) {
        for (let i = 0; i < xColumnArr.select_arr.a_stu_grade.length; i++) {
          if (xColumnArr.select_arr.a_stu_grade[i].value == params.data.a_stu_grade) {
            stu_grade_str = xColumnArr.select_arr.a_stu_grade[i].text;
          }
        }
      }
      let stu_school_name_str = params.data.a_school_name.substring(0, 2);
      render_str = (
        <div>
          {render_str}
          <span className="ml-1" style={{ color: "gray", fontSize: "12px" }}>
            {stu_school_name_str + " " + stu_grade_str}
          </span>
        </div>
      );
    }
    return render_str;
  }, []);

  const onSelectionChanged = () => {
    setTimeout(() => {
      let tmp_select_seq_arr: any = [];
      let stu_name_arr: any[] = [];
      let trade_tot_info: any = {};
      let selected_row = listAggridRef.current.getSelectedRows();
      for (let i = 0; i < selected_row.length; i++) {
        tmp_select_seq_arr.push(selected_row[i].a_seq);
        stu_name_arr.push(selected_row[i].a_user_name);
        if (selected_row[i]["trade_tot_info"]) {
          for(let key in selected_row[i]["trade_tot_info"]){
            if(trade_tot_info[key]==undefined){trade_tot_info[key]=0;}
            trade_tot_info[key]+=parseInt(strFunc.uncomma(selected_row[i]["trade_tot_info"][key]));
          }
        }
      }
      TradeListAreaRef.current.setInitData({
        "stu_seq_arr": tmp_select_seq_arr,
        "stu_name_arr": stu_name_arr,
        "trade_tot_info": trade_tot_info,
      });
      set_select_seq_arr(tmp_select_seq_arr);
    }, 100);
  };

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{ minWidth: 300 }}>
            <div className="relative" style={{ minHeight: 55 }}>
              <div className="search-box relative py-0">
                <select className="search-input_select" name="s_class_seq" value={listOpt.s_class_seq} onChange={handleInputChange}
                  style={{ width: 70 }} >
                  <option value="">클래스</option>
                  {
                    classSelectArr.map((item: any, idx: number) => {
                      return (
                        <option value={item.value} key={idx}>{item.text}</option>
                      );
                    })
                  }
                </select>
                <select className="search-input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
                  <option value="">현황</option>
                  {
                    xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
                      return (
                        <option value={item.value} key={idx}>{item.text}</option>
                      );
                    })
                  }
                </select>
                <select className="search-input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
                  <option value="">학년</option>
                  <option value="elementary">초</option>
                  <option value="middle">중</option>
                  <option value="high">고</option>
                  {
                    xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
                      return (
                        <option value={item.value} key={idx}>{item.text}</option>
                      );
                    })
                  }
                </select>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => {
                    let change_s_stu_bookmark = listOpt.s_stu_bookmark;
                    if (change_s_stu_bookmark == "1") {
                      change_s_stu_bookmark = "";
                    } else {
                      change_s_stu_bookmark = "1";
                    }
                    strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
                    list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
                  }}>
                  {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
                </span>
                <SearchLectureSelect
                  name="s_stu_lecture_seq"
                  value={listOpt.s_stu_lecture_seq}
                  on_change_input={(inData: any) => {
                    let name = inData.name;
                    let value = inData.value;
                    list({ now_page: 1, [name]: value });
                  }}
                ></SearchLectureSelect>
              </div>

              <div className="search-box relative py-0 ml-0 mt-1">
                <select className="search-input_select" name="s_search_text_type" value={listOpt.s_search_text_type} onChange={handleInputChange} >
                  <option value="name">이름</option>
                  <option value="phone">핸드폰</option>
                </select>
                <input type="text" className="search-input" name="s_search_text"
                  style={{ width: 80 }} placeholder="검색어" value={listOpt.s_search_text}
                  onChange={handleInputChange} autoComplete="off"
                  onKeyUp={(e: any) => { if (e.key === 'Enter') { list({ "now_page": 1 }); } }} />
                <button className="btn btn-gray" onClick={() => { list({ "now_page": 1 }); }} >검색</button>
              </div>
            </div>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{ ...xColumnArr }}
              list={list}
              gridOpt={{
                fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num: false,
                is_add_checkbox: true,
                floatingFilter: false,
                onGridReady: () => {
                  list({});
                },
                onCellClicked: cellClickedListener,
                cellRenderer: cellRenderer,
                onSelectionChanged: onSelectionChanged
              }}
            ></ListAggrid>
            <Paging now_page={listOpt.now_page}
              num_per_page={listOpt.num_per_page}
              total_rec={countInfo.tot}
              onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
          </div>
          <div className="grow" style={{ minWidth: 450 }} >
            <TradeListArea
              ref={TradeListAreaRef}
              list={list}
            ></TradeListArea>
          </div>
        </div>
      </div>
    </CompLayout>
  );
}

export default ListPage;