import DisCoverMapWriteArea from "@/pages/comp/edu/discover_map/common/write";
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";

function DiscoveryMapTabArea(props: any) {
  let myProps = {
    select_mcomp_data: {
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_info: {},
    is_view_mode: true,
    ...props
  };
  let select_mcomp_data = myProps.select_mcomp_data;
  const DisCoverMapWriteAreaRef = useRef<any>(null);

  const [list_opt,set_list_opt] = useState({
    s_date_type: "a_date",
    s_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t"),
  });

  useEffect(() => {
    go_write_page({
      stu_seq: myProps.stu_info.a_seq,
      select_mcomp_data: select_mcomp_data,
    });
  }, [myProps.stu_info.a_seq, select_mcomp_data.mcomp_seq]);

  const go_write_page = (inData: any) => {
    let opt_obj = {
      stu_seq: myProps.stu_info.a_seq,
      info: {},
      info_arr: [],
      is_update: true,
      is_view_mode: true,
      select_mcomp_data: select_mcomp_data,
      list_opt:list_opt,
      ...inData
    };
    if (DisCoverMapWriteAreaRef.current) {
      DisCoverMapWriteAreaRef.current.initData({
        ...opt_obj
      });
    }
  };

  return (
    <div>
      <div>
        <SearchArea
          list_opt={list_opt}
          set_list_opt={set_list_opt}
          list={(inData: any) => {
            go_write_page({
              list_opt: inData
            });
          }}
        ></SearchArea>
      </div>
      <div className="mt-1">
        <DisCoverMapWriteArea
          ref={DisCoverMapWriteAreaRef}
          list={(inData: any) => {
            
          }}
        ></DisCoverMapWriteArea>
      </div>
    </div>
  );
};
export default DiscoveryMapTabArea;