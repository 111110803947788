import strFunc from "@/lib/lyg/string";
import ListBox from "./list_box";

function ListArea(props:any){
   let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    infoArr:[],
    setInfoArr:(inData:any)=>{},
    list:(inData:any)=>{},
    writer_seq:"",
    writer_name:"",
    user:{},
    Style:{},
    delete_by_ajax:(inData:any)=>{},
    update_by_ajax:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  
  const get_list_box_tags=()=>{
    let list_box_tags=myProps.infoArr.map((item:any,idx:number)=>{
      return (
        <ListBox
          key={idx}
          info={item}
          row_num={idx}
          infoArr={myProps.infoArr}
          setInfoArr={myProps.setInfoArr}
          user={myProps.user}
          select_mcomp_data={myProps.select_mcomp_data}
          Style={Style}
          delete_by_ajax={myProps.delete_by_ajax}
          update_by_ajax={myProps.update_by_ajax}
        ></ListBox>
      );
    });

    if(strFunc.is_empty(list_box_tags)){
      // list_box_tags=(
      //   <div style={{lineHeight:"30px",textAlign:"center",color:"#777"}}>
      //     내용이 없습니다.
      //   </div>
      // );
    }

    return list_box_tags;
  };

  return (
  <div className={Style.list_wrap}>
    {get_list_box_tags()}
  </div>
  );
};
export default ListArea;