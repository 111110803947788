import ExcelDownComponent from "@/pcomponents/common/excel/down";
import { useRef,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const DiscoverListExcelDown = forwardRef((props:any, ref) => {
   let myProps={
    xColumnArr:{},
    infoArr:[],
    ...props
  };
  let xColumnArr = myProps.xColumnArr;
  const ExcelDownComponentRef = useRef<any>();
  let infoArr = myProps.infoArr;

  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    goExcelDown
  }))

  const goExcelDown = () => {
    if (ExcelDownComponentRef.current) {
      let tmp_info_arr: any = [...infoArr];
      for (let i = 0; i < tmp_info_arr.length; i++) {
        let info = tmp_info_arr[i];
        for(let col_i=0;col_i<xColumnArr.x_column_list_arr.length;col_i++){
          let row_col=xColumnArr.x_column_list_arr[col_i];
          let key=row_col["key"];
          let render_str="";
          if(info[key]){
            render_str=info[key];
          }
          if(key=="a_stu_name"){
            if(info.stu_info){
              let tmp_stu_grade="";
              for(let i=0;i<select_stu_grade_arr.length;i++){
                if(select_stu_grade_arr[i]["value"]==info.stu_info["a_stu_grade"]){
                  tmp_stu_grade=select_stu_grade_arr[i]["text"];
                }
              }
              render_str=render_str+" "+tmp_stu_grade;
            }
          }else if(key=="a_homework_state"||key=="a_study_state"){
            for(let i=0;i<select_homework_state_arr.length;i++){
              if(select_homework_state_arr[i]["value"]==info[key]){
                render_str=select_homework_state_arr[i]["text"];
                break;
              }
            }
          }else if(key=="a_textbook_study"){
            if(info["a_textbook_study_num"]){
              render_str=info["a_textbook_study_num"]+". "+render_str;
            }
          }else if(key=="a_textbook_study_det"){
            if(info["a_textbook_study_det_num"]){
              render_str=info["a_textbook_study_det_num"]+". "+render_str;
            }
          }
          if(render_str){
            info[key]=render_str;
          }
        }
      }
      ExcelDownComponentRef.current.setInitData({
        infoArr: tmp_info_arr
      });
      setTimeout(() => {
        ExcelDownComponentRef.current.goDownExcel();
      }, 100);
    }
  };
  
  return (
  <>
    <ExcelDownComponent
        ref={ExcelDownComponentRef}
        xColumnArr={xColumnArr}
        is_add_example={false}
      ></ExcelDownComponent>
  </>
  );
});
export default DiscoverListExcelDown;