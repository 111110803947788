import AttendStateFunc from "@/pcomponents/common/content/edu/attend/func/state_func";

class TdDataFunc {
  static setting_data_at_td_data_arr = (inData: any) => {
    let opt_obj = {
      cal_data: {},
      td_data_arr: [],
      attend_info_arr:[],
      is_show_memo:false,
      ...inData,
    };
    let td_data_arr = opt_obj["td_data_arr"];
    let attend_info_arr=opt_obj["attend_info_arr"];
    let this_obj=this;
    
    for(let td_i=0;td_i<td_data_arr.length;td_i++){
      let td_data=td_data_arr[td_i];
      td_data_arr[td_i]["content"] = this_obj.get_td_content_of_td_data_arr({
        td_data:td_data,
        attend_info_arr:attend_info_arr,
        is_show_memo:opt_obj["is_show_memo"]
      });
    }

    return td_data_arr;
  };
  static get_td_content_of_td_data_arr = (inData: any) => {
    let opt_obj={
      td_data:{},
      attend_info_arr:[],
      is_show_memo:false,
      ...inData
    };
    let td_data=opt_obj["td_data"];
    let attend_info_arr=opt_obj["attend_info_arr"];

    let attend_stu_arr:any={};//[{in:null,out:null}]
    for(let i=0;i<attend_info_arr.length;i++){
      let attend_info=attend_info_arr[i];
      if(attend_info["a_ymd"]===td_data["date_str"]){
        let stu_seq=attend_info["a_user_seq"];
        let attend_sort=attend_info["a_sort"];
        if(attend_stu_arr[stu_seq]==undefined){
          attend_stu_arr[stu_seq]={in:null,out:null};
        }
        attend_stu_arr[stu_seq][attend_sort]=attend_info;
      }
    };
    
    let attend_content_str:any="";
    let attend_tags_arr:any=[];
    let stu_idx=0;
    for(let key in attend_stu_arr){
      stu_idx++;
      let attend_td=attend_stu_arr[key];
      let in_time_str:any="";
      if(attend_td["in"]!=null){
        let state_data=AttendStateFunc.get_color_by_attend_info({
          attend_info:attend_td["in"]
        });
        let attend_text_style:any={background:state_data["background"],color:"#fff",fontSize:"11px"};
        if(attend_td["in"]["a_sort_detail"]=="attend"){
          attend_text_style["display"]="none";
        }
        in_time_str=(
          <div>
            {attend_td["in"]["a_user_name"]} 
            {attend_td["in"]["a_sort_detail"]!="absent"&& 
              <span className="ml-1">{attend_td["in"]["a_time"].substr(0,5)}</span>
            }
            <span className="ml-1" style={attend_text_style}>
              {state_data["text"]}
            </span>
            {(opt_obj["is_show_memo"]&&attend_td["in"]["a_memo"]!="") && 
            (
              <div className="ml-5">
                {attend_td["in"]["a_memo"]}
              </div>
            )}
          </div>
        );
      }
      let out_time_str:any="";
      if(attend_td["out"]!=null){
        out_time_str=(
          <div className="ml-5">
            하원 {attend_td["out"]["a_time"].substr(0,5)}
          </div>
        );
      }
      attend_tags_arr.push((
        <div>
          {in_time_str}
          {out_time_str}
        </div>
      ));
    }
    attend_content_str=attend_tags_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx} className="text-left">
          {item}
        </div>
      );
    });

    return attend_content_str;
  };
}
export default TdDataFunc;