import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import LygCalendarFunc from '@/lib/lyg/calendar';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useState, useRef } from 'react';
import UserGroupDataFunc from "@/pcomponents/common/content/list/user/search/tab_area/func/group_data";
import DailyGroupDataFunc from "../../func/group_data_func";
import getXcolumnJson from "./xcolumn/list";
import Style from "./css/style.module.css";
import CardBoxArea from "./card_box";

function DailyListArea(props: any) {
  let myProps = {
    tab_id: "",
    info_arr: [],
    set_info_arr: (inData: any) => { },
    group_data_arr_ref: null,
    select_date: "",
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    is_print_mode: false,
    is_show_etc_group: false,
    show_group_time:{},
    set_group_time:(inData: any) => { },
    able_time_arr_ref:null,
    save_daily_report_by_stu_arr: (inData: any) => { },
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    open_write_td_memo_popup: (inData: any) => { },
    openTodoDaySort: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };

  let group_data_arr_ref = myProps.group_data_arr_ref;
  let now_d_json = DateFunc.get_date_json(new Date());
  let select_date_json = DateFunc.get_date_json(new Date(myProps["select_date"]));
  let select_day_str = DateFunc.get_day_str_by_day_num(select_date_json.day);
  let cal_data=LygCalendarFunc.get_cal_data_by_select_date(myProps["select_date"]);
  let pre_date_change_num = -1;
  if (select_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps["select_date"]), 'day', pre_date_change_num), "Y-m-d");

  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });

  const xColumnArr = getXcolumnJson();
  if (myProps.is_print_mode ) {
    for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
      if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_checkbox") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "";
      }else if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_manage") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "";
      }
    }
  }
  let show_column_cnt = 0;
  for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
    if (xColumnArr.x_column_list_arr[i]["is_show"] == "1") {
      show_column_cnt++;
    }
  }
  const [focused_stu_seq,set_focused_stu_seq]=useState("");
  const auto_save_data = useRef<any>({
    time_out_ref:null,
    delay_time: 500,
    write_data_arr: [],
  });

  const save_auto_write_daily_info_by_time_out = () => {
    return false;
    if(auto_save_data.current.time_out_ref){
      clearTimeout(auto_save_data.current.time_out_ref);
      auto_save_data.current.time_out_ref=null;
    }
    if(auto_save_data.current.write_data_arr.length==0){
      return false;
    }
    auto_save_data.current.time_out_ref = setTimeout(() => {
      if (auto_save_data.current.write_data_arr.length > 0) {
        myProps.save_daily_report_by_stu_arr({
          report_data_arr: auto_save_data.current.write_data_arr,
        });
      }
      auto_save_data.current.write_data_arr = [];
      auto_save_data.current.time_out_ref = null;
    }, auto_save_data.current.delay_time);
  };


  const on_check_all = (e: any) => {
    if (e.target.checked == true) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for(let g_i=0;g_i<group_data_arr_ref.current.length;g_i++){
        let group_data=group_data_arr_ref.current[g_i];
        if(myProps.is_show_etc_group==false&&group_data["title"]=="기타"){
          continue;
        }
        let tmp_lecture_seq="";
        if(group_data["lecture_info"]){
          tmp_lecture_seq=group_data["lecture_info"]["a_seq"];
        }
        for (let i = 0; i < group_data["info_arr"].length; i++) {
          let info = group_data["info_arr"][i];
          let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq+","+group_data["time_str"];
          if (strFunc.str_in_array(tmp_card_row_pri_val, myProps.check_row_val_arr) == -1) {
            tmp_check_row_val_arr.push(tmp_card_row_pri_val);
          }
        }
      }
      
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      myProps.set_check_row_val_arr([]);
    }
  };

  const on_change_group_checkbox = (inData: any) => {
    let opt_obj = {
      checked: false,
      info_arr: [],
      lecture_info:null,
      lecture_time_str:"",
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["info_arr"])) {
      return false;
    }
    let tmp_lecture_seq="";
    let lecture_time_str=opt_obj.lecture_time_str;
    if(opt_obj["lecture_info"]){
      tmp_lecture_seq=opt_obj["lecture_info"]["a_seq"];
    }

    if (opt_obj["checked"]) {
      //추가
      let tmp_check_row_val_arr = [];
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        let tmp_card_row_pri_val=info["a_seq"]+","+tmp_lecture_seq+","+lecture_time_str;
        if (strFunc.str_in_array(tmp_card_row_pri_val, tmp_check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(tmp_card_row_pri_val);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      myProps.set_check_row_val_arr([]);
    }
  };

  const set_info_of_daily_report = (inData: any) => {
    let opt_obj = {
      info: {},
      lecture_info:null,
      lecture_time_str:"",
      ...inData
    };
    let info = opt_obj.info;
    let lecture_info=opt_obj.lecture_info;
    let lecture_time_str=opt_obj.lecture_time_str;
    let lecture_seq="";
    if(lecture_info){
      lecture_seq=lecture_info["a_seq"];
    }
    let tmp_card_row_pri_val=info["a_seq"]+","+lecture_seq+","+lecture_time_str;;

    let tmp_info_arr = myProps.info_arr;
    let change_check_row_val_arr = [...myProps.check_row_val_arr];
    for (let i = 0; i < tmp_info_arr.length; i++) {
      if (tmp_info_arr[i]["a_seq"] == info["a_seq"]) {
        tmp_info_arr[i] = info;
        //auto save
        auto_save_data.current.write_data_arr=get_add_daily_report_atuo_write_data_arr({
          daily_report_arr: info.daily_report_arr
        });

        //auto check
        if (strFunc.str_in_array(tmp_card_row_pri_val, change_check_row_val_arr) == -1) {
          change_check_row_val_arr.push(tmp_card_row_pri_val);
        }
      }
    }
    if(myProps.check_row_val_arr.length!=change_check_row_val_arr.length){
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    }
    save_auto_write_daily_info_by_time_out();
    myProps.set_info_arr(tmp_info_arr);
  };
  const get_add_daily_report_atuo_write_data_arr = (inData: any) => {
    let opt_obj = {
      daily_report_arr: [],
      ...inData
    };
    let change_write_data_arr = auto_save_data.current.write_data_arr;
    let add_data_arr = opt_obj.daily_report_arr;

    for (let i = 0; i < add_data_arr.length; i++) {
      let add_data = add_data_arr[i];
      let is_exist = false;
      for (let j = 0; j < change_write_data_arr.length; j++) {
        let change_data = change_write_data_arr[j];
        if (!strFunc.is_empty(change_data["a_seq"])
          && change_data["a_ymd"] == add_data["a_ymd"]
          && change_data["a_seq"] == add_data["a_seq"]) {
          is_exist = true;
        } else if (change_data["a_lecture_seq"] == add_data["a_lecture_seq"]
          && change_data["a_stu_seq"] == add_data["a_stu_seq"]
          && change_data["a_date"] == add_data["a_date"]
          && change_data["a_lecture_time"] == add_data["a_lecture_time"]) {
          is_exist = true;
        }
      }
      if (is_exist == false) {
        change_write_data_arr.push(add_data);
      }
    }

    return change_write_data_arr;
  };

  const get_cardbox_tags = (inData: any) => {
    let opt_obj = {
      infoArr: [],
      first_td_tag: "",
      lecture_info: null,
      lecture_time_str: "",
      ...inData
    };
    let infoArr = opt_obj["infoArr"];
    let first_td_tag = opt_obj["first_td_tag"];
    let lecture_info = opt_obj["lecture_info"];
    let lecture_time_str = opt_obj["lecture_time_str"];
    
    let cardbox_tags: any = infoArr.map((item: any, idx: number) => {
      let is_focus_row=false;
      if(focused_stu_seq==item["a_seq"]){
        is_focus_row=true;
      }
      return (
        <CardBoxArea
          key={idx}
          info={item}
          set_info={(info: any) => {
            set_info_of_daily_report({ info: info,lecture_info:lecture_info,lecture_time_str:lecture_time_str });
            set_focused_stu_seq(item["a_seq"]);
          }}
          row_num={idx}
          is_print_mode={myProps.is_print_mode}
          is_focus_row={is_focus_row}
          xColumnArr={xColumnArr}
          first_td_tag={first_td_tag}
          lecture_info={lecture_info}
          select_date={myProps.select_date}
          pre_date_str={pre_date_str}
          list={myProps.list}
          Style={Style}
          lecture_time_str={lecture_time_str}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          select_stu_grade_arr={select_stu_grade_arr}
          select_homework_state_arr={select_homework_state_arr}
          save_daily_report_by_stu_arr={myProps.save_daily_report_by_stu_arr}
          on_click_tr_row={(inData: any) => {set_focused_stu_seq(item["a_seq"]);}}
          openAttendPopup={myProps.openAttendPopup}
          deleteAttend={myProps.deleteAttend}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
          open_write_td_memo_popup={myProps.open_write_td_memo_popup}
          openTodoDaySort={myProps.openTodoDaySort}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
          open_find_study_title_popup={myProps.open_find_study_title_popup}
        ></CardBoxArea>
      );
    });

    if (strFunc.is_empty(cardbox_tags)) {
      //cardbox_tags = "";
    }

    return cardbox_tags;
  };

  const get_cardbox_group_tags = () => {
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: myProps.select_date,
      info_arr: myProps.info_arr,
      tab_id: myProps.tab_id,
    });
    DailyGroupDataFunc.set_group_time_data({
      group_data_arr:group_data_arr,
      group_data_arr_ref:group_data_arr_ref,
      able_time_arr_ref:myProps.able_time_arr_ref,
    });

    let cardbox_group_tags = group_data_arr.map((group_info: any, idx: number) => {
      let group_title_str = group_info["title"];
      if (myProps.is_show_etc_group == false) {
        if (group_info["title"] == "기타") {
          return "";
        }
      }
      if(myProps.show_group_time.sort=="now"){
        if(group_info["end_time_str"]){
          let end_time_h=parseInt(strFunc.uncomma(group_info["end_time_str"].substring(0,2)));
          let now_h=parseInt(now_d_json.h);
          if(end_time_h<now_h){
            return "";
          }
        }
      }else if(myProps.show_group_time.sort=="select"){
        if(strFunc.str_in_array(group_info["start_time_str"],myProps.show_group_time.select_time_arr)==-1){
          return "";
        }
      }
      let lecture_info = group_info["lecture_info"];
      if (myProps.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_stu_grade_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      let first_row_span_num = group_info["info_arr"].length;
      let first_td_tag = (
        <td rowSpan={first_row_span_num} title={group_title_str} style={{border:"1px solid #ccc"}}>
          <div style={{ display: "inline-block" }}>
            <label>
              {myProps.is_print_mode==false &&
                <input type="checkbox" className="ml-1" onChange={(e: any) => {
                  on_change_group_checkbox({
                    checked: e.target.checked,
                    info_arr: group_info["info_arr"],
                    lecture_info: lecture_info,
                    lecture_time_str: group_info["time_str"],
                  });
                }} />
              }
              <br />
              {group_title_str.substring(0, 5)}
              <br />
              ~
              <br />
              {group_title_str.substring(6, 11)}
              <br />
              {strFunc.cut_str(group_title_str.substring(11), 5, "")}
            </label>
          </div>
        </td>
      );
      if (first_row_span_num < (4)) {
        first_td_tag = (
          <td rowSpan={first_row_span_num} title={group_title_str} style={{border:"1px solid #ccc"}}>
            <div style={{ display: "inline-block" }}>
              <label>
                {myProps.is_print_mode==false &&
                  <input type="checkbox" className="ml-1" onChange={(e: any) => {
                    on_change_group_checkbox({
                      checked: e.target.checked,
                      info_arr: group_info["info_arr"],
                      lecture_info: lecture_info,
                      lecture_time_str: group_info["time_str"],
                    });
                  }} hidden />
                }
                {group_title_str.substring(0, 5)}
                <br />
                {strFunc.cut_str(group_title_str.substring(11), 5, "")}
              </label>
            </div>
          </td>
        );
      }
      return (
        <tbody key={idx} >
          {get_cardbox_tags({
            infoArr: group_info["info_arr"],
            first_td_tag: first_td_tag,
            lecture_info: lecture_info,
            lecture_time_str: group_info["time_str"],
          })}
        </tbody>
      );
    });

    if (strFunc.is_empty(cardbox_group_tags)) {
      cardbox_group_tags = (
        <tbody>
          <tr>
            <td colSpan={show_column_cnt}>
              <div className="text-center" style={{ lineHeight: "50px" }}>
                내용이 없습니다.
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return cardbox_group_tags;
  };

  let wrap_table_style:any={
    minWidth:"1200px"
  };
  if(myProps.is_print_mode){
    wrap_table_style["padding"]=15;
  }

  return (
    <div className="mt-1" style={wrap_table_style}>
      <div style={{ height: "auto" }}>
        <div className={Style.report_table}>
          <table>
            <colgroup>
              {
                xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
                  if (item["is_show"] != "1") {
                    return "";
                  }
                  return (
                    <col key={idx} width={item["width"]}></col>
                  );
                })
              }
            </colgroup>
            <thead>
              <tr>
                <th colSpan={show_column_cnt}>
                  <div style={{ position: "relative", textAlign: "center" }}>
                    일일보고서
                    <span style={{ position: "absolute", right: 0, fontSize: 12 }}>
                      {myProps.select_date} {cal_data.select_month_week_i+"주차"} ({select_day_str})
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                {
                  xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
                    if (item["is_show"] != "1") {
                      return "";
                    }
                    let th_render_str: any = item["name"];
                    if (item["key"] == "row_view_checkbox") {
                      th_render_str = (
                        <input type="checkbox" onChange={on_check_all} />
                      );
                    }
                    return (
                      <th key={idx} >{th_render_str}</th>
                    );
                  })
                }
              </tr>
            </thead>
            {get_cardbox_group_tags()}
          </table>
        </div>
      </div>
    </div>
  );
};
export default DailyListArea;