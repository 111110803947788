import strFunc from "@/lib/lyg/string";

class DailyGroupDataFunc
{
  static set_group_time_data(inData:any){
    let opt_obj={
      group_data_arr:[],
      group_data_arr_ref:null,
      able_time_arr_ref:null,
      ...inData
    };
    let able_time_arr:any=[];
    let group_data_arr=opt_obj.group_data_arr;
    opt_obj.group_data_arr_ref.current=group_data_arr;
    for(let i=0;i<group_data_arr.length;i++){
      let group_data=group_data_arr[i];
      if(strFunc.str_in_array(group_data["start_time_str"],able_time_arr)==-1){
        able_time_arr.push(group_data["start_time_str"]);
      }
    }

    if(opt_obj.able_time_arr_ref){
      opt_obj.able_time_arr_ref.current=able_time_arr;
    }
  };

};
export default DailyGroupDataFunc;