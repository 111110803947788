import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"dicover_map_stu_write_stu_list",
    x_column_list_arr:[
      {"key":"row_view_time","name":"시간","width":"4","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':200,
      'order_id':'a_stu_grade, a_user_name',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
      "s_user_name_like":""
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  
  return xColumnArr;
};

export default getXcolumnJson;