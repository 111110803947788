import Style from "./css/style.module.css";
function DiscorverListArea(props: any) {
  let myProps = {
    discover_map_arr: [],
    go_write_page: (inData: any) => { },
    select_discover_seq: "",
    set_select_discover_seq: (inData: any) => { },
    ...props
  };
  let discover_map_arr = myProps.discover_map_arr;

  const go_write_page = (inData: any) => {
    let opt_obj = {
      discorver_info: {},
      ...inData
    };
    let discorver_info = opt_obj.discorver_info;
    myProps.go_write_page({
      stu_seq: discorver_info["a_stu_seq"],
      info: discorver_info,
      info_arr: [],
      is_update: true,
      is_view_mode: true,
    });
  };

  const get_discorver_list_tags = (inData: any) => {
    let opt_obj = {
      is_success: false,
      ...inData
    };
    let list_tags = discover_map_arr.map((item: any, idx: number) => {
      let tmp_row_pri_val = item["a_ymd"] + "," + item["a_seq"];
      if (opt_obj.is_success) {
        if (item["a_is_success"] != "1") {
          return "";
        }
      } else {
        if (item["a_is_success"] == "1") {
          return "";
        }
      }

      let item_class_name = Style.list_item;
      if (myProps.select_discover_seq == tmp_row_pri_val) {
        item_class_name += " " + Style.is_select;
      }

      return (
        <div key={idx} className={item_class_name}
          onClick={() => {
            go_write_page({
              discorver_info: item
            });
            myProps.set_select_discover_seq(tmp_row_pri_val);
          }}>
          <span>
            {item["a_stu_name"]}
            <span style={{ color: "gray" }}>
              ({item["a_date"].substring(5,10)})
            </span>
          </span>
        </div>
      );
    });
    return list_tags;
  };

  return (
    <div>
      <div className={Style.box_wrap}>
        <div className={Style.box} style={{border:0,background:"#fff"}}>미완료</div>
        <div className={Style.box} style={{border:0,background:"#fff"}}>처방완료</div>
      </div>
      <div className={Style.box_wrap}>
        <div className={Style.box}>
          {get_discorver_list_tags({ is_success: false })}
        </div>
        <div className={Style.box}>
          {get_discorver_list_tags({ is_success: true })}
        </div>
      </div>
    </div>
  );
};
export default DiscorverListArea;