import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import RoomListWrap from "../../area/room_list_wrap";

function ViewRoomPopup(props: any) {
  const myProps = {
    room_seq: "",
    is_window_popup: true,
    closePopup: () => { },
    callBackRfresh: () => { },
    ...props
  };
  const [room_seq,set_room_seq]=useState<any>(myProps.room_seq);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    set_room_seq(params.get("room_seq"));
  }, []);

  const close_popup=()=>{
    if(myProps.is_window_popup){
      window.close();
    }else{
      myProps.closePopup();
    }
  };

  return (
    <div>
      {room_seq!=""&&
        <RoomListWrap
          s_room_seq={room_seq}
          is_show_search_room={false}
          is_show_paging={false}
          is_detal_popup={false}
          on_refresh_parent={myProps.callBackRfresh}
        ></RoomListWrap>
      }
      <div className="btn-box-center mt-1">
        <button className="btn-m btn-line-gray" onClick={() => { close_popup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default ViewRoomPopup;