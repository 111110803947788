import { useState, useRef, useEffect } from 'react';
import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import DiscoverList from "./discover_list";

function DiscorverMapHistoryListArea(props: any) {
  let myProps = {
    ...props
  };
  const FindStudentListRef = useRef<any>(null);
  const DiscoverListRef = useRef<any>(null);

  return (
    <div>
      <table>
        <colgroup>
          <col width={"20%"}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <div style={{ minWidth: "410px" }}>
                <div className="mt-1">
                  <FindStudentList
                    ref={FindStudentListRef}
                    listOpt={{
                      s_addon_discover_map: "1",
                      s_addon_discover_map_start_date: "",
                      s_addon_discover_map_end_date: "",
                    }}
                    grid_opt={{
                      is_idx_num: false,
                      is_add_checkbox: false,
                      floatingFilter: false,
                      rowMultiSelectWithClick: true,
                      rowSelection: "single",
                    }}
                    show_key_arr={["row_view_discover_map_view"]}
                    list={(inData: any) => {
                      if (DiscoverListRef.current) {
                        let s_stu_user_seq: any = [];
                        if (inData.s_stu_user_seq) {
                          s_stu_user_seq = inData.s_stu_user_seq;
                        }

                        DiscoverListRef.current.list({
                          "now_page": "1",
                          "s_stu_seq": s_stu_user_seq,
                          ...inData
                        });
                      }
                    }}
                    discover_select={(inData: any) => {

                    }}
                  ></FindStudentList>
                </div>
              </div>
            </td>
            <td style={{ verticalAlign: "top" }}>
              <div style={{ minWidth: 400 }}>
                <DiscoverList
                  ref={DiscoverListRef}
                ></DiscoverList>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default DiscorverMapHistoryListArea;