//import CounselTodoDataFunc from "@/pcomponents/common/content/edu/counsel_todo/func/data_func";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

class CounselTodoDataFunc
{
  static get_reserve_date_arr(inData){
    let opt_obj={
      init_date_str:"",
      ...inData
    };
    let init_date_str=opt_obj.init_date_str;
    let reserve_date_arr=[];
    if(strFunc.is_empty(init_date_str)){
      init_date_str=DateFunc.get_date_format(new Date(),"Y-m-d");
    }

    reserve_date_arr.push(init_date_str);
    reserve_date_arr.push(DateFunc.get_date_format(DateFunc.get_change_date(new Date(init_date_str),'day',+7),"Y-m-d"));
    reserve_date_arr.push(DateFunc.get_date_format(DateFunc.get_change_date(new Date(init_date_str),'month',+1),"Y-m-d"));
    reserve_date_arr.push(DateFunc.get_date_format(DateFunc.get_change_date(new Date(init_date_str),'month',+2),"Y-m-d"));
    reserve_date_arr.push(DateFunc.get_date_format(DateFunc.get_change_date(new Date(init_date_str),'month',+3),"Y-m-d"));
    reserve_date_arr.push(DateFunc.get_date_format(DateFunc.get_change_date(new Date(init_date_str),'month',+6),"Y-m-d"));

    return reserve_date_arr;
  };
}
export default CounselTodoDataFunc;