import Style from "./css/style.module.css";
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import CounselListArea from "./area/counsel_list";
import LoadingComponent from "@/pcomponents/common/content/loading";

function ViewCounselTodoOfDay(props: any) {
  let myProps = {
    "select_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "stu_info_arr": [],
    "list_opt":{},
    show_write_diary_page:(inData:any)=>{},
    callback: () => { },
    closePopup: () => { },
    ...props
  };
  const user = useSelector((state:any) => state.user);
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    //"s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_date_type": "a_reserve_date",
    "s_reserve_start_date": myProps.select_date,
    "s_reserve_end_date": myProps.select_date,
    "s_stu_seq": "",
    "s_counseler_seq": "",
    "s_writer_seq": "",
    "s_addon_user": "1",
    "s_addon_file":"1",
    "s_addon_study_diary":"1",
    ...myProps.list_opt
  });
  const [counsel_todo_arr, set_counsel_todo_arr] = useState([]);
  const [check_idx_arr, set_check_idx_arr] = useState<any>([]);
  const [is_show_content_arr,set_is_show_content_arr]=useState<any>([]);
  const [loading_data, set_loading_data] = useState({
    is_display:false,
    text:"저장중..",
  });
  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let select_date_time = new Date(myProps.select_date).getTime();
  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    let tmp_list_form_json = list_form_json;
    if (myProps.stu_info_arr) {
      let tmp_s_stu_seq = [];
      for (let i = 0; i < myProps.stu_info_arr.length; i++) {
        let stu_info = myProps.stu_info_arr[i];
        tmp_s_stu_seq.push(stu_info["a_seq"]);
      }
      tmp_list_form_json["s_stu_seq"] = tmp_s_stu_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', tmp_list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_counsel_todo_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const update_content_by_ajax=(select_arr: any[])=>{
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    let change_data_arr = select_arr;
    if (change_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": change_data_arr,
      "is_update": "1"
    };
    set_loading_data({
      is_display:true,
      text:"저장중..",
    });
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(select_arr.length==1&&select_arr[0].file_obj&&select_arr[0].file_obj.value){
            goWriteFileUpload(select_arr[0]);
          }else{
            set_loading_data({
              is_display:true,
              text:"저장되었습니다.",
            });
            setTimeout(() => {
              set_loading_data({
                is_display:false,
                text:"저장중..",
              });
            }, 1000);
            list({});
            set_check_idx_arr([]);
            myProps.callback();
          }
        } else {

        }
      });
  };

  const goWriteFileUpload=(row_data:any) => {
    let is_file_exist=false;
    if(row_data.file_obj&&row_data.file_obj.value){
      is_file_exist=true;
    }
    if(is_file_exist==false){
      set_loading_data({
        is_display:true,
        text:"저장되었습니다.",
      });
      setTimeout(() => {
        set_loading_data({
          is_display:false,
          text:"저장중..",
        });
      }, 1000);
      list({});
      set_check_idx_arr([]);
      myProps.callback();
    }
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let file_row_data={
      "a_ymd":row_data.a_ymd,
      "a_seq":"",
      "a_comp_seq":user.comp_seq,
      "a_par_id":"counsel_todo",
      "a_par_seq":row_pri_val,
      "a_sort1":"image",
      "a_sort2":"",
      "a_sort3":"",
      "a_writer":user.user_name,
      "a_create_seq":user.user_seq,
    };

    //var form_json_data= new FormData(form);
    var form_json_data = new FormData();
    let file_row_data_arr: any = [];
    for (let i = 0; i < row_data.file_obj.files.length; i++) {
      let file_info = row_data.file_obj.files[i];
      form_json_data.append('file_arr[]', file_info);
      file_row_data_arr.push({ ...file_row_data });
    }
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization",localStorage.intoyou_token);
    
    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_loading_data({
          is_display:true,
          text:"저장되었습니다.",
        });
        setTimeout(() => {
          set_loading_data({
            is_display:false,
            text:"저장중..",
          });
        }, 1000);
        list({});
        set_check_idx_arr([]);
        myProps.callback();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const change_state_by_ajax = (change_state: string, select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let confirm_msg = "완료 처리 하시겠습니까?";
    if (change_state == "complete") {
      confirm_msg = "완료 처리 하시겠습니까?";
    } else {
      confirm_msg = "준비 중으로 바꾸시겠습니까?";
    }
    if (!confirm(confirm_msg)) {
      return false;
    }

    let change_data_arr = [];
    for (let i = 0; i < select_arr.length; i++) {
      let info = select_arr[i];
      let a_is_success = "";
      let a_counsel_date=info["a_counsel_date"];
      if (change_state == "complete") {
        a_is_success = "1";
        if(info["a_is_success"]!="1"){
          a_counsel_date=DateFunc.get_date_format(new Date(),"Y-m-d");
        }
      }
      let change_data_row={
        "a_ymd": info["a_ymd"],
        "a_seq": info["a_seq"],
        "a_is_success": a_is_success,
        "a_state": change_state,
        "a_counsel_date":a_counsel_date
      };
      change_data_arr.push(change_data_row);
    }
    if (change_data_arr.length == 0) {
      alert("변경할 데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": change_data_arr,
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display:true,
            text:"변경되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display:false,
              text:"저장중..",
            });
          }, 1000);
          list({});
          set_check_idx_arr([]);
          myProps.callback();
        } else {

        }
      });
  };

  const go_del_counsel_row = (select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm(select_arr.length + "개를 삭제 하시겠습니까?")) {
      return false;
    }

    let form_json_data = {
      "data_arr": select_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display:true,
            text:"삭제 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display:false,
              text:"저장중..",
            });
          }, 1000);
          list({});
          set_check_idx_arr([]);
          myProps.callback();
        } else {

        }
      });
  };

  const show_write_diary_page=(cousel_seq:string)=>{
    let select_counsel=null;
    let stu_seq_arr:any=[];
    let select_stu_seq="";
    for(let i=0;i<counsel_todo_arr.length;i++){
      let row_info=counsel_todo_arr[i];
      let row_pri_val=row_info["a_ymd"]+","+row_info["a_seq"];
      if(cousel_seq==row_pri_val){
        select_counsel=row_info;
      }
      stu_seq_arr.push(row_info["a_stu_seq"]);
    }
    if(select_counsel!=null){
      select_stu_seq=select_counsel["a_stu_seq"];
    }

    myProps.show_write_diary_page({
      stu_seq_arr:stu_seq_arr,
      select_stu_seq:select_stu_seq,
      select_cousel_seq:cousel_seq,
      select_date:myProps.select_date,
    });
    myProps.closePopup();
  };

  return (
    <div>
      <div className={Style.date_title_div}>
        {myProps.select_date}
        <span style={{ color: "blue", marginLeft: 10 }}>( {counsel_todo_arr.length} 개 )</span>
      </div>
      <CounselListArea
        Style={Style}
        counsel_todo_arr={counsel_todo_arr}
        now_date_time={now_date_time}
        select_date_time={select_date_time}
        check_idx_arr={check_idx_arr}
        update_content_by_ajax={update_content_by_ajax}
        set_counsel_todo_arr={set_counsel_todo_arr}
        list={list}
        set_check_idx_arr={set_check_idx_arr}
        callback={myProps.callback}
        change_state_by_ajax={change_state_by_ajax}
        is_show_content_arr={is_show_content_arr}
        set_is_show_content_arr={set_is_show_content_arr}
        go_del_counsel_row={go_del_counsel_row}
        show_write_diary_page={show_write_diary_page}
      ></CounselListArea>
      <div className="btn-box-center mt-3">
        <button className="btn-m btn-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
}
export default ViewCounselTodoOfDay;