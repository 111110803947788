//import KatalkMsgData from "@/pcomponents/common/content/basic/katalk_msg/data";

class KatalkMsgData 
{
  static default_corp_opt = {
    a_corp_seq: "",
    a_attend_in_msg: "열심히 지도하겠습니다.",
    a_attend_out_msg: "안전하게 귀가 시키겠습니다.",
    a_attend_late_msg: "지도 부탁 드리겠습니다. ",
    a_attend_absent_msg: " ",
    a_homework_msg: "열심히 지도하겠습니다.",
  };
}
export default KatalkMsgData;