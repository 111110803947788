import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import LygCalendarFunc from '@/lib/lyg/calendar';
import DailyDataFunc from "@/pages/comp/edu/daily_report/card_list/func/daily_data_func";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";

class GetDailyReportData
{
  static get_data(inData: any){
    let opt_obj={
      list_tab:"",
      tab_id:"",
      info_arr:[],
      group_data_arr_ref:null,
      select_date:"",
      is_print_mode:true,
      is_show_etc_group:true,
      show_group_time:{},
      able_time_arr_ref:[],
      xColumnArr:{},
      group_data_arr:[],
      ...inData
    };
    let info_arr=opt_obj.info_arr;
    let select_date=opt_obj.select_date;
    let is_print_mode=opt_obj.is_print_mode;
    let is_show_etc_group=opt_obj.is_show_etc_group;
    let able_time_arr_ref=opt_obj.able_time_arr_ref;
    let xColumnArr=opt_obj.xColumnArr;
    let group_data_arr=opt_obj.group_data_arr;

    let now_d_json = DateFunc.get_date_json(new Date());
    let select_date_json = DateFunc.get_date_json(new Date(select_date));
    let select_day_str = DateFunc.get_day_str_by_day_num(select_date_json.day);
    let cal_data=LygCalendarFunc.get_cal_data_by_select_date(select_date);
    let pre_date_change_num = -1;
    if (select_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date), 'day', pre_date_change_num), "Y-m-d");

    let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
    let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });

    let wscols:any=[];//{ wpx: 100 },
    let wsrows:any=[];//{ hpx: 20 }
    let header_row_data:any=[];//["시간"];
    let merge_cell_arr:any=[];//{x:0,y:0,col_span:1,row_span:1}
    let data:any=[];//[row,row2]
    let this_obj=this;
    let tmp_except_col_arr:any=[];
    if(is_print_mode){
      tmp_except_col_arr=["row_view_checkbox","row_view_manage"];
    }
    let row_i=0;
    let show_x_column_arr=[];
    for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
      let x_column=xColumnArr.x_column_list_arr[i];
      if(x_column["is_show"]!="1"){continue;}
      if(strFunc.str_in_array(x_column["key"],tmp_except_col_arr)!=-1){continue;}
      let width_num=x_column["width"].replace("%","")*30;
      if(x_column["key"]=="row_view_time"){
        width_num=200;
      }
      if(x_column["key"]=="row_view_stu_name"){
        width_num=100;
      }
      
      wscols.push({ wpx: width_num });
      header_row_data.push(x_column["name"]);
      show_x_column_arr.push(x_column);
    }
    data.push(header_row_data);
    row_i++;
    
    for(let group_i=0;group_i<group_data_arr.length;group_i++){
      let group_info=group_data_arr[group_i];
      if (is_show_etc_group == false) {
        if (group_info["title"] == "기타") {
          continue;
        }
      }
      if(opt_obj.show_group_time.sort=="now"){
        if(group_info["end_time_str"]){
          let end_time_h=parseInt(strFunc.uncomma(group_info["end_time_str"].substring(0,2)));
          let now_h=parseInt(now_d_json.h);
          if(end_time_h<now_h){
            continue;
          }
        }
      }else if(opt_obj.show_group_time.sort=="select"){
        if(strFunc.str_in_array(group_info["start_time_str"],opt_obj.show_group_time.select_time_arr)==-1){
          continue;
        }
      }
      let group_title_str = group_info["title"];
      
      let lecture_info = group_info["lecture_info"];
      if (opt_obj.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_stu_grade_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      let first_row_span_num = group_info["info_arr"].length;
      merge_cell_arr.push({
        x:0,
        y:row_i,
        col_span:1,
        row_span:first_row_span_num,
      });

      if(group_info["info_arr"]){
        for(let info_i=0;info_i<group_info["info_arr"].length;info_i++){
          let info=group_info["info_arr"][info_i];
          let row_data:any=this_obj.get_row_data({
            info:info,
            lecture_info:lecture_info,
            row_i:row_i,
            show_x_column_arr:show_x_column_arr,
            group_title_str:group_title_str,
            select_stu_grade_arr:select_stu_grade_arr,
            select_homework_state_arr:select_homework_state_arr,
            select_date:select_date,
            pre_date_str:pre_date_str,
            lecture_time_str:group_title_str.substring(0, 11),
          });
          row_i++;
          data.push(row_data);
        }
      }

    }

    let result_data={
      wscols:wscols,
      wsrows:wsrows,
      header_row_data:header_row_data,
      merge_cell_arr:merge_cell_arr,
      data:data
    };
    return result_data;
  };

  static get_row_data(inData: any){
    let opt_obj={
      info:{},
      lecture_info:null,
      row_i:0,
      show_x_column_arr:[],
      group_title_str:"",
      select_stu_grade_arr:[],
      select_homework_state_arr:[],
      select_date:"",
      pre_date_str:"",
      lecture_time_str:"",
      ...inData
    };
    let row_data:any=[];
    let info=opt_obj.info;
    let lecture_info=opt_obj.lecture_info;
    let row_i=opt_obj.row_i;
    let show_x_column_arr=opt_obj.show_x_column_arr;
    let group_title_str=opt_obj.group_title_str;
    let select_stu_grade_arr=opt_obj.select_stu_grade_arr;
    let select_homework_state_arr=opt_obj.select_homework_state_arr;
    let last_daily_info = {
      a_stu_seq: "",
      a_writer_seq: "",
      a_lecture_seq: "",
      a_textbook: "",
      a_subtextbook: "",
      a_homework: "",
    };
    if (info.last_daily_report_arr && info.last_daily_report_arr.length > 0 && lecture_info != null) {
      for (let i = 0; i < info.last_daily_report_arr.length; i++) {
        let row_daily_info = info.last_daily_report_arr[i];
        if (row_daily_info["a_stu_seq"] == info["a_seq"]
          //&& row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
          && row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]
          && strFunc.is_empty(last_daily_info["a_stu_seq"])) {
          last_daily_info = row_daily_info;
        }
      }
    }
    let daily_info = DailyDataFunc.get_daily_info_of_date({
      date_str: opt_obj.select_date,
      pre_date_str: opt_obj.pre_date_str,
      last_daily_info: last_daily_info,
      lecture_time_str: opt_obj.lecture_time_str,
      info: info,
      lecture_info: lecture_info,
    });
    let this_obj=this;

    for(let col_i=0;col_i<show_x_column_arr.length;col_i++){
      let x_column=show_x_column_arr[col_i];
      let col_val:any=this_obj.get_value_by_key({
        key:x_column["key"],
        info:info,
        lecture_info:lecture_info,
        row_i:row_i,
        group_title_str:group_title_str,
        select_stu_grade_arr:select_stu_grade_arr,
        select_homework_state_arr:select_homework_state_arr,
        last_daily_info:last_daily_info,
        daily_info:daily_info,
        select_date:opt_obj.select_date,
        pre_date_str:opt_obj.pre_date_str,
      });
      row_data.push(col_val);
    }

    return row_data;
  };

  static get_value_by_key(inData: any){
    let opt_obj={
      key:"",
      info:{},
      lecture_info:null,
      row_i:0,
      group_title_str:"",
      select_stu_grade_arr:[],
      select_homework_state_arr:[],
      last_daily_info:null,
      daily_info:null,
      select_date:"",
      pre_date_str:"",
      ...inData
    };
    let key=opt_obj.key;
    let info=opt_obj.info;
    let lecture_info=opt_obj.lecture_info;
    let row_i=opt_obj.row_i;
    let group_title_str=opt_obj.group_title_str;
    let select_stu_grade_arr=opt_obj.select_stu_grade_arr;
    let select_homework_state_arr=opt_obj.select_homework_state_arr;
    let last_daily_info=opt_obj.last_daily_info;
    let daily_info=opt_obj.daily_info;
    let col_val:any="";
    let this_obj=this;

    if(key=="row_view_time"){
      col_val=group_title_str;
    }else if(key=="row_view_stu_name"){
      col_val=info["a_user_name"];
      let stu_grade_str = "";
      for (let i = 0; i < select_stu_grade_arr.length; i++) {
        if (info["a_stu_grade"] == select_stu_grade_arr[i]["value"]) {
          stu_grade_str = select_stu_grade_arr[i]["text"];
        }
      }
      col_val=col_val+" "+stu_grade_str;
    }else if(key=="row_view_month_prescribe"){
      col_val=this_obj.get_month_prescribe_value({
        info:info,
        lecture_info:lecture_info,
      });
    }else if(key=="row_view_attend"){
      col_val=this_obj.get_attend_value({
        info:info,
        lecture_info:lecture_info,
      });
    }else if(key=="row_view_todo_study"){
      let daily_todo_data=this_obj.get_daily_todo_data({
        info:info,
        sort:"study",
      });
      if(daily_todo_data.todo_count>0){
        col_val=daily_todo_data.per_average+"%";
      }
      let todo_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
        info: info,
        date_sort: "now",//pre,now
        select_date: opt_obj.select_date,
        pre_date_str: opt_obj.pre_date_str,
        file_sort: "study"
      });
      if(todo_file_cnt){
        col_val=col_val+"("+todo_file_cnt+")";
      }
      let state_val=this_obj.get_homework_state_value({
        daily_info:daily_info,
        select_homework_state_arr:select_homework_state_arr,
        daily_key_name:"a_study_state",
      });
      col_val=col_val+" "+state_val;
    }else if(key=="row_view_todo_homework"){
      let daily_todo_data=this_obj.get_daily_todo_data({
        info:info,
        sort:"pre_homework",
      });
      if(daily_todo_data.todo_count>0){
        col_val=daily_todo_data.per_average+"%";
      }
      let todo_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
        info: info,
        date_sort: "pre",//pre,now
        select_date: opt_obj.select_date,
        pre_date_str: opt_obj.pre_date_str,
        file_sort: "homework"
      });
      if(todo_file_cnt){
        col_val=col_val+"("+todo_file_cnt+")";
      }
      let state_val=this_obj.get_homework_state_value({
        daily_info:daily_info,
        select_homework_state_arr:select_homework_state_arr,
        daily_key_name:"a_homework_state",
      });
      col_val=col_val+" "+state_val;
    }else if(key=="row_view_homework"){
      if(daily_info){
        col_val=daily_info["a_homework"];
      }
    }else if(key=="row_view_textbook"){
      if(daily_info){
        col_val=daily_info["a_textbook"];
      }
    }else if(key=="row_view_textbook_study"){
      if(daily_info){
        col_val=daily_info["a_textbook_study_num"]+". "+daily_info["a_textbook_study"];
      }
    }else if(key=="row_view_textbook_study_det"){
      if(daily_info){
        col_val=daily_info["a_textbook_study_det_num"]+". "+daily_info["a_textbook_study_det"];
      }
    }else if(key=="row_view_subtextbook"){
      if(daily_info){
        col_val=daily_info["a_subtextbook"];
      }
    }else if(key=="a_study_amount_memo"){
      if(daily_info){
        col_val=daily_info["a_study_amount_memo"];
      }
    }else if(key=="row_view_memo"){
      if(daily_info){
        col_val=daily_info["a_memo"];
      }
    }
    return col_val;
  };
  
  static get_month_prescribe_value(inData: any){
    let opt_obj={
      info:{},
      lecture_info:null,
      ...inData
    };

    let value_str:any="";
    let info=opt_obj.info;
    let lecture_info=opt_obj.lecture_info;
    let month_prescribe_arr = [];
    if (info.month_prescribe_arr) {
      month_prescribe_arr = info.month_prescribe_arr;
    }

    let custom_prescribe_data: any = {
      "math": {
        arr: [],
        cnt: 0,
        success: 0,
      },
      "en": {
        arr: [],
        cnt: 0,
        success: 0,
      },
    };

    for (let i = 0; i < month_prescribe_arr.length; i++) {
      if (month_prescribe_arr[i]["a_subject_name"] == "수학") {
        custom_prescribe_data["math"]["arr"].push(month_prescribe_arr[i]);
        custom_prescribe_data["math"]["cnt"]++;
        if (month_prescribe_arr[i]["a_is_success"] == "1") {
          custom_prescribe_data["math"]["success"]++;
        }
      } else if (month_prescribe_arr[i]["a_subject_name"] == "영어") {
        custom_prescribe_data["en"]["arr"].push(month_prescribe_arr[i]);
        custom_prescribe_data["en"]["cnt"]++;
        if (month_prescribe_arr[i]["a_is_success"] == "1") {
          custom_prescribe_data["en"]["success"]++;
        }
      }
    }

    if (!strFunc.is_empty(lecture_info)) {
      if(lecture_info["a_subject"]=="수학"){
        if(custom_prescribe_data["math"]["cnt"]>0){
          value_str = custom_prescribe_data["math"]["success"] + "/" + custom_prescribe_data["math"]["cnt"];
        }
      }else if(lecture_info["a_subject"]=="영어"){
        if(custom_prescribe_data["en"]["cnt"]>0){
          value_str = custom_prescribe_data["en"]["success"] + "/" + custom_prescribe_data["en"]["cnt"];
        }
      }
    }

    return value_str;
  };

  static get_attend_value(inData: any){
    let opt_obj={
      info:{},
      lecture_info:null,
      ...inData
    };
    let value_str:any="";
    let info=opt_obj.info;
    let lecture_info=opt_obj.lecture_info;

    let attend_color_arr=[
      {"value":"attend","text":"등원",color:"#eab358"},
      {"value":"late","text":"등원(지각)",color:"#ea880b"},
      {"value":"absent","text":"결석",color:"#f26b6f"},
      {"value":"out","text":"하원",color:"#53a500"},
    ];

    let attend_in_info:any=null;
    if(info.attend_in_arr&&info.attend_in_arr.length>0){
      attend_in_info=info.attend_in_arr[0];
    }
    let attend_out_info:any=null;
    if(info.attend_out_arr&&info.attend_out_arr.length>0){
      attend_out_info=info.attend_out_arr[0];
    }
    let attend_state_str="미등원";
    let attend_time="";
    let attend_memo="";
    if(attend_in_info!=null){
      attend_memo=attend_in_info["a_memo"];
      attend_time=attend_in_info["a_time"].substring(0,5);
      let a_sort_detail=attend_in_info["a_sort_detail"];
      if(attend_out_info!=null){
        a_sort_detail=attend_out_info["a_sort_detail"];
      }
      for(let i=0;i<attend_color_arr.length;i++){
        let item=attend_color_arr[i];
        if(item.value==a_sort_detail){
          attend_state_str=item.text;
        }
      }
      if(attend_state_str=="등원"){
        attend_state_str="등원완료";
        attend_state_str=attend_time;
      }
    }
    value_str=attend_state_str;

    return value_str;
  };

  static get_homework_state_value(inData: any){
    let opt_obj={
      daily_info:{},
      select_homework_state_arr:[],
      daily_key_name:"a_homework_state",//a_study_state
      ...inData
    };
    let daily_info=opt_obj.daily_info;
    let select_homework_state_arr=opt_obj.select_homework_state_arr;
    let daily_key_name=opt_obj.daily_key_name;
    let value_str:any="";
    for(let i=0;i<select_homework_state_arr.length;i++){
      let item=select_homework_state_arr[i];
      if(daily_info[daily_key_name]&&daily_info[daily_key_name]==item.value){
        value_str=item.text;
      }
    }

    return value_str;
  };
  static get_daily_todo_data(inData: any){
    let opt_obj={
      info:{},
      sort:"study",//pre_homework
      ...inData
    };
    let info=opt_obj.info;
    let sort=opt_obj.sort;
    let per_average = 0;
    let todo_count = 0;

    if(sort=="study"){
      if (info.td_sort_data_json && info.td_sort_data_json["study"]) {
        per_average = info.td_sort_data_json["study"]["percent"];
        todo_count = info.td_sort_data_json["study"]["count"];
      }
    }
    if(sort=="pre_homework"){
      if (info.td_sort_data_json && info.td_sort_data_json["pre_homework"]) {
        per_average = info.td_sort_data_json["pre_homework"]["percent"];
        todo_count = info.td_sort_data_json["pre_homework"]["count"];
      }
    }

    let daily_todo_data={
      per_average:per_average,
      todo_count:todo_count,
    };

    return daily_todo_data;
  };
}
export default GetDailyReportData;