function SearchArea(props: any) {
  let myProps = {
    list: (inOptObj: any) => {},
    listOpt:{},
    setListOpt: (inOptObj: any) => {},
    isShowRight: true,
    setIsShowRight: (inIsShowRight: boolean) => {},
    goWriteFrame: (inIsUpdate: boolean) => {},
    goDelete: (inData: any) => {},
    ...props
  };
  let listOpt=myProps.listOpt;
  let isShowRight = myProps.isShowRight;

  const on_change_search_input=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;

    let search_list_opt={
      [name]:value
    };
    myProps.list(search_list_opt);
  };
  
  return (
    <div className="btn-box-left relative h-8" style={{ lineHeight: "22px" }}>
      <input type="text" className="search-input" name="s_name_like" value={listOpt.s_name_like} 
      onChange={on_change_search_input} style={{width:"80px",lineHeight:"22px"}}
      autoComplete="off" placeholder="이름" />
      사용여부:
      <select className="search-input_select"
        value={listOpt.s_is_use}
        onChange={(e: any) => { myProps.list({ "now_page": "1", "s_is_use": e.target.value }) }} >
        <option value="" >전체</option>
        <option value="1" >사용</option>
        <option value="empty" >미사용</option>
      </select>
      <button className="btn btn-dark ml-2" onClick={() => { myProps.goWriteFrame(false); }}>신규등록</button>
      <button className="btn btn-dark" onClick={myProps.goDelete}>삭제</button>
      <button className="btn btn-dark"
        onClick={() => { myProps.setIsShowRight(!isShowRight); }}
      >
        {isShowRight ? "-" : "+"}
      </button>
    </div>
  );
};
export default SearchArea;