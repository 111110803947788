import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SelectAddStudyTitleArea from "./area/select_area";
import SearchArea from "./area/search_area";
import strFunc from '@/lib/lyg/string';

function AddStudyTitleByVideoPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    listOpt: {},
    is_no_show_column_arr: [],
    callBackData: (data: any) => {
      let optObj = {

      };
    },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
    let tmp_xcolumn = xColumnArr.x_column_list_arr[i];
    if (strFunc.str_in_array(tmp_xcolumn["key"], myProps.is_no_show_column_arr) != -1) {
      xColumnArr.x_column_list_arr[i]["is_show"] = "";
    }
  }
  const listAggridRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_category_seq":"",
    "s_title_like": "",
    //"s_addon_title_img": "1",
    "s_addon_category": "1",
    "s_addon_section": "1",
    "s_addon_video": "1",
    //"s_addon_use_stu": "1",
    "s_addon_request": "1",
    ...myProps.listOpt
  });
  const [default_study_title, set_default_study_title] = useState<any>({
    "s_subject_sort": "",
    "s_subject_main_seq": "",
    "s_subject_seq": "",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  useEffect(() => {

  }, []);
  const list = (inOptObj: any) => {
    let change_list_opt: any = {
      ...listOpt,
      ...inOptObj
    };
    if (listAggridRef.current) {
      change_list_opt["sc"] = listAggridRef.current.getListSc();
    }
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/corse/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_select_click = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    let video_corse_seq_arr: any = [];
    for (let i = 0; i < selected_row.length; i++) {
      if (!strFunc.is_empty(selected_row[i]["a_seq"])) {
        video_corse_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }

    if(video_corse_seq_arr.length==0){
      alert("선택이 없습니다.2");
      return false;
    }

    if(!confirm("선택한 비디오강의를  가져오시겠습니까?")){
      return false;
    }

    let tmp_default_study_title:any ={};
    for(let key in default_study_title){
      let tmp_key = key.replace("s_","a_");
      tmp_default_study_title[tmp_key] = default_study_title[key];
    }
    let form_json_data: any = {
      video_corse_seq_arr: video_corse_seq_arr,
      "default_study_title": {
        a_subject_sort: "",
        a_subject_main_seq: "",
        a_subject_seq: "",
        a_open_group: "1",
        ...tmp_default_study_title
      },
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/add_from_video', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("등록되었습니다.");
          myProps.callBackData({});
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_section") {
      let section_num = 0;
      let video_num = 0;
      if (params.data.section_arr) {
        section_num = params.data.section_arr.length;
      }
      if (params.data.video_arr) {
        video_num = params.data.video_arr.length;
      }
      render_str = section_num + "/" + video_num;
    } else if (key == "row_view_stu_cnt") {
      if (params.data.use_stu_arr) {
        render_str = params.data.use_stu_arr.length;
      }
    } else if (key == "row_view_request") {
      if (params.data.request_arr) {
        render_str = params.data.request_arr.length;
      }
    }

    return render_str;
  }, []);
  return (
    <div>
      <SelectAddStudyTitleArea
        on_select_click={on_select_click}
        default_study_title={default_study_title}
        set_default_study_title={set_default_study_title}
        closePopup={myProps.closePopup}
      ></SelectAddStudyTitleArea>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: true,
          floatingFilter: true,
          rowMultiSelectWithClick: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        is_view_num_per_page={true}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
};
export default AddStudyTitleByVideoPopup;