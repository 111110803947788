import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState,useRef,useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import Style from "../css/style.module.css";
import { Link,useNavigate } from 'react-router-dom';

const renderLegend = (props:any) => {
  const { payload } = props;

  return (
    <div className={Style.stu_legend_wrap} >
      {
        payload.map((entry:any, index:number) => {
          let text_name=entry.value;
          return (
            <span key={`item-${index}`} className={Style.stu_legend_item} >
              <span className="mr-1" style={{color:entry.color}}>■</span>
              {text_name}
            </span>
          );
        })
      }
    </div>
  );
}

const StuChartArea=()=>{
  const navigate = useNavigate();
  const now_date_obj=useRef(DateFunc.get_date_json(new Date()));
  const pre1_date_obj=useRef(DateFunc.get_date_json(DateFunc.get_change_date(new Date(),'month',-1)));
  const pre2_date_obj=useRef(DateFunc.get_date_json(DateFunc.get_change_date(new Date(),'month',-2)));
  const [is_load,set_is_load]=useState(false);
  const [new_cnt,set_new_cnt]=useState(0);
  const [chart_data,set_chart_data]=useState([
    {
      name:pre2_date_obj.current.m+"월",
      "재원생":0,
      "신규원생":0,
      "퇴원/휴원생":0,
    },
    {
      name:pre1_date_obj.current.m+"월",
      "재원생":0,
      "신규원생":0,
      "퇴원/휴원생":0,
    },
    {
      name:now_date_obj.current.m+"월",
      "재원생":0,
      "신규원생":0,
      "퇴원/휴원생":0,
    },
  ]);

  useEffect(()=>{
    
    get_chart_data();
  },[]);

  const get_chart_data=()=>{
    let json_form_data={
      "s_date_type":"a_create_date",
      "s_start_date":pre2_date_obj.current.Y+"-"+DateFunc.get_digit_str(pre2_date_obj.current.m)+"-01",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/stat/stat_stu/list',json_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        let stat_stu_arr=response.data["data"]["info_arr"];
        let stat_stu_arr_len=stat_stu_arr.length;
        let tmp_chart_data=chart_data;
        for(let i=0;i<stat_stu_arr_len;i++){
          let stat_stu_info=stat_stu_arr[i];
          let tmp_ymd=stat_stu_info.a_ymd+"";
          let tmp_month=parseInt(tmp_ymd.substring(4,6));
          
          for(let j=0;j<tmp_chart_data.length;j++){
            let chart_m=parseInt(tmp_chart_data[j]["name"].replace("월",""));
            if(tmp_month==chart_m){
              tmp_chart_data[j]["재원생"]=stat_stu_info["a_now"];
              tmp_chart_data[j]["신규원생"]=stat_stu_info["a_new"];
              stat_stu_info["a_rest"]=parseInt(strFunc.uncomma(stat_stu_info["a_rest"]));
              stat_stu_info["a_end"]=parseInt(strFunc.uncomma(stat_stu_info["a_end"]));
              tmp_chart_data[j]["퇴원/휴원생"]=stat_stu_info["a_rest"]+stat_stu_info["a_end"];

              if(j==tmp_chart_data.length-1){
                if(stat_stu_info["a_new"]){
                  set_new_cnt(stat_stu_info["a_new"]);
                }
              }
            }
          }
        }
        set_chart_data(tmp_chart_data);
      }else{
        
      }
      set_is_load(true);
    });
  };

  return (
    <div style={{height:350,width:"100%"}}>
      <div className={Style.box_title_wrap}>
        원생현황
        {new_cnt!=0&&
        <span className={Style.box_title_cnt_span}>
          +{new_cnt}
        </span>
        }
        <a className={Style.box_title_right_link} onClick={()=>{navigate("/comp/basic/student/list");}}>
          바로가기{">"}
        </a>
      </div>
      {is_load&&
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={400}
          height={300}
          data={chart_data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis unit="명" />
          <Tooltip />
          <Legend layout="horizontal" align="center" verticalAlign="top" margin={{left:10}} content={renderLegend} />
          <Bar dataKey="재원생" fill="#7470F9" />
          <Bar dataKey="신규원생" fill="#d9e8ef" />
          <Bar dataKey="퇴원/휴원생" fill="#505050" />
        </BarChart>
      </ResponsiveContainer>
      }
    </div>
  );
};
export default StuChartArea;