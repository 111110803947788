import getXcolumnJson from "../../list/xcolumn/list";
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import ParViewArea from "@/pages/comp/plan/stu_todo_history/popup/write/area/par_view";

function ViewPopupContent(props: any) {
  //props.rowData.a_user_name;
  const xColumnArr = getXcolumnJson();
  let rowData = props.rowData;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>{props.rowData.a_stu_name}</td>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
            <tr>
              <th>과목</th>
              <td>{props.rowData.a_subject}</td>
              <th>구분</th>
              <td>
                <select className="row-input border" name="a_sort" value={props.rowData.a_sort} onChange={() => { }}>
                  <option value="">선택없음</option>
                  {
                    xColumnArr.select_arr.a_sort.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.value}>{item.text}</option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>수행</th>
              <td>
                {props.rowData.a_amount_num}/{props.rowData.a_success_amount}
              </td>
              <th>상태</th>
              <td>
                <select className="row-input border" name="a_state" value={props.rowData.a_state} onChange={() => { }}>
                  <option value="">선택없음</option>
                  {
                    xColumnArr.select_arr.a_state.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.value}>{item.text}</option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>{props.rowData.a_title}</td>
            </tr>
            <tr>
              <th colSpan={4} className="text-center">내용</th>
            </tr>
            <tr>
              <td colSpan={4}>
                {
                  props.rowData.a_content && props.rowData.a_content.split('\n').map((line: string, idx: number) => {
                    return (<span key={idx}>{line}<br /></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>성공여부</th>
              <td>{props.rowData.a_is_success}</td>
              <th>작성자</th>
              <td>{props.rowData.a_writer_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ParViewArea
        rowData={rowData}
      ></ParViewArea>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={() => {
          props.closePopup();
          props.openOnePopup("write", true);
        }}>수정</button>
        <button className="btn btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
      <FileListWriteFrame
        isView={true}
        listOpt={
          {
            s_par_id: xColumnArr.table,
            s_par_seq: xColumnArr.func.getPriValByDataRow({
              xColumnArr: xColumnArr,
              rowData: props.rowData
            }),
            s_start_date: props.rowData.a_create_date
          }
        }
      ></FileListWriteFrame>
    </div>
  );
}

export default ViewPopupContent;
