import Style from "../../css/style.module.css";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import RoomBoxArea from "./area/room_box";

const RoomListArea = forwardRef((props: any, ref) => {
  const myProps: any = {
    room_info_arr: [],
    now_ymdhis: "",
    pre_date_str: "",
    open_find_stu_popup:(inData:any)=>{},
    open_view_desk_popup:(inData:any)=>{},
    open_select_stu_alloc_desk_popup:(inData:any)=>{},
    is_detal_popup:true,
    is_show_only_now_lecture:true,
    is_show_msg:true,
    is_show_stu_select_list:true,
    is_stu_filter_lecture:true,
    is_stu_filter_use_desk:true,
    select_stu_filter:{},
    set_select_stu_filter:(inData:any)=>{},
    select_stu_data: {},
    set_select_stu_data: (inData: any) => { },
    room_msg_arr:[],
    student_info_arr:[],
    ...props
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언

  }));

  let room_box_tags = myProps.room_info_arr.map((room_info: any, idx: number) => {
    return (
      <RoomBoxArea key={idx}
        room_info={room_info}
        now_ymdhis={myProps.now_ymdhis}
        pre_date_str={myProps.pre_date_str}
        open_find_stu_popup={myProps.open_find_stu_popup}
        open_view_desk_popup={myProps.open_view_desk_popup}
        open_select_stu_alloc_desk_popup={myProps.open_select_stu_alloc_desk_popup}
        is_show_only_now_lecture={myProps.is_show_only_now_lecture}
        is_detal_popup={myProps.is_detal_popup}
        is_show_msg={myProps.is_show_msg}
        is_show_stu_select_list={myProps.is_show_stu_select_list}
        is_stu_filter_lecture={myProps.is_stu_filter_lecture}
        is_stu_filter_use_desk={myProps.is_stu_filter_use_desk}
        select_stu_filter={myProps.select_stu_filter}
        set_select_stu_filter={myProps.set_select_stu_filter}
        select_stu_data={myProps.select_stu_data}
        set_select_stu_data={myProps.set_select_stu_data}
        room_msg_arr={myProps.room_msg_arr}
        student_info_arr={myProps.student_info_arr}
      ></RoomBoxArea>
    );
  });

  return (
    <div>
      <div className={Style.room_wrap} >
        {room_box_tags}
      </div>
    </div>
  );
});

export default RoomListArea;