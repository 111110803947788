import ViewArea from "./area/view_area";

function ViewNoticePopup(props:any){
   let myProps={
    notice_arr:[],
    notice_idx:0,
    is_notice_popup:true,
    closePopup:()=>{},
    ...props
  };
  
  return (
  <div>
    <ViewArea
      notice_arr={myProps.notice_arr}
      notice_idx={myProps.notice_idx}
      is_notice_popup={myProps.is_notice_popup}
      closePopup={myProps.closePopup}
    ></ViewArea>
  </div>
  );
};
export default ViewNoticePopup;