function SearchArea(props:any){
   let myProps={
    listOpt:{},
    setListOpt:(inData:any)=>{},
    list:(inData:any)=>{},
    ...props
  };
  let listOpt=myProps.listOpt;
  const on_change_search_input=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;
    let change_list_opt={
      ...listOpt
    };
    change_list_opt[name]=value;
    myProps.setListOpt(change_list_opt);
  };
  
  return (
  <div className="text-center">
    <input type="text" className="search-input" name="s_title_like" value={listOpt.s_title_like}
      style={{width:100}} onChange={on_change_search_input} placeholder="제목"
      autoComplete="off" 
      onKeyUp={(e:any)=>{
        if(e.keyCode==13){
          myProps.list({now_page:1});
        }
      }} />
    <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({now_page:1});}}>검색</button>
  </div>
  );
};
export default SearchArea;