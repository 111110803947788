import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import { useRef, useEffect } from 'react';
function WriteArea(props: any) {
  let myProps = {
    prop_daily_report: {},
    daily_report: {},
    set_daily_report: (inData: any) => { },
    td_sort: "a_memo",
    ...props
  };

  const text_area_ref = useRef<any>(null);
  useEffect(() => {
    if (text_area_ref.current) {
      let row_input_arr = text_area_ref.current.getElementsByClassName("row-input");
      if (row_input_arr) {
        row_input_arr[0].focus();
        if(row_input_arr[0].setSelectionRange){
          let row_val=row_input_arr[0].value;
          row_input_arr[0].setSelectionRange(row_val.length,row_val.length);
        }
      }
    }
  }, []);

  const on_change_input = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData,
    };
    let name = opt_obj.name;
    let value = opt_obj.value;
    let change_row_data: any = { ...myProps.daily_report };
    change_row_data[name] = value;
    myProps.set_daily_report(change_row_data);
  };

  return (
    <div>
      <div ref={text_area_ref}>
        <TextAreaComponent
          name={myProps.td_sort}
          value={myProps.daily_report[myProps.td_sort]}
          onChange={(e: any) => {
            on_change_input({
              name: e.target.name,
              value: e.target.value
            });
          }}
          style={{ minHeight: 80, border: "1px solid #ccc" }}
        ></TextAreaComponent>
      </div>
    </div>
  );
};
export default WriteArea;