import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import KatalkMsgData from "@/pcomponents/common/content/basic/katalk_msg/data";
import TempleteListArea from "./area/templete_list";
import WriteArea from "./area/write";

function MsgOptPage() {
  let user = useSelector((state: any) => state.user);
  useEffect(() => {
    get_corp_opt_by_ajax();
  }, [user.corp_seq]);
  useEffect(() => {
    get_katalk_templete_by_ajax();
  }, [user.comp_seq]);

  let default_corp_opt = {
    ...KatalkMsgData.default_corp_opt,
    a_corp_seq: user.corp_seq,
    // a_attend_in_msg: "열심히 지도하겠습니다.",
    // a_attend_out_msg: "안전하게 귀가 시키겠습니다.",
    // a_attend_late_msg: "지도 부탁 드리겠습니다. ",
    // a_attend_absent_msg: " ",
    // a_homework_msg: "열심히 지도하겠습니다.",
  };
  const [corp_opt, set_corp_opt] = useState(default_corp_opt);
  const [katalk_templete_arr, set_katalk_templete_arr] = useState([]);

  const get_corp_opt_by_ajax = () => {
    if (strFunc.is_empty(user.corp_seq)) {
      return false;
    }
    let form_json_data = {
      "s_pri_arr": [user.corp_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp_opt/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_corp_opt(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const get_katalk_templete_by_ajax = () => {
    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    let form_json_data = {
      "s_sender_mcomp_seq": [user.comp_seq],
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/mkatalk_templete/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_katalk_templete_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const save_corp_opt_by_ajax = () => {
    if (strFunc.is_empty(corp_opt.a_corp_seq)) {
      return false;
    }
    if (confirm("저장 하시겠습니까?") == false) {
      return false;
    }

    let form_json_data = {
      "data_arr": [corp_opt],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp_opt/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장되었습니다.");
          get_corp_opt_by_ajax();
        } else {
          alert("저장에 실패하였습니다."+response.data["msg"]);
        }
      });
  }

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="mt-1">
          <TempleteListArea
            katalk_templete_arr={katalk_templete_arr}
          ></TempleteListArea>
        </div>
        <div className="mt-2">
          <div>
            hello_msg 설정
          </div>
          <WriteArea
            corp_opt={corp_opt}
            set_corp_opt={set_corp_opt}
          ></WriteArea>
        </div>
        <div className="mt-1 text-center">
          <button className="btn btn-dark" onClick={() => { save_corp_opt_by_ajax(); }}>저장</button>
          <button className="btn btn-dark ml-1" onClick={() => { set_corp_opt(default_corp_opt); }}>초기화</button>
        </div>
      </div>
    </CompLayout>
  );
}

export default MsgOptPage;