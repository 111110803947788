import strFunc from "@/lib/lyg/string";
function StuNameTd(props: any) {
  let myProps = {
    Style: {},
    info: {},
    daily_info:{},
    select_stu_grade_arr: [],
    open_stu_layer_popup:(inData:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let stu_grade_str = "";
  for (let i = 0; i < myProps.select_stu_grade_arr.length; i++) {
    if (info["a_stu_grade"] == myProps.select_stu_grade_arr[i]["value"]) {
      stu_grade_str = myProps.select_stu_grade_arr[i]["text"];
    }
  }

  let row_input_style:any={width:"99%",border:0,textAlign:"center",cursor:"pointer"};
  if(strFunc.is_empty(myProps.daily_info["a_seq"])){
    // row_input_style["background"]="#707070";
    // row_input_style["color"]="#fff";
  }

  let td_class_name="";
  if(info.attend_state_str=="결석"){
    td_class_name=Style.disable_td;
  }

  return (
    <td className={td_class_name}
      title={info.a_user_name + " " + stu_grade_str + " " + info["a_school_name"]}
      onClick={()=>{
        myProps.openTodoDaySort({
          select_stu_name: info["a_user_name"],
          select_stu_seq: info["a_seq"],
        });
        //myProps.open_stu_layer_popup({stu_info:info});
      }}
      style={{cursor:"pointer"}}>
      <input type="text" value={strFunc.cut_str(info.a_user_name, 4, "")+" " + stu_grade_str}  
        readOnly
        style={row_input_style}/>
    </td>
  );
};
export default StuNameTd;