import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    setListOpt: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const [categoryInfoArr, setCategoryInfoArr] = useState([]);
  useEffect(() => {
    list_category();
  }, []);

  const list_category = () => {
    let category_form_json = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/category/list', category_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setCategoryInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  return (
    <div>
      <div className="search-box relative">
        카테고리
        <select className="search-input_select" value={listOpt.s_category_seq}
          onChange={(e: any) => {
            myProps.list({
              "now_page": 1,
              "s_category_seq": e.target.value
            });
          }}>
          <option value="">선택없음</option>
          {
            categoryInfoArr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_seq}>{item.a_title}</option>
              );
            })
          }
        </select>
        <input className="search-input pl-1" value={listOpt.s_title_like} name="s_title_like"
          onChange={handleInputChange} placeholder="제목" style={{width:100}} autoComplete="off" />
        <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      </div>
    </div>
  );
};

export default SearchArea;