import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"discover_map",
    list_id:"discover_map_history",
    x_column_list_arr:[
      {"key":"a_stu_name","name":"학생","width":"100","is_show":"1"},
      {"key":"a_date","name":"작성일","width":"110","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"110","is_show":"1"},
      {"key":"a_is_req_prescribe","name":"처방요청","width":"110","is_show":"1"},
      {"key":"a_is_success","name":"처방여부","width":"110","is_show":"1"},
      {"key":"a_success_date","name":"처방일","width":"120","is_show":"1"},
      {"key":"a_reviewer","name":"처방자","width":"120","is_show":"1"},

      {"key":"a_exam_math_score","name":"수학점수","width":"120","is_show":"1"},
      {"key":"a_exam_en_score","name":"영어점수","width":"120","is_show":"1"},
      {"key":"a_exam_language_score","name":"국어점수","width":"120","is_show":"1"},
      {"key":"a_exam_society_score","name":"사회점수","width":"120","is_show":"1"},
      {"key":"a_exam_science_score","name":"과학","width":"120","is_show":"1"},
      {"key":"a_exam_average_score","name":"평균점수","width":"120","is_show":"1"},
      {"key":"a_stu_problem_condition","name":"난제상황","width":"120","is_show":"1"},
      {"key":"a_lesson_reaction_en","name":"수업반응영어","width":"120","is_show":"1"},
      {"key":"a_lesson_reaction_math","name":"수업반응수학","width":"120","is_show":"1"},
      {"key":"a_stu_tendency","name":"성향","width":"120","is_show":"1"},

      {"key":"a_attitude_en","name":"학습태도영어","width":"120","is_show":"1"},
      {"key":"a_attitude_math","name":"학습태도수학","width":"120","is_show":"1"},
      {"key":"a_emotion_en","name":"학습정서영어","width":"120","is_show":"1"},
      {"key":"a_emotion_math","name":"학습정서수학","width":"120","is_show":"1"},
      {"key":"a_director_review","name":"원장총평","width":"120","is_show":"1"},

      {"key":"a_prescribe_en","name":"학습처방영어","width":"120","is_show":"1"},
      {"key":"a_prescribe_math","name":"학습처방수학","width":"120","is_show":"1"},
      {"key":"a_teaching_point","name":"티칭포인트","width":"120","is_show":"1"},
      {"key":"a_last_counsel","name":"최종상담","width":"120","is_show":"1"},
      
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_date DESC, a_create_date DESC',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.order_id_col_arr=[
    {value:"a_date",text:"작성일"},
    {value:"a_stu_name",text:"학생"},
    {value:"a_writer",text:"작성자"},
  ];
  

  return xColumnArr;
};

export default getXcolumnJson;