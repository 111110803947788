import strFunc from "@/lib/lyg/string";
import CounselTodoDataFunc from "@/pcomponents/common/content/edu/counsel_todo/func/data_func";

function CounselBox(props:any){
   let myProps={
    Style:{},
    info:{},
    openCounselWritePopup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  //1차: 당일,  2차: 일주일, 3차: 한달.
  let counsel_state_arr:any=[
    {
      "text":"1차",
      "sort":1,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"2차",
      "sort":2,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"3차",
      "sort":3,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"2개월",
      "sort":4,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"3개월",
      "sort":5,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
  ];
  const set_counsel_info_data=()=>{
    let reserve_date_arr=CounselTodoDataFunc.get_reserve_date_arr({
      init_date_str:info["a_join_date"],
    });
    for(let i=0;i<counsel_state_arr.length;i++){
      let counsel_state=counsel_state_arr[i];
      if(reserve_date_arr[counsel_state.sort]){
        counsel_state_arr[i]["select_date"]=reserve_date_arr[counsel_state.sort];
      }

      if(info.add_memo_info){
        let sort_num=counsel_state.sort+"";
        let counsel_date_col="a_init_counsel_date"+sort_num;
        let counsel_seq_col="a_init_counsel_seq"+sort_num;
        if(!strFunc.is_empty(info.add_memo_info[counsel_date_col])&&info.add_memo_info[counsel_date_col]!="0000-00-00"){
          counsel_state_arr[i]["select_date"]=info.add_memo_info[counsel_date_col];
          counsel_state_arr[i]["counsel_todo_pri_val"]=info.add_memo_info[counsel_seq_col];
        }
      }
    }
  };
  set_counsel_info_data();

  const get_counsel_state_tags=(indata:any)=>{
    let opt_obj={
      start:0,
      end:2,
      ...indata
    };
    let show_state_arr:any=[];
    for(let i=0;i<counsel_state_arr.length;i++){
      if(i>=opt_obj.start&&i<=opt_obj.end){
        show_state_arr.push(counsel_state_arr[i]);
      }
    }
    let counsel_state_tags=show_state_arr.map((item:any,idx:number)=>{
      let is_active=false;
      let row_class_name=Style.cousel_box_state;
      let state_str=item["text"];
      if(!strFunc.is_empty(item.select_date)){
        state_str=item.select_date.substring(5,10);
      }
      if(!strFunc.is_empty(item.counsel_todo_pri_val)){
        is_active=true;
        row_class_name+=" "+Style.active;
      }
      return (
        <div key={idx} className={row_class_name} onClick={()=>{
          if(strFunc.is_empty(item.counsel_todo_pri_val)){
            myProps.openCounselWritePopup({
              stu_info:info,
              select_date:item.select_date,
            });
          }else{
            myProps.openCounselWritePopup({
              stu_info:info,
              isUpdate:true,
              counsel_todo_pri_val:item.counsel_todo_pri_val,
            });
          }
        }}>
          {state_str}
        </div>
      );
    });

    return counsel_state_tags;
  };
  
  return (
  <div className={Style.cousel_box_wrap}>
    <div className={Style.cousel_box_state_wrap}>
      {get_counsel_state_tags({start:0,end:2})}
    </div>
    <div className={Style.cousel_box_state_wrap} style={{marginTop:"10px"}}>
      {get_counsel_state_tags({start:3,end:5})}
    </div>
  </div>
  );
};
export default CounselBox;