import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector } from 'react-redux';
import SearchArea from "./area/search";

const PaytSupplyListArea = forwardRef((props: any, ref) => {
  let myProps = {
    stu_list: (inData: any) => { },
    unselect_stu: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_mcomp_seq": "",
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
    "s_user_seq": [],
    "s_writer_seq": "",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_addon_user": "1",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }));

  const list = (inData: any) => {
    let change_listOpt={
      ...listOpt,
      ...inData
    };
    change_listOpt["sc"] = listAggridRef.current.getListSc();
    setListOpt(change_listOpt);

    change_listOpt["s_mcomp_seq"]=user.comp_seq;
    if(strFunc.is_empty(change_listOpt)){
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/trade/payt_supply/list', change_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/trade/payt_supply/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_stu_name") {
      if (params.data.stu_info) {
        let tmp_stu_grade = "";
        for (let i = 0; i < select_stu_grade_arr.length; i++) {
          if (select_stu_grade_arr[i]["value"] == params.data.stu_info["a_stu_grade"]) {
            tmp_stu_grade = select_stu_grade_arr[i]["text"];
          }
        }
        render_str = render_str + " " + tmp_stu_grade;
      }
    }

    if (key != "a_memo") {
      render_str = (
        <span title={render_str}>
          {render_str}
        </span>
      );
    }

    return render_str;
  }, []);

  return (
    <div>
      <div className="relative" style={{ minHeight: 57 }}>
        <SearchArea
          xColumnArr={xColumnArr}
          listOpt={listOpt}
          list={list}
          stu_list={myProps.stu_list}
          goDelete={goDelete}
          unselect_stu={()=>{myProps.unselect_stu();}}
        ></SearchArea>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: true,
          col_autoHeight: true,
          onGridReady: () => {
            list({});
          },
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
    </div>
  );
});
export default PaytSupplyListArea;