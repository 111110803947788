import { useState, useRef, useEffect } from 'react';
import LoadingComponent from "@/pcomponents/common/content/loading";
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewCounselTodoOfDay from "@/pages/comp/basic/counsel/calendar/area/popup/view_ctodo_day";
import DailyReportTestListFunc from "./func/list_func";
import CardListArea from "./area/card_list";

function DailyReportTextListPopup(props: any) {
  let myProps = {
    select_date: "",
    stu_info_arr: [],
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let select_date = myProps.select_date;
  if (strFunc.is_empty(select_date)) {
    select_date = now_date_str;
  }
  const [stu_info_arr, set_stu_info_arr] = useState<any>([]);
  const [check_seq_arr, set_check_seq_arr] = useState([]);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "로딩중..",
  });
  const [counsel_todo_arr, set_counsel_todo_arr] = useState([]);
  const [temp_memo_arr, set_temp_memo_arr] = useState<any>([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view_ctodo",
    stu_info_arr: [],
    select_date: select_date,
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  useEffect(() => {
    if (document.getElementsByClassName("popup-content-div")[0]) {
      let popup_content_div: any = document.getElementsByClassName("popup-content-div")[0];
      popup_content_div.style.background = "#f4f0ff";
    }
    if (document.getElementsByClassName("popup-title-bar")[0]) {
      let popup_title_bar: any = document.getElementsByClassName("popup-title-bar")[0];
      popup_title_bar.style.color = "#ea5a2f";
    }

    let change_stu_info_arr = [...myProps.stu_info_arr];
    let new_stu_info_arr = DailyReportTestListFunc.get_stu_info_arr_by_props({
      stu_info_arr: change_stu_info_arr,
      select_date: select_date,
      user: user,
    });
    set_stu_info_arr(new_stu_info_arr);
    get_counsel_todo_by_ajax({
      stu_info_arr: new_stu_info_arr,
    });
    get_comment_temp_memo_by_ajax({
      stu_info_arr: new_stu_info_arr
    });
  }, [myProps.stu_info_arr]);

  const check_all_stu = (e: any) => {
    let tmp_arr: any = [];
    if (e.target.checked) {
      for (let i = 0; i < stu_info_arr.length; i++) {
        tmp_arr.push(stu_info_arr[i]["a_seq"]);
      }
    }
    set_check_seq_arr(tmp_arr);
  };

  const get_select_stu_info_arr = () => {
    let select_stu_info_arr: any = [];
    for (let i = 0; i < stu_info_arr.length; i++) {
      let stu_info = stu_info_arr[i];
      if (strFunc.str_in_array(stu_info["a_seq"], check_seq_arr) != -1) {
        select_stu_info_arr.push(stu_info);
      }
    }
    return select_stu_info_arr;
  };

  const copy_clipboard_report = (inData: any) => {
    let opt_obj = {
      select_stu_info_arr: get_select_stu_info_arr(),
      ...inData
    };
    if (opt_obj.select_stu_info_arr.length == 0) {
      alert("선택된 학생이 없습니다.");
      return false;
    }
    save_comment_temp_memo_by_ajax({select_stu_info_arr:opt_obj.select_stu_info_arr});

    let copy_text_arr: any = [];
    let line_break = "\n";
    for (let i = 0; i < opt_obj.select_stu_info_arr.length; i++) {
      let stu_info = opt_obj.select_stu_info_arr[i];
      let copy_text = "";
      copy_text += stu_info["report"];
      if (stu_info["comment"]) {
        copy_text += line_break;
        copy_text += stu_info["comment"];
      }
      copy_text_arr.push(copy_text);
    }

    navigator.clipboard.writeText(copy_text_arr.join(line_break + line_break));
    set_loading_data({
      is_display: true,
      text: "복사됨",
    });
    setTimeout(() => {
      set_loading_data({
        is_display: false,
        text: "로딩중..",
      });
    }, 500);
  };

  const get_counsel_todo_by_ajax = (inData: any) => {
    let opt_obj = {
      stu_info_arr: stu_info_arr,
      ...inData
    };
    let stu_seq_arr: any = [];
    for (let i = 0; i < opt_obj.stu_info_arr.length; i++) {
      stu_seq_arr.push(opt_obj.stu_info_arr[i]["a_seq"]);
    }
    if (stu_seq_arr.length == 0) {
      return false;
    }
    let counsel_start_date = select_date;
    let counsel_end_date = select_date;
    if (select_date) {
      counsel_start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date), 'month', -1), "Y-m-01");;
      counsel_end_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date), 'day', +5), "Y-m-d");;
    }
    let form_json_data: any = {
      "s_par_id": "daily_report",
      "s_stu_seq": stu_seq_arr,
      s_date_type: "a_reserve_date",
      s_start_date: counsel_start_date,
      s_end_date: counsel_end_date,
      "order_id": "a_reserve_date DESC, a_create_date DESC",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_counsel_todo_arr(response.data["data"]["info_arr"]);
          let new_stu_info_arr = DailyReportTestListFunc.get_stu_info_arr_by_counsel_added({
            stu_info_arr: opt_obj.stu_info_arr,
            counsel_todo_arr: response.data["data"]["info_arr"],
            select_date: select_date,
          });
          set_stu_info_arr(new_stu_info_arr);
        } else {

        }
      });
  };

  const wrtie_counsel_todo_by_ajax = (inData: any) => {
    let opt_obj = {
      select_stu_info_arr: get_select_stu_info_arr(),
      is_study_prescribe:false,
      ...inData
    };
    if (opt_obj.select_stu_info_arr.length == 0) {
      alert("선택된 학생이 없습니다.");
      return false;
    }
    let write_counsel_arr: any = [];
    for (let i = 0; i < opt_obj.select_stu_info_arr.length; i++) {
      let stu_info = opt_obj.select_stu_info_arr[i];
      if (strFunc.is_empty(stu_info["counsel_todo"]) && stu_info["daily_report"]) {
        let row_daily_report = stu_info["daily_report"];
        let row_daily_report_pri_val = row_daily_report["a_ymd"] + "," + row_daily_report["a_seq"];
        let tmp_ymd = DateFunc.get_date_format(new Date(select_date), "Ymd");
        let tmp_content = stu_info["report"];

        let counsel_kind="진도";
        let a_is_success="1";
        let a_state="complete";
        let a_sort1="퍼스널학습상담";
        if(opt_obj.is_study_prescribe){
          counsel_kind="학습처방";
          tmp_content=tmp_content.replace("1:1퍼스널 학습","1:1퍼스널 학습처방");
          a_is_success="";
          a_state="ready";
          a_sort1="학습처방";
        }

        if (stu_info["comment"]) {
          tmp_content += "\n" + stu_info["comment"];
        }

        let w_counsel_todo_row: any = {
          "a_ymd": tmp_ymd,
          "a_seq": "",
          "a_stu_seq": stu_info["a_seq"],
          "a_stu_name": stu_info["a_user_name"],
          "a_sort1": a_sort1,
          "a_sort2": "상담",
          "a_is_public": "1",
          "a_kind": counsel_kind,

          "a_counsel_date": select_date,
          "a_reserve_date": select_date,
          "a_writer_seq": user.user_seq,
          "a_writer": user.user_name,
          "a_par_id": "daily_report",
          "a_par_seq": row_daily_report_pri_val,
          "a_counseler_seq": user.user_seq,
          "a_counseler": user.user_name,
          "a_title": "상담",
          "a_content": tmp_content,
          "a_feedback": "",
          "a_eval_score": "",
          "a_reference": stu_info["comment"],
          "a_is_success": a_is_success,
          "a_state": a_state,
        };
        write_counsel_arr.push(w_counsel_todo_row);
      }
    }
    if (write_counsel_arr.length == 0) {
      alert("상담 저장 할 내용이 없습니다.");
      return false;
    }

    if (confirm("상담내용을 저장하시겠습니까?") == false) {
      return false;
    }

    save_comment_temp_memo_by_ajax({select_stu_info_arr:opt_obj.select_stu_info_arr});

    let form_json_data: any = {
      data_arr: write_counsel_arr,
      is_default_val: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("상담내용이 저장되었습니다.");
          get_counsel_todo_by_ajax({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_comment_temp_memo_by_ajax = (inData: any) => {
    let opt_obj={
      stu_info_arr:stu_info_arr,
      ...inData
    };
    let s_user_seq_arr:any=[];
    for(let i=0;i<opt_obj.stu_info_arr.length;i++){
      s_user_seq_arr.push(opt_obj.stu_info_arr[i]["a_seq"]);
    }

    let form_json_data:any={
      s_user_seq:s_user_seq_arr,
      s_par_id:"user",
      s_par_seq:s_user_seq_arr,
      s_sort1:"daily_comment",
      order_id:"a_date DESC, a_update_date DESC",
      is_need_count:"",
      is_need_info_arr:"1",
      is_no_limit:"1",
      max_limit_num:1000,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user_temp_memo/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_temp_memo_arr(response.data["data"]["info_arr"]);
          let change_stu_info_arr=DailyReportTestListFunc.get_stu_info_arr_by_temp_memo_added({
            stu_info_arr:opt_obj.stu_info_arr,
            temp_memo_arr:response.data["data"]["info_arr"],
            select_date:select_date,
          });
          set_stu_info_arr(change_stu_info_arr);
        }
      });
  };

  const get_pre_comment_info_by_arr=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData
    };
    let pre_info=null;
    for(let i=0;i<temp_memo_arr.length;i++){
      let row=temp_memo_arr[i];
      if(row["a_date"]==opt_obj.row_data["a_date"]
        &&row["a_user_seq"]==opt_obj.row_data["a_user_seq"]
        &&row["a_sort1"]==opt_obj.row_data["a_sort1"]
      ){
        pre_info=row;
        break;
      }
    }
    return pre_info;
  };

  const save_comment_temp_memo_by_ajax=(inData:any)=>{
    let opt_obj={
      select_stu_info_arr: get_select_stu_info_arr(),
      ...inData
    };
    if (opt_obj.select_stu_info_arr.length == 0) {
      return false;
    }
    let write_temp_memo_arr: any = [];
    for(let i=0;i<opt_obj.select_stu_info_arr.length;i++){
      let select_stu_info=opt_obj.select_stu_info_arr[i];
      let tmp_ymd=DateFunc.get_date_format(new Date(select_date),"Ymd");
      let tmp_row:any={
        a_ymd:tmp_ymd,
        a_seq:"",
        a_date:select_date,
        a_user_seq:select_stu_info["a_seq"],
        a_user_name:select_stu_info["a_user_name"],
        a_writer_seq:user.user_seq,
        a_writer_name:user.user_name,
        a_par_id:"user",
        a_par_seq:select_stu_info["a_seq"],
        a_sort1:"daily_comment",
        a_sort2:"",
        a_content:select_stu_info["comment"],
        a_content2:select_stu_info["report"],
      };
      let tmp_pre_info:any=get_pre_comment_info_by_arr({row_data:tmp_row});
      if(tmp_pre_info){
        tmp_row={
          ...tmp_row,
        }
        if(tmp_pre_info["a_ymd"]&&tmp_pre_info["a_seq"]){
          tmp_row["a_ymd"]=tmp_pre_info["a_ymd"];
          tmp_row["a_seq"]=tmp_pre_info["a_seq"];
          tmp_row["a_create_date"]=tmp_pre_info["a_create_date"];
        }
      }
      write_temp_memo_arr.push(tmp_row);
    }
    
    let form_json_data: any = {
      data_arr:write_temp_memo_arr,
      is_default_val:"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user_temp_memo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          get_comment_temp_memo_by_ajax({});
        }
      });
  };

  const open_view_counsel_todo = (inData: any) => {
    let opt_obj = {
      selected_stu_arr: get_select_stu_info_arr(),
      select_date: select_date,
      ...inData
    };
    let selected_stu_arr = opt_obj.selected_stu_arr;

    if (selected_stu_arr.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "view_ctodo",
      title: "상담일정 보기",
      stu_info_arr: selected_stu_arr,
      select_date: opt_obj.select_date,
      ...inData
    });
  };

  return (
    <div>
      <div style={{ margin: "0 auto", width: "1200px",position:"relative" }}>
        <div>{select_date}</div>
        <label>
          <input type="checkbox" onChange={check_all_stu} /> 전체선택
        </label>
      </div>
      <CardListArea
        stu_info_arr={stu_info_arr}
        set_stu_info_arr={set_stu_info_arr}
        check_seq_arr={check_seq_arr}
        set_check_seq_arr={set_check_seq_arr}
        copy_clipboard_report={copy_clipboard_report}
        counsel_todo_arr={counsel_todo_arr}
        wrtie_counsel_todo_by_ajax={wrtie_counsel_todo_by_ajax}
        open_view_counsel_todo={open_view_counsel_todo}
      ></CardListArea>
      <div className="text-center mt-2">
        <button className="btn btn-orange ml-1" onClick={() => { wrtie_counsel_todo_by_ajax({}); }} >상담등록</button>
        <button className="btn btn-dark ml-1" onClick={() => { copy_clipboard_report({}); }} >복사</button>
        <button className="btn btn-dark ml-1" onClick={() => { copy_clipboard_report({ select_stu_info_arr: stu_info_arr }); }} >모두복사</button>
        <button className="btn btn-dark ml-1" onClick={() => { open_view_counsel_todo({selected_stu_arr:stu_info_arr}); }} >상담보기</button>
        <button className="btn btn-dark ml-1" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      {loading_data.is_display &&
        <LoadingComponent text={loading_data.text} is_display={loading_data.is_display}></LoadingComponent>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort == "view_ctodo" &&
            <ViewCounselTodoOfDay
              select_date={popupData.select_date}
              stu_info_arr={popupData.stu_info_arr}
              list_opt={{}}
              show_write_diary_page={() => { }}
              callback={() => { get_counsel_todo_by_ajax({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewCounselTodoOfDay>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DailyReportTextListPopup;