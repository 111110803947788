import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import StudentCardListArea from "./stu_card_list";

function RoomRowArea(props:any){
   let myProps={
    room_info:null,
    Style:{},
    student_info_arr:[],
    now_ymdhis:"",
    pre_date_str:"",
    cardview_sort:"",
    open_view_room_popup:(inData:any)=>{},
    show_detail_view:(inData:any)=>{},
    open_view_desk_popup:(inData:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    openTodoWriteOnePopup:(inData:any)=>{},
    go_change_todo_state_by_ajax:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let room_info=myProps.room_info;
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec=now_date_json.date_obj.getTime()/1000;

  let desk_arr: any = [];
  if (room_info.desk_arr) {
    desk_arr = room_info.desk_arr;
  }
  let desku_arr: any = [];
  if (room_info.desku_arr) {
    desku_arr = room_info.desku_arr;
  }
  const get_room_now_desku_arr=()=>{
    let tmp_now_desku_arr:any=[];
    let desku_arr_len = desku_arr.length;
    for (let i = 0; i < desku_arr_len; i++) {
      let desku_info=desku_arr[i];
      let start_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_start_h"],desku_info["a_start_m"])+":00";
      let start_date_json=DateFunc.get_date_json(new Date(start_date_str));
      let end_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      if(!strFunc.is_empty(desku_info["a_end_date"])){
        end_date_str=desku_info["a_end_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      }
      let end_date_json=DateFunc.get_date_json(new Date(end_date_str));

      let start_sec = start_date_json.date_obj.getTime() / 1000;
      let end_sec = end_date_json.date_obj.getTime() / 1000;
      
      if (start_sec <= now_sec && now_sec < end_sec) {
        tmp_now_desku_arr.push(desku_info);
      }
    }
    
    return tmp_now_desku_arr;
  };
  let now_desku_arr:any=get_room_now_desku_arr();

  const get_room_name_str=()=>{
    let room_name_str:any=room_info.a_name;
    let desk_arr_len=desk_arr.length;
    let now_desku_arr_len=now_desku_arr.length;
    if(desk_arr_len>0){
      let now_u_cnt_color="gray";
      if(now_desku_arr_len>0){
        now_u_cnt_color="green";
      }
      if(now_desku_arr_len>=desk_arr_len){
        now_u_cnt_color="red";
      }
      room_name_str=(
        <span style={{fontSize:"16px"}}>
          {room_name_str}
          <span className="ml-1" style={{color:now_u_cnt_color}}>
            (
              {now_desku_arr_len}
              {"/"}
              {desk_arr_len}
            )
          </span>
        </span>
      );
    }
    return room_name_str;
  };
  
  return (
  <div>
    <div className="text-center">
      {get_room_name_str()}
      <button className="btn btn-dark ml-1" 
        onClick={(e: any) => {
          myProps.open_view_room_popup({
            room_seq:room_info.a_seq,
          });
        }}>좌석설정</button>
    </div>
    <div>
      <StudentCardListArea
        room_info={room_info}
        student_info_arr={myProps.student_info_arr}
        now_desku_arr={now_desku_arr}
        now_ymdhis={myProps.now_ymdhis}
        pre_date_str={myProps.pre_date_str}
        cardview_sort={myProps.cardview_sort}
        Style={Style}
        show_detail_view={myProps.show_detail_view}
        open_view_desk_popup={myProps.open_view_desk_popup}
        openTodoDaySort={myProps.openTodoDaySort}
        openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
        go_change_todo_state_by_ajax={myProps.go_change_todo_state_by_ajax}
      ></StudentCardListArea>
    </div>
  </div>
  );
};
export default RoomRowArea;