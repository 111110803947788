import TdContentArea from "./td_content";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { default_daily_report_last_info,get_last_daily_report_by_month_prescribe_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";

function WeekTableArea(props: any) {
  let myProps = {
    week_td_arr: [],
    week_info_row_num_arr: [],
    set_week_info_row_num_arr: (inData: any) => { },
    week_info_arr: [],
    on_change_week_info: (inData: any) => { },
    is_view_mode: false,
    select_mcomp_data: {},
    go_write_week_info_arr_by_default_row: (inData:any) => { },
    delete_week_info_by_ajax: (inData:any) => { },
    openOnePopup: (inData:any) => { },
    openFindPrescribePopup: (inData:any) => { },
    openFindCounselorePopup: (inData:any) => { },
    openWriteCounselPopup: (inData:any) => { },
    comment_list:[],
    refresh_comment: (inData:any) => { },
    list: (inData:any) => { },
    ...props
  };
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let week_td_arr= myProps.week_td_arr;
  let week_info_row_num_arr = myProps.week_info_row_num_arr;
  let week_info_arr= myProps.week_info_arr;
  let is_view_mode=myProps.is_view_mode;
  let select_mcomp_data=myProps.select_mcomp_data;

  const get_week_colgroup_tags = () => {
    let col_per_num = 100;
    if (week_td_arr.length > 0) {
      col_per_num = Math.floor(100 / week_td_arr.length);
    }
    let colgroup_tags = week_td_arr.map((item: any, idx: number) => {
      return (
        <col key={idx} width={col_per_num} />
      );
    });

    return colgroup_tags;
  };

  const get_week_tr_td_tags = (inData: any) => {
    let opt_obj = {
      name: "",
      ...inData
    };
    let week_td_tags = week_td_arr.map((item: any, idx: number) => {
      let week_info: any = {};
      let week_idx_row_num = 0;
      let is_now_week = false;
      let is_select_date= false;
      let week_date_str_arr = [];
      for (let i = 0; i < item.row_td_data_arr.length; i++) {
        week_date_str_arr.push(item.row_td_data_arr[i].date_str);
        if (item.row_td_data_arr[i].date_str == now_date_str) {
          is_now_week = true;
        }
        if(item.row_td_data_arr[i].date_str==myProps.s_select_date){
          is_select_date=true;
        }
      }

      if (week_info_row_num_arr[idx]) {
        week_idx_row_num = week_info_row_num_arr[idx];
      }
      if (week_info_arr[idx] != undefined && week_info_arr[idx].length > 0) {
        if (week_idx_row_num > week_info_arr[idx].length - 1) {
          week_idx_row_num = 0;
        }
        week_info = week_info_arr[idx][week_idx_row_num];
      }

      let row_td_style: any = { border: "1px solid #ccc", verticalAlign: "top" };
      if (week_info.a_is_success == "1") {
        row_td_style["background"] = "#fbfffb";
      }
      if (is_now_week) {
        row_td_style["border"] = "2px solid #7470F9";
      }
      if(is_select_date){
        row_td_style["border"] = "2px solid #7470F9";
      }
      let writer_span_style: any = {};
      if (week_info.a_is_success == "1" && week_info.a_is_success_check != "1") {
        writer_span_style["background"] = "#ec634c";
        writer_span_style["color"] = "#ffffff";
        writer_span_style["borderRadius"] = "5px";
      }
      
      let last_daily_report_info: any = get_last_daily_report_by_month_prescribe_info({
        row_data: week_info,
        end_date:item.end_date,
      });

      return (
        <td key={idx} className="text-center" style={row_td_style}>
          {opt_obj["name"] == "" &&
            <div>
              {item.start_date.substring(5, 7)} 월
              <span className="ml-1" style={{ color: "gray" }}>
                ({item.start_date.substring(8, 10)}일
                ~)
                {/* {item.end_date.substring(8, 10)}일) */}
              </span>
              {is_view_mode == false &&
                <button className="btn-s btn-sky ml-1"
                  onClick={() => {
                    myProps.go_write_week_info_arr_by_default_row({
                      default_week_info: week_info
                    });
                  }}>저장</button>
              }
              {(is_view_mode == false && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-red ml-1" onClick={() => { myProps.delete_week_info_by_ajax({ data_arr: [week_info] }); }}>삭제</button>
              }

              {(is_view_mode && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-dark ml-1"
                  onClick={() => { myProps.openOnePopup({ sort: "view", rowData: week_info, week_i: item.idx }); }} >복사</button>
              }

              {!strFunc.is_empty(week_info.a_seq) &&
                <div>
                  {(week_info_arr[idx] != undefined && week_info_arr[idx].length > 0) &&
                    <span>
                      {week_info_arr[idx].map((row_week_info: any, row_week_info_idx: number) => {
                        let row_week_info_class_name = "btn-s btn-gray ml-1";
                        if (row_week_info_idx == week_idx_row_num) {
                          row_week_info_class_name = "btn-s btn-sky ml-1";
                        }
                        let row_week_info_title_str = row_week_info["a_reserve_date"] + " " + row_week_info["a_writer_name"];
                        return (
                          <button key={row_week_info_idx} className={row_week_info_class_name}
                            onClick={() => {
                              if (row_week_info_idx != week_idx_row_num) {
                                let change_week_info_row_num_arr = [...week_info_row_num_arr];
                                change_week_info_row_num_arr[idx] = row_week_info_idx;
                                myProps.set_week_info_row_num_arr(change_week_info_row_num_arr);
                                myProps.refresh_comment(inData);
                                myProps.list();
                              }
                            }}
                            title={row_week_info_title_str}>{row_week_info["a_reserve_date"].substring(8, 10)}</button>
                        );
                      })}
                    </span>
                  }
                  <span className="ml-1">
                    작성:
                    <span className="ml-2" style={writer_span_style}>
                      {week_info.a_writer_name}
                    </span>
                  </span>
                </div>
              }
            </div>
          }
          {opt_obj["name"] != "" &&
            <TdContentArea
              select_mcomp_data={{...select_mcomp_data}}
              name={opt_obj["name"]}
              week_info={week_info}
              week_idx={idx}
              week_idx_row_num={week_idx_row_num}
              on_change_week_info={myProps.on_change_week_info}
              is_view_mode={is_view_mode}
              go_write_week_info_arr_by_default_row={myProps.go_write_week_info_arr_by_default_row}
              openFindPrescribePopup={myProps.openFindPrescribePopup}
              openFindCounselorePopup={myProps.openFindCounselorePopup}
              openWriteCounselPopup={myProps.openWriteCounselPopup}
              comment_list={myProps.comment_list}
              refresh_comment={(inData: any) => {
                myProps.refresh_comment(inData);
                myProps.list();
              }}
              last_daily_report_info={last_daily_report_info}
              is_now_week={is_now_week}
            ></TdContentArea>
          }
        </td>
      );
    });

    return week_td_tags;
  };

  return (
    <div className="write_table_small mt-1">
      <table>
        <colgroup>
          {get_week_colgroup_tags()}
        </colgroup>
        <tbody>
          <tr>
            {get_week_tr_td_tags({})}
          </tr>
          <tr>
            {get_week_tr_td_tags({ name: "a_progress_reason" })}
          </tr>
          <tr>
            {get_week_tr_td_tags({ name: "a_progress" })}
          </tr>
          <tr>
            {get_week_tr_td_tags({ name: "a_grow" })}
          </tr>
          <tr>
            {get_week_tr_td_tags({ name: "a_lack" })}
          </tr>
          <tr>
            {get_week_tr_td_tags({ name: "a_content" })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WeekTableArea;