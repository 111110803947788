import strFunc from "@/lib/lyg/string";
import Style from "../../../../css/style.module.css";
import DeskBoxArea from "./desk_box";
import DateFunc from '@/lib/lyg/date_func';
import popupFunc from "@/lib/lyg/popup";
import RoomMessageBox from "./msg_box";
import StuSelectBoxArea from "./stu_select_box";

const RoomBoxArea = (props: any) => {
  let myProps: any = {
    room_info: {},
    now_ymdhis: "",
    pre_date_str: "",
    open_find_stu_popup: (inData: any) => { },
    open_view_desk_popup: (inData: any) => { },
    open_select_stu_alloc_desk_popup: (inData: any) => { },
    is_show_only_now_lecture: true,
    is_detal_popup: true,
    is_show_msg: true,
    is_show_stu_select_list: true,
    is_stu_filter_lecture: true,
    is_stu_filter_use_desk: true,
    select_stu_filter:{},
    set_select_stu_filter:(inData:any)=>{},
    select_stu_data: {},
    set_select_stu_data: (inData: any) => { },
    room_msg_arr: [],
    student_info_arr: [],
    ...props
  };
  let room_info = myProps.room_info;
  let lecture_data_arr: any = [];
  let now_lecture_data: any = null;
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec=now_date_json.date_obj.getTime()/1000;
  //x
  let desk_row_arr = [];
  let desk_row_len = parseInt(room_info["a_size_y"]);
  for (let i = 0; i < desk_row_len; i++) {
    desk_row_arr.push(i);
  }
  //y
  let desk_col_arr: any = [];
  let desk_col_len = parseInt(room_info["a_size_x"]);
  for (let i = 0; i < desk_col_len; i++) {
    desk_col_arr.push(i);
  }
  let desk_arr: any = [];
  if (room_info.desk_arr) {
    desk_arr = room_info.desk_arr;
  }
  let desku_arr: any = [];
  if (room_info.desku_arr) {
    desku_arr = room_info.desku_arr;
  }
  const get_room_now_desku_arr=()=>{
    let tmp_now_desku_arr:any=[];
    let desku_arr_len = desku_arr.length;
    for (let i = 0; i < desku_arr_len; i++) {
      let desku_info=desku_arr[i];
      let start_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_start_h"],desku_info["a_start_m"])+":00";
      let start_date_json=DateFunc.get_date_json(new Date(start_date_str));
      let end_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      if(!strFunc.is_empty(desku_info["a_end_date"])){
        end_date_str=desku_info["a_end_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      }
      let end_date_json=DateFunc.get_date_json(new Date(end_date_str));

      let start_sec = start_date_json.date_obj.getTime() / 1000;
      let end_sec = end_date_json.date_obj.getTime() / 1000;
      
      if (start_sec <= now_sec && now_sec < end_sec) {
        tmp_now_desku_arr.push(desku_info);
      }
    }
    
    return tmp_now_desku_arr;
  };
  let now_desku_arr:any=get_room_now_desku_arr();

  const open_room_detail_popup = () => {
    var win_w = (window.innerWidth || document.documentElement.clientWidth) / 3 * 2;
    var win_h = (window.innerHeight || document.documentElement.clientHeight) / 3 * 2;
    popupFunc.openPopup({
      'url': '/comp/edu/desk/popup/view_room_popup?room_seq=' + myProps.room_info.a_seq,
      'width': win_w,
      'height': win_h,
      'pop_name': 'room_detail_popup',
      'auto_size': true
    });
  };

  const get_lecture_time_list_tags = () => {
    let lecture_tags: any = "";//14:50~16:00 (90분)
    let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
    let now_sec = strFunc.timeToSeconds(now_date_json["h"], now_date_json["i"], now_date_json["s"]);
    //지금가능한 강의 찾기
    if (room_info.lecture_arr) {
      for (let lec_i = 0; lec_i < room_info.lecture_arr.length; lec_i++) {
        let lecture_info = room_info.lecture_arr[lec_i];

        if (lecture_info.time_arr) {
          for (let i = 0; i < lecture_info.time_arr.length; i++) {
            let time_stat = lecture_info.time_arr[i];
            if (now_date_json["day"] == time_stat["a_day_num"]) {
              let st_sec = strFunc.timeToSeconds(time_stat["a_start_h"], time_stat["a_start_m"], "0");
              let end_sec = strFunc.timeToSeconds(time_stat["a_end_h"], time_stat["a_end_m"], "0");
              let diff_sec = end_sec - st_sec;
              let minute_num = 0;
              let is_now = false;
              if (diff_sec != 0) {
                minute_num = Math.round(diff_sec / 60);
              }
              if (st_sec <= now_sec && now_sec < end_sec) {
                is_now = true;
              }

              if (is_now) {
                now_lecture_data = lecture_data_arr[lecture_data_arr.length - 1];
              }
              if (myProps.is_show_only_now_lecture) {
                if (is_now) {
                  lecture_data_arr.push({
                    "title": lecture_info["a_name"],
                    "start_h": time_stat["a_start_h"],
                    "start_m": time_stat["a_start_m"],
                    "end_h": time_stat["a_end_h"],
                    "end_m": time_stat["a_end_m"],
                    "start_hi": strFunc.HourMinuteToTime(time_stat["a_start_h"], time_stat["a_start_m"]),
                    "end_hi": strFunc.HourMinuteToTime(time_stat["a_end_h"], time_stat["a_end_m"]),
                    "minute_num": minute_num,
                    "is_now": is_now,
                  });
                }
              } else {
                lecture_data_arr.push({
                  "title": lecture_info["a_name"],
                  "start_h": time_stat["a_start_h"],
                  "start_m": time_stat["a_start_m"],
                  "end_h": time_stat["a_end_h"],
                  "end_m": time_stat["a_end_m"],
                  "start_hi": strFunc.HourMinuteToTime(time_stat["a_start_h"], time_stat["a_start_m"]),
                  "end_hi": strFunc.HourMinuteToTime(time_stat["a_end_h"], time_stat["a_end_m"]),
                  "minute_num": minute_num,
                  "is_now": is_now,
                });
              }
            }
          }
        }
      }
    }
    lecture_tags = lecture_data_arr.map((item: any, idx: number) => {
      let lecture_title_style: any = {};
      if (item.is_now) {
        lecture_title_style["color"] = "#00f";
      }
      return (
        <div key={idx}>
          <span style={lecture_title_style}>{item.title}</span>
          <span className="mx-2">
            {item.start_hi}~{item.end_hi}
          </span>
          ({item.minute_num}분)
        </div>
      );
    });
    if (lecture_tags == "") {
      lecture_tags = "수업이 없습니다.";
    }

    return lecture_tags;
  };
  let lecture_tags = get_lecture_time_list_tags();

  const get_desk_info_by_xy = (x: number, y: number) => {
    let desk_info = null;
    let desk_arr_len = desk_arr.length;
    for (let i = 0; i < desk_arr_len; i++) {
      if (parseInt(desk_arr[i]["a_x"]) == x && parseInt(desk_arr[i]["a_y"]) == y) {
        desk_info = desk_arr[i];
      }
    }
    return desk_info;
  };
  const get_desku_info_by_xy = (x: number, y: number) => {
    let desku_info = null;
    let desku_arr_len = now_desku_arr.length;
    for (let i = 0; i < desku_arr_len; i++) {
      let now_desku_info = now_desku_arr[i];
      if (parseInt(now_desku_info["a_x"]) == x && parseInt(now_desku_info["a_y"]) == y && desku_info == null) {
        desku_info = now_desku_info;
      }
    }
    return desku_info;
  };

  let desk_row_tags = desk_row_arr.map((row_num: number, row_idx: number) => {
    let desk_col_tags = desk_col_arr.map((col_num: number, col_idx: number) => {
      let desk_info = get_desk_info_by_xy(col_num, row_num);
      let desku_info = get_desku_info_by_xy(col_num, row_num);
      return (
        <DeskBoxArea
          key={col_idx}
          x={col_num}
          y={row_num}
          room_info={room_info}
          desk_info={desk_info}
          desku_info={desku_info}
          now_ymdhis={myProps.now_ymdhis}
          pre_date_str={myProps.pre_date_str}
          now_lecture_data={now_lecture_data}
          select_stu_data={myProps.select_stu_data}
          student_info_arr={myProps.student_info_arr}
          open_find_stu_popup={myProps.open_find_stu_popup}
          open_view_desk_popup={myProps.open_view_desk_popup}
          open_select_stu_alloc_desk_popup={myProps.open_select_stu_alloc_desk_popup}
        ></DeskBoxArea>
      );
    });
    return (
      <div className={Style.desk_box_row} key={row_idx}>
        {desk_col_tags}
      </div>
    );
  });

  const get_room_name_str=()=>{
    let room_name_str:any=room_info.a_name;
    let desk_arr_len=desk_arr.length;
    let now_desku_arr_len=now_desku_arr.length;
    if(desk_arr_len>0){
      let now_u_cnt_color="gray";
      if(now_desku_arr_len>0){
        now_u_cnt_color="green";
      }
      if(now_desku_arr_len>=desk_arr_len){
        now_u_cnt_color="red";
      }
      room_name_str=(
        <span style={{fontSize:"16px"}}>
          {room_name_str}
          <span className="ml-1" style={{color:now_u_cnt_color}}>
            (
              {now_desku_arr_len}
              {"/"}
              {desk_arr_len}
            )
          </span>
        </span>
      );
    }
    return room_name_str;
  };

  return (
    <div className={Style.room_box} >
      {myProps.is_show_stu_select_list &&
        <StuSelectBoxArea
          Style={Style}
          now_ymdhis={myProps.now_ymdhis}
          room_info={room_info}
          student_info_arr={myProps.student_info_arr}
          is_stu_filter_lecture={myProps.is_stu_filter_lecture}
          is_stu_filter_use_desk={myProps.is_stu_filter_use_desk}
          select_stu_filter={myProps.select_stu_filter}
          set_select_stu_filter={myProps.set_select_stu_filter}
          select_stu_data={myProps.select_stu_data}
          set_select_stu_data={myProps.set_select_stu_data}
        ></StuSelectBoxArea>
      }
      <div className={Style.room_box_con}>
        <div className={Style.room_box_title_bar}>
          <div className={Style.room_box_title} >
            {get_room_name_str()}
          </div>
          <div className={Style.room_box_sub_title} >
            {lecture_tags}
          </div>
        </div>
        <div className={Style.room_box_middle} >
          <div className={Style.room_box_desk_wrap} >
            {desk_row_tags}
          </div>
          {myProps.is_show_msg &&
            <RoomMessageBox
              room_info={room_info}
              room_msg_arr={myProps.room_msg_arr}
              Style={Style}
            ></RoomMessageBox>
          }
        </div>
        {myProps.is_detal_popup &&
          <div className={Style.room_box_bottom_bar}>
            <button className="btn-m btn-dark" onClick={() => { open_room_detail_popup(); }}>상세보기</button>
          </div>
        }
      </div>
    </div>
  );
};
export default RoomBoxArea;