//import DiscoverMapDataFunc from "@/pcomponents/common/content/edu/discover_map/func/data_func";
import strFunc from '@/lib/lyg/string';

class DiscoverMapDataFunc
{
  static default_info_data={
    a_ymd:"",
    a_seq:"",
    a_date:"",
    a_stu_seq:"",
    a_stu_name:"",
    a_stu_grade:"",
    a_stu_total_code:"",
    a_stu_think_type:"",
    a_stu_join_date:"",
    a_writer_seq:"",
    a_writer:"",
    a_reviewer_seq:"",
    a_reviewer:"",
    a_is_req_prescribe:"",
    a_req_prescribe_date:"",
    a_is_success:"",
    a_success_date:"",
    a_en_daily_report_seq:"",
    a_en_daily_report_json:"",
    a_math_daily_report_seq:"",
    a_math_daily_report_json:"",
    a_exam_math_score:"",
    a_exam_en_score:"",
    a_exam_language_score:"",
    a_exam_society_score:"",
    a_exam_science_score:"",
    a_exam_average_score:"",
    a_stu_problem_condition:"",
    a_lesson_reaction_en:"",
    a_lesson_reaction_math:"",
    a_stu_tendency:"",
    a_attitude_en:"",
    a_attitude_math:"",
    a_emotion_en:"",
    a_emotion_math:"",
    a_prescribe_en:"",
    a_prescribe_math:"",
    a_teaching_point:"",
    a_last_counsel:"",
    a_director_review:""
  };
  static get_info_of_stu_by_stu_info(inData: any){
    let opt_obj = {
      info: {},
      stu_info: {},
      stu_grade_option_arr: [],
      ...inData
    };
    let user_info = opt_obj.stu_info;
    let stu_grade_option_arr = opt_obj.stu_grade_option_arr;

    let grade_str = "";
    if (stu_grade_option_arr) {
      for (let i = 0; i < stu_grade_option_arr.length; i++) {
        if (stu_grade_option_arr[i]["value"] == user_info["a_stu_grade"]) {
          grade_str = stu_grade_option_arr[i]["text"];
        }
      }
    }
    let change_row_info: any = {
      ...DiscoverMapDataFunc.default_info_data,
      ...opt_obj.info,
      a_stu_seq: user_info["a_seq"],
      a_stu_name: user_info["a_user_name"],
      a_stu_grade: grade_str,
      a_stu_total_code: user_info["a_stu_total_code"],
      a_stu_think_type: user_info["a_stu_think_type"],
      a_stu_join_date: user_info["a_join_date"],
    };
    if (user_info.add_memo_info) {
      change_row_info["a_exam_math_score"] = user_info.add_memo_info["a_pre_math_exam_score"];
      change_row_info["a_exam_en_score"] = user_info.add_memo_info["a_pre_en_exam_score"];
      change_row_info["a_exam_language_score"] = user_info.add_memo_info["a_pre_language_exam_score"];
      change_row_info["a_exam_society_score"] = user_info.add_memo_info["a_pre_science_exam_score"];
      let tmp_total_score_arr = [];
      tmp_total_score_arr.push(parseFloat(strFunc.uncomma(user_info.add_memo_info["a_pre_math_exam_score"])));
      tmp_total_score_arr.push(parseFloat(strFunc.uncomma(user_info.add_memo_info["a_pre_en_exam_score"])));
      tmp_total_score_arr.push(parseFloat(strFunc.uncomma(user_info.add_memo_info["a_pre_language_exam_score"])));
      tmp_total_score_arr.push(parseFloat(strFunc.uncomma(user_info.add_memo_info["a_pre_science_exam_score"])));
      let tmp_total_score = 0;
      for (let i = 0; i < tmp_total_score_arr.length; i++) {
        tmp_total_score += tmp_total_score_arr[i];
      }
      let tmp_average_score = 0;
      if (tmp_total_score > 0) {
        tmp_average_score = Math.round(tmp_total_score / 4);
      }
      change_row_info["a_exam_average_score"] = tmp_average_score;
    }
    if (user_info.last_daily_report_arr && user_info.last_daily_report_arr.length > 0) {
      let exist_en_math_daily = {
        math: false,
        en: false,
      };
      for (let i = 0; i < user_info.last_daily_report_arr.length; i++) {
        let tmp_last_daily_report = user_info.last_daily_report_arr[i];
        if (tmp_last_daily_report["a_subject"] == "수학" && exist_en_math_daily.math == false) {
          exist_en_math_daily.math = true;
          change_row_info["a_math_daily_report_seq"] = tmp_last_daily_report["a_stu_seq"] + "," + tmp_last_daily_report["a_lecture_seq"];
          change_row_info["a_math_daily_report_json"] = JSON.stringify(tmp_last_daily_report);
          change_row_info["last_daily_report_math"] = tmp_last_daily_report;
        }
        if (tmp_last_daily_report["a_subject"] == "영어" && exist_en_math_daily.en == false) {
          exist_en_math_daily.en = true;
          change_row_info["a_en_daily_report_seq"] = tmp_last_daily_report["a_stu_seq"] + "," + tmp_last_daily_report["a_lecture_seq"];
          change_row_info["a_en_daily_report_json"] = JSON.stringify(tmp_last_daily_report);
          change_row_info["last_daily_report_en"] = tmp_last_daily_report;
        }
      }
    }
    return change_row_info;
  };
}
export default DiscoverMapDataFunc;