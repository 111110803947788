import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import search_ico_img from "@/img/ico/search2.png";
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const StuSearchList = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => { },
    ...props
  };
  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      ...inOptObj
    };
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      "s_stu_state": "now",
      s_stu_grade: "",
      s_class_seq: "",
      s_addon_todo: "1",
      s_addon_todo_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
      s_addon_todo_end_date: DateFunc.get_date_format(new Date(), "Y-m-t"),
      s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    },
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  useEffect(() => {

    get_lecture_select_arr();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let tmpPageData = { ...pageData };
    tmpPageData.listOpt[name] = value;
    setPageData({
      ...tmpPageData
    });
    if (name == "s_search_text") {
      return false;
    }
    list({ [name]: value });
  };

  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (listAggridRef.current) {
            listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          }
        } else {

        }
      });
  };

  const setSelectFalseAll = () => {
    listAggridRef.current.setSelectFalseAll();
    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": "",
      "s_stu_info_arr": [],
    });
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {

    }
  }

  const removeSelectStu = (stu_seq: any) => {
    let all_node_rows = listAggridRef.current.getNodeRows();
    for (let i = 0; i < all_node_rows.length; i++) {
      let tmp_seq = all_node_rows[i].data["a_seq"];
      if (tmp_seq == stu_seq) {
        all_node_rows[i].setSelected(false);
      }
    }
    setTimeout(() => {
      onSelectionChanged({});
    }, 100);
  };

  const get_info_arr = () => {
    return infoArr;
  };
  const get_select_info_arr = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    return selected_row;
  };
  const set_height = (height: any) => {
    if (listAggridRef.current) {
      listAggridRef.current.setHeight(height);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll, removeSelectStu, get_info_arr, get_select_info_arr,
    set_height
  }));


  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if(key=="a_user_name"){
      render_str +=" "+params.data.a_stu_total_code;
    }else if (key == "row_view_manage") {

    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_grade") {
      render_str = strFunc.cut_str(params.data["a_school_name"], 2, "");
      let grade_str = "";
      for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
        if (xColumnArr.select_arr["a_stu_grade"][i]["value"] == params.data["a_stu_grade"]) {
          grade_str = xColumnArr.select_arr["a_stu_grade"][i]["text"];
        }
      }
      render_str += " " + grade_str;
    } else if (key == "row_view_plan") {
      render_str = "";
      if (params.data.todo_arr) {
        let todo_span_color = "#777";
        let todo_row_tot_data = {
          "tot": params.data.todo_arr.length,
          "success": 0,
        };
        for (let i = 0; i < todo_row_tot_data["tot"]; i++) {
          if (params.data.todo_arr[i]["a_is_success"] == "1") {
            todo_row_tot_data["success"]++;
          }
        }
        if (todo_row_tot_data["tot"] > 0) {
          todo_span_color = "#db6c58";
          if (todo_row_tot_data["success"]) {
            todo_span_color = "#eab358";
          } else if (todo_row_tot_data["success"] == todo_row_tot_data["tot"]) {
            todo_span_color = "#A2B854";
          }
        }
        render_str = (
          <span style={{ color: todo_span_color }}>
            {todo_row_tot_data.success + "/" + todo_row_tot_data.tot}
          </span>
        );
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": select_seq_arr,
      "s_stu_info_arr": selected_row,
    });
  };

  return (
    <div>
      <div className="relative" >
        <div className="relative py-3 text-center" style={{ lineHeight: "20px" }}>
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={pageData.listOpt.s_stu_lecture_seq}
            on_change_input={(inData: any) => {
              let name = inData.name;
              let value = inData.value;
              list({ now_page: 1, [name]: value });
            }}
            wrap_style={{ width: 90, display: "inline-table" }}
            select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
          ></SearchLectureSelect>
          <select className="search-input ml-1" name="s_stu_grade" value={pageData.listOpt.s_stu_grade}
            onChange={handleInputChange} style={{ width: 56, height: 29 }} >
            <option value="">학년</option>
            {xColumnArr.select_arr["a_stu_grade"].map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.value} >{item.text}</option>
              );
            })}
          </select>
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = pageData.listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {pageData.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <input type="text" className="search-input ml-1" name="s_search_text"
            style={{ width: 40, padding: 4 }} placeholder="이름" value={pageData.listOpt.s_search_text}
            onChange={handleInputChange} onKeyUp={(e: any) => { list({ "now_page": 1 }); }} />
          <button onClick={() => { list({ "now_page": 1 }); }} style={{ background: "#7470F9", padding: 4, borderTop: "1px solid #7470F9" }} >
            <img src={search_ico_img} style={{ height: 18 }} />
          </button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: false,
          height: (window.innerHeight / 5) * 4,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
    </div>
  );
});

export default StuSearchList;