import CompLayout from "@/pcomponents/comp/layout/layout";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useState, useEffect } from 'react';

function DataMainPage(){
  let user=useSelector((state:any) => state.user);
  let [row_user_grade,set_row_user_grade]=useState(user.user_grade);

  useEffect(() => {
    get_group_manager_by_ajax();
  }, [user]);

  const get_group_manager_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length>0) {
            set_row_user_grade("super");
          }
        } else {

        }
      });
  };

  let is_able_move_btn=false;
  if(!strFunc.is_empty(user.comp_id)&&!strFunc.is_empty(user.user_seq)){
    is_able_move_btn=true;
  }

  const open_data_page_popup=()=>{
    if(!is_able_move_btn){
      alert("로그인 정보가 없습니다.");
      return false;
    }
    // let url_str=process.env.REACT_APP_DATA_URL+"/login";
    // let url_data="?comp_id="+user.comp_id+"&user_id="+user.user_id;

    let url_str="https://wdata.planiedu.com";
    window.open(url_str,"_blank");
  };
  
  const open_manual_page_popup=(sort:any)=>{
    let url_str=process.env.REACT_APP_API_URL+"/view/home/manual/page/manager";
    if(row_user_grade=="master"){
      url_str=process.env.REACT_APP_API_URL+"/view/home/manual/page/director";
    }
    if(row_user_grade==="super"){
      url_str=process.env.REACT_APP_API_URL+"/view/home/manual/manage";
    }
    if(sort==="student"){
      url_str=process.env.REACT_APP_API_URL+"/view/home/manual/page/student";
    }
    
    window.open(url_str,"_blank");
  };

  return (
    <CompLayout>
      <div className="mt-5 text-center">
        <button className="btn btn-sky" onClick={open_data_page_popup}>자료실 열기</button>
      </div>
      <div className="mt-5 text-center">
        <button className="btn btn-dark ml-2" onClick={()=>{open_manual_page_popup("manager");}}>관리자 메뉴얼</button>
        <button className="btn btn-dark ml-2" onClick={()=>{open_manual_page_popup("student");}}>학생 메뉴얼</button>
      </div>
    </CompLayout>
  );
}

export default DataMainPage;