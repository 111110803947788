import strFunc from "@/lib/lyg/string";
import ArrFunc from '@/lib/lyg/arr_func';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function ReportBoxArea(props: any) {
  let myProps = {
    stu_info: {},
    stu_info_arr: [],
    set_stu_info_arr: (inData: any) => { },
    Style: {},
    check_seq_arr: [],
    set_check_seq_arr: (inData: any) => { },
    copy_clipboard_report: (inData: any) => { },
    wrtie_counsel_todo_by_ajax: (inData: any) => { },
    open_view_counsel_todo: (inData: any) => { },
    ...props
  };

  let stu_info = myProps.stu_info;
  let daily_report = null;
  if (stu_info.daily_report) {
    daily_report = stu_info.daily_report;
  }
  let Style = myProps.Style;
  let is_checked_row = false;
  if (strFunc.str_in_array(stu_info["a_seq"], myProps.check_seq_arr) != -1) {
    is_checked_row = true;
  }
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let tmp_school_name = stu_info["a_school_name"].substring(0, 2);
  let tmp_stu_grade = "";
  for (let i = 0; i < select_stu_grade_arr.length; i++) {
    if (select_stu_grade_arr[i]["value"] == stu_info["a_stu_grade"]) {
      tmp_stu_grade = select_stu_grade_arr[i]["text"];
    }
  }

  const on_change_checkbox = (e: any) => {
    let tmp_arr = [...myProps.check_seq_arr];
    if (e.target.checked) {
      if (strFunc.str_in_array(stu_info["a_seq"], tmp_arr) == -1) {
        tmp_arr.push(stu_info["a_seq"]);
      }
    } else {
      tmp_arr = ArrFunc.get_remove_arr_by_value({
        arr: tmp_arr,
        except_val_arr: [stu_info["a_seq"]]
      });
    }
    myProps.set_check_seq_arr(tmp_arr);
  };

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_stu_info = { ...stu_info };
    change_stu_info[name] = value;

    let change_stu_info_arr = [...myProps.stu_info_arr];
    for (let i = 0; i < change_stu_info_arr.length; i++) {
      if (change_stu_info_arr[i]["a_seq"] == stu_info["a_seq"]) {
        change_stu_info_arr[i] = change_stu_info;
        break;
      }
    }
    if (name == "comment") {
      strFunc.set_storage("daily_report_comment", value);
    }
    myProps.set_stu_info_arr(change_stu_info_arr);
  };

  let text_area_style: any = {
    padding: "5px 3px", fontSize: "16px", lineHeight: "25px",
    border: "1px solid #ddd"
  };
  let text_area_is_read_only = false;
  let counsel_add_btn_style: any = {};
  if (!strFunc.is_empty(stu_info["counsel_todo"])) {
    counsel_add_btn_style = { color: "#fff", background: "gray" };
    text_area_is_read_only = true;
  }

  return (
    <div className={Style.card_box_con_box}>
      <div className={Style.stu_top_con}>
        <label>
          <input type="checkbox" onChange={on_change_checkbox} checked={is_checked_row}
            style={{ width: "17px", height: "17px" }} />
          <span className="ml-2">
            {stu_info["a_user_name"]}
          </span>
          <span className="ml-2">
            {tmp_school_name}{tmp_stu_grade}
          </span>
          <span className="ml-2" style={{ "color": "#00968b", fontSize: "15px" }}>
            {stu_info["a_stu_total_code"]}
          </span>
        </label>
        {text_area_is_read_only == false &&
          <button className="btn btn-dark"
            style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={() => {
              myProps.wrtie_counsel_todo_by_ajax({
                select_stu_info_arr: [stu_info],
                is_study_prescribe: true
              });
            }}
          >학습처방상담</button>
        }
      </div>
      <div>
        {text_area_is_read_only == false &&
          <TextAreaComponent
            name="report"
            value={stu_info["report"]}
            onChange={(e: any) => {
              on_change_input(e);
            }}
            class_name={Style.report_msg_textarea}
            placeholder=""
            style={text_area_style}
            scroll_add_height={4}
            autoComplete="off"
            is_auto_height={true}
            is_read_only={text_area_is_read_only}
          ></TextAreaComponent>
        }
        {text_area_is_read_only == true &&
          <div className={Style.report_msg_textarea + " " + Style.disable_msg_back}>
            <MultyLineTextView
              text={stu_info["counsel_todo"]["a_content"].replace(stu_info["counsel_todo"]["a_reference"], "")}
            ></MultyLineTextView>
          </div>
        }
      </div>
      <div className={Style.report_daily_report_wrap}>
        <div className={Style.report_daily_report_title}>
          MEMO.
        </div>
        <div className={Style.report_daily_report_memo}>
          {stu_info.daily_report_arr &&
            stu_info.daily_report_arr.map((item: any, idx: number) => {
              return (
                <MultyLineTextView key={idx}
                  text={"(" + item["a_subject"] + " " + item["a_lecture_time"] + ") " + item["a_memo"] + (item["a_study_amount_memo"] ? "\n" + item["a_study_amount_memo"] : "")}
                ></MultyLineTextView>
              )
            })
          }
        </div>
      </div>
      <div>
        Comment
      </div>
      <div>
        {text_area_is_read_only == false &&
          <TextAreaComponent
            name="comment"
            value={stu_info["comment"]}
            onChange={(e: any) => {
              on_change_input(e);
            }}
            class_name={Style.comment_msg_textarea}
            placeholder=""
            style={text_area_style}
            scroll_add_height={4}
            autoComplete="off"
            is_auto_height={true}
            is_read_only={text_area_is_read_only}
          ></TextAreaComponent>
        }
        {text_area_is_read_only == true &&
          <div className={Style.comment_msg_textarea + " " + Style.disable_msg_back}>
            <MultyLineTextView
              text={stu_info["counsel_todo"]["a_reference"]}
            ></MultyLineTextView>
          </div>
        }
      </div>
      <div className="text-right mt-1">
        <button className="btn btn-dark"
          onClick={() => {
            myProps.copy_clipboard_report({
              select_stu_info_arr: [stu_info]
            });
          }}>복사</button>
        <button className="btn btn-orange ml-2"
          style={counsel_add_btn_style}
          onClick={() => {
            if (strFunc.is_empty(stu_info["counsel_todo"])) {
              myProps.wrtie_counsel_todo_by_ajax({
                select_stu_info_arr: [stu_info]
              });
            } else {
              myProps.open_view_counsel_todo({
                selected_stu_arr: [stu_info],
                select_date: stu_info["counsel_todo"]["a_reserve_date"],
              });
            }
          }}>
          {strFunc.is_empty(stu_info["counsel_todo"]) ? "상담등록" : "상담등록됨"}
        </button>
      </div>
    </div>
  );
};
export default ReportBoxArea;