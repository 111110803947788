import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import TimeHisInput from "@/pcomponents/common/date/time_his";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function WriteArea(props: any) {
  let myProps = {
    rowData: {},
    setRowData: (inData:any) => { },
    xColumnArr: {},
    ...props
  };
  let rowData = myProps.rowData;
  let xColumnArr = myProps.xColumnArr;
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  
  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    myProps.setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data: any = {
      ...rowData,
      ...{ [key]: date_str }
    };
    if (key == "a_reserve_start_date") {
      change_row_data["a_reserve_end_date"] = date_str;
    }
    myProps.setRowData(change_row_data);
  };

  return (
    <div className="write_table_small mt-1">
      <table>
        <colgroup>
          <col width="20%"></col>
          <col width="30%"></col>
          <col width="20%"></col>
          <col width="30%"></col>
        </colgroup>
        <tbody>
          <tr>
            <th>원생</th>
            <td>{rowData.a_stu_name}</td>
            <th>작성자</th>
            <td>{rowData.a_writer_name}</td>
          </tr>
          <tr>
            <th>구분</th>
            <td>
              <select className="row-input" name="a_sort" value={rowData.a_sort} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr.a_sort.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
            <th>과목</th>
            <td>
              <input type="text" className="row-input" name="a_subject"
                value={rowData.a_subject} onChange={handleInputChange} placeholder="과목" style={{ width: "80px" }} />
              <select className="row-input ml-1" name="a_subject" value={rowData.a_subject}
                onChange={handleInputChange}
                style={{ width: 70 }} >
                <option value="">과목</option>
                {
                  subject_option_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item.value} key={idx}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <th>예정일</th>
            <td colSpan={3} className="text-center">
              <div style={{ width: 100, display: "inline-block" }}>
                <DatePicker
                  selected={rowData.a_reserve_start_date != "" ? new Date(rowData.a_reserve_start_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_reserve_start_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </div>
              ~
              <div style={{ width: 100, display: "inline-block" }}>
                <DatePicker
                  selected={rowData.a_reserve_end_date != "" ? new Date(rowData.a_reserve_end_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_reserve_end_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan={3}>
              <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan={3}>
              <TextAreaComponent
                name={"a_content"}
                value={rowData.a_content}
                onChange={handleInputChange}
                placeholder={"내용"}
              ></TextAreaComponent>
            </td>
          </tr>
          <tr>
            <th>완료</th>
            <td>
              <select className="row-input" name="a_is_success" value={rowData.a_is_success} onChange={handleInputChange}>
                <option value="">아니오</option>
                <option value="1">예</option>
              </select>
            </td>
            <th>상태</th>
            <td >
              <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr.a_state.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <th>목표구분</th>
            <td>
              <select className="row-input" name="a_success_sort" value={rowData.a_success_sort} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr.a_success_sort.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
            <th>사진필요</th>
            <td>
              <select className="row-input" name="a_is_need_photo" value={rowData.a_is_need_photo}
                onChange={handleInputChange} style={{ width: 100 }}>
                <option value="">아니오</option>
                <option value="1">예</option>
              </select>
            </td>
          </tr>
          {rowData.a_success_sort == "amount" ?
            <tr>
              <th>달성량</th>
              <td>
                <input type="text" className="row-input" name="a_amount_num"
                  value={rowData.a_amount_num} onChange={handleInputChange} placeholder="달성량"
                  style={{ width: 60, textAlign: "right" }} />
                <input type="text" className="row-input ml-1" name="a_amount_sort"
                  value={rowData.a_amount_sort} onChange={handleInputChange} placeholder="단위"
                  style={{ width: 50 }} />
              </td>
              <th>목표량</th>
              <td>
                <input type="text" className="row-input" name="a_success_amount"
                  value={rowData.a_success_amount} onChange={handleInputChange} placeholder="목표량"
                  style={{ width: 60, textAlign: "right" }} />
              </td>
            </tr>
            :
            <tr>
              <th>달성시간</th>
              <td>
                <TimeHisInput
                  is_after_name={true}
                  value={rowData.a_time_sec}
                  onChangeData={(value: any) => {
                    handleInputChange({
                      target: {
                        name: "a_time_sec",
                        value: value,
                        type: "text",
                        checked: false
                      }
                    });
                  }}
                ></TimeHisInput>
              </td>
              <th>목표시간</th>
              <td>
                <TimeHisInput
                  is_after_name={true}
                  value={rowData.a_success_time_sec}
                  onChangeData={(value: any) => {
                    handleInputChange({
                      target: {
                        name: "a_success_time_sec",
                        value: value,
                        type: "text",
                        checked: false
                      }
                    });
                  }}
                ></TimeHisInput>
              </td>
            </tr>
          }
          <tr>
            <th>시작종료일</th>
            <td colSpan={3}>
              <div>
                시작:{rowData.a_start_date}
              </div>
              <div>
                종료:{rowData.a_end_date}
              </div>
              <div>
                최근시작:{rowData.a_start_date_last}
              </div>
            </td>
          </tr>
          <tr>
            <th>결과메모</th>
            <td colSpan={3}>
              <TextAreaComponent
                name={"a_result_memo"}
                value={rowData.a_result_memo}
                onChange={handleInputChange}
                placeholder={"결과메모"}
              ></TextAreaComponent>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WriteArea;