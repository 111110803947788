//import MonthPrescribeWriteFunc from "@/pcomponents/common/content/edu/month_prescribe/write/write_func";
import strFunc from '@/lib/lyg/string';

class MonthPrescribeWriteFunc {
  //몇주차 얻기
  static check_write_data_row(inData: any) {
    let opt_obj = {
      info: {},
      last_daily_report: null,
      ...inData
    };
    let info = opt_obj["info"];
    let last_daily_report = opt_obj["last_daily_report"];
    
    let result_data:any={
      is_able:true,
      msg:"",
    };

    let check_info_key_json:any={
      a_stu_seq:{"msg":"학생 선택이 필요합니다."},
      a_writer_seq:{"msg":"작성자 정보 없음."},
      a_subject_name:{"msg":"과목선택 필요."},
      // a_progress_reason:{"msg":"진도전략 입력이 필요합니다."},
      a_grow:{"msg":"성장한 부분 입력이 필요합니다."},
      a_homework_degree:{"msg":"숙제 수행도 입력이 필요합니다."},
      a_understand_degree:{"msg":"이해점수 입력이 필요합니다."},
      a_lesson_attitude:{"msg":"수업태도 입력이 필요합니다."},
      a_study_emotion:{"msg":"학습정서(감정) 입력이 필요합니다."},
      a_lack:{"msg":"새로운 발견 입력이 필요합니다."},
    };
    let check_daily_report_key_json:any={
      a_textbook:{"msg":"일일보고서 본교재 입력이 필요합니다."},
      // a_subtextbook:{"msg":"일일보고서 부교재 입력이 필요합니다."},
      a_textbook_study:{"msg":"일일보고서 대단원 입력이 필요합니다."},
      a_textbook_study_det:{"msg":"일일보고서 소단원 입력이 필요합니다."},
      a_homework:{"msg":"일일보고서 숙제 입력이 필요합니다."},
      a_study_amount_memo:{"msg":"일일보고서 학습량 입력이 필요합니다."},
      // a_memo:{"msg":"일일보고서 일일메모 입력이 필요합니다."},
    };
    let update_basice_key_arr=[
      "a_stu_seq",
      "a_writer_seq",
      "a_subject_name",
    ];

    for(let key in check_info_key_json){
      if(strFunc.is_empty(info["a_seq"])||info["a_is_reserve"]=="1"){
        if(strFunc.is_empty(info[key])){
          result_data.is_able=false;
          result_data.msg=check_info_key_json[key]["msg"];
          return result_data;
        }
      }else{
        if(strFunc.str_in_array(key,update_basice_key_arr)!=-1){
          if(strFunc.is_empty(info[key])){
            result_data.is_able=false;
            result_data.msg=check_info_key_json[key]["msg"];
            return result_data;
          }
        }
      }
    }

    if(strFunc.is_empty(info["a_seq"])||info["a_is_reserve"]=="1"){
      if(strFunc.is_empty(last_daily_report)){
        result_data.is_able=false;
        result_data.msg="일일보고서를 입력해주세요.";
        return result_data;
      }
    }

    if(strFunc.is_empty(info["a_seq"])||info["a_is_reserve"]=="1"){
      for(let key in check_daily_report_key_json){
        if(strFunc.is_empty(last_daily_report[key])){
          result_data.is_able=false;
          result_data.msg=check_daily_report_key_json[key]["msg"];
          return result_data;
        }
      }
    }

    return result_data;
  }
}
export default MonthPrescribeWriteFunc;