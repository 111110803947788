import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./search";
import StuMapListArea from "@/pages/comp/plan/study/study_map/common/stu_map/right/stu_map_list";

function StuStudyMapArea(props: any) {
  let myProps = {
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    "s_stu_state": "now",
    s_stu_grade: "",
    s_class_seq: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_study_map: "1",
    s_addon_study_map_s_order_recent: "1",
    s_addon_study_map_det: "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const StuMapListAreaRef=useRef<any>();

  const list = (inOptObj: any) => {
    let change_list_opt: any = {
      ...listOpt,
      ...inOptObj,
    };
    change_list_opt["sc"] = listAggridRef.current.getListSc();
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (listAggridRef.current) {
            listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          }
        } else {

        }
      });
  };

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "a_user_name") {
      render_str += " " + params.data.a_stu_total_code;
    } else if (key == "row_view_manage") {

    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_grade") {
      render_str = strFunc.cut_str(params.data["a_school_name"], 2, "");
      let grade_str = "";
      for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
        if (xColumnArr.select_arr["a_stu_grade"][i]["value"] == params.data["a_stu_grade"]) {
          grade_str = xColumnArr.select_arr["a_stu_grade"][i]["text"];
        }
      }
      render_str += " " + grade_str;
    } else if (key == "row_view_map_stu") {
      if (params.data.study_map_arr) {
        render_str = (
          <div className="text-center">
            {params.data.study_map_arr.length}
          </div>
        );
      }
    }
    return render_str;
  };

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if(selected_row.length>0){
      if(StuMapListAreaRef.current){
        StuMapListAreaRef.current.setInitData({
          stu_seq:selected_row[0]["a_seq"],
          select_stu_info_arr:selected_row
        });
      }
    }
  };

  return (
    <div>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col width={"25%"} />
          <col width={"*"} />
        </colgroup>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <div style={{ minWidth: 380 }} >
                <SearchArea
                  listOpt={listOpt}
                  setListOpt={setListOpt}
                  list={list}
                  xColumnArr={xColumnArr}
                ></SearchArea>
                <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{ ...xColumnArr }}
                  list={list}
                  gridOpt={{
                    fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num: false,
                    is_add_checkbox: true,
                    floatingFilter: false,
                    onGridReady: () => {
                      list({});
                    },
                    cellRenderer: cellRenderer,
                    onSelectionChanged: onSelectionChanged
                  }}
                ></ListAggrid>
                <Paging now_page={listOpt.now_page}
                  num_per_page={listOpt.num_per_page}
                  total_rec={countInfo.tot}
                  onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
              </div>
            </td>
            <td style={{ verticalAlign: "top" }}>
              <div style={{ minWidth: 400, paddingLeft: 20 }}>
                <StuMapListArea
                  ref={StuMapListAreaRef}
                  list={list}
                ></StuMapListArea>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div >
  );
};
export default StuStudyMapArea;