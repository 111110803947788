import DailyLastArea from "@/pages/comp/edu/month_prescribe/common/write/area/daily_last";

function ViewDailyReportContentArea(props:any){
   let myProps={
    daily_report:{},
    ...props
  };
  let daily_report=myProps.daily_report;
  
  return (
  <div>
    <DailyLastArea
      last_daily_report={daily_report}
    ></DailyLastArea>
  </div>
  );
};
export default ViewDailyReportContentArea;