import CompLayout from "@/pcomponents/comp/layout/layout";
import PaytSupplyHistoryListArea from "@/pages/comp/supply/payt/payt_supply/common/history_list";

function PaytSuppyListPage(props: any) {
  let myProps = {
    ...props
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <PaytSupplyHistoryListArea></PaytSupplyHistoryListArea>
      </div>
    </CompLayout>
  );
};
export default PaytSuppyListPage;