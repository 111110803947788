import Style from "../../../../css/style.module.css";

const CardBoxTodoPerArea = (props: any) => {
  let myProps: any = {
    user_info: {},
    todo_stat_data: {},
    todo_color: "green",
    base_date_str: "",
    pre_date_str: "",
    openViewTodoDayPopup: (inData: any) => { },
    ...props
  };
  let user_info = myProps.user_info
  let base_date_str = myProps.base_date_str;
  let pre_date_str = myProps.pre_date_str;

  let count_div_str: any = "";
  if (myProps.todo_stat_data["total"]["count"]) {
    count_div_str = (
      <div className="text-center" style={{ lineHeight: "8px", position: "absolute", right: 10, top: 10, color: "12px" }}>
        과제: {myProps.todo_stat_data["total"]["success_cnt"]}
        /
        {myProps.todo_stat_data["total"]["count"]}
      </div>
    );
  }

  const get_home_work_file_cnt = (date_sort: "pre" | "now") => {
    let row_file_cnt = 0;
    if (user_info.home_work_file_arr && user_info.home_work_file_arr.length > 0) {
      for (let i = 0; i < user_info.home_work_file_arr.length; i++) {
        if (date_sort == "pre") {
          if (user_info.home_work_file_arr[i]["a_create_date"].substring(0, 10) >= pre_date_str
            && user_info.home_work_file_arr[i]["a_create_date"].substring(0, 10) < base_date_str) {
            row_file_cnt++;
          }
        } else if (date_sort == "now") {
          if (user_info.home_work_file_arr[i]["a_create_date"].substring(0, 10) == base_date_str) {
            row_file_cnt++;
          }
        }
      }
    }
    return row_file_cnt;
  };
  let pre_homework_file_cnt = get_home_work_file_cnt("pre");

  return (
    <div className={Style.card_box_todo}>
      {count_div_str}
      <table>
        <colgroup>
          <col width={"30%"}></col>
          <col width={"70%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th style={{ whiteSpace: "nowrap", cursor: "pointer" }}
              onClick={() => {
                myProps.openViewTodoDayPopup({
                  stu_info: myProps.user_info
                });
              }}>
              학습({myProps.todo_stat_data.study.percent}%)
              {(user_info.plan_study_file_arr && user_info.plan_study_file_arr.length > 0)  &&
                <span className={Style.mid_todo_row_home_work_file_ico}>
                  {user_info.plan_study_file_arr.length}
                </span>
              }
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{ width: myProps.todo_stat_data.study.percent + "%", background: myProps.todo_color }}></div>
              </div>
            </td>
          </tr>
          <tr>
            <th style={{ whiteSpace: "nowrap", cursor: "pointer" }}
              onClick={() => {
                myProps.openViewTodoDayPopup({
                  stu_info: myProps.user_info
                });
              }}>
              강의({myProps.todo_stat_data.video.percent}%)
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{ width: myProps.todo_stat_data.video.percent + "%", background: myProps.todo_color }}></div>
              </div>
            </td>
          </tr>
          {/* <tr>
            <th>
              과제({myProps.todo_stat_data.homework.percent}%)
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{width:myProps.todo_stat_data.homework.percent+"%",background:myProps.todo_color}}></div>
              </div>
            </td>
          </tr> */}
          <tr>
            <th style={{ whiteSpace: "nowrap", cursor: "pointer" }}
              onClick={() => {
                myProps.openViewTodoDayPopup({
                  stu_info: myProps.user_info,
                  select_date: myProps.pre_date_str
                });
              }}
            >
              숙제({myProps.todo_stat_data.pre_homework.percent}%)
              {pre_homework_file_cnt > 0 &&
                <span className={Style.mid_todo_row_home_work_file_ico}>
                  {pre_homework_file_cnt}
                </span>
              }
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{ width: myProps.todo_stat_data.pre_homework.percent + "%", background: myProps.todo_color }}></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CardBoxTodoPerArea;