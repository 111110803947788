import { useState,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import * as XLSX from "xlsx-js-style";
import DateFunc from '@/lib/lyg/date_func';

const ExcelDownComponent = forwardRef((props:any, ref) => {
  let myProps:any={
    xColumnArr:{},
    is_add_example:true,
    customCellRenderer:(inOpt:any)=>{
      let opObj={
        "rowData":{},
        "idx":0,
        "key":"",
        "value":"",
        ...inOpt
      };
      return opObj.value;
    },
    excelFileType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    excelFileExtension:'.xlsx',
    excelFileName:'데이터'+DateFunc.get_date_format(new Date(),"Ymd_his"),
    ...props
  };
  
  const [infoArr,setInfoArr]=useState<any>([]);
  const [xColumnArr,setXColumnArr]=useState<any>(myProps.xColumnArr);
  const [is_add_example,set_is_add_example]=useState<boolean>(myProps.is_add_example);
  const [excelFileName,setExcelFileName]=useState<any>(myProps.excelFileName);

  const setInitData=(inData:any)=>{
    let optObj={
      xColumnArr:xColumnArr,
      is_add_example:is_add_example,
      infoArr:infoArr,
      excelFileName:"데이터"+DateFunc.get_date_format(new Date(),"Ymd_his"),
      ...inData
    };

    setXColumnArr(optObj.xColumnArr);
    setExcelFileName(optObj.excelFileName);
    set_is_add_example(optObj.is_add_example);
    setInfoArr(optObj.infoArr);
  };

  const getExampleRowArr=()=>{
    let example_arr=[];
    for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
      let column=xColumnArr.x_column_list_arr[i];
      let val_str="문자";
      if(xColumnArr.select_arr[column.key]!=undefined){
        let tmp_select_val_arr:any=[];
        for(let select_i=0;select_i<xColumnArr.select_arr[column.key].length;select_i++){
          let tmp_option=xColumnArr.select_arr[column.key][select_i];
          tmp_select_val_arr.push(tmp_option.value+":"+tmp_option.text);
        }
        val_str=tmp_select_val_arr.join("\n");//String.fromCharCode(10)
      }else  if(strFunc.str_in_array(column.key,xColumnArr.date_col_arr)!=-1){
        val_str="날짜(0000-00-00)";
      }else  if(strFunc.str_in_array(column.key,xColumnArr.number_col_arr)!=-1){
        val_str="숫자";
      }
      example_arr.push(val_str);
    }
    return example_arr;
  };

  const goDownExcel=()=>{
    let excel_head_arr=[];
    let width_arr=[];
    for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
      excel_head_arr.push(xColumnArr.x_column_list_arr[i]["name"]);
      let width_size=parseInt(strFunc.getNumber2(xColumnArr.x_column_list_arr[i]["width"]));
      if(width_size>0){
        width_size=width_size;
      }
      width_arr.push(
        {wpx:width_size}
      );
    }
    
    let init_row_head=[
      excel_head_arr
    ];
    if(is_add_example){
      init_row_head.push(getExampleRowArr());
    }
    
    //제목설정
    const ws = XLSX.utils.aoa_to_sheet(init_row_head);
    //크기설정
    ws['!cols'] = width_arr;
    //높이
    var wsrows=[{hpx: 30}];
    if(is_add_example){
      wsrows=[{hpx: 30},{hpx: 100}];
    }
    ws['!rows'] = wsrows;

    let header_row_i=1;
    if(is_add_example){
      header_row_i=2;
    }

    //데이터넣기
    for(let row_i=0;row_i<infoArr.length;row_i++){
      let rowData=infoArr[row_i];
      let addDataArr=[];
      for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
        let val_str="";
        let key=xColumnArr.x_column_list_arr[i]["key"];
        if(rowData[key]!=undefined){
          val_str=rowData[key];
        }
        if(strFunc.str_in_array(key,xColumnArr.number_col_arr)!=-1){
          val_str=strFunc.uncomma(val_str);
        }
        if(strFunc.str_in_array(key,xColumnArr.date_col_arr)!=-1){
          val_str=strFunc.str_replace("-","",val_str);
        }

        val_str=myProps.customCellRenderer({
          "rowData":rowData,
          "idx":row_i,
          "key":key,
          "value":val_str,
        });

        addDataArr.push(val_str);
      }
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          addDataArr
        ],
        {origin: -1}
      );
    }
    //ABC 배열 얻기
    let abc_arr=strFunc.get_abc_arr(width_arr.length);

    //border넣기
    let border_1={
      top:{
        style: "thin", color: {rgb: "666666"}
      },
      bottom:{
        style: "thin", color: {rgb: "666666"}
      },
      left:{
        style: "thin", color: {rgb: "666666"}
      },
      right:{
        style: "thin", color: {rgb: "666666"}
      },
    };
    let all_row_i=infoArr.length+header_row_i;
    for(let row_i=0;row_i<all_row_i;row_i++){
      for(let i=0;i<abc_arr.length;i++){
        if(ws[abc_arr[i]+(row_i+1)]!=undefined){
          ws[abc_arr[i]+(row_i+1)].s={
            alignment:{
              horizontal:"center",
              wrapText: true 
            },
            border:border_1
          };
        }
      }
    }

    //header 굵게
    for(let row_i=0;row_i<header_row_i;row_i++){
      for(let i=0;i<abc_arr.length;i++){
        ws[abc_arr[i]+(row_i+1)].s={
          alignment:{
            horizontal:"center",
            vertical: "center",
            wrapText: true 
          },
          border:border_1,
          font:{bold:true},
          fill: { fgColor: { rgb: "DCE6F1" } }
        };
      }
    }

    const wb: any = {Sheets: { data: ws }, SheetNames: ['data']};
    XLSX.writeFile(wb, excelFileName + myProps.excelFileExtension);
  };

  useImperativeHandle(ref, () => ({
    setInitData,
    goDownExcel
  }));

  return (
    <>
    </>
  );
});
export default ExcelDownComponent;