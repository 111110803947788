import box_check_icon from "@/img/ico/box_check.png";
import box_empty_icon from "@/img/ico/box_empty.png";
import strFunc from "@/lib/lyg/string";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import { useRef } from 'react';

function TodoDetailList(props: any) {
  let myProps = {
    Style: {},
    user_info: {},
    study_sort: "study",
    base_date_str: "",
    pre_date_str: "",
    openTodoWriteOnePopup: (inData: any) => { },
    go_change_todo_state_by_ajax: (inData: any) => { },
    ...props
  };

  let Style = myProps.Style;
  let user_info = myProps.user_info;
  let study_sort = myProps.study_sort;
  let base_date_str = myProps.base_date_str;
  let pre_date_str = myProps.pre_date_str;
  let row_todo_info_arr: any = [];
  let more_todo_info_arr: any = [];
  const more_todo_list_ref = useRef<any>(null);
  if (user_info.todo_arr && user_info.todo_arr.length > 0) {
    let todo_len = myProps.user_info.todo_arr.length;
    for (let i = 0; i < todo_len; i++) {
      let todo_info = user_info.todo_arr[i];
      if(study_sort!="pre_homework"){
        if (todo_info["a_reserve_start_date"].substring(0, 10) == base_date_str) {
          if (todo_info["a_sort"] == study_sort) {
            row_todo_info_arr.push(todo_info);
          }
        }
      }else if(study_sort=="pre_homework"){
        if (todo_info["a_reserve_start_date"].substring(0, 10) >= pre_date_str
        && todo_info["a_reserve_start_date"].substring(0, 10) < base_date_str){
          if (todo_info["a_sort"] == study_sort) {
            row_todo_info_arr.push(todo_info);
          }
        }
      }
    }
    //6개 이상 이면 6개만 보여준다.
    if (todo_len > 6) {
      let tmp_row_todo_info_arr = [];
      for (let i = 0; i < row_todo_info_arr.length; i++) {
        if (i < 6) {
          tmp_row_todo_info_arr.push(row_todo_info_arr[i]);
        } else {
          more_todo_info_arr.push(row_todo_info_arr[i]);
        }
      }
      row_todo_info_arr = tmp_row_todo_info_arr;
    }
  }

  const get_todo_detail_tags = (inData: any) => {
    let opt_obj = {
      view_todo_info_arr: [],
      ...inData
    };
    let todo_detail_tags = opt_obj.view_todo_info_arr.map((todo_info: any, idx: number) => {
      let row_stat_data = TodoStateFunc.get_stat_data_by_todo_info(todo_info);
      let todo_color_json = TodoStateFunc.get_color_of_percent(row_stat_data.percent);
      if (strFunc.is_empty(todo_info["a_amount_num"])) {
        todo_color_json.per = "#444";
      }

      let todo_subject = "";
      if (!strFunc.is_empty(todo_info["a_subject"])) {
        todo_subject = "[" + todo_info["a_subject"] + "]";
      }

      let todo_content_str = todo_subject + todo_info["a_title"];
      let todo_amount_str = " (" + todo_info["a_amount_num"] + "/" + todo_info["a_success_amount"] + ")";
      return (
        <div key={idx} className={Style.todo_detail_row} >
          <div className={Style.todo_detail_ico} 
            onClick={()=>{
              myProps.go_change_todo_state_by_ajax({
                todo_info: todo_info,
                is_success: todo_info["a_is_success"] == "1" ? "" : "1",
              });
            }}>
            <img src={todo_info["a_is_success"] == "1" ? box_check_icon : box_empty_icon} alt="check" />
          </div>
          <div className={Style.todo_detail_content} title={todo_content_str + todo_amount_str}
            onClick={() => {
              myProps.openTodoWriteOnePopup({
                todo_info: todo_info,
                isUpdate: true,
              });
            }}>
            <input type="text" value={todo_content_str} onChange={() => { }}
              style={{ width: "100%", border: 0, background: "transparent", color: todo_color_json.per }} />
          </div>
        </div>
      );
    });
    return todo_detail_tags;
  };

  return (
    <div onMouseEnter={() => {
      if (more_todo_info_arr.length > 0) {
        more_todo_list_ref.current.style.display = "block";
      }
    }}
      onMouseLeave={() => { more_todo_list_ref.current.style.display = "none" }}>
      <div className={Style.todo_detail_list} >
        {get_todo_detail_tags({ view_todo_info_arr: row_todo_info_arr })}
      </div>
      {more_todo_info_arr.length > 0 &&
        <div className={Style.todo_detail_list}
          style={{ width: "100%", position: "absolute", display: "none" }}
          ref={more_todo_list_ref}>
          {get_todo_detail_tags({ view_todo_info_arr: more_todo_info_arr })}
        </div>
      }
    </div>
  );
};
export default TodoDetailList;