import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteOptPopupContent from "@/pcomponents/common/xcolumn/popup/write_opt";
import SearchArea from "./area/search";
import DiscoverListExcelDown from "./area/excel_down";

const DiscoverList = forwardRef((props: any, ref) => {
  let myProps = {
    stu_list: (inData: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  const listAggridRef = useRef<any>();
  const DiscoverListExcelDownRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
    "s_stu_seq": [],
    "s_writer_seq": "",
    "s_addon_user": "1",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [teacher_arr, set_teacher_arr] = useState([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "list_opt",//view
    title: "팝업",
    width: "800px",
    height: "99%",
  });

  useEffect(() => {
    get_teacher_list();
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }));

  const list = (inOptObj: any) => {
    let change_list_opt={
      ...listOpt,
      ...inOptObj
    };
    change_list_opt["sc"] = listAggridRef.current.getListSc();
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_list = () => {
    let teacher_list_form_json = {
      "s_is_login": "1",
      "s_grade": ["teacher", "master", "staff"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', teacher_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_excel_down = () => {
    if (DiscoverListExcelDownRef.current) {
      DiscoverListExcelDownRef.current.goExcelDown();
    }
  };

  const openListOptPopup = () => {
    let pop_title = "리스트설정";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "list_opt",
      width: "800px",
      height: "99%",
    });
  };

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_stu_name") {
      if (params.data.stu_info) {
        let tmp_stu_grade = "";
        for (let i = 0; i < select_stu_grade_arr.length; i++) {
          if (select_stu_grade_arr[i]["value"] == params.data.stu_info["a_stu_grade"]) {
            tmp_stu_grade = select_stu_grade_arr[i]["text"];
          }
        }
        render_str = render_str + " " + tmp_stu_grade;
      }
    }

    if (key != "a_memo") {
      render_str = (
        <span title={render_str}>
          {render_str}
        </span>
      );
    }

    return render_str;
  }, []);

  return (
    <div>
      <div className="relative" style={{ minHeight: 57 }}>
        <SearchArea
          xColumnArr={xColumnArr}
          listOpt={listOpt}
          list={list}
          stu_list={myProps.stu_list}
          goDelete={goDelete}
          go_excel_down={go_excel_down}
          teacher_arr={teacher_arr}
          openListOptPopup={openListOptPopup}
        ></SearchArea>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: true,
          col_autoHeight: true,
          onGridReady: () => {
            list({});
          },
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
      <DiscoverListExcelDown
        ref={DiscoverListExcelDownRef}
        xColumnArr={xColumnArr}
        infoArr={infoArr}
      ></DiscoverListExcelDown>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {
            popupData.sort == "list_opt" &&
            <WriteOptPopupContent
              xColumnArr={xColumnArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteOptPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
});
export default DiscoverList;