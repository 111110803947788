function ListHistoryArea(props: any) {
  let myProps = {
    info: {},
    set_info: (inData: any) => { },
    info_arr: [],
    delete_by_ajax: (inData: any) => { },
    ...props
  };
  let info = myProps.info;

  const get_info_tr_tags = () => {
    let info_tr_tags = myProps.info_arr.map((item: any, idx: number) => {
      let tr_style: any = {};
      let is_select = false;
      if (item["a_ymd"] == info["a_ymd"] && item["a_seq"] == info["a_seq"]) {
        is_select = true;
        tr_style["background"] = "green";
        tr_style["color"] = "#fff";
      }

      let state_str: any = "";
      if (item.a_is_req_prescribe == "1") {
        state_str = (
          <span style={{ color: "gray" }}>
            처방요청
          </span>
        );
      }
      if (item.a_is_success == "1") {
        state_str = (
          <span style={{ color: "blue" }}>
            완료
          </span>
        );
      }

      return (
        <tr key={idx} style={tr_style} >
          <td className="text-center" onClick={() => { myProps.set_info(item); }}>{idx + 1}</td>
          <td className="text-center" onClick={() => { myProps.set_info(item); }}>
            {item["a_date"]}
          </td>
          <td className="text-center" onClick={() => { myProps.set_info(item); }}>
            {item["a_writer"]}
          </td>
          <td className="text-center" onClick={() => { myProps.set_info(item); }}>
            {state_str}
          </td>
          <td className="text-center">
            <button className="btn btn-red"
              onClick={() => {
                myProps.delete_by_ajax({ del_info: item });
              }}>삭제</button>
          </td>
        </tr>
      );
    });

    return info_tr_tags;
  };

  return (
    <div className="write_table_small">
      <table>
        <colgroup>
          <col width={"3%"}></col>
          <col width={"10%"}></col>
          <col width={"10%"}></col>
          <col width={"5%"}></col>
          <col width={"5%"}></col>
        </colgroup>
        <thead>
          <tr>
            <th>No</th>
            <th>작성일</th>
            <th>작성자</th>
            <th>상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {get_info_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default ListHistoryArea;