import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { add_memo_select_arr } from "@/pages/comp/basic/student/common/write/data/default_data";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import noimg_img from "@/img/ico/noimg.png";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FileSlideArea from "@/pages/comp/basic/file/popup/view_file_slide";

function CounselInfoTopArea(props: any) {
  let myProps = {
    select_mcomp_data: {
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    info: {},
    add_memo_info: {},
    set_add_memo_info: (inData: any) => { },
    is_update: false,
    is_view_mode: false,
    refresh_data: () => { },
    Style: {},
    ...props
  };
  let select_mcomp_data = myProps.select_mcomp_data;
  let info = myProps.info;
  let add_memo_info = myProps.add_memo_info;
  let is_view_mode = myProps.is_view_mode;
  let user = useSelector((state: any) => state.user);

  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);
  const writeFileForm2 = useRef<any>(null);
  const fileInput2 = useRef<any>(null);
  const [counsel_file_opt, set_counsel_file_opt] = useState({
    select_idx: 0,
    size: 0,
  });
  const downloadFormRef = useRef<HTMLFormElement>(null);
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "view_img",

    select_file_idx: 0,
    img_file_arr: [],

    title: "사진보기",
    width: "100%",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    let tmp_size = 0;
    if (info.counsel_file_arr) {
      tmp_size += info.counsel_file_arr.length;
    }
    if (info.counsel_data_file_arr) {
      tmp_size += info.counsel_data_file_arr.length;
    }
    set_counsel_file_opt({
      select_idx: 0,
      size: tmp_size,
    });
  }, [info.counsel_file_arr, info.counsel_data_file_arr]);

  const handleInputChange2 = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_add_memo_info({
      ...add_memo_info,
      ...{ [name]: value }
    });
  };

  const open_img_view_popup = (inData: any) => {
    let opt_obj = {
      select_file_idx: 0,
      img_file_arr: [],
      ...inData
    };

    setPopupData({
      ...popupData,
      title: "사진보기",
      sort: "view_img",
      ...opt_obj,
      "isOpen": true
    });
  };

  const goDownLoad = (file_info: any) => {
    if (file_info == undefined) {
      alert("파일정보 없음");
      return false;
    }
    let pri_val = file_info["a_ymd"] + "," + file_info["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = file_info["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };

  const goCounselfileWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    let default_file_row_data: any = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": info.a_seq,
      "a_sort1": "counsel",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    if (!strFunc.is_empty(select_mcomp_data.mcomp_seq)) {
      default_file_row_data.a_comp_seq = select_mcomp_data.mcomp_seq;
      default_file_row_data.a_create_seq = select_mcomp_data.api_user_seq;
    }
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    if (!confirm("상담 사진을 저장 하시겠습니까?")) {
      return false;
    }

    var form_json_data = new FormData();
    let file_row_data_arr: any = [];
    for (let i = 0; i < fileInput.current.files.length; i++) {
      let file_info = fileInput.current.files[i];
      form_json_data.append('file_arr[]', file_info);
      file_row_data_arr.push({ ...file_row_data });
    }
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const goCounselDatafileWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    let default_file_row_data: any = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": info.a_seq,
      "a_sort1": "counsel_data",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    if (!strFunc.is_empty(select_mcomp_data.mcomp_seq)) {
      default_file_row_data.a_comp_seq = select_mcomp_data.mcomp_seq;
      default_file_row_data.a_create_seq = select_mcomp_data.api_user_seq;
    }
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput2.current == null) {
      return false;
    }
    if (writeFileForm2.current == null) {
      return false;
    }

    if (fileInput2.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    if (!confirm("상담데이터 사진을 저장 하시겠습니까?")) {
      return false;
    }

    var form_json_data = new FormData();
    let file_row_data_arr: any = [];
    for (let i = 0; i < fileInput2.current.files.length; i++) {
      let file_info = fileInput2.current.files[i];
      form_json_data.append('file_arr[]', file_info);
      file_row_data_arr.push({ ...file_row_data });
    }
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goCounselFileDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("상담 사진을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    }, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        //파일안보이게
        myProps.refresh_data();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  const go_change_counsel_file_idx = (inData: any) => {
    let opt_obj = {
      sort: "+",//+,-
      ...inData
    };
    let counsel_file_arr: any = info.counsel_file_arr;
    if (info.counsel_data_file_arr) {
      counsel_file_arr = [...counsel_file_arr, ...info.counsel_data_file_arr];
    }
    let select_idx = counsel_file_opt.select_idx;
    if (opt_obj.sort == "+") {
      select_idx++;
    } else {
      select_idx--;
    }
    if (select_idx < 0) { select_idx = counsel_file_arr.length - 1;; }
    if (select_idx >= counsel_file_arr.length) { select_idx = 0; }
    set_counsel_file_opt({
      select_idx: select_idx,
      size: counsel_file_arr.length
    });
  };

  const get_counsel_file_tags = () => {
    let counsel_file_arr: any = info.counsel_file_arr;
    if (info.counsel_data_file_arr) {
      counsel_file_arr = [...counsel_file_arr, ...info.counsel_data_file_arr];
    }
    if (strFunc.is_empty(counsel_file_arr)) {
      return "";
    }
    let select_idx = counsel_file_opt.select_idx;
    if (select_idx < 0) { select_idx = 0; }
    if (select_idx >= counsel_file_arr.length) { select_idx = counsel_file_arr.length - 1; }
    let show_file_data = counsel_file_arr[select_idx];

    let thum_img_src = noimg_img;
    if (show_file_data["is_image"] == "1") {
      thum_img_src = show_file_data["thum_img_url"];
    }
    let counsel_file_tags = (
      <div style={{ textAlign: "center", position: "absolute", top: 0, left: 0, width: "100%" }} >
        <a onClick={() => {
          open_img_view_popup({
            select_file_idx: select_idx,
            img_file_arr: counsel_file_arr,
          });
        }} >
          <img src={thum_img_src} style={{ width: "85%", maxWidth: "100%", maxHeight: "420px" }} />
        </a>

        <div>
          {show_file_data.a_oriname}
          {is_view_mode == false &&
            <>
              <button className="btn-s btn-dark ml-1" onClick={() => {
                goDownLoad(show_file_data);
              }}>다운</button>
              <button className="btn-s btn-red ml-1" onClick={() => {
                goCounselFileDelete(show_file_data);
              }}>X</button>
            </>
          }
        </div>

      </div>
    );
    return counsel_file_tags;
  };

  return (
    <div>
      <div className="write_table_small mt-2">
        <table>
          <colgroup>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            <col width={"23%"}></col>
            <col width={"5%"}></col>
            <col width={"23%"}></col>
            <col width={"39%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={2}>Wish</th>
              <th>학생</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_wish_student}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_wish_student"}
                    value={add_memo_info.a_wish_student}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_wish_student",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
              <td rowSpan={13}>
                <div className="text-center">
                  RUTA 테스트
                  <span className="ml-1">{counsel_file_opt.select_idx + 1}/{counsel_file_opt.size}</span>
                  <button className="btn-s btn-dark ml-1" onClick={() => { go_change_counsel_file_idx({ "sort": "-" }); }}>◀</button>
                  <button className="btn-s btn-dark ml-1" onClick={() => { go_change_counsel_file_idx({ "sort": "+" }); }}>▶</button>
                  {(myProps.is_update && is_view_mode == false) &&
                    <>
                      <div style={{ display: "inline-block" }} >
                        <label className="btn btn-sky ml-2" htmlFor="files_counsel" placeholder="RUTA테스트 사진 추가"
                          style={{ cursor: "pointer" }} >
                          ruta+
                        </label>
                        <form method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
                          <input type="file" id="files_counsel" className="input_file" name="input_file[]"
                            multiple
                            ref={fileInput} hidden accept="image/*" onChange={(e: any) => {
                              if (e.target.files.length > 0) {
                                goCounselfileWrite({});
                              }
                            }} />
                        </form>
                      </div>
                    </>
                  }
                  {(myProps.is_update && is_view_mode == false) &&
                    <>
                      <div style={{ display: "inline-block" }} >
                        <label className="btn btn-sky ml-2" htmlFor="files_counsel_data" placeholder="자료사진 추가"
                          style={{ cursor: "pointer" }} >
                          data+
                        </label>
                        <form method="post" ref={writeFileForm2} onSubmit={() => { return false; }} >
                          <input type="file" id="files_counsel_data" className="input_file" name="input_file[]"
                            multiple
                            ref={fileInput2} hidden accept="image/*" onChange={(e: any) => {
                              if (e.target.files.length > 0) {
                                goCounselDatafileWrite({});
                              }
                            }} />
                        </form>
                      </div>
                    </>
                  }
                  <form ref={downloadFormRef} method="post" >
                    <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
                  </form>
                </div>
                <div className="text-center mt-1" >
                  <div style={{ position: "relative", minHeight: "450px" }}>
                    {get_counsel_file_tags()}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>학부모</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_wish_parent}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_wish_parent"}
                    value={add_memo_info.a_wish_parent}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_wish_parent",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th rowSpan={5}>영어</th>
              <th style={{ fontSize: "11px" }}>학습정서</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_attitude}
                  value={add_memo_info.a_en_attitude}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_en_attitude", value: inData });
                    }
                  }
                  }
                ></SelectTab>
              </td>
              <th style={{ fontSize: "11px" }}>학습역량</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_ability}
                  value={add_memo_info.a_en_ability}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_en_ability", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>issue</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_issue}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_issue"}
                    value={add_memo_info.a_en_issue}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_issue",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>plan</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_plan}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_plan"}
                    value={add_memo_info.a_en_plan}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_plan",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>action</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_action}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_action"}
                    value={add_memo_info.a_en_action}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_action",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>진도</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_en_progress_main}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_en_progress_main"}
                    value={add_memo_info.a_en_progress_main}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_en_progress_main",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th rowSpan={5}>수학</th>
              <th style={{ fontSize: "11px" }}>학습정서</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_attitude}
                  value={add_memo_info.a_math_attitude}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_math_attitude", value: inData });
                    }
                  }
                  }
                ></SelectTab>
              </td>
              <th style={{ fontSize: "11px" }}>학습역량</th>
              <td>
                <SelectTab
                  select_arr={add_memo_select_arr.a_ability}
                  value={add_memo_info.a_math_ability}
                  on_select={(inData: any) => {
                    if (is_view_mode == false) {
                      handleInputChange2({ name: "a_math_ability", value: inData });
                    }
                  }}
                ></SelectTab>
              </td>
            </tr>
            <tr>
              <th>issue</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_issue}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_issue"}
                    value={add_memo_info.a_math_issue}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_issue",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>plan</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_plan}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_plan"}
                    value={add_memo_info.a_math_plan}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_plan",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>action</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_action}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_action"}
                    value={add_memo_info.a_math_action}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_action",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th>진도</th>
              <td colSpan={3} style={{ paddingBottom: 1 }} >
                {is_view_mode ?
                  <MultyLineTextView text={add_memo_info.a_math_progress_main}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_math_progress_main"}
                    value={add_memo_info.a_math_progress_main}
                    height={32}
                    onChange={(e: any) => {
                      handleInputChange2({
                        name: "a_math_progress_main",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "view_img" &&
            <FileSlideArea
              file_info_arr={popupData.img_file_arr}
              select_file_idx={popupData.select_file_idx}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FileSlideArea>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default CounselInfoTopArea;