function WriteArea(props: any) {
  let myProps = {
    corp_opt: {},
    set_corp_opt: (inData: any) => { },
    ...props
  };
  let corp_opt = myProps.corp_opt;

  const on_change_row_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_corp_opt = { ...corp_opt };
    change_corp_opt[name] = value;
    myProps.set_corp_opt(change_corp_opt);
  };

  return (
    <div className="write-table-div">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="80%" />
        </colgroup>
        <tbody>
          <tr>
            <th>등원</th>
            <td>
              <input type="text" className="row-input"
                name="a_attend_in_msg"
                value={corp_opt.a_attend_in_msg}
                onChange={on_change_row_input} />
            </td>
          </tr>
          <tr>
            <th>하원</th>
            <td>
              <input type="text" className="row-input"
                name="a_attend_out_msg"
                value={corp_opt.a_attend_out_msg}
                onChange={on_change_row_input} />
            </td>
          </tr>
          <tr>
            <th>지각</th>
            <td>
              <input type="text" className="row-input"
                name="a_attend_late_msg"
                value={corp_opt.a_attend_late_msg}
                onChange={on_change_row_input} />
            </td>
          </tr>
          <tr>
            <th>결석</th>
            <td>
              <input type="text" className="row-input"
                name="a_attend_absent_msg"
                value={corp_opt.a_attend_absent_msg}
                onChange={on_change_row_input} />
            </td>
          </tr>
          <tr>
            <th>숙제</th>
            <td>
              <input type="text" className="row-input"
                name="a_homework_msg"
                value={corp_opt.a_homework_msg}
                onChange={on_change_row_input} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WriteArea;