function ParViewArea(props: any) {
  let myProps = {
    rowData: {},
    ...props
  };
  let rowData = myProps.rowData;
  
  let is_show_par = false;
  let study_title = "";
  let study_detail = "";
  if (rowData.study_info) {
    is_show_par = true;
    if (rowData.study_info.title_info) {
      study_title = rowData.study_info.title_info.a_title;
    }
    if (rowData.study_info.section_info) {
      study_detail = rowData.study_info.section_info.a_title;
    }
  }
  if(rowData.video_info){
    is_show_par = true;
    if(rowData.video_info.corse_info){
      study_title = rowData.video_info.corse_info.a_title;
    }
    if(rowData.video_info.section_info){
      study_detail = rowData.video_info.section_info.a_title;
    }
  }

  return (
    <div>
      {is_show_par &&
        <div className="write_table_small mt-1">
          <table>
            <colgroup>
              <col width="20%"></col>
              <col width="80%"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>교재</th>
                <td>{study_title}</td>
              </tr>
              <tr>
                <th>대단원원</th>
                <td>{study_detail}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};
export default ParViewArea;