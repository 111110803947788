import { useState } from 'react';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

function ViewArea(props: any) {
  let myProps = {
    notice_arr: [],
    notice_idx:0,
    is_notice_popup:true,
    closePopup: () => { },
    ...props
  };
  let init_notice_idx=myProps.notice_idx;
  if(init_notice_idx<0){
    init_notice_idx=0;
  };
  if(init_notice_idx>=myProps.notice_arr.length){
    init_notice_idx=myProps.notice_arr.length-1;
  };
  const [view_notice_idx, set_view_notice_idx] = useState(init_notice_idx);

  let notice_info: any = null;
  if (myProps.notice_arr.length > 0 && myProps.notice_arr[view_notice_idx]) {
    notice_info = myProps.notice_arr[view_notice_idx];
  }

  const go_move_notice_idx = (arrow: "pre" | "after") => {
    let change_idx = view_notice_idx;
    if (arrow === "pre") {
      change_idx--;
    } else if (arrow === "after") {
      change_idx++;
    }
    if (change_idx < 0) {
      change_idx = myProps.notice_arr.length - 1;
    }
    if (change_idx >= myProps.notice_arr.length) {
      change_idx = 0;
    }
    set_view_notice_idx(change_idx);
  };

  const set_except_notice_pri_val=()=>{
    let except_system_notice_pri_val_arr:any=strFunc.get_storage("except_system_notice_pri_val_arr", "[]");
    except_system_notice_pri_val_arr=JSON.parse(except_system_notice_pri_val_arr);
    
    //오래된건 거르자.
    let least_ymd=DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'day',-24),"Ymd");
    let new_except_system_notice_pri_val_arr:any=[];
    for(let i=0;i<except_system_notice_pri_val_arr.length;i++){
      let item=except_system_notice_pri_val_arr[i];
      if(item.substring(0,8)>least_ymd){
        new_except_system_notice_pri_val_arr.push(item);
      }
    }
    except_system_notice_pri_val_arr=new_except_system_notice_pri_val_arr;
    
    //추가
    for(let i=0;i<myProps.notice_arr.length;i++){
      let item=myProps.notice_arr[i];
      let row_pri_val=item.a_ymd+","+item.a_seq;
      if(strFunc.str_in_array(row_pri_val,except_system_notice_pri_val_arr)==-1){
        except_system_notice_pri_val_arr.push(row_pri_val);
      }
    }

    //저장
    strFunc.set_storage("except_system_notice_pri_val_arr",JSON.stringify(except_system_notice_pri_val_arr));
    myProps.closePopup();
  };

  const get_notice_content = () => {
    if (notice_info == null) {
      return "";
    }
    let title_style: any = {
      fontSize: "20px",
      fontWeight: "bold",
    };
    if (notice_info.a_color) {
      title_style.color = notice_info.a_color;
    }
    return (
      <div>
        <div>
          {notice_info.a_sort &&
            <span style={title_style}>[{notice_info.a_sort}]</span>
          }
          <span className="ml-1" style={title_style}>
            {notice_info.a_title}
          </span>
        </div>
        <div>
          <MultyLineTextView
            text={notice_info.a_board_content}
            style={{ fontSize: "16px" }}
          ></MultyLineTextView>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="text-center" >
        <button className="btn btn-dark mr-2" onClick={() => { go_move_notice_idx("pre"); }}>◀</button>
        <span style={{ fontSize: "20px" }}>
          {view_notice_idx + 1}/{myProps.notice_arr.length}
        </span>
        <button className="btn btn-dark ml-2" onClick={() => { go_move_notice_idx("after"); }}>▶</button>
      </div>
      {get_notice_content()}
      <div className="mt-2 text-center">
        {myProps.is_notice_popup &&
          <button className="btn btn-line-yellowish2" onClick={()=>{set_except_notice_pri_val();}}>이 내용 팝업 띄우지 않기</button>
        }
        <button className="btn btn-dark ml-2" onClick={myProps.closePopup}>닫기</button>
      </div>
    </div>
  );
};
export default ViewArea;