import { lazy } from 'react';

const MainDefault = lazy(() => import('@/pages/main/index'));
const CorpMainPage = lazy(() => import('@/pages/main/basic/corp/main'));
const MainOptionMain = lazy(() => import('@/pages/main/basic/moption/main'));
const UserListPage = lazy(() => import('@/pages/main/basic/user/list'));
const AuthGListPage = lazy(() => import('@/pages/main/basic/auth_grade/list'));
const SortListPage = lazy(() => import('@/pages/main/basic/sort/list'));
const FileListPage = lazy(() => import('@/pages/main/basic/file/list'));
const ListOptList = lazy(() => import('@/pages/main/basic/list_opt/list'));
const MyPage = lazy(() => import('@/pages/main/basic/mypage'));

const MCompList = lazy(() => import('@/pages/main/mcomp/mcomp/list'));
const MGroupList = lazy(() => import('@/pages/main/mcomp/group/list'));
const PointList = lazy(() => import('@/pages/main/mcomp/point/list'));
const SMSList = lazy(() => import('@/pages/main/basic/sms/list'));
const SMSSenderList = lazy(() => import('@/pages/main/basic/sms_sender/list'));
const KatalkList = lazy(() => import('@/pages/main/basic/katalk/list'));
const KatalkSenderList = lazy(() => import('@/pages/main/basic/katalk_sender/list'));
const KatalkTempleteList = lazy(() => import('@/pages/main/basic/katalk_templete/list'));
const MjoinRequestList = lazy(() => import('@/pages/main/basic/mjoin_request/list'));

const MainUserList = lazy(() => import('@/pages/main/main_user/user/list'));

const MakeSupplyPage = lazy(() => import('@/pages/main/trade/make_supply/make'));
const SubjectList = lazy(() => import('@/pages/main/trade/subject/list'));
const SubjectDList = lazy(() => import('@/pages/main/trade/subjectd/list'));
const PosCardList = lazy(() => import('@/pages/main/trade/mpos_card/list'));
const CashReceiptList = lazy(() => import('@/pages/main/trade/mcash_receipt/list'));
const StuPointList = lazy(() => import('@/pages/main/trade/stu_point/list'));

const MainBoardNotice = lazy(() => import('@/pages/main/board/notice/list'));
const SystemNoticeListPage = lazy(() => import('@/pages/main/board/notice/system_list'));
const MainAppBoardNotice = lazy(() => import('@/pages/main/board/app_notice/list'));
const McounselReqListPage = lazy(() => import('@/pages/main/board/mcounsel_req/list'));
const ManualBoardListPage = lazy(() => import('@/pages/main/board/manual_board/list'));

const MonthPrescribeListPage= lazy(() => import('@/pages/main/edu/month_prescribe/list'));
const PrescribeCheckCateList = lazy(() => import('@/pages/main/edu/prescribe/check_cate/list'));
const PrescribeCheckTree = lazy(() => import('@/pages/main/edu/prescribe/check_list/check_tree'));

const LoginLogList = lazy(() => import('@/pages/main/log/login_log/list'));

const routerArr:object[] = [
  {
    path: "/main",
    element: <MainDefault></MainDefault>,
  },
  {
    path: "/main/basic/corp/main",
    element: <CorpMainPage></CorpMainPage>,
  },
  {
    path: "/main/basic/corp/option",
    element: <MainOptionMain></MainOptionMain>,
  },
  {
    path: "/main/basic/user/list",
    element: <UserListPage></UserListPage>,
  },
  {
    path: "/main/basic/authg/list",
    element: <AuthGListPage></AuthGListPage>,
  },
  {
    path: "/main/basic/sort/list",
    element: <SortListPage></SortListPage>,
  },
  {
    path: "/main/basic/file/list",
    element: <FileListPage></FileListPage>,
  },
  {
    path: "/main/basic/list_opt/list",
    element: <ListOptList></ListOptList>,
  },
  {
    path: "/main/basic/mypage",
    element: <MyPage></MyPage>,
  },


  {
    path: "/main/mcomp/mcomp/list",
    element: <MCompList></MCompList>,
  },
  {
    path: "/main/mcomp/group/list",
    element: <MGroupList></MGroupList>,
  },
  {
    path: "/main/mcomp/point/list",
    element: <PointList></PointList>,
  },
  {
    path: "/main/mcomp/sms/list",
    element: <SMSList></SMSList>,
  },
  {
    path: "/main/mcomp/sms_sender/list",
    element: <SMSSenderList></SMSSenderList>,
  },
  {
    path: "/main/mcomp/katalk/list",
    element: <KatalkList></KatalkList>,
  },
  {
    path: "/main/mcomp/katalk_sender/list",
    element: <KatalkSenderList></KatalkSenderList>,
  },
  {
    path: "/main/mcomp/katalk_templete/list",
    element: <KatalkTempleteList></KatalkTempleteList>,
  },
  {
    path: "/main/mcomp/join_request/list",
    element: <MjoinRequestList></MjoinRequestList>,
  },
  
  {
    path: "/main/mcomp/main_user/user/list",
    element: <MainUserList></MainUserList>,
  },

  
  {
    path: "/main/trade/make_supply/make",
    element: <MakeSupplyPage></MakeSupplyPage>,
  },
  {
    path: "/main/trade/subject/list",
    element: <SubjectList></SubjectList>,
  },
  {
    path: "/main/trade/subjectd/list",
    element: <SubjectDList></SubjectDList>,
  },
  {
    path: "/main/trade/pos_card/list",
    element: <PosCardList></PosCardList>,
  },
  {
    path: "/main/trade/cash_receipt/list",
    element: <CashReceiptList></CashReceiptList>,
  },
  {
    path: "/main/trade/stu_point/list",
    element: <StuPointList></StuPointList>,
  },

  {
    path: "/main/board/notice/list",
    element: <MainBoardNotice></MainBoardNotice>,
  },
  {
    path: "/main/board/system_notice/list",
    element: <SystemNoticeListPage></SystemNoticeListPage>,
  },
  {
    path: "/main/board/app_notice/list",
    element: <MainAppBoardNotice></MainAppBoardNotice>,
  },
  {
    path: "/main/board/mcounsel_req/list",
    element: <McounselReqListPage></McounselReqListPage>,
  },
  {
    path: "/main/board/manual_board/list",
    element: <ManualBoardListPage></ManualBoardListPage>,
  },
  {
    path: "/main/edu/month_prescribe/list",
    element: <MonthPrescribeListPage></MonthPrescribeListPage>,
  },
  {
    path: "/main/edu/prescribe/check_cate/list",
    element: <PrescribeCheckCateList></PrescribeCheckCateList>,
  },
  {
    path: "/main/edu/prescribe/check_list/check_tree",
    element: <PrescribeCheckTree></PrescribeCheckTree>,
  },

  {
    path: "/main/log/login/list",
    element: <LoginLogList></LoginLogList>,
  },
];

export default routerArr;