import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props:any){
   let myProps={
    listOpt:{
      "s_state":"",
    },
    list:(inData:any)=>{},
    xColumnArr:{},
    goWriteFrame:(inData:any)=>{},
    goDelete:(inData:any)=>{},
    isShowRight:true,
    setIsShowRight:(inData:any)=>{},
    ...props
  };
  let select_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  const on_change_search_input=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;

    let search_list_opt={
      [name]:value
    };
    myProps.list(search_list_opt);
  };
  
  return (
  <div className="btn-box-left relative h-8">
    <input type="text" className="search-input" name="s_name_like" value={myProps.listOpt.s_name_like} 
      onChange={on_change_search_input} style={{width:"80px",lineHeight:"22px"}}
      autoComplete="off" placeholder="수업명" />
    <select className="search-input" name="s_subject" value={myProps.listOpt.s_subject} 
      onChange={on_change_search_input}>
        <option value="">전체</option>
        {select_option_arr.map((item:any,idx:number)=>{
          return (
            <option key={idx} value={item.value}>{item.text}</option>
          );
        })}
    </select>
    <select className="search-input" name="s_state" value={myProps.listOpt.s_state} 
      onChange={on_change_search_input}>
        <option value="">전체</option>
        {myProps.xColumnArr.select_arr["a_state"]?.map((item:any,idx:number)=>{
          return (
            <option key={idx} value={item.value}>{item.text}</option>
          );
        })}
    </select>
    <button className="btn btn-dark" onClick={()=>{myProps.goWriteFrame(false);}}>신규등록</button>
    <button className="btn btn-red" onClick={myProps.goDelete}>삭제</button>
 </div>
  );
};
export default SearchArea;