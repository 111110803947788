import Style from "../css/style.module.css";
import { Link, useNavigate } from 'react-router-dom';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewNoticePopup from "./popup/view_popup";
import strFunc from '@/lib/lyg/string';

const NoticeListArea = () => {
  const navigate = useNavigate();

  const [infoArr, setInfoArr] = useState([]);
  const [popup_notice_arr, set_popup_notice_arr] = useState([]);
  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view_popup",//view
    notice_arr: [],
    notice_idx: 0,
    is_notice_popup: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list();
    get_notice_popup_by_ajax();
  }, []);

  const list = () => {
    let list_form_json: any = {
      "s_id": "system_notice",
      'order_id': 'a_create_date DESC',
      "max_limit_num": "5",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/board/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_notice_popup_by_ajax = () => {
    let list_form_json: any = {
      "s_id": "system_notice",
      'order_id': 'a_create_date DESC',
      "s_is_popup": "1",
      "s_popup_period_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "max_limit_num": "5",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/board/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_popup_notice_arr(response.data["data"]["info_arr"]);
          open_notice_popup({
            notice_arr: response.data["data"]["info_arr"]
          });
        } else {

        }
      });
  };

  const open_notice_popup = (inData: any) => {
    let opt_obj = {
      notice_arr: popup_notice_arr,
      notice_idx: 0,
      is_notice_popup: true,
      ...inData
    };
    let except_system_notice_pri_val_arr: any = strFunc.get_storage("except_system_notice_pri_val_arr", "[]");
    except_system_notice_pri_val_arr = JSON.parse(except_system_notice_pri_val_arr);

    let able_notice_arr: any = [];
    for (let i = 0; i < opt_obj.notice_arr.length; i++) {
      let item = opt_obj.notice_arr[i];
      let row_pri_val = item.a_ymd + "," + item.a_seq;
      if (strFunc.str_in_array(row_pri_val, except_system_notice_pri_val_arr) == -1) {
        able_notice_arr.push(item);
      }
    }

    if(opt_obj.is_notice_popup==false){
      able_notice_arr=opt_obj.notice_arr;
    }

    if (able_notice_arr.length == 0) {
      return false;
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      notice_arr: able_notice_arr,
      notice_idx: opt_obj.notice_idx,
      is_notice_popup: opt_obj.is_notice_popup,
      "title": "공지사항",
      "sort": "view_popup",
    });
  };

  const getNoticeTags = () => {
    let notice_tags = infoArr.map((item: any, idx: number) => {
      let pre_title = "";
      if (item.a_sort) {
        pre_title = "[" + item.a_sort + "]";
      }
      let title_style: any = {
        cursor:"pointer"
      };
      if (item.a_color) {
        title_style.color = item.a_color;
      }
      return (
        <div key={idx} className={Style.notice_row}>
          <div className={Style.notice_row_title} 
            style={title_style} title={item.a_title} 
            onClick={()=>{
              open_notice_popup({
                notice_arr: infoArr,
                notice_idx: idx,
                is_notice_popup: false
              });
            }} >
            {pre_title} {item.a_title.substring(0, 20)}
          </div>
          <div className={Style.notice_row_right_text}>
            {item.a_create_date.substring(0, 10)}
          </div>
        </div>
      );
    });
    return notice_tags;
  };

  return (
    <div>
      <div className={Style.box_title_wrap}>
        공지사항
        <a className={Style.box_title_right_link} onClick={() => { navigate("/comp/board/system_notice/list"); }} >
          바로가기
        </a>
      </div>
      <div className={Style.notice_list_wrap}>
        {infoArr.length == 0 &&
          <div className={Style.notice_empty_box}>
            내용이 없습니다.
          </div>
        }
        {
          getNoticeTags()
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "view_popup" &&
            <ViewNoticePopup
              notice_arr={popupData.notice_arr}
              notice_idx={popupData.notice_idx}
              is_notice_popup={popupData.is_notice_popup}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewNoticePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default NoticeListArea;