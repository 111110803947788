import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import WriteMultyPopup from '../popup/write_multy/index';
import WriteMultyMakeduPopup from '../popup/write_multy_makedu/index';
import WriteOptPopupContent from "@/pcomponents/common/xcolumn/popup/write_opt";
import SendSMSPopup from "@/pages/comp/basic/sms/popup/send_sms";
import AddAttendPopup from "@/pages/comp/edu/attend/popup/add_attend";
import StuEndPopup from "../popup/stu_end_popup";
import ViewStuLayerPopup from "../popup/view_layer";
import CharOptGoldOfStuOnePopup from "@/pages/comp/basic/student/popup/char_opt_gold_of_stu_one";
import SendPushPopup from "@/pages/comp/basic/push/popup/send";
import ViewCounselTodoOfDay from "@/pages/comp/basic/counsel/calendar/area/popup/view_ctodo_day";

import SearchArea from './area/search/search_area';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';

import attend_late_active_ico from "@/img/ico/attend/attend_late_active.png";
import attend_late_ico from "@/img/ico/attend/attend_late.png";
import attend_o_active_ico from "@/img/ico/attend/attend_o_active.png";
import attend_o_ico from "@/img/ico/attend/attend_o.png";
import attend_x_active_ico from "@/img/ico/attend/attend_x_active.png";
import attend_x_ico from "@/img/ico/attend/attend_x.png";
import attend_out_active_ico from "@/img/ico/head/head_attend_ico_active.png";
import attend_out_ico from "@/img/ico/head/head_attend_ico.png";
import PhoneCheckBoxFunc from "./func/phone_check";
import WriteStuPage from "@/pages/comp/basic/student/common/write";
import ManageBtnArea from "./area/manage_btns";
import StuListExcelDown from "./area/excel_down";

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>(null);
  const StuListExcelDownRef = useRef<any>(null);
  let origin_order_id = "a_create_date DESC";

  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    order_id: origin_order_id,
    s_grade: ["student"],
    // s_addon_data:"1",
    s_addon_class_user: "1",
    s_addon_link_user: "1",
    //s_addon_profile: "1",
    s_addon_invite_code: "1",
    s_addon_comp_link: "1",
    s_addon_comp_chat_opt_by_comp_link: "1",
    s_addon_attend: "1",
    s_addon_attend_start_date: now_ymd_str,
    s_addon_attend_end_date: now_ymd_str,
    s_attend_sort_detail: "",//attend,late,absent,empty,''
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
    s_addon_add_memo: "1",
    //s_addon_recent_counsel:"1",
    //s_addon_lecture_time: "1",
    s_addon_stu_bookmark_of_stu: "1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
  });

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  let is_under_width = MyScreenFunc.get_is_under();
  const [write_page_data, set_write_page_data] = useState({
    is_show: false,
    is_update: false,
    is_view_mode: true,
    is_view_basic: false,
    info_arr: [],
    select_idx: 0,
    default_write_info: {},
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view, write_multy, send_sms, list_opt, add_attend, view_ctodo
    isUpdate: false,
    rowData: {},
    receive_arr: [],
    receive_user_seq_arr: [],
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    has_header: true,
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
    },
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  useEffect(() => {
    setTimeout(() => {
      list({});
      setxColumnByScreen();
    }, 100);
  }, [user]);

  const setxColumnByScreen = () => {
    if (is_under_width) {
      //몇개지우자
      let tmp_xColumnArr = xColumnArr;//x_column_list_arr
      let tmp_column_len = tmp_xColumnArr.x_column_list_arr.length;
      let tmp_column_arr = [];
      let tmp_except_key_arr = [
        "row_view_par_phone",
        "a_school_name",
        "row_view_class_name",
        "a_stu_state",
      ];
      let is_show_col_arr = [
        "row_view_recent_councel"
      ];
      for (let i = 0; i < tmp_column_len; i++) {
        let tmp_xcolumn = tmp_xColumnArr.x_column_list_arr[i];
        //크기 10씩만  줄이자
        tmp_xcolumn["width"] = parseInt(tmp_xcolumn["width"]) - 10;
        //보여주기
        if (strFunc.str_in_array(tmp_xcolumn["key"], is_show_col_arr) != -1) {
          tmp_xcolumn["is_show"] = "1";
        }
        //제외
        if (strFunc.str_in_array(tmp_xcolumn["key"], tmp_except_key_arr) != -1) {
          continue;
        }
        tmp_column_arr.push(tmp_xcolumn);
      }
      tmp_xColumnArr.x_column_list_arr = tmp_column_arr;
      tmp_xColumnArr.x_column_list_orig_arr = tmp_column_arr;
      ListComponentRef.current.setXColumnArr(tmp_xColumnArr);
    }
  };

  const list = (inOptObj: any) => {
    let tmp_list_opt = { ...listOpt, ...inOptObj };
    setListOpt(tmp_list_opt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',
      tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = useCallback(() => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_user_seq = selected_row[0]["a_seq"];
    if (tmp_delete_user_seq == "1") {
      alert("삭제 할 수 없는 회원입니다.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_u_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      if (selected_row[i]["a_seq"] == "1") {
        alert("삭제 할 수 없는 회원입니다.");
        return false;
      }
      del_u_arr.push({
        "a_seq": selected_row[i]["a_seq"],
      });
    }
    let del_from_json = {
      data_arr: del_u_arr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/delete', del_from_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.user_data_create_date = "";
          list({});
          alert("삭제 되었습니다.");
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (!confirm(confirm_msg)) {
      return false;
    }
    let del_form_data = {
      "data_arr": dataArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete'
      , del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const add_stu_bookmark = (inData:any) => {
    let opt_obj={
      selected_row:ListComponentRef.current?ListComponentRef.current.getSelectedRows():[],
      ...inData
    };
    let selected_row = opt_obj.selected_row;
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("즐겨찾기에 등록 하시겠습니까?")) {
      return false;
    }

    let w_data_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let info = selected_row[i];
      if (strFunc.is_empty(info.stu_bookmark_info)) {
        w_data_arr.push({
          a_owner_seq: user.user_seq,
          a_stu_seq: info.a_seq,
          a_stu_name: info.a_user_name,
        });
      }
    }

    if (w_data_arr.length == 0) {
      alert("저장되었습니다.");
      return false;
    }

    let w_form_data = {
      "data_arr": w_data_arr,
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/stu_bookmark/write'
      , w_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("즐겨찾기에 저장되었습니다.");
          list({});
        } else {

        }
      });
  };

  const del_stu_bookmark = (inData:any) => {
    let opt_obj={
      selected_row:ListComponentRef.current?ListComponentRef.current.getSelectedRows():[],
      ...inData
    };
    let selected_row = opt_obj.selected_row;
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("즐겨찾기에서 삭제 하시겠습니까?")) {
      return false;
    }

    let del_data_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let info = selected_row[i];
      if (!strFunc.is_empty(info.stu_bookmark_info)) {
        del_data_arr.push(info.stu_bookmark_info);
      }
    }

    if (del_data_arr.length == 0) {
      alert("삭제 할 즐겨찾기가 없습니다.");
      return false;
    }
    let del_form_data = {
      "data_arr": del_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/stu_bookmark/delete'
      , del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("즐겨찾기에서 삭제되었습니다.");
          list({});
        } else {

        }
      });
  };

  const openAttendPopup = (inData:any) => {
    let opt_obj={
      attend_sort: "in",
      attend_sort_detail:"attend",
      selected_row:ListComponentRef.current?ListComponentRef.current.getSelectedRows():[],
      ...inData
    };
    let attend_sort = opt_obj.attend_sort;
    let attend_sort_detail = opt_obj.attend_sort_detail;
    let selected_row=opt_obj.selected_row;

    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    if (xColumnArr.select_arr.a_sort_detail) {
      for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
        let item = xColumnArr.select_arr.a_sort_detail[i];
        if (item.value == attend_sort_detail) {
          pop_title = item.text;
        }
      }
    }
    
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "add_attend",
      title: pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      attend_date: attend_date,
      userInfoArr: selected_row,
      width: "500px",
      height: "60%",
      has_header: true,
    });
  };

  const openOnePopup = (sort: string, isUpdate: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = ListComponentRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort,
      width: "800px",
      height: "85%",
      has_header: true,
    });
  };

  const goExcelDown = () => {
    if(StuListExcelDownRef.current){
      StuListExcelDownRef.current.goExcelDown();
    }
  };

  const goAddVideoUseByMakedu = () => {
    //api/comp/plan/video/use/add_by_makedu
    if (!confirm("실행하시겠습니까?")) {
      return false;
    }
    let video_use_form_json = {};
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/use/add_by_makedu'
      , video_use_form_json, get_axios_data())
      .then((response) => {
        alert(response.data["msg"]);
      });
  };

  const openListOptPopup = () => {
    let pop_title = "리스트설정";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": false,
      "title": pop_title,
      "sort": "list_opt",
      has_header: true,
    });
  };
  const openSendSMSPopup = () => {
    let pop_title = "문자보내기";
    let selected_row = ListComponentRef.current.getRows();
    let receive_arr: any = [];
    for (let i = 0; i < selected_row.length; i++) {
      let row_data = selected_row[i];
      if (row_data["is_phone_check"]) {
        receive_arr.push({
          "user_seq": row_data.a_seq,
          "phone_num": strFunc.autoHypenPhone(row_data.a_user_phone),
          "user_name": row_data.a_user_name
        });
      }

      if (row_data.link_user_arr && row_data.link_user_arr[0] && row_data.link_user_arr[0]["par_info"]) {
        let tmp_line_len = row_data.link_user_arr.length;
        for (let j = 0; j < tmp_line_len; j++) {
          let par_info = row_data.link_user_arr[j]["par_info"];
          if (row_data.link_user_arr[j]["is_phone_check"]) {
            receive_arr.push({
              "user_seq": par_info.a_seq,
              "phone_num": strFunc.autoHypenPhone(par_info.a_user_phone),
              "user_name": par_info.a_user_name
            });
          }
        }
      }
    }
    setPopupData({
      ...popupData,
      "receive_arr": receive_arr,
      "isOpen": true,
      "isUpdate": false,
      "title": pop_title,
      "sort": "send_sms",
      has_header: true,
    });
  };
  const openStuEndPopup = () => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    setPopupData({
      ...popupData,
      "userInfoArr": selected_row,
      "isOpen": true,
      "isUpdate": false,
      "title": "휴,퇴원 처리",
      "sort": "stu_end",
      width: "700px",
      height: "70%",
      has_header: true,
    });
  };
  const open_send_push_popup = () => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    let receive_user_seq_arr: any = [];
    for (let i = 0; i < selected_row.length; i++) {
      receive_user_seq_arr.push(selected_row[i].a_seq);
    }
    setPopupData({
      ...popupData,
      "receive_user_seq_arr": receive_user_seq_arr,
      "isOpen": true,
      "title": "푸시메세지 보내기",
      "sort": "send_push",
      width: "700px",
      height: "70%",
      has_header: true,
    });
  };
  const select_show_write_page = () => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    } else if (selected_row.length != 1) {
      alert("1명만 선택해주세요.");
      return false;
    }
    let stu_seq = selected_row[0]["a_seq"];
    if (strFunc.is_empty(stu_seq)) {
      alert("학생키 없음.");
      return false;
    }
    show_write_page(true, selected_row[0]);
  };
  const show_write_page = (is_update: boolean, info: any) => {
    let select_idx = -1;
    let all_rows = ListComponentRef.current.getRows();
    if (is_update) {
      for (let i = 0; i < all_rows.length; i++) {
        if (all_rows[i]["a_seq"] == info["a_seq"]) {
          select_idx = i;
        }
      }
    }
    if (is_update) {
      if (select_idx == -1) {
        alert("선택이 잘못되었습니다.");
        return false;
      }
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: is_update,
        is_view_mode: is_update,
        is_view_basic: !is_update,
        select_idx: select_idx,
        info_arr: all_rows,
        default_write_info: {...info},
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });
  };
  const open_char_opt_gold_of_stu_one_popup = (inData:any) => {
    let opt_obj={
      selected_row:ListComponentRef.current?ListComponentRef.current.getSelectedRows():[],
      ...inData
    };
    let selected_row = opt_obj.selected_row;
    
    if (selected_row.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    } else if (selected_row.length != 1) {
      alert("1명만 선택해주세요.");
      return false;
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "학생양분",
      "sort": "stu_char_gold",
      userInfoArr: selected_row,
      width: "98%",
      height: "80%",
      has_header: true,
    });
  };
  const open_view_counsel_todo=(inData: any)=>{
    let opt_obj={
      selected_row:ListComponentRef.current?ListComponentRef.current.getSelectedRows():[],
      select_date:DateFunc.get_date_format(new Date(), "Y-m-d"),
      ...inData
    };
    let selected_row = opt_obj.selected_row;
    
    if (selected_row.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"view_ctodo",
      title:"상담일정 보기",
      userInfoArr:selected_row,
      select_date:opt_obj.select_date,
      ...inData
    });
  };
  const close_write_page = () => {
    list({});
    set_write_page_data({
      ...write_page_data,
      is_show: false,
    });
  };

  const setOrderByThKey = (th_key: string) => {
    let pre_order_id = listOpt.order_id;
    let change_order_id = pre_order_id;

    if (th_key == "checkbox") {
      return false;
    } else if (th_key.indexOf("row_view_") != -1) {
      if (th_key == "row_view_class_name") {
        th_key = "a_class_seq";
      } else {
        return false;
      }
    }

    if (pre_order_id.indexOf(th_key) != -1) {
      if (pre_order_id.indexOf(" DESC") != -1) {
        change_order_id = th_key;
      } else {
        change_order_id = th_key + " DESC";
      }
    } else {
      change_order_id = th_key;
    }

    list({
      now_page: 1,
      order_id: change_order_id
    });
  };

  const rowOnClick = useCallback((rowData: any, idx: number, e: any) => {
    // if(key=="a_user_name"||key=="a_user_id"){
    //   openOnePopup("write",true);
    // }
  }, []);

  const onClickTh = (key: string, e: any) => {
    setOrderByThKey(key);
  };

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key = params.key;
    var render_str = params.value;
    if (key == "row_view_manage") {
      render_str = (
        <div className="text-center">
          <button className="btn-m2 btn-line-gray"
            onClick={() => {
              setTimeout(() => {
                ListComponentRef.current.set_active_row_one_by_idx(params.idx);
                setTimeout(() => {
                  select_show_write_page();
                }, 100);
              }, 100);
            }}>상세</button>
          {/* <button className="btn-m2 btn-line-gray ml-1" onClick={()=>{setTimeout(()=>{goDelete();},100);}}>삭제</button> */}
        </div>
      );
    } else if (key == "a_user_name") {
      let tmp_stu_name = params.value;
      tmp_stu_name += " " + params.rowData.a_stu_total_code;
      render_str = (
        <div className="text-left">
          <a onClick={() => {
            setTimeout(() => {
              ListComponentRef.current.set_active_row_one_by_idx(params.idx);
              setTimeout(() => {
                select_show_write_page();
              }, 100);
            }, 100);
          }}>{tmp_stu_name}</a>
        </div>
      );
    } else if (key == "row_view_class_name") {
      if (params.rowData.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.rowData.class_arr.length; i++) {
          class_name_arr.push(
            params.rowData.class_arr[i]["class_name"]
          );
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_par_phone") {
      render_str = "학부모 미등록";
      let par_phone_num_arr = [];
      if (params.rowData.link_user_arr && params.rowData.link_user_arr[0] && params.rowData.link_user_arr[0]["par_info"]) {
        for (let i = 0; i < params.rowData.link_user_arr.length; i++) {
          let link_user = params.rowData.link_user_arr[i];
          if (link_user["par_info"]) {
            let par_relation_str = "기타";
            if (link_user["par_info"]["a_user_name"].indexOf("어머니") != -1) {
              par_relation_str = "모";
            } else if (link_user["par_info"]["a_user_name"].indexOf("아버지") != -1) {
              par_relation_str = "부";
            }
            let par_phone_num = strFunc.autoHypenPhone(link_user["par_info"]["a_user_phone"]);
            par_phone_num_arr.push({
              "sort": "parent",
              "phone": par_phone_num,
              "relation": par_relation_str,
              "is_phone_check": link_user.is_phone_check,
              "link_row_num": i
            });
          }
        }
      }
      if (par_phone_num_arr.length == 0) {
        par_phone_num_arr.push({
          "sort": "parent",
          "phone": "학부모미등록",
          "relation": "",
          "is_phone_check": false,
          "link_row_num": 0,
        });
      }

      par_phone_num_arr = [
        {
          "sort": "student",
          "phone": strFunc.autoHypenPhone(params.rowData.a_user_phone),
          "relation": "",
          "is_phone_check": params.rowData.is_phone_check,
          "link_row_num": 0,
        },
        ...par_phone_num_arr
      ];
      render_str = (
        <div style={{ lineHeight: "20px" }}>
          {par_phone_num_arr.map((item: any, idx: number) => {
            let relation_str = "";
            if (item.relation) {
              relation_str = "(" + item.relation + ")";
            }
            return (
              <div key={idx} className="text-left">
                <label>
                  <input type="checkbox" className="mr-1"
                    checked={item.is_phone_check ? true : false}
                    onChange={(e: any) => {
                      let change_info_arr = PhoneCheckBoxFunc.onCheckPhoneNum({
                        info_arr: ListComponentRef.current.getRows(),
                        sort: item.sort,//"student"|"parent"
                        is_check: e.target.checked,
                        row_num: params.idx,
                        link_row_num: item.link_row_num,
                      });
                      setInfoArr(change_info_arr);
                      ListComponentRef.current.setInfoArr(change_info_arr);
                    }} />
                  {item.phone}{relation_str}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (key == "a_stu_num") {
      render_str = strFunc.getNumber2(render_str);
    } else if (key == "row_view_is_app") {
      render_str = (<div className="text-gray-500" title="어플가입없음.">x</div>);
      if (params.rowData.comp_link_info) {
        render_str = (<div className="main_text_color">가입</div>);
      }
      if (params.rowData.comp_link_info) {
        render_str = (<div className="text-gray-500" title={"로그아웃"}>가입</div>);
        if (params.rowData.comp_link_info.main_u_info && params.rowData.comp_link_info.main_u_info.a_push_token) {
          render_str = (<div className="main_text_color">가입</div>);
        }
      }
    } else if (key == "row_view_attend") {
      let attend_in_btns = getAttendInCellRenderBtns(params);
      let attend_info = null;
      if (params.rowData.attend_in_arr && params.rowData.attend_in_arr.length > 0) {
        attend_info = params.rowData.attend_in_arr[0];
      }
      render_str =
        <div>
          {attend_in_btns}
          {attend_info != null &&
            <div className="mt-1" title={attend_info["a_memo"]} style={{ color: "#777" }}>
              {strFunc.cut_str(attend_info["a_memo"], 6, "..")}
            </div>
          }
        </div>;
    } else if (key == "row_view_recent_councel") {
      render_str = (
        <span 
          onClick={()=>{
            open_view_counsel_todo({
              selected_row:[params.rowData],
            });
          }}>
          없음
        </span>
      );
      if (params.rowData.add_memo_info && !strFunc.is_empty(params.rowData.add_memo_info["a_counsel_todo_recent_seq"])) {
        let tmp_recent_councel_date = DateFunc.get_date_format(new Date(params.rowData.add_memo_info["a_counsel_todo_recent_date"]), "m.d");
        render_str = (
          <button className="btn-m2 btn-line-gray" 
            onClick={()=>{
              open_view_counsel_todo({
                selected_row:[params.rowData],
                select_date:params.rowData.add_memo_info["a_counsel_todo_recent_date"],
              });
            }} >{tmp_recent_councel_date}</button>
        );
      }
    } else if (key == "row_view_school_grade") {
      let stu_grade_str = "";
      if (xColumnArr.select_arr["a_stu_grade"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
          let tmp_row_opt = xColumnArr.select_arr["a_stu_grade"][i];
          if (tmp_row_opt["value"] == params.rowData.a_stu_grade) {
            stu_grade_str = tmp_row_opt["text"];
          }
        }
      }
      let school_and_grade_title = params.rowData.a_school_name + " " + stu_grade_str;
      render_str = (
        <div className="text-center" title={school_and_grade_title}>
          {strFunc.cut_str(params.rowData.a_school_name, 2, "")} {stu_grade_str}
        </div>
      );
    } else if (key == "row_view_stu_bookmark") {
      render_str = "☆";
      let is_stu_bookmark = false;
      if (params.rowData["stu_bookmark_info"] && params.rowData["stu_bookmark_info"]["a_stu_seq"]) {
        render_str = "★";
        is_stu_bookmark = true;
      }
      render_str = (
        <a style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            if (is_stu_bookmark) {
              del_stu_bookmark({
                selected_row: [params.rowData],
              });
            } else {
              add_stu_bookmark({
                selected_row: [params.rowData],
              });
            }
          }}>
          {render_str}
        </a>
      );
    } else if (key == "row_view_char_opt") {
      if (params.rowData.char_opt) {
        render_str = strFunc.comma(params.rowData.char_opt["a_gold"]);
        render_str = (
          <span style={{ cursor: "pointer" }}
            onClick={() => {
              open_char_opt_gold_of_stu_one_popup({
                selected_row: [params.rowData],
              });
            }}>
            {render_str}
          </span>
        );
      }
    }else if(key=="a_stu_state"){
      let stu_state_str:any="";
      if(xColumnArr.select_arr["a_stu_state"]){
        for(let i=0;i<xColumnArr.select_arr["a_stu_state"].length;i++){
          let item=xColumnArr.select_arr["a_stu_state"][i];
          if(item.value==params.rowData.a_stu_state){
            stu_state_str=item.text;
          }
        }
      }
      if(params.rowData.a_stu_state=="end"||params.rowData.a_stu_state=="rest"){
        stu_state_str=(
          <div>
            {stu_state_str}
            <p style={{fontSize:"12px",fontWeight:"normal"}}>
              {params.rowData["a_stu_end_date"]}
            </p>
          </div>
        );
      }

      render_str=stu_state_str;
    }
    return render_str;
  };

  const getAttendInCellRenderBtns = (params: any) => {
    let attend_in_state_arr = [
      { "value": "attend", "text": "등원", "img": attend_o_ico, "img_active": attend_o_active_ico },
      { "value": "late", "text": "등원(지각)", "img": attend_late_ico, "img_active": attend_late_active_ico },
      { "value": "absent", "text": "결석", "img": attend_x_ico, "img_active": attend_x_active_ico },
    ];
    let attend_info:any = null;
    let attend_out_info:any = null;
    if (params.rowData.attend_in_arr && params.rowData.attend_in_arr.length > 0) {
      attend_info = params.rowData.attend_in_arr[0];
    }
    if (params.rowData.attend_out_arr && params.rowData.attend_out_arr.length > 0) {
      attend_out_info = params.rowData.attend_out_arr[0];
    }
    if(attend_info!=null&&attend_info["a_sort_detail"]!="absent"){
      attend_in_state_arr.push(
        { "value": "out", "text": "하원", "img": attend_out_ico, "img_active": attend_out_active_ico },
      );
    }

    let attend_in_btns = attend_in_state_arr.map((item, idx) => {
      if(attend_info!=null){
        if(attend_info["a_sort_detail"]==item["value"]){
          
        }else if(item["value"]=="out"){

        }else{
          return false;
        }
      }
      let row_attned_info=attend_info;
      let row_in_out="in";
      if(item.value=="out"){
        row_attned_info=attend_out_info;
        row_in_out="out";
      }
      let is_checked_btn = false;
      let row_attend_time_str="";
      if (row_attned_info!=null) {
        if (row_attned_info["a_sort_detail"] == item["value"]) {
          is_checked_btn = true;
          row_attend_time_str=row_attned_info["a_time"];
        }
      }
      return (
        <button key={idx}
          className={"ml-1"}
          onClick={() => {
            if (is_checked_btn) {
              if (row_attned_info != null) {
                deleteAttend([row_attned_info], item["text"]+" 삭제 하시겠습니까?");
              }
            } else {
              setTimeout(() => {
                openAttendPopup({
                  attend_sort: row_in_out,
                  attend_sort_detail: item["value"],
                  selected_row: [params.rowData],
                });
              }, 100);
            }
          }}
          title={item.text+" "+row_attend_time_str}
        >
          <img src={is_checked_btn ? item.img_active : item.img} style={{ height: 21 }} />
        </button>
      );
    });
    return attend_in_btns;
  };

  const customThCellRenderer = (params: any) => {
    /*
    {
      "key":key,
      "name":name,
      "head_str":head_str,
    }
    */
    if (params.key == "row_view_par_phone") {
      params.head_str = (
        <div className="text-left">
          <p>
            <label>
              <input type="checkbox" className="mr-1"
                onClick={(e: any) => {
                  let change_info_arr = PhoneCheckBoxFunc.onClickPhoneCheckAll({
                    info_arr: ListComponentRef.current.getRows(),
                    sort: "student",//"student"|"parent"
                    is_check: e.target.checked,
                  });
                  setInfoArr(change_info_arr);
                  ListComponentRef.current.setInfoArr(change_info_arr);
                }} />
              학생번호
            </label>
          </p>
          <p>
            <label>
              <input type="checkbox" className="mr-1"
                onClick={(e: any) => {
                  let change_info_arr = PhoneCheckBoxFunc.onClickPhoneCheckAll({
                    info_arr: ListComponentRef.current.getRows(),
                    sort: "parent",//"student"|"parent"
                    is_check: e.target.checked,
                  });
                  setInfoArr(change_info_arr);
                  ListComponentRef.current.setInfoArr(change_info_arr);
                }} />
              학부모번호
            </label>
          </p>
        </div>
      );
    }
    return params.head_str;
  };

  return (
    <CompLayout isConTitle={false}>
      {write_page_data.is_show == false ?
        <div className="con_wrap" >
          <SearchArea
            listOpt={listOpt}
            setListOpt={setListOpt}
            xColumnArr={xColumnArr}
            list={list}
          ></SearchArea>

          <ManageBtnArea
            is_under_width={is_under_width}
            countInfo={countInfo}
            listOpt={listOpt}
            list={list}
            goExcelDown={goExcelDown}
            openListOptPopup={openListOptPopup}
            openSendSMSPopup={openSendSMSPopup}
            open_send_push_popup={open_send_push_popup}
            openOnePopup={openOnePopup}
            show_write_page={show_write_page}
            select_show_write_page={select_show_write_page}
            add_stu_bookmark={add_stu_bookmark}
            del_stu_bookmark={del_stu_bookmark}
            openStuEndPopup={openStuEndPopup}
            goDelete={goDelete}
          ></ManageBtnArea>
          <div className="list_round_box">
            <ListComponent
              ref={ListComponentRef}
              xColumnArr={{ ...xColumnArr }}
              gridOpt={{
                fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                is_add_idx_num: false,
                is_add_checkbox: true,
                td_align: "center",
                height: "auto",
                is_fix_width: true,
                rowMultiSelectWithClick: true,
                onGridReady: () => {

                },
                rowOnClick: rowOnClick,
                onClickTh: onClickTh,
                customCellRenderer: customCellRenderer,
                customThCellRenderer: customThCellRenderer,
                class_name_json: {
                  table_div: "basic_list_div",
                  td_div: "row-col-div",
                  input_check: "chk_row",
                  row_input: "row-input",
                  data_row_tr: "data_row_tr",
                  active_row: "active",
                  empty_con_div: "list_empty_content_div",
                },
              }}
            ></ListComponent>
            <Paging now_page={listOpt.now_page}
              num_per_page={listOpt.num_per_page}
              total_rec={countInfo.tot}
              onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
              is_view_num_per_page={true}
              onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
          </div>
        </div>
        :
        <WriteStuPage
          info_arr={write_page_data.info_arr}
          select_idx={write_page_data.select_idx}
          close_write={close_write_page}
          list={list}
          is_update={write_page_data.is_update}
          is_view_mode={write_page_data.is_view_mode}
          default_write_info={write_page_data.default_write_info}
        ></WriteStuPage>
      }
      <StuListExcelDown
        ref={StuListExcelDownRef}
        infoArr={infoArr}
      ></StuListExcelDown>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              openStuEndPopup={openStuEndPopup}></WritePopupContent>
          }
          {popupData.sort === "write_multy" &&
            <WriteMultyPopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={() => { list({}); }}
            ></WriteMultyPopup>
          }
          {popupData.sort === "write_multy_makedu" &&
            <WriteMultyMakeduPopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={() => { list({}); }}
            ></WriteMultyMakeduPopup>
          }
          {
            popupData.sort == "list_opt" &&
            <WriteOptPopupContent
              xColumnArr={xColumnArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteOptPopupContent>
          }
          {popupData.sort === "send_sms" &&
            <SendSMSPopup
              receive_arr={popupData.receive_arr}
              callBackRefresh={() => { }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></SendSMSPopup>
          }
          {popupData.sort === "add_attend" &&
            <AddAttendPopup attend_sort={popupData.attend_sort} attend_sort_detail={popupData.attend_sort_detail}
              attend_date={popupData.attend_date}
              userInfoArr={popupData.userInfoArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
          }
          {popupData.sort === "stu_end" &&
            <StuEndPopup
              stu_info_arr={popupData.userInfoArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></StuEndPopup>
          }
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
          {(popupData.sort == "stu_char_gold" && popupData.userInfoArr.length > 0) &&
            <CharOptGoldOfStuOnePopup
              stu_seq={popupData.userInfoArr[0]["a_seq"]}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></CharOptGoldOfStuOnePopup>
          }
          {popupData.sort === "send_push" &&
            <SendPushPopup
              receive_user_seq_arr={popupData.receive_user_seq_arr}
              callback={() => { }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></SendPushPopup>
          }
          {popupData.sort=="view_ctodo"&&
          <ViewCounselTodoOfDay
            select_date={popupData.select_date}
            stu_info_arr={popupData.userInfoArr}
            list_opt={{}}
            show_write_diary_page={()=>{}}
            callback={()=>{list({});}}
            closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
          ></ViewCounselTodoOfDay>
        }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;