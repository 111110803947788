import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"payt_supply",
    list_id:"payt_supply_history",
    x_column_list_arr:[
      {"key":"a_user_name","name":"학생","width":"100","is_show":"1"},
      {"key":"a_date","name":"날짜","width":"110","is_show":"1"},
      {"key":"a_state","name":"상태","width":"100","is_show":"1"},
      {"key":"a_product_nm","name":"청구사유","width":"150","is_show":"1"},
      {"key":"a_message","name":"메세지","width":"130","is_show":"1"},
      {"key":"a_phone","name":"핸드폰","width":"150","is_show":"1"},
      {"key":"a_price","name":"가격","width":"100","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"110","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_date DESC, a_create_date DESC',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.order_id_col_arr=[
    {value:"a_date",text:"보고일"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;