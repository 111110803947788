import DeskPlanPage from "@/pages/comp/edu/desk/desk_plan";
import DiscoverMapListPage from "@/pages/comp/edu/discover_map/list";
import DiscoverMapHistoryPage from "@/pages/comp/edu/discover_map/history_list";

const routerArr:object[] = [
  {
    path: "/comp/edu/desk/desk_plan",
    element: <DeskPlanPage></DeskPlanPage>,
  },
  {
    path: "/comp/edu/discover_map/list",
    element: <DiscoverMapListPage></DiscoverMapListPage>,
  },
  {
    path: "/comp/edu/discover_map/history_list",
    element: <DiscoverMapHistoryPage></DiscoverMapHistoryPage>,
  },
];

export default routerArr;