import TopInfoArea from "@/pages/comp/plan/todo/stu_card_list/area/card_list/area/card_list/card_box/top_info";
import MidTodoArea from "@/pages/comp/plan/todo/stu_card_list/area/card_list/area/card_list/card_box/mid_todo";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
function StuCardBox(props: any) {
  let myProps = {
    stu_info: null,
    desku_info: null,
    desk_info: null,
    room_info: null,
    Style: {},
    now_ymdhis: "",
    pre_date_str: "",
    openTodoDaySort: (inData: any) => { },
    show_detail_user_by_seq: (inData: any) => { },
    openTodoWriteOnePopup: (inData: any) => { },
    open_view_desk_popup: (inData: any) => { },
    ...props
  };
  if (myProps.now_ymdhis == "") {
    myProps.now_ymdhis = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
  }
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec = now_date_json.date_obj.getTime() / 1000;
  let stu_info=myProps.stu_info;
  let desku_info=myProps.desku_info;
  let desk_info=myProps.desk_info;
  let room_info=myProps.room_info;
  let Style=myProps.Style;
  let base_date_str=myProps.now_ymdhis.substring(0,10);
  let pre_date_str=myProps.pre_date_str;

  let td_sort_data_arr:any=[];
  if(stu_info.td_sort_data_json){
    let td_sort_data_json=stu_info.td_sort_data_json;
    for(let key in td_sort_data_json){
      if(key!="homework"){
        td_sort_data_arr.push(td_sort_data_json[key]);
      }
    }
  }

  let desk_num_str = "";
  if (desku_info != null) {
    desk_num_str = desku_info["a_name"];
  }

  let desk_per_height = "0%";
  let user_name = "";//홍길동
  let per_num = 0;//35
  let remain_minute_num = 0;
  let per_target_num: any = "";// /90
  let time_period = "";//21:00 ~ 22:30
  let percent_num = 0;
  let desk_back_class_name = Style.desk_back_per;
  if (desku_info != null) {
    per_num = 0;
    if (myProps.now_ymdhis.substring(0, 10) == desku_info["a_date"]) {
      let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
      let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
      let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
      if (!strFunc.is_empty(desku_info["a_end_date"])) {
        end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
      }
      let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

      let start_sec = start_date_json.date_obj.getTime() / 1000;
      let end_sec = end_date_json.date_obj.getTime() / 1000;

      let target_time_sec = end_sec - start_sec;

      if (target_time_sec != 0) {
        per_target_num = target_time_sec;
      }
      if (now_sec >= start_sec && now_sec < end_sec) {
        per_num = now_sec - start_sec;
        remain_minute_num = Math.round(per_target_num - per_num);
        if (remain_minute_num != 0) {
          remain_minute_num = Math.ceil(remain_minute_num / 60);
        }
      } else if (now_sec >= end_sec) {
        per_num = per_target_num;
        remain_minute_num = 0;
      }
      per_num = Math.round(per_num);
    }

    user_name = desku_info["a_user_name"];
    if (per_num != 0 && per_target_num != 0) {
      percent_num = (per_num / per_target_num) * 100;
      percent_num = Math.round(strFunc.uncomma(percent_num));
    }
    if (percent_num > 80) {
      desk_back_class_name += " " + Style.is_ending;
    }
    desk_per_height = percent_num + "%";
    time_period = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
    time_period += " ~ " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]);

  }

  let top_sub_text=(
    <span title={time_period}>
      <span>좌석 {desk_num_str}.</span>
      <span className="ml-2">
        <span style={{color:"#2eb62c"}}>{remain_minute_num}</span>
        <span className="ml-1">분</span>
      </span>
    </span>
  );

  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_back}></div>
      <div className={Style.card_box_back_white}></div>
      <div className={Style.card_box_con}>
        <TopInfoArea
          info={stu_info}
          Style={Style}
          td_sort_data_arr={td_sort_data_arr}
          sub_title_text={top_sub_text}
          openTodoDaySort={myProps.openTodoDaySort}
          show_detail_user_by_seq={myProps.show_detail_user_by_seq}
          on_click_sub_title={() => {
            myProps.open_view_desk_popup({
              room_info: room_info,
              desk_info: desk_info,
              desku_info: desku_info,
              now_ymdhis: myProps.now_ymdhis,
            });
          }}
        ></TopInfoArea>
        <MidTodoArea
          info={stu_info}
          Style={Style}
          td_sort_data_arr={td_sort_data_arr}
          base_date_str={base_date_str}
          pre_date_str={pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
        ></MidTodoArea>
      </div>
    </div>
  );
};
export default StuCardBox;