import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import search_ico2 from "@/img/ico/search2.png";
import { useState, useRef, useEffect } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import strFunc from "@/lib/lyg/string";
import StuListSelectArea from "./stu_list_select";
import DiscorverListArea from "./discover_list";

function StuSearchArea(props: any) {
  let myProps = {
    list_opt: {},
    set_list_opt: (inData: any) => { },
    list: (inData: any) => { },
    infoArr: [],
    select_stu_info: null,
    set_select_stu_info: (inData: any) => { },
    is_search_stu_ing: false,
    discover_map_arr: [],
    go_write_page: (inData: any) => { },
    select_discover_seq: "",
    set_select_discover_seq: (inData: any) => { },
    ...props
  };
  let list_opt = myProps.list_opt;

  const [popupData, setPopupData] = useState({
      isOpen: false,
      sort: "find",//view
      isUpdate: false,
      rowData: {},
      has_header: true,
      title: "팝업",
      width: "800px",
      height: "80%",
    });

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt = {
      now_page: 1,
      [key]: date_str
    };
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };

  const go_change_date_by_sort = (inData: any) => {
    let opt_obj = {
      sort: "empty",
      ...inData
    };
    let sort = opt_obj.sort;
    let change_list_opt: any = {
      ...list_opt,
      now_page: 1,
      s_addon_discover_map_start_date: "",
      s_addon_discover_map_end_date: "",
    };
    if (sort == "today") {
      let today_date = DateFunc.get_date_format(new Date(), "Y-m-d");
      change_list_opt.s_addon_discover_map_start_date = today_date;
      change_list_opt.s_addon_discover_map_end_date = today_date;
    } else if (sort == "month") {
      change_list_opt.s_addon_discover_map_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      change_list_opt.s_addon_discover_map_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      change_list_opt.s_addon_discover_map_start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01");
      change_list_opt.s_addon_discover_map_end_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-t");
    }
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };
  const go_change_date_by_pre_after=(inData:any)=>{
    let opt_obj={
      "pre_after":"",//pre,after
      "sort":"month",//day,month
      ...inData
    };
    let select_date_str=list_opt.s_addon_discover_map_start_date;
    if(strFunc.is_empty(select_date_str)){
      select_date_str=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    if(opt_obj.sort!="day"){
      select_date_str=DateFunc.get_date_format(new Date(select_date_str),"Y-m-01");
    }

    let plus_minus_num=1;
    if(opt_obj["pre_after"]=="pre"){
      plus_minus_num=-1;
    }
    let start_date_str="";
    let end_date_str="";
    if(opt_obj.sort=="day"){
      start_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str),opt_obj["sort"],plus_minus_num),"Y-m-d");
      end_date_str=start_date_str;
    }else{
      start_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str),opt_obj["sort"],plus_minus_num),"Y-m-01");
      end_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str),opt_obj["sort"],plus_minus_num),"Y-m-t");
    }

    let change_list_opt: any = {
      ...list_opt,
      now_page: 1,
      s_addon_discover_map_start_date: start_date_str,
      s_addon_discover_map_end_date: end_date_str,
    };
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let change_list_opt = { ...list_opt };

    change_list_opt[name] = value;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.set_list_opt(change_list_opt);
    myProps.list({ ...change_list_opt, now_page: 1 });
  };

  const open_find_stu_popup = () => {
    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find",
      width: "800px",
      height: "80%",
    });
  };
  const callBackDataStuPopup = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData,
    };
    if (optObj["info_arr"].length > 0) {
      let select_stu_info = myProps.set_select_stu_info(optObj["info_arr"][0]);
    }
  };

  return (
    <div className="basic_search_wrap">
      <div className="text-center">
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={list_opt.s_addon_discover_map_start_date != "" ? new Date(list_opt.s_addon_discover_map_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_discover_map_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={list_opt.s_addon_discover_map_end_date != "" ? new Date(list_opt.s_addon_discover_map_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_discover_map_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_sort({ sort: "today" }); }}>오늘</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_sort({ sort: "month" }); }}>이번달</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_sort({ sort: "pre_month" }); }}>지난달</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "pre",sort:"month" }); }}>◀</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "pre",sort:"day" }); }}>◁</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "after",sort:"day" }); }}>▷</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "after",sort:"month" }); }}>▶</button>
        {/* <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_sort({}); }}>전체</button> */}
      </div>
      <div className="basic_search_title_bar mt-2" style={{ textAlign: "center" }}>
        학생검색
        <div className="basic_search_item_input_wrap ml-2" style={{ display: "inline-block" }} >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={list_opt.s_user_name_like} onChange={handleInputChange} placeholder="이름" autoComplete="off"
            style={{ padding: "0px 4px", lineHeight: "30px" }} />
        </div>
        <div className="basic_search_item_input_wrap ml-1" style={{ display: "inline-block" }}>
          <button className="btn-l btn-yellowish2" onClick={() => { open_find_stu_popup(); }}
            style={{ padding: "6px", height: "32px" }}>
            <img src={search_ico2} style={{ maxHeight: 25, minWidth: 20 }} />
          </button>
        </div>
        {myProps.select_stu_info &&
          <span className="ml-2">{myProps.select_stu_info["a_user_name"]}</span>
        }
      </div>
      {myProps.is_search_stu_ing &&
        <div className="mt-1">
          <StuListSelectArea
            infoArr={myProps.infoArr}
            select_stu_info={myProps.select_stu_info}
            set_select_stu_info={myProps.set_select_stu_info}
          ></StuListSelectArea>
        </div>
      }
      <div className="mt-1">
        <DiscorverListArea
          discover_map_arr={myProps.discover_map_arr}
          go_write_page={myProps.go_write_page}
          select_discover_seq={myProps.select_discover_seq}
          set_select_discover_seq={myProps.set_select_discover_seq}
        ></DiscorverListArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "find" &&
            <FindMultyStudentPopup
              callBackData={callBackDataStuPopup}
              listOpt={{
                s_addon_discover_map: "1",
                s_addon_discover_map_start_date: list_opt.s_addon_discover_map_start_date,
                s_addon_discover_map_end_date: list_opt.s_addon_discover_map_end_date,
              }}
              is_show_discover_map={true}
              is_multi_select_by_click={false}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default StuSearchArea;