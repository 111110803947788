import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {hmenu,smenu,get_smenu_arr} from "@/pcomponents/comp/layout/menu/data/menu_arr";
import strFunc from '@/lib/lyg/string';

const SNBArea=(props:any)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user=useSelector((state:any) => state.user);

  const movePage=(url:string)=>{
    navigate(url);
  };

  let now_h_key="";
  for(let i=0;i<smenu.length;i++){
    if(smenu[i].url==window.location.pathname){
      now_h_key=smenu[i].hmenu;
      let title_prefix="플랜아이";
      if(user.corp_name!=""){
        title_prefix=user.corp_name;
      }
      document.title =title_prefix+" "+smenu[i].name;
    }
  }

  let comp_sort_detail="plani";
  if (location.hostname == "dalirant.planiedu.com") {
    comp_sort_detail="dalirant";
  }
  let smenu_arr=get_smenu_arr({
    "user_grade":user.user_grade,
    "comp_sort_detail":comp_sort_detail,
  });
  let tmp_except_menu_key_arr:any=[];
  if(user.comp_seq=="19"){
    tmp_except_menu_key_arr.push("desk_main");
  }
  
  const menuArrTags=smenu_arr.map((item,idx)=>{
    if(item.hmenu!=now_h_key){
      return "";
    }
    if(!item.isShow){
      return "";
    }
    if(strFunc.str_in_array(item.key,tmp_except_menu_key_arr)!=-1){
      return "";
    }

    let snb_class_name="snb_item";
    if(item.url==window.location.pathname){
      snb_class_name+=" active";
    }
    
    return (
      <div key={idx} className={snb_class_name} onClick={()=>{movePage(item.url);}} >
        <div className="snb_item_text" >
          {item.name}
        </div>
      </div>
    );
  });

  return (
    <div className="snb_area">
      <div className="snb_area_con">
        {menuArrTags}
      </div>
    </div>
  );
};

export default SNBArea;