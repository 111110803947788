import { useState, useRef } from 'react';
import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import PaytSupplyListArea from "./area/list";

function PaytSupplyHistoryListArea(props: any) {
  let myProps = {
    ...props
  };

  const findStudentListRef = useRef<any>(null);
  const ListRef = useRef<any>(null);

  return (
    <div>
      <div className="flex flex-row" style={{ gap: 5 }}>
        <div style={{ minWidth: 230 }} >
          <FindStudentList
            ref={findStudentListRef}
            list={(inData: any) => {
              if (ListRef.current) {
                let s_stu_user_seq: any = [];
                if (inData.s_stu_user_seq) {
                  s_stu_user_seq = inData.s_stu_user_seq;
                }

                ListRef.current.list({
                  "now_page": "1",
                  "s_user_seq": s_stu_user_seq,
                  ...inData
                });
              }
            }}
          ></FindStudentList>
        </div>
        <div style={{ flexGrow: 1, minWidth: 400 }}>
          <PaytSupplyListArea
            ref={ListRef}
            unselect_stu={() => {
              if(findStudentListRef.current){
                findStudentListRef.current.setSelectFalseAll();
              }
            }}
          ></PaytSupplyListArea>
        </div>
      </div>
    </div>
  );
};
export default PaytSupplyHistoryListArea;